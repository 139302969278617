import React, { useState, useEffect, useContext } from "react";

import { AppContext } from "../../Core/store/app-context";
import { Layout } from "../../Core/layout";
import { DataTable, getClickableItemIndex } from "../../Core/common/DataTable";
import { FeatureTypes } from "../../Core/store/app-feature";
import { GetTimezoneOffset } from "../../Core/common/helpers";
import { SidePanelFilter } from "../../Core/common/SidePanelFilter";
import { SidePanelDisplay } from "../../Core/common/SidePanelDisplay";
import { SidePanelPreferences } from "../../Core/common/SidePanelPreferences";

export const TransactionList = (props) => {
    const ctx = useContext(AppContext);

    const [pageSize, setPageSize] = useState(10);
    const [offsetValue, setOffsetValue] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [sortByValue, setSortByValue] = useState("");
    const [orderByValue, setOrderByValue] = useState("");
    const [showFilterDrawer, setShowFilterDrawer] = useState(false);
    const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
    const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

    const [columnList, setColumnList] = useState([]);
    const [recordList, setRecordList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [searchPlaceholder, setSearchPlaceholder] = useState("Search");
    const [listData, setListData] = useState({});
    const [filterData, setFilterData] = useState({ isFilter: false });

    useEffect(() => {
        setOffsetValue(1);
        getRecordList(searchValue, sortByValue, orderByValue, 1);
        if(!filterData.isFilter){
          setShowFilterDrawer(false);
        }
    }, [filterData.isFilter]);

    const getRecordList = async (
        searchBy = searchValue,
        sortBy = sortByValue,
        orderBy = orderByValue,
        offset = offsetValue,
        limit = pageSize
    ) => {
        let queryParams = {
            search: searchBy,
            sortBy: sortBy,
            orderBy: orderBy,
            offset: offset,
            limit: limit,
            utcOffset: GetTimezoneOffset(),
            ...filterData,
        };
        const response = await ctx.HttpGetList("/Transaction/list", queryParams);
        if (response) {
            setRecordList(response.Data);
            setTotalCount(response.Count);
            setColumnList(response.Headers);
            setSearchPlaceholder(response.searchPlaceholder);
        }
    };
    const getItemData = async (id) => {
        const response = await ctx.HttpGet("/Transaction", { id: id });
        if (response) {
            setListData(response);
            setShowDisplayDrawer(true);
        }
    };

    // add click event if user have view access
    if (ctx.acl(FeatureTypes.Transactions, 'GetTransaction') && columnList?.length > 1) {
        columnList[getClickableItemIndex(columnList)]["onClick"] = (record) => {
            getItemData(record.Id);
        };
    }


    return (
        <>
            <Layout
                page="Transaction"
                id={FeatureTypes.Transactions}
                searchOnModule={FeatureTypes.Transactions}
                searchPlaceholder={searchPlaceholder}
                onSearchChange={(value) => {
                    setSearchValue(value);
                    setOffsetValue(1);
                    getRecordList(value, null, null, 1);
                }}
                filterApplied={filterData.isFilter}
                onFilterClick={() => {
                    setShowFilterDrawer(true);
                }}
            >
                <DataTable
                    dataSource={recordList}
                    columns={columnList}
                    totalRecords={totalCount}
                    pageSize={pageSize}
                    currentPage={offsetValue}
                    rowKey={"Id"}
                    onUserPreferenceClick={() => {
                      setShowPreferenceDrawer(true);
                    }}
                    onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
                        let sortOrderValue = "";
                        if (sorterOptions && sorterOptions.order) {
                            sortOrderValue =
                                sorterOptions.order === "ascend" ? "ASC" : "DESC";
                            setOrderByValue(sortOrderValue);
                            setSortByValue(sorterOptions.columnKey);
                        }
                        setPageSize(pageSizeOptions.pageSize);
                        setOffsetValue(pageSizeOptions.current);
                        getRecordList(
                            searchValue,
                            sorterOptions.columnKey,
                            sortOrderValue,
                            pageSizeOptions.current,
                            pageSizeOptions.pageSize
                        );
                    }}
                />
            </Layout>

            <SidePanelFilter
                moduleRoute={"/Transaction"}
                filterData={filterData}
                setFilterData={setFilterData}
                panelIcon={"transaction"}
                panelVisible={showFilterDrawer}
                onCancelClick={() => {
                    setShowFilterDrawer(false);
                }}
                onChangeClick={() => {
                    setShowFilterDrawer(false);
                    if(filterData.isFilter){
                        setOffsetValue(1);
                      getRecordList(searchValue, sortByValue, orderByValue, 1);
                    }
                }}
            />

            <SidePanelDisplay
                listData={listData}
                headerData={columnList}
                panelIcon={"transaction"}
                panelTitle={"Transaction Details"}
                panelVisible={showDisplayDrawer}
                onCancelClick={() => {
                    setShowDisplayDrawer(false);
                    setListData({});
                }}
            />

            <SidePanelPreferences
                module={"/Transaction/list"}
                panelIcon={"transaction"}
                panelVisible={showPreferenceDrawer}
                onCancelClick={() => {
                    setShowPreferenceDrawer(false);
                }}
                onChangeClick={() => {
                    setShowPreferenceDrawer(false);
                    getRecordList(searchValue, sortByValue, orderByValue, offsetValue);
                }}
            />

        </>
    );
};
