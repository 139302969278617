import React, { useState, useEffect, useContext } from "react";

import { AppContext } from "../../Core/store/app-context";
import { ItemDetail } from "../../Core/common/ItemDetail";

export const SystemDisplay = (props) => {

    const ctx = useContext(AppContext);

    const {
        itemId
    } = props;

    const [listData, setListData] = useState({});

    useEffect(() => {
        if (itemId) {
            getItemData();
        }
        else {
            setListData({
                id: "",
                name: "",
                type: null,
                status: 0,
            });
        }
    }, [itemId]);

    const getItemData = async (id) => {
        const response = await ctx.HttpGet("/System", { id: itemId });
        if (response) {
            setListData(response);
        }
    };


    const getDisplayRenderer = () => {

        switch (listData?.type) {
            case 1:
            case 2:
            case 10:
            case 13:
            case 14:
                return (
                    <ItemDetail label="Connection String" itemValue={listData?.connectString} />
                );

            case 11:
                return (
                    <>
                        <ItemDetail label="Host Name" itemValue={listData?.hostName} />
                        <ItemDetail label="Port" itemValue={listData?.port} />
                        <ItemDetail label="Use TLS" itemValue={listData?.useTLS ? "true" : "false"} />
                        <ItemDetail label="Queue Name" itemValue={listData?.queueName} />
                        <ItemDetail label="Queue Routing Key" itemValue={listData?.queueRoutingKey} />
                        <ItemDetail label="User ID" itemValue={listData?.userId} />
                        <ItemDetail label="Password" itemValue={listData?.password ? "Secret" : ""} />
                    </>
                );

            case 20:
                return (
                    <>
                        <ItemDetail label="Key Name" itemValue={listData?.keyName} />
                        <ItemDetail label="FIPS Compliant" itemValue={listData?.useTLS ? "true" : "false"} />
                        <ItemDetail label="Shared Key" itemValue={listData?.sharedKey} />
                        <ItemDetail label="Host Name" itemValue={listData?.hostName} />
                        <ItemDetail label="Service" itemValue={listData?.serviceId} />
                    </>
                );

            case 21:
                return (
                    <>
                        <ItemDetail label="Name/IP White List" itemValue={listData?.ipWhiteList} />
                        <ItemDetail label="Service" itemValue={listData?.serviceId} />
                    </>
                );

            case 22:
                return (
                    <>
                        <ItemDetail label="OpenId Type" itemValue={listData?.openIdType} />
                        <ItemDetail label="Token Url" itemValue={listData?.tokenUrl} />
                        <ItemDetail label="Issuer Url" itemValue={listData?.issuerUrl} />
                        <ItemDetail label="Grant Type" itemValue={listData?.grantType} />
                        <ItemDetail label="Scope" itemValue={listData?.scope} />
                        <ItemDetail label="Client ID" itemValue={listData?.client_id} />
                        <ItemDetail label="Client Secret" itemValue={listData?.client_secret} />
                        <ItemDetail label="Audience" itemValue={listData?.audience} />
                        <ItemDetail label="Redirect Url" itemValue={listData?.redirect_url} />
                        <ItemDetail label="User ID" itemValue={listData?.userId} />
                        <ItemDetail label="Password" itemValue={listData?.password ? "Secret" : ""} />
                    </>
                );

            case 23:
                return (
                    <>
                        <ItemDetail label="Host Name" itemValue={listData?.hostName} />
                        <ItemDetail label="User ID" itemValue={listData?.userId} />
                        <ItemDetail label="Password" itemValue={listData?.password ? "Secret" : ""} />
                    </>
                );

            case 30:
                return (
                    <>
                        <ItemDetail label="Host Name" itemValue={listData?.hostName} />
                        <ItemDetail label="Port" itemValue={listData?.port} />
                        <ItemDetail label="Use TLS" itemValue={listData?.useTLS ? "true" : "false"} />
                        <ItemDetail label="From Email" itemValue={listData?.fromEmail} />
                        <ItemDetail label="User ID" itemValue={listData?.userId} />
                        <ItemDetail label="Password" itemValue={listData?.password ? "Secret" : ""} />
                    </>
                );

            case 31:
                return (
                    <>
                        <ItemDetail label="Host Name" itemValue={listData?.hostName} />
                        <ItemDetail label="Port" itemValue={listData?.port} />
                        <ItemDetail label="Use Certificate" itemValue={listData?.useTLS ? "true" : "false"} />
                        <ItemDetail label="User ID" itemValue={listData?.userId} />
                        <ItemDetail label="Password" itemValue={listData?.password ? "Secret" : ""} />
                    </>
                );

            case 100:
                return (
                    <>
                        <ItemDetail label="Host Name" itemValue={listData?.hostName} />
                        <ItemDetail label="Port" itemValue={listData?.port} />
                        <ItemDetail label="User ID" itemValue={listData?.userId} />
                        <ItemDetail label="Password" itemValue={listData?.password ? "Secret" : ""} />
                    </>
                );

            case 101:
                return (
                    <>
                        <ItemDetail label="Host Name" itemValue={listData?.hostName} />
                        <ItemDetail label="Gateway Name" itemValue={listData?.gatewayName} />
                        <ItemDetail label="Client" itemValue={listData?.client} />
                        <ItemDetail label="System Number" itemValue={listData?.systemNo} />
                        <ItemDetail label="User ID" itemValue={listData?.userId} />
                        <ItemDetail label="Password" itemValue={listData?.password ? "Secret" : ""} />
                    </>
                );

            case 102:
                return (
                    <>
                        <ItemDetail label="Host Name" itemValue={listData?.hostName} />
                        <ItemDetail label="Sync Page Size" itemValue={listData?.syncPageSize} />
                        <ItemDetail label="Use Certificate" itemValue={listData?.useTLS ? "true" : "false"} />
                        <ItemDetail label="User ID" itemValue={listData?.userId} />
                        <ItemDetail label="Password" itemValue={listData?.password ? "Secret" : ""} />
                    </>
                );

            case 200:
                return (
                    <>
                        <ItemDetail label="Authentication Type" itemValue={listData?.openIdType} />
                        <ItemDetail label="Token Url" itemValue={listData?.tokenUrl} />
                        <ItemDetail label="Issuer Url" itemValue={listData?.issuerUrl} />
                        <ItemDetail label="Grant Type" itemValue={listData?.grantType} />
                        <ItemDetail label="Scope" itemValue={listData?.scope} />
                        <ItemDetail label="Client ID" itemValue={listData?.client_id} />
                        <ItemDetail label="Client Secret" itemValue={listData?.client_secret} />
                        <ItemDetail label="Audience" itemValue={listData?.audience} />
                        <ItemDetail label="Redirect Url" itemValue={listData?.redirect_url} />
                        <ItemDetail label="API Url" itemValue={listData?.apiUrl} />
                        <ItemDetail label="Resource Url" itemValue={listData?.resourceUrl} />
                        <ItemDetail label="Report ID" itemValue={listData?.reportId} />
                        <ItemDetail label="WorkSpace ID" itemValue={listData?.workspaceId} />
                        <ItemDetail label="User ID" itemValue={listData?.userId} />
                        <ItemDetail label="Password" itemValue={listData?.password ? "Secret" : ""} />
                    </>
                );
        }
        return "";
    };

    return (
        <div className={"panel-content custom-scrollbar px-7"}>
            <ItemDetail label="System Name" itemValue={listData?.name} />
            <ItemDetail label="System Type" itemValue={listData?.typeName} />
            {getDisplayRenderer()}
            <ItemDetail itemValue={listData?.status} itemType="status" />
        </div>
    );
};
