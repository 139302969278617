import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Layout } from "../../../Core/layout";
import { AppContext } from "../../../Core/store/app-context";
import { FeatureTypes } from "../../../Core/store/app-feature";
import { DataTable, getClickableItemIndex } from "../../../Core/common/DataTable";
import { SidePanelPreferences } from "../../../Core/common/SidePanelPreferences";


export const HardwareList = (props) => {
    const history = useHistory();
    const ctx = useContext(AppContext);
    const { zoneId } = useParams();

    const [pageSize, setPageSize] = useState(10);
    const [offsetValue, setOffsetValue] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [sortByValue, setSortByValue] = useState("");
    const [orderByValue, setOrderByValue] = useState("");
    const [showUpdateDrawer, setShowUpdateDrawer] = useState(false);
    const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
    const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

    const [columnList, setColumnList] = useState([]);
    const [recordList, setRecordList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [searchPlaceholder, setSearchPlaceholder] = useState("Search");
    const [filterTrigger, setFilterTrigger] = useState(null);

    const [hardwareId, setHardwareId] = useState(null);
    const [selectedItemName, setSelectedItemName] = useState("");

    // Consent
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        getRecordList();
    }, [searchValue]);

    const getRecordList = async (
        searchBy = searchValue,
        sortBy = sortByValue,
        orderBy = orderByValue,
        offset = offsetValue,
        limit = pageSize
    ) => {
        let queryParams = {
            search: searchBy,
            sortBy: sortBy,
            orderBy: orderBy,
            offset: offset,
            limit: limit,
            zoneID: zoneId
        };
        const response = await ctx.HttpGetList("/Location/reader/list", queryParams);
        if (response) {
            setRecordList(response.Data);
            setTotalCount(response.Count);
            setColumnList(response.Headers);
            setSearchPlaceholder(response.searchPlaceholder);
        }
    };

    // add click event if user have view access
    if (ctx.acl(FeatureTypes.Locations, 'GetZone') && columnList?.length > 1) {
        columnList[getClickableItemIndex(columnList)]['onClick'] = (record) => {
            setHardwareId(record.id);
            setShowDisplayDrawer(true);
        }
    }

    const getActionButtonItems = (listItem = null) => {
        let actionButtonItems = ["Update", "Remove"];
    }

    const handleOptionMenuItemClick = (clickedItem) => {
        setShowDisplayDrawer(false);
        if (clickedItem === "Update") {
            setShowUpdateDrawer(true);
        }
        else if (clickedItem === "Remove") {
            setShowDeleteModal(true);
        }
    };

    return (
        <>
            <Layout page="hardwares"
                id={FeatureTypes.Hardwares}
                title="Hardware"
                subTitle="Manage Hardware here"
                pageTitleButton={false}
                buttonOnClick={() => {
                    // TODO
                }}
                buttonLabel="Add Hardware"
                searchOnModule={FeatureTypes.Hardwares}
                searchPlaceholder={searchPlaceholder}
                onSearchChange={(value) => {
                    setSearchValue(value);
                    setOffsetValue(1);
                    getRecordList(value, null, null, 1);
                }}
                showPageActionButton={true}
                pageActionButtonLabel="Back"
                pageActionButtonIcon="/images/icons/Previous-icon.svg"
                pageActionButtonOnClick={() => {
                    history.goBack();
                }}
                showFilter={false}
            >

                <DataTable
                    showActionButton={true}
                    onActionButtonClick={(record) => {
                        setHardwareId(record.id);
                        setSelectedItemName(record.name.toString().trim());
                    }}
                    getActionButtonItems={getActionButtonItems}
                    actionButtonItemClick={(clickedItem) => {
                        handleOptionMenuItemClick(clickedItem);
                    }}
                    dataSource={recordList}
                    columns={columnList}
                    totalRecords={totalCount}
                    pageSize={pageSize}
                    currentPage={offsetValue}
                    rowKey={"id"}
                    onUserPreferenceClick={() => {
                        setShowPreferenceDrawer(true);
                    }}
                    onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
                        let sortOrderValue = "";
                        if (sorterOptions && sorterOptions.order) {
                            sortOrderValue = sorterOptions.order === "ascend" ? "ASC" : "DESC";
                            setOrderByValue(sortOrderValue);
                            setSortByValue(sorterOptions.columnKey);
                        }
                        setPageSize(pageSizeOptions.pageSize);
                        setOffsetValue(pageSizeOptions.current);
                        getRecordList(
                            searchValue,
                            sorterOptions.columnKey,
                            sortOrderValue,
                            pageSizeOptions.current,
                            pageSizeOptions.pageSize
                        );
                    }}
                />
            </Layout>

            <SidePanelPreferences
                module={"/Location/hardware/list"}
                panelIcon={"devices"}
                panelVisible={showPreferenceDrawer}
                onCancelClick={() => {
                    setShowPreferenceDrawer(false);
                }}
                onChangeClick={() => {
                    setShowPreferenceDrawer(false);
                    getRecordList(searchValue, sortByValue, orderByValue, offsetValue);
                }}
            />
        </>
    );
};