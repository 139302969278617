import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Button } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { CustomModal } from "../common/CustomModal";

import { AppContext } from '../store/app-context';

export const Login = (props) => {

    const context = useContext(AppContext);
    const history = useHistory();

    //state vars
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');

    const [welcomeText, setWelcomeText] = useState("Login to Catamaran");
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    //default img set from localstorage. useEffect will handle if doesn't exist/null
    const [backgroundImage, setBackgroundImage] = useState(localStorage.getItem('bckgrndImg'));

    //Consent
    const [userCancel, setUserCancel] = useState(false);
    const [showConsent, setShowConsent] = useState(false);
    const [consentText, setConsentText] = useState("");
    const [consentTitle, setConsentTitle] = useState("USG Warning and Consent Banner");

    const [showLoginPage, setShowLoginPage] = useState(false);

    let hideUserForm = false;
    // add other open id type in below array to hide user form
    if([context.IssuerTypes.AZURE].includes(context.authInfo?.OpenIdType?.toUpperCase())){
        hideUserForm = true;
    }


    useEffect(() => {
        //if localstorage doesn't have image and ctx img exists, then use ctx img 
        if (!backgroundImage && context?.pageInfo?.LoginImage) {
            setBackgroundImage(context?.pageInfo?.LoginImage)
        }
    }, [])

    useEffect(() => {
        fetchData();
    }, [context.pageInfo]);

    const fetchData = async () => {

        if (context.isLogout) {

            return;
        }

        else if (context.pageInfo === null) {

            await context.AppInfo(history);
        }

        else if (context.userInfo === null && userCancel === false) {

            setShowLoginPage(false);
            if (!backgroundImage && context.pageInfo?.LoginImage) {
                setBackgroundImage(`data:image/png;base64,${context.pageInfo.LoginImage}`)
            }

            if (context.pageInfo?.WelcomeText)
                setWelcomeText(context.pageInfo.WelcomeText);

            if (context.pageInfo?.SecurityText) {
                if (showConsent === false) {
                    setConsentText(context.pageInfo.SecurityText);
                    if (context.pageInfo.SecurityTitle)
                        setConsentTitle(context.pageInfo.SecurityTitle);
                    setShowConsent(true);
                }
            }
            else {
                await doLogin();
            }
        }
    };

    const doLogin = async () => {

        setShowConsent(false);

        if (context.authInfo) {

            setTimeout(() => {
                setShowLoginPage(true);
            }, 500);
            return;
        } else if (!context.pageInfo?.SecurityText) {
            const result = await context.AppLogin();
            if (result)
                props.history.push("/home");
            return;
        }
    };

    const closeConsent = () => {

        setUserCancel(true);
        setShowConsent(false);
    };


    const onChange = (event) => {

        if (event?.target?.name === "userId") {
            setUserId(event?.target?.value);
        }
        else if (event?.target?.name === "password") {
            setPassword(event?.target?.value)
        }
    };

    const onSubmit = async (event) => {

        event.preventDefault();
        try {
            if (!hideUserForm) {
                if (!userId) {
                    throw new Error('Please Enter User Id');
                }
                else if (!password) {
                    throw new Error('Please Enter Password');
                }
            }

            let result = await context.AuthLogin(userId, password);
            if (result)
                props.history.push("/home");
        }
        catch (e) {
            context.showToastAlert({ type: 'error', message: e.message });
        }

    };


    return (
        <>
            <div style={{
                opacity: showLoginPage ? '0' : '1',
                height: showLoginPage ? '0' : 'auto',
                transition: '500ms opacity'
            }}>
                <Row style={{ height: "100vh" }}>

                    <Col style={{ width: "100%", height: "100%" }}>
                        <img
                            src={backgroundImage || null}
                            layout="fill"
                            style={{ objectFit: "cover", height: "100%", width: "100%" }}
                            alt=""
                        />
                    </Col>

                </Row>
                <CustomModal
                    showModal={showConsent}
                    titleText={consentTitle}
                    messageText={consentText}
                    showCancelButton={true}
                    handleCancel={closeConsent}
                    confirmButtonText={`Continue`}
                    handleConfirm={doLogin}
                />
            </div>

            <div className={`bg-white ${showLoginPage ? 'h-full' : ''}`}
                style={{
                    opacity: showLoginPage ? '1' : '0',
                    height: showLoginPage ? 'auto' : '0',
                    transition: '500ms opacity'
                }}
            >
                {showLoginPage ?
                    <Row className="h-full manual-login overflow-hidden">
                        <Col span={12}>
                            {backgroundImage ?
                                <img
                                    src={backgroundImage}
                                    layout="fill"
                                    style={{ objectFit: "cover", height: "100%", width: "100%" }}
                                    alt=""
                                /> :
                                <div
                                    className="flex justify-center h-full bg-cover bg-center h-full"
                                    style={{ backgroundColor: '#e1e1e1' }}
                                />}

                        </Col>

                        <Col span={12} className="my-auto">
                            <div className="h-full bg-white">
                                <Row type="flex">
                                    <Col span={8} />
                                    <Col span={8}>
                                        <div className="">
                                            <div className="flex justify-center">
                                                <img
                                                    alt="Logo"
                                                    src="/images/Shipcom_Wireless Logo_Black.svg"
                                                    className="w-48 h-16"
                                                />
                                            </div>
                                            <div className="font-poppins pt-4 pb-1 text-center text-3xl font-semibold leading-normal text-pickled-bluewood">
                                                Welcome
                                            </div>
                                        </div>
                                    </Col>

                                    <Col span={8} />
                                    <Col span={6} />

                                    <Col span={12}>
                                        <div className="font-poppins pb-8 text-sm text-center leading-normal text-regent-gray">
                                            {welcomeText}
                                        </div>
                                    </Col>

                                    <Col span={6} />
                                    <Col span={6} />

                                    <Col span={12}>
                                        <div>
                                            {
                                                !hideUserForm ? (
                                                    <>
                                                        <div className="relative">
                                                            <div
                                                                className="absolute font-poppins text-xs font-medium leading-tight bg-white text-regent-gray"
                                                                style={{ top: '-9px', left: '9px', padding: '2px' }}
                                                            >
                                                                User ID
                                                            </div>
                                                            <input
                                                                onKeyDown={(event) => event?.key === "Enter" ? onSubmit(event) : null}
                                                                value={userId}
                                                                name="userId"
                                                                onChange={(event) => onChange(event)}
                                                                className="input-with-label-in-textbox text-pickled-bluewood font-poppins p-3 text-sm font-medium leading-tight bg-white w-full"
                                                            />
                                                        </div>
                                                        <div className="relative my-6">
                                                            <div
                                                                className="absolute font-poppins text-xs font-medium leading-tight bg-white text-regent-gray"
                                                                style={{ top: '-9px', left: '9px', padding: '2px' }}
                                                            >
                                                                Password
                                                            </div>
                                                            <input
                                                                onKeyDown={(event) => event?.key === "Enter" ? onSubmit(event) : null}
                                                                type={isPasswordVisible ? 'text' : 'password'}
                                                                value={password}
                                                                name="password"
                                                                onChange={(event) => onChange(event)}
                                                                className="input-with-label-in-textbox font-poppins p-3 text-sm font-medium leading-tight bg-white w-full text-pickled-bluewood"
                                                            />
                                                            <div
                                                            className="absolute icon-btn"
                                                            style={{
                                                              right: 0, top: 0, bottom: 0, padding: '2px',
                                                            }}
                                                            >
                                                              <Button
                                                                onClick={()=>{setIsPasswordVisible(!isPasswordVisible)}}
                                                                className="border-0 shadow-none"
                                                                style={{ backgroundColor: 'transparent',top: '50%',transform: 'translateY(-50%)',padding:0, lineHeight:0 }}
                                                                icon={
                                                                  isPasswordVisible ? (
                                                                    <EyeOutlined />
                                                                  ) : (
                                                                    <EyeInvisibleOutlined />
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                        </div>
                                                    </>
                                                    ) : null
                                                }
                                            <div className="text-center mt-4">
                                                <Button
                                                    onClick={onSubmit}
                                                    size="large"
                                                    className="font-poppins rounded text-sm text-white leading-normal font-semibold w-full bg-daisy-bush"
                                                >
                                                    Login
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={6} />
                                </Row>
                            </div>
                        </Col>
                        <small id="app-version" className="text-regent-gray">Version: {context.appVersion}</small>
                    </Row>
                    : ''}
            </div>
        </>
    );
}
