import React, { useContext } from "react";
import { Layout } from "../layout";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { AppContext } from "../store/app-context";

export const Home = (props) => {
  const ctx = useContext(AppContext);

  return (
    <Layout page="home" title="Home" mainContentClassName="my-4" showSearch={false} showFilter={false} showPageActionButton={false}>
      {/* breadcrum */}
      <Row className="pb-2" gutter={[16, 16]} style={{marginTop: "-1.25rem"}}>
        {ctx?.userInfo?.UserFeatures
          ? ctx?.userInfo?.UserFeatures.map((menuItem, index) => {
              return (
                  menuItem?.submenu.length
                    ? menuItem?.submenu.map((item) => {
                        return (
                          <Col span={6} key={item.name}>
                            <Link to={item?.url}>
                              <div className="bg-white board-card board-shadow cursor-pointer flex flex-col justify-center p-4 py-12 rounded-lg shadow-md">
                                <div className="h-6">
                                  <img
                                    className="m-auto"
                                    src={`/images/icons/api-${item.icon}-home.svg`}
                                    alt={`${item.name} Icon`}
                                  />
                                </div>
                                <div className="mt-1 font-poppins text-butterfly-bush text-center text-h2 pt-1">
                                  {item.name}
                                </div>
                              </div>
                            </Link>
                          </Col>
                        );
                      })
                    : <Col span={6} key={menuItem.name}>
                    <Link to={menuItem?.url}>
                      <div className="bg-white board-card board-shadow cursor-pointer flex flex-col justify-center p-4 py-12 rounded-lg shadow-md">
                        <div className="h-6">
                          <img
                            className="m-auto"
                            src={`/images/icons/api-${menuItem.icon}-home.svg`}
                            alt={`${menuItem.name} Icon`}
                          />
                        </div>
                        <div className="mt-1 font-poppins text-butterfly-bush text-center text-h2 pt-1">
                          {menuItem.name}
                        </div>
                      </div>
                    </Link>
                  </Col>
              );
            })
          : null}
      </Row>
    </Layout>
  );
};
