import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "antd";

import { AppContext } from "../../Core/store/app-context";

import { ItemDetail } from "../../Core/common/ItemDetail";

export const RuleDisplay= (props) => {
  const ctx = useContext(AppContext);
  const { itemId } = props;

  const [listData, setListData] = useState(null);

  useEffect(() => {
    if (itemId) {
      getItemData();
    }
  }, [itemId]);

  const getItemData = async () => {
    const response = await ctx.HttpGet("/Rule", { id: itemId });
    if (response) {
      setListData(response);
    }
  };

  const getRuleConfigurationData = (record) => {
    if (record?.TypeId === 908) {
      return (
        <div>
          {record?.MinBatteryLevel !== null ? (
            <Row>
              <Col span={18}>Minimum battery level</Col>
              <Col span={6} className="text-right">
                {record?.MinBatteryLevel}
              </Col>
            </Row>
          ) : (
            "-"
          )}
        </div>
      );
    } else if (record?.TypeId === 909 || record?.TypeId === 810) {
      return (
        <div>
          {record?.NotReportedMins !== null ? (
            <Row>
              <Col span={20}>
                {record?.TypeId === 810 ? "Missing duration" : "Not reported"}
              </Col>
              <Col span={4} className="text-right">
                {record?.NotReportedMins} mins
              </Col>
            </Row>
          ) : (
            "-"
          )}
        </div>
      );
    } else if (record?.TypeId > 1 && record?.TypeId < 100) {
      return (
        <>
          <div>
            {record?.AlarmMin !== null ? (
              <Row>
                <Col span={20}>Alarm Minimum</Col>
                <Col span={4} className="text-right">
                  {record?.AlarmMin}
                </Col>
              </Row>
            ) : (
              ""
            )}
          </div>
          <div>
            {record?.WarningMin !== null ? (
              <Row>
                <Col span={20}>Warning Minimum</Col>
                <Col span={4} className="text-right">
                  {record?.WarningMin}
                </Col>
              </Row>
            ) : (
              ""
            )}
          </div>
          <div>
            {record?.WarningMax !== null ? (
              <Row>
                <Col span={20}>Warning Maximum</Col>
                <Col span={4} className="text-right">
                  {record?.WarningMax}
                </Col>
              </Row>
            ) : (
              ""
            )}
          </div>
          <div>
            {record?.AlarmMax !== null ? (
              <Row>
                <Col span={20}>Alarm Maximum</Col>
                <Col span={4} className="text-right">
                  {record?.AlarmMax}
                </Col>
              </Row>
            ) : (
              ""
            )}
          </div>
        </>
      );
    } else if (record?.TypeId === 100 || record?.TypeId === 102) {
      return (
        <div>
          {record?.ToleranceMins !== null ? (
            <Row>
              <Col span={20}>Tolerance</Col>
              <Col span={4} className="text-right">
                {record?.ToleranceMins} mins
              </Col>
            </Row>
          ) : (
            "-"
          )}
        </div>
      );
    } else {
      return <div>-</div>;
    }
  };

  return (
    <div className="overflow-hidden">
      <div className="px-7">
        <ItemDetail label="Name" itemValue={listData?.Name} />
        <ItemDetail label="Description" itemValue={listData?.Description} />
        <ItemDetail label="Category" itemValue={listData?.objectTypeName} />
        <ItemDetail label="Rule Type(Usecase)" itemValue={listData?.typeName} />
        <ItemDetail label="Severity" itemValue={listData?.severityName} />
        <ItemDetail label="Status" itemValue={listData?.StatusName} />
      </div>
      <div className="mb-5 border-t-2 pt-5" style={{ borderColor: "#F3F5F8" }}>
        <div className="px-7">
          {listData?.TypeId > 1 && listData?.TypeId < 100 ? (
            <div
              className="font-medium text-h1 mb-3"
              style={{ color: "#8892A5" }}
            >
              Rule Configuration
            </div>
          ) : (
            ""
          )}
          <div
            className="font-semibold text-h1 break-all"
            style={{ color: "#383A65" }}
          >
            {getRuleConfigurationData(listData)}
          </div>
          {listData?.TypeId <= 1 || listData?.TypeId >= 100 ? (
            <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
              Rule Configuration
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
