import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../../Core/store/app-context";
import { CustomSwitchToggle } from "../../../Core/common/CustomSwitchToggle";
import { SelectDropdown } from "../../../Core/common/SelectDropdown";
import { CustomModal } from "../../../Core/common/CustomModal";
import { useParams } from "react-router-dom";
import { SidePanelFooter } from "../../../Core/common/SidePanelFooter";

export const RuleLocationChange = (props) => {
  const { itemId, locationViewLocationId, getRecordList, hideChangeDrawer } =
    props;
  const ctx = useContext(AppContext);
  const { ruleId } = useParams();

  const defaultListData = {
    ruleId: ruleId,
    typeId: null,
    locationId: null,
    sequence: 999,
    status: 0,
  };

  const [listAddData, setListAddData] = useState(defaultListData);

  const [locationTypeList, setLocationTypeList] = useState([]);
  const [locationList, setLocationList] = useState([]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      await getLocationTypeList();
      if (itemId && locationViewLocationId) {
        await getItemData();
      } else {
        setListAddData({ ...defaultListData });
      }
    }
    fetchData();
  }, [itemId, locationViewLocationId]);

  useEffect(() => {
    if (itemId) {
      getLocationList(itemId);
    }
  }, [listAddData.typeId]);

  const getLocationTypeList = async () => {
    const response = await ctx.HttpGet("/Rule/locationtype");
    setLocationTypeList(response);
  };

  const getLocationList = async (id) => {
    const response = await ctx.HttpGet("/Rule/location/locationtype", {
      type: id,
    });
    setLocationList(response);
  };

  const getItemData = async () => {
    const response = await ctx.HttpGet("/Rule/location", {
      ruleId: ruleId,
      typeId: itemId,
      locationId: locationViewLocationId,
    });
    setListAddData(response);
  };

  const validate = () => {
    if (listAddData.typeId === null) {
      ctx.showToastAlert({
        type: "error",
        message: "Please select location type",
      });
      return false;
    } else if (listAddData.locationId === null) {
      ctx.showToastAlert({ type: "error", message: "Please select location" });
      return false;
    }
    return true;
  };

  const onPrimaryClick = () => {
    if (validate()) {
      if (itemId && locationViewLocationId) setShowUpdateModal(true);
      else setShowAddModal(true);
    }
  };

  const createLocation = async () => {
    setModalButtonLoading(true);

    let payload = { ...listAddData, ruleId: ruleId };

    const response = await ctx.HttpPost("/Rule/location", payload);
    if (response) {
      getRecordList();
      ctx.showToastAlert({ type: "success", message: response });
      hideChangeDrawer();
      setListAddData({ ...defaultListData });
    }
    setShowAddModal(false);
    setModalButtonLoading(false);
  };

  const updateLocation = async () => {
    setModalButtonLoading(true);

    let payload = {
      ...listAddData,
      ruleId: ruleId,
      typeId: itemId,
      locationId: locationViewLocationId,
    };

    const response = await ctx.HttpPut("/Rule/location", payload);
    if (response) {
      getRecordList();
      ctx.showToastAlert({ type: "success", message: response });
      hideChangeDrawer();
      setListAddData({ ...defaultListData });
    }
    setShowUpdateModal(false);
    setModalButtonLoading(false);
  };

  return (
    <>
      <div className="px-7 pt-3">
        <SelectDropdown
          dataList={listAddData.typeId}
          optionsList={locationTypeList}
          isRequired={true}
          mode="single"
          optionKeyName="Id"
          optionValueName="Id"
          optionDisplayName="Value"
          label="location type"
          value={
            listAddData.typeId !== null ? listAddData.typeId.toString() : null
          }
          onChange={(value, key) => {
            console.log(key.key);
            setListAddData({
              ...listAddData,
              typeId: parseInt(value),
              locationId: null,
            });
            getLocationList(key.key);
          }}
          disabled={itemId && locationViewLocationId ? true : false}
        />
        <SelectDropdown
          dataList={listAddData.locationId}
          optionsList={locationList}
          isRequired={true}
          mode="single"
          optionKeyName="Id"
          optionValueName="Id"
          optionDisplayName="Value"
          label="location"
          value={
            listAddData.locationId !== null
              ? itemId && locationViewLocationId
                ? listAddData?.locationName
                : listAddData.locationId
              : listAddData.locationId
          }
          onChange={(value, key) => {
            let locationNameValue;
            locationList.forEach((item) => {
              if (item.Id === value) {
                locationNameValue = item.Value;
              }
            });
            setListAddData({
              ...listAddData,
              locationId: value,
              locationName: locationNameValue,
            });
          }}
          disabled={
            listAddData.typeId !== null
              ? itemId && locationViewLocationId
                ? true
                : false
              : true
          }
        />
        <CustomSwitchToggle
          label="Status"
          switchValue={listAddData?.status}
          onText="Active"
          offText="Inactive"
          onChange={(checked) =>
            setListAddData({
              ...listAddData,
              status: checked ? 1 : 0,
            })
          }
          checked={listAddData.status}
          className="mb-6"
        />
      </div>

      <SidePanelFooter
        secondaryLabel={"Cancel"}
        onCancelClick={() => {
          hideChangeDrawer();
          setListAddData({ ...defaultListData });
        }}
        primaryLabel={"Apply"}
        onPrimaryClick={() => onPrimaryClick()}
      />

      {/* Add Rule Location */}
      <CustomModal
        showModal={showAddModal}
        titleText="Add Location"
        messageText={`Are you sure you want to add ${listAddData?.locationName}?`}
        handleCancel={() => {
          setShowAddModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={createLocation}
        isLoading={modalButtonLoading}
      />

      {/* Update Rule Location */}
      <CustomModal
        showModal={showUpdateModal}
        titleText="Update Location"
        messageText={`Are you sure you want to update the changes made to ${listAddData?.locationName}?`}
        handleCancel={() => {
          setShowUpdateModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={updateLocation}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
