import React, { useState, useEffect, useContext } from "react";

import { Layout } from "../../Core/layout";
import { DataTable } from "../../Core/common/DataTable";
import { AppContext } from "../../Core/store/app-context";
import { FeatureTypes } from "../../Core/store/app-feature";
import { GetTimezoneOffset } from "../../Core/common/helpers";
import { OptionsDropdown } from "../../Core/common/OptionsDropdown";
import { SidePanelChange } from "../../Core/common/SidePanelChange";
import { SidePanelDisplay } from "../../Core/common/SidePanelDisplay";
import { SidePanelPreferences } from "../../Core/common/SidePanelPreferences";

import { TenantChange } from "./TenantChange";

export const TenantList = (props) => {
  const ctx = useContext(AppContext);

  const [pageSize, setPageSize] = useState(10);
  const [offsetValue, setOffsetValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [showUpdateDrawer, setShowUpdateDrawer] = useState(false);
  const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
  const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

  const [columnList, setColumnList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");
  const [listData, setListData] = useState({});

  useEffect(() => {
      async function fetchData() {
          // You can await here
          await getRecordList();
      }
      fetchData();
  }, []);

  const getRecordList = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    offset = offsetValue,
    limit = pageSize
  ) => {
    let queryParams = {
      search: searchBy,
      sortBy: sortBy,
      orderBy: orderBy,
      offset: offset,
      limit: limit,
      utcOffset: GetTimezoneOffset(),
    };
    const response = await ctx.HttpGetList("/Tenant/list", queryParams);
    if (response) {
      setRecordList(response.Data);
      setTotalCount(response.Count);
      setColumnList(response.Headers);
      setSearchPlaceholder(response.searchPlaceholder);
    }
  };

  const getItemData = async (id) => {
    const response = await ctx.HttpGet("/Tenant", { id: id });
    if (response) {
        setListData(response);
    }
  };

  // add click event if user have view access
  if (ctx.acl(FeatureTypes.Tenants, 'GetTenant') && columnList?.length > 1) {
      columnList[0]["onClick"] = (record) => {
          getItemData(record?.Id);
          setShowDisplayDrawer(true);
      }
  }

  const handleOptionMenuItemClick = (clickedItem) => {
    setShowDisplayDrawer(false);
    if (clickedItem === "Update") {
      setShowUpdateDrawer(true);
    }
  };

  // add action items as per role access
  const getActionButtonItems = () => {
    let actionButtonItems = [];
    if (ctx.acl(FeatureTypes.Tenants, 'PutTenant')) {
      actionButtonItems.push('Update');
    }
    return actionButtonItems;
  }

  return (
    <>
      <Layout
        page="tenants"
        id={FeatureTypes.Tenants}
        searchOnModule={FeatureTypes.Tenants}
        searchPlaceholder={searchPlaceholder}
        onSearchChange={(value) => {
          setSearchValue(value);
          getRecordList(value);
        }}
        showFilter={false}
      >
        <DataTable
          showActionButton={getActionButtonItems().length ? true : false}
          getActionButtonItems={getActionButtonItems}
          onActionButtonClick={(record) => {
             setListData(record);
          }}
          actionButtonItemClick={(clickedItem) => {
              handleOptionMenuItemClick(clickedItem);
          }}
          dataSource={recordList}
          columns={columnList}
          totalRecords={totalCount}
          pageSize={pageSize}
          currentPage={offsetValue}
          rowKey={"Id"}
          onUserPreferenceClick={() => {
              setShowPreferenceDrawer(true);
          }}
          onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
            let sortOrderValue = "";
            if (sorterOptions && sorterOptions.order) {
                sortOrderValue =
                sorterOptions.order === "ascend" ? "ASC" : "DESC";
                setOrderByValue(sortOrderValue);
                setSortByValue(sorterOptions.columnKey);
            }
            setPageSize(pageSizeOptions.pageSize);
            setOffsetValue(pageSizeOptions.current);
            getRecordList(
                searchValue,
                sorterOptions.columnKey,
                sortOrderValue,
                pageSizeOptions.current,
                pageSizeOptions.pageSize
            );
          }}
        />
      </Layout>

        <SidePanelChange
            panelIcon={"tenants"}
            panelTitle={"Update Tenant"}
            panelVisible={showUpdateDrawer}
            panelBody={(
                <TenantChange
                    itemId={listData?.Id}
                    getRecordList={getRecordList}
                    hideChangeDrawer={() => {
                        setShowUpdateDrawer(false);
                        setListData({});
                    }}
                />
            )}
        />

        <SidePanelDisplay
            listData={listData}
            headerData={columnList}
            panelIcon={"tenants"}
            panelTitle={"Tenant Details"}
            panelVisible={showDisplayDrawer}
            customAction={(
                <OptionsDropdown
                    actionButtonItems={getActionButtonItems()}
                    actionButtonItemClick={(clickedItem) => {
                        handleOptionMenuItemClick(clickedItem);
                    }}
                />
            )}
            onCancelClick={() => {
                setShowDisplayDrawer(false);
                setListData({});
            }}
        />

        <SidePanelPreferences
            module={"/Tenant/list"}
            panelIcon={"tenants"}
            panelVisible={showPreferenceDrawer}
            onCancelClick={() => {
                setShowPreferenceDrawer(false);
            }}
            onChangeClick={() => {
                setShowPreferenceDrawer(false);
                getRecordList(searchValue, sortByValue, orderByValue, offsetValue);
            }}
        />
    </>
  );
};
