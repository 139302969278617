import React, { useState, useContext, useEffect } from "react";
import { Button, Col, Row } from 'antd';

import { AppContext } from "../../Core/store/app-context";
import { CustomModal } from "../../Core/common/CustomModal";
import { CustomInput } from "../../Core/common/CustomInput";
import { SelectDropdown } from "../../Core/common/SelectDropdown";
import { CustomTextarea } from "../../Core/common/CustomTextarea";
import { CustomSwitchToggle } from "../../Core/common/CustomSwitchToggle";
import { SidePanelFooter } from "../../Core/common/SidePanelFooter";

export const SystemChange = (props) => {

    const ctx = useContext(AppContext);
    const {
        itemId,
        getRecordList,
        hideChangeDrawer,
    } = props;

    const defaultListData = {
        id: "",
        name: "",
        type: null,
        status: 0,
    };

    const [systemTypes, setSystemTypes] = useState([]);
    const [serviceNames, setServiceNames] = useState([]);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [modalButtonLoading, setModalButtonLoading] = useState(false);
    const [listAddData, setListAddData] = useState(defaultListData);

    useEffect(() => {
        async function fetchData() {
            // You can await here
            await getSystemTypes();
            await getServiceNames();
            if (itemId) {
                await getItemData();
            }
        }
        setListAddData(defaultListData);
        fetchData();

    }, [itemId]);

    const getSystemTypes = async () => {
        const response = await ctx.HttpGet("/System/systemtypes", {});
        if (response) {
            setSystemTypes(response);
        }
    };

    const getServiceNames = async () => {
        const response = await ctx.HttpGet("/System/services", {});
        if (response) {
            setServiceNames(response);
        }
    };

    const getItemData = async () => {
        const response = await ctx.HttpGet("/System", { id: itemId });
        if (response) {
            setListAddData(response);
        }
    };

    const getSharedKey = async () => {
        const response = await ctx.HttpGet("/System/sharedkey", { name: listAddData.keyName, isFips: listAddData.useTLS });
        if (response) {
            setListAddData({ ...listAddData, sharedKey: response });
        }
    };

    const validate = () => {
        if (listAddData.name === "" || listAddData.name.trim() === "") {
            ctx.showToastAlert({ type: "error", message: "Please enter name" });
            return false;
        }
        else if (listAddData.type === null) {
            ctx.showToastAlert({ type: "error", message: "Please select system type" });
            return false;
        }
        return true;
    };

    const onPrimaryClick = () => {
        if (validate()) {
            if (itemId)
                setShowUpdateModal(true);
            else
                setShowAddModal(true);
        }
    };

    const addSystemData = async () => {
        setModalButtonLoading(true);

        const response = await ctx.HttpPost("/System", listAddData);
        if (response) {
            getRecordList();
            ctx.showToastAlert({ type: "success", message: response });
            hideChangeDrawer();
            setListAddData(defaultListData);
        }
        setShowAddModal(false);
        setModalButtonLoading(false);
    };

    const updateSystemData = async () => {
        setModalButtonLoading(true);

        const response = await ctx.HttpPut("/System", listAddData);
        if (response) {
            getRecordList();
            ctx.showToastAlert({ type: "success", message: response });
            hideChangeDrawer();
            setListAddData(defaultListData);
        }
        setShowUpdateModal(false);
        setModalButtonLoading(false);
    }
    return (
        <>
            <div className="mb-3 pt-3 px-7 relative custom-scrollbar" style={{
                    height: "calc(100vh - 153px)",
                    overflow: "auto"
            }}>
                <CustomInput
                    label="System Name"
                    isRequired={true}
                    inputValue={listAddData?.name}
                    onChange={(value) => {
                        setListAddData({ ...listAddData, name: value });
                    }}
                />
                <SelectDropdown
                    dataList={listAddData?.type}
                    optionsList={systemTypes}
                    mode="single"
                    optionKeyName="Id"
                    optionValueName="Id"
                    optionDisplayName="Value"
                    label="System Type"
                    isRequired={true}
                    disabled={itemId ? true : false}
                    value={
                        listAddData?.type !== null ? listAddData?.type.toString() : null
                    }
                    onChange={(value, key) => {
                        setListAddData({
                            id: listAddData.id,
                            name: listAddData.name,
                            type: parseInt(value),
                            status: listAddData.status,
                        });
                    }}
                />

                {[1, 2, 10, 13, 14].includes(listAddData?.type) ? (
                    <CustomTextarea
                        label="Connection String"
                        inputValue={listAddData?.connectString}
                        rows="4"
                        onChange={(value) => {
                            setListAddData({ ...listAddData, connectString: value });
                        }}
                    />
                ) : (
                    ""
                )}

                {listAddData?.type === 20 ? (
                    <>
                        <CustomInput
                            label="Key Name"
                            inputValue={listAddData?.keyName}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, keyName: value });
                            }}
                        />
                        <CustomSwitchToggle
                            label="FIPS Compliant"
                            switchValue={listAddData?.useTLS}
                            onChange={(checked) =>
                                setListAddData({
                                    ...listAddData,
                                    useTLS: checked ? 1 : 0,
                                })
                            }
                            checked={listAddData.useTLS}
                            styleName="labelGreyDarkText"
                        />
                        <Row>
                            <Col span={19}>
                                <CustomTextarea
                                    label="Shared Key"
                                    inputValue={listAddData?.sharedKey}
                                    rows="6"
                                    onChange={(value) => {
                                        setListAddData({ ...listAddData, sharedKey: value });
                                    }}
                                />
                            </Col>
                            <Col span={3}>
                                <Button
                                    onClick={getSharedKey}
                                    size="medium"
                                    className="modal-cancel-btn rounded-md h-auto border-2 body-color2 mr-3 shadow-none"
                                    style={{ height: "45px", left: "13px" }}
                                >
                                    Get
                                </Button>
                            </Col>
                        </Row>
                        <CustomInput
                            label="Host Name"
                            inputValue={listAddData?.hostName}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, hostName: value });
                            }}
                        />
                    </>
                ) : (
                    ""
                )}

                {listAddData?.type === 21 ? (
                    <CustomInput
                        label="Name/IP White List"
                        inputValue={listAddData?.ipWhiteList}
                        onChange={(value) => {
                            setListAddData({ ...listAddData, ipWhiteList: value });
                        }}
                    />
                ) : (
                    ""
                )}

                {listAddData?.type === 20 || listAddData?.type === 21 ? (
                    <SelectDropdown
                        dataList={listAddData?.serviceId}
                        optionsList={serviceNames}
                        mode="single"
                        optionKeyName="Id"
                        optionValueName="Id"
                        optionDisplayName="Value"
                        label="Service Name"
                        value={
                            listAddData?.serviceId !== null ? listAddData?.serviceId : null
                        }
                        onChange={(value, key) => {
                            setListAddData({ ...listAddData, serviceId: value });
                        }}
                    />
                ) : (
                    ""
                )}

                {listAddData?.type === 11 ? (
                    <>
                        <CustomInput
                            label="Host Name"
                            inputValue={listAddData?.hostName}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, hostName: value });
                            }}
                        />
                        <CustomInput
                            label="Port"
                            inputValue={parseInt(listAddData?.port) || 0}
                            maxlength={9}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, port: parseInt(value) });
                            }}
                        />
                        <CustomSwitchToggle
                            label="Use TLS"
                            switchValue={listAddData?.useTLS}
                            onChange={(checked) =>
                                setListAddData({
                                    ...listAddData,
                                    useTLS: checked ? 1 : 0,
                                })
                            }
                            checked={listAddData.useTLS}
                            styleName="labelGreyDarkText"
                        />
                        <CustomInput
                            label="Queue Name"
                            inputValue={listAddData?.queueName}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, queueName: value });
                            }}
                        />
                        <CustomInput
                            label="Queue Routing Key"
                            inputValue={listAddData?.queueRoutingKey}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, queueRoutingKey: value });
                            }}
                        />
                        <CustomInput
                            label="User ID"
                            inputValue={listAddData?.userId}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, userId: value });
                            }}
                        />
                        <CustomInput
                            label="Password"
                            type="password"
                            inputValue={listAddData?.password}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, password: value });
                            }}
                        />
                    </>
                ) : (
                    ""
                )}

                {listAddData?.type === 22 ? (
                    <>
                        <CustomInput
                            label="OpenId Type"
                            inputValue={listAddData?.openIdType}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, openIdType: value });
                            }}
                        />
                        <CustomInput
                            label="Token Url"
                            inputValue={listAddData?.tokenUrl}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, tokenUrl: value });
                            }}
                        />
                        <CustomInput
                            label="Issuer Url"
                            inputValue={listAddData?.issuerUrl}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, issuerUrl: value });
                            }}
                        />
                        <CustomInput
                            label="Grant Type"
                            inputValue={listAddData?.grantType}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, grantType: value });
                            }}
                        />
                        <CustomInput
                            label="Scope"
                            inputValue={listAddData?.scope}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, scope: value });
                            }}
                        />
                        <CustomInput
                            label="Client ID"
                            inputValue={listAddData?.client_id}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, client_id: value });
                            }}
                        />
                        <CustomInput
                            label="Client Secret"
                            inputValue={listAddData?.client_secret}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, client_secret: value });
                            }}
                        />
                        <CustomInput
                            label="Audience"
                            inputValue={listAddData?.audience}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, audience: value });
                            }}
                        />
                        <CustomInput
                            label="Redirect Url"
                            inputValue={listAddData?.redirect_url}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, redirect_url: value });
                            }}
                        />
                        <CustomInput
                            label="User ID"
                            inputValue={listAddData?.userId}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, userId: value });
                            }}
                        />
                        <CustomInput
                            label="Password"
                            type="password"
                            inputValue={listAddData?.password}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, password: value });
                            }}
                        />
                    </>
                ) : (
                    ""
                )}

                {listAddData?.type === 23 ? (
                    <>
                        <CustomInput
                            label="Host Name"
                            inputValue={listAddData?.hostName}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, hostName: value });
                            }}
                        />
                        <CustomInput
                            label="User ID"
                            inputValue={listAddData?.userId}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, userId: value });
                            }}
                        />
                        <CustomInput
                            label="Password"
                            type="password"
                            inputValue={listAddData?.password}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, password: value });
                            }}
                        />
                    </>
                ) : (
                    ""
                )}

                {listAddData?.type === 30 ? (
                    <>
                        <CustomInput
                            label="Host Name"
                            inputValue={listAddData?.hostName}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, hostName: value });
                            }}
                        />
                        <CustomInput
                            label="Port"
                            inputValue={parseInt(listAddData?.port) || 0}
                            maxlength={9}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, port: parseInt(value) });
                            }}
                        />
                        <CustomSwitchToggle
                            label="Use TLS"
                            switchValue={listAddData?.useTLS}
                            onChange={(checked) =>
                                setListAddData({
                                    ...listAddData,
                                    useTLS: checked ? 1 : 0,
                                })
                            }
                            checked={listAddData?.useTLS}
                            styleName="labelGreyDarkText"
                        />
                        <CustomInput
                            label="From Email"
                            inputValue={listAddData?.fromEmail}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, fromEmail: value });
                            }}
                        />
                        <CustomInput
                            label="User ID"
                            inputValue={listAddData?.userId}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, userId: value });
                            }}
                        />
                        <CustomInput
                            label="Password"
                            type="password"
                            inputValue={listAddData?.password}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, password: value });
                            }}
                        />
                    </>
                ) : (
                    ""
                )}

                {listAddData?.type === 31 ? (
                    <>
                        <CustomInput
                            label="Host Name"
                            inputValue={listAddData?.hostName}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, hostName: value });
                            }}
                        />
                        <CustomInput
                            label="Port"
                            inputValue={parseInt(listAddData?.port) || 0}
                            maxlength={9}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, port: parseInt(value) });
                            }}
                        />
                        <CustomSwitchToggle
                            label="Use Certificate"
                            switchValue={listAddData?.useTLS}
                            onChange={(checked) =>
                                setListAddData({
                                    ...listAddData,
                                    useTLS: checked ? 1 : 0,
                                })
                            }
                            checked={listAddData.useTLS}
                            styleName="labelGreyDarkText"
                        />
                        <CustomInput
                            label="User ID"
                            inputValue={listAddData?.userId}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, userId: value });
                            }}
                        />
                        <CustomInput
                            label="Password"
                            type="password"
                            inputValue={listAddData?.password}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, password: value });
                            }}
                        />
                    </>
                ) : (
                    ""
                )}

                {listAddData?.type === 100 ? (
                    <>
                        <CustomInput
                            label="Host Name"
                            inputValue={listAddData?.hostName}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, hostName: value });
                            }}
                        />
                        <CustomInput
                            label="Port"
                            inputValue={parseInt(listAddData?.port) || 0}
                            maxlength={9}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, port: parseInt(value) });
                            }}
                        />
                        <CustomInput
                            label="User ID"
                            inputValue={listAddData?.userId}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, userId: value });
                            }}
                        />
                        <CustomInput
                            label="Password"
                            type="password"
                            inputValue={listAddData?.password}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, password: value });
                            }}
                        />
                    </>
                ) : (
                    ""
                )}

                {listAddData?.type === 101 ? (
                    <>
                        <CustomInput
                            label="Host Name"
                            inputValue={listAddData?.hostName}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, hostName: value });
                            }}
                        />
                        <CustomInput
                            label="Gateway Name"
                            inputValue={listAddData?.gatewayName}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, gatewayName: value });
                            }}
                        />
                        <CustomInput
                            label="Client"
                            inputValue={listAddData?.client}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, client: value });
                            }}
                        />
                        <CustomInput
                            label="System Number"
                            inputValue={listAddData?.systemNo}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, systemNo: value });
                            }}
                        />
                        <CustomInput
                            label="User ID"
                            inputValue={listAddData?.userId}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, userId: value });
                            }}
                        />
                        <CustomInput
                            label="Password"
                            type="password"
                            inputValue={listAddData?.password}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, password: value });
                            }}
                        />
                    </>
                ) : (
                    ""
                )}

                {listAddData?.type === 102 ? (
                    <>
                        <CustomInput
                            label="Host Name"
                            inputValue={listAddData?.hostName}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, hostName: value });
                            }}
                        />
                        <CustomInput
                            label="Sync Page Size"
                            inputValue={listAddData?.syncPageSize}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, syncPageSize: value });
                            }}
                        />
                        <CustomSwitchToggle
                            label="Use Certificate"
                            switchValue={listAddData?.useTLS}
                            onChange={(checked) =>
                                setListAddData({
                                    ...listAddData,
                                    useTLS: checked ? 1 : 0,
                                })
                            }
                            checked={listAddData.useTLS}
                            styleName="labelGreyDarkText"
                        />
                        <CustomInput
                            label="User ID"
                            inputValue={listAddData?.userId}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, userId: value });
                            }}
                        />
                        <CustomInput
                            label="Password"
                            type="password"
                            inputValue={listAddData?.password}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, password: value });
                            }}
                        />
                    </>
                ) : (
                    ""
                )}

                {listAddData?.type === 200 ? (
                    <>
                        <SelectDropdown
                            dataList={listAddData?.openIdType}
                            optionsList={[{Id:1, Value: 'ServicePrincipal'},{Id:2, Value: 'MasterUser'},{Id:3, Value: 'User'}]}
                            mode="single"
                            optionKeyName="Id"
                            optionValueName="Value"
                            optionDisplayName="Value"
                            label="Authentication Type"
                            isRequired={true}
                            value={
                                listAddData?.openIdType !== null ? listAddData?.openIdType : null
                            }
                            onChange={(value, key) => {
                                setListAddData({ ...listAddData, openIdType: value });
                            }}
                        />
                         {listAddData?.openIdType && listAddData?.openIdType.toLowerCase() !== 'user' ? ( 
                        <>
                        <CustomInput
                            label="Token Url"
                            inputValue={listAddData?.tokenUrl}
                            isRequired={true}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, tokenUrl: value });
                            }}
                        />
                        <CustomInput
                            label="Grant Type"
                            inputValue={listAddData?.grantType}
                            isRequired={true}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, grantType: value });
                            }}
                        />
                        <CustomInput
                            label="Client ID"
                            inputValue={listAddData?.client_id}
                            isRequired={true}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, client_id: value });
                            }}
                        />
                           </>
                        ) : ''} 
                        {listAddData?.openIdType && listAddData?.openIdType.toLowerCase() === 'masteruser' ? ( 
                        <>
                            <CustomInput
                                label="User ID"
                                inputValue={listAddData?.userId}
                                isRequired={true}
                                onChange={(value) => {
                                    setListAddData({ ...listAddData, userId: value });
                                }}
                            />
                            <CustomInput
                                label="Password"
                                type="password"
                                inputValue={listAddData?.password}
                                isRequired={true}
                                onChange={(value) => {
                                    setListAddData({ ...listAddData, password: value });
                                }}
                            />
                        </>
                        ) : ''} 
                        
                        {listAddData?.openIdType && listAddData?.openIdType.toLowerCase() === 'serviceprincipal' ? (
                            <>
                            <CustomInput
                                label="Client Secret"
                                inputValue={listAddData?.client_secret}
                                isRequired={true}
                                onChange={(value) => {
                                    setListAddData({ ...listAddData, client_secret: value });
                                }}
                            />
                            </>
                        ) : ''}
                        
                        <CustomInput
                            label="API Url"
                            inputValue={listAddData?.apiUrl}
                            isRequired={true}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, apiUrl: value });
                            }}
                        />
                        <CustomInput
                            label="Scope"
                            inputValue={listAddData?.scope}
                            isRequired={true}
                            onChange={(value) => {
                                setListAddData({ ...listAddData, scope: value });
                            }}
                        />
                    </>
                ) : (
                    ""
                )}

                <CustomSwitchToggle
                    label="Status"
                    switchValue={listAddData?.status}
                    onText="Active"
                    offText="Inactive"
                    onChange={(checked) =>
                        setListAddData({
                            ...listAddData,
                            status: checked ? 1 : 0,
                        })
                    }
                    checked={listAddData?.status}
                    styleName="labelGreyDarkText"
                />

            </div>

            <SidePanelFooter
                secondaryLabel={"Cancel"}
                onCancelClick={() => {
                    hideChangeDrawer();
                    setListAddData(defaultListData);
                }}
                primaryLabel={"Apply"}
                onPrimaryClick={() => onPrimaryClick()}
            />

            {/* Add System */}
            <CustomModal
                showModal={showAddModal}
                titleText="Add System"
                messageText={`Are you sure you want to add ${listAddData?.name}?`}
                handleCancel={() => {
                    setShowAddModal(false);
                }}
                confirmButtonText={"Yes"}
                handleConfirm={addSystemData}
                isLoading={modalButtonLoading}
            />

            {/* Update System Id */}
            <CustomModal
                showModal={showUpdateModal}
                titleText="Update System"
                messageText={`Are you sure you want to update the changes made to ${listAddData?.name}?`}
                handleCancel={() => {
                    setShowUpdateModal(false);
                }}
                confirmButtonText={"Yes"}
                handleConfirm={updateSystemData}
                isLoading={modalButtonLoading}
            />

        </>
    );
};
