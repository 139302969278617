import React from 'react';

export const Dot = (props) => {
    const { x, y, radius, styles } = props;

    return (
        <div
          className="react-image-dot"
          style={{
            ...styles,
            height: radius * 2,
            width: radius * 2,
            borderRadius: radius,
            transform: `translate(${-radius}, ${-radius})`,
            top: y,
            left: x
          }}
        />
    );
};