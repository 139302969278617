import React, { useEffect, useContext, useState } from "react";
import { Row, Col } from "antd";
import { AppContext } from "../../Core/store/app-context";
import { ItemDetail } from "../../Core/common/ItemDetail";
import { CustomTooltip } from "../../Core/common/CustomTooltip";

export const OrganizationDisplay = (props) => {
    const ctx = useContext(AppContext);

    const {
        itemId
    } = props;
    const [listData, setListData] = useState({});
    const [organizationBase64Image, setOrganizationBase64Image] = useState("");

    useEffect(() => {
        async function fetchData() {
            // You can await here
            setOrganizationBase64Image("");
            if (itemId) {
                await getItemData();
            }
            else {
                setListData({});
            }
        }
        fetchData();
    }, [itemId]);

    const getItemData = async () => {
        const response = await ctx.HttpGet("/Organization", { id: itemId });
        if (response) {
            setListData(response);

            if (response.image) {
                setOrganizationBase64Image("data:image/png;base64," + response.image);
            }
        }
    };

    return (
        <div>
            <div className="flex items-center pb-5 border-b-2 px-5" style={{ borderColor: "#F3F5F8" }}>
                <div className="ml-3">
                    <div
                        className="font-semibold text-h2 flex items-center mb-2 break-all"
                        style={{ color: "#383A65" }}
                    >
                        {listData?.name ? listData?.name : "-"}
                    </div>
                    <div
                        className="text-h1 font-medium my-2 overflow-hidden overflow-ellipsis whitespace-nowrap flex"
                        style={{ color: "#8892A5", maxWidth: "360px" }}
                    >
                        Code: {listData?.code ? <CustomTooltip
                        title={listData?.code}
                        /> : "-"}
                    </div>
                    <div
                        style={{
                            backgroundColor:
                                listData?.Status?.toString() === "1" ? "#84D49D" : "#dbdae0",
                            borderRadius: "15px",
                            padding: "2px 10px",
                        }}
                        className="text-xs font-medium text-white inline"
                    >
                        {listData?.Status?.toString() === "1" ? "Active" : "Inactive"}
                    </div>
                </div>
            </div>
            <div
                className="pt-5 px-8"
                style={{ borderColor: "#F3F5F8" }}
            >
                <div className="flex items-center" style={{ marginBottom: "22px" }}>
                    <img
                        src="/images/icons/user.svg"
                        alt="user"
                        style={{ height: "38px", width: "38px" }}
                    />
                    <div
                        className="ml-3 font-medium text-h1"
                        style={{ color: "#383A65" }}
                    >
                        Basic details
                    </div>
                </div>
                <ItemDetail label="Name" itemValue={listData?.name} />
                <ItemDetail label="Code" itemValue={listData?.code} />
                <ItemDetail label="Description" itemValue={listData?.description} />
                <div>
                    <Row>
                        <Col span={12}>
                        <ItemDetail label="Object ID" itemValue={listData?.objectId} />
                        </Col>
                        <Col>
                        <ItemDetail label="Storage Location" itemValue={listData?.storageLoc} />
                        </Col>
                    </Row>
                </div>
                <ItemDetail label="Address" itemValue={listData?.fullAddress} />
                <div>
                    <Row>
                        <Col span={12}>
                            <ItemDetail label="Can Assign Users" itemValue={listData?.userFlag === 1 ? "On" : "Off"} />
                        </Col>
                        <Col>
                            <ItemDetail label="Occasionally connected" itemValue={listData?.edgeFlag === 1 ? "On" : "Off"} />
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row>
                        <Col span={12}>
                            <ItemDetail label="Latitude" itemValue={listData?.latitude} />
                        </Col>
                        <Col>
                            <ItemDetail label="Longitude" itemValue={listData?.longitude} />
                        </Col>
                    </Row>
                </div>
                <div className="relative my-6">
                    <div className="flex flex-col">
                        {organizationBase64Image ? (
                            <div className="flex w-full">
                                <div className="relative w-full">
                                    <img
                                        className="rounded-lg w-full"
                                        src={organizationBase64Image}
                                        alt="Organization"
                                        style={{ height: "12rem" }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};