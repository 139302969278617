import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import { AppContext } from "../../../Core/store/app-context";

import { Layout } from "../../../Core/layout";
import { FeatureTypes } from "../../../Core/store/app-feature";
import { DataTable, getClickableItemIndex } from "../../../Core/common/DataTable";
import { CustomModal } from "../../../Core/common/CustomModal";
import { SidePanelPreferences } from "../../../Core/common/SidePanelPreferences";
import { SidePanelChange } from "../../../Core/common/SidePanelChange";
import { SidePanelDisplay } from "../../../Core/common/SidePanelDisplay";

import { ZoneChange } from "./ZoneChange";
import { ZoneDisplay } from "./ZoneDisplay";

export const ZoneList = (props) => {
  const history = useHistory();
  const ctx = useContext(AppContext);
  const { spaceId } = useParams();

  const [pageSize, setPageSize] = useState(10);
  const [offsetValue, setOffsetValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [showUpdateDrawer, setShowUpdateDrawer] = useState(false);
  const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
  const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

  const [columnList, setColumnList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");

  const [itemId, setItemId] = useState(null);
  const [itemName, setItemName] = useState(null);

  // Consent
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);

  useEffect(() => {
    getRecordList();
  }, [searchValue]);

  const getRecordList = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    offset = offsetValue,
    limit = pageSize
  ) => {
    let queryParams = {
      search: searchBy,
      sortBy: sortBy,
      orderBy: orderBy,
      offset: offset,
      limit: limit,
      floorID: spaceId,
    };
    const response = await ctx.HttpGetList("/Location/zone/list", queryParams);
    if (response) {
      setRecordList(response.Data);
      setTotalCount(response.Count);
      setColumnList(response.Headers);
      setSearchPlaceholder(response.searchPlaceholder);
    }
  };

  // add click event if user have view access
  if (ctx.acl(FeatureTypes.Locations, "GetZone") && columnList?.length > 1) {
    columnList[getClickableItemIndex(columnList)]["onClick"] = (record) => {
      setItemId(record.id);
      setItemName(record.name);
      setShowDisplayDrawer(true);
    };
  }

  // add action items as per role access
  const getActionButtonItems = (listItem = null) => {
    let actionButtonItems = [];
    if (ctx.acl(FeatureTypes.Locations, "PutZone")) {
      actionButtonItems.push("Update");
    }
    if (ctx.acl(FeatureTypes.Locations, "DeleteZone")) {
      actionButtonItems.push("Remove");
    }
    if (ctx.acl(FeatureTypes.Locations, "GetReaderList")) {
      actionButtonItems.push("View Hardware");
    }
    return actionButtonItems;
  };

  const handleOptionMenuItemClick = (clickedItem) => {
    setShowDisplayDrawer(false);
    if (clickedItem === "Update") {
      setShowUpdateDrawer(true);
    } else if (clickedItem === "Remove") {
      setShowDeleteModal(true);
    }

    // set module state before redirecting
    // handle state from here, refer rule list

    if (clickedItem === "View Hardware" && showDisplayDrawer && itemId) {
      history.push(`/location/space/zone/hardware/${itemId}`);
    }
  };

  const deleteZoneData = async () => {
    setModalButtonLoading(true);
    const response = await ctx.HttpDelete("/Location/zone", {
      aId: itemId,
    });
    if (response) {
      ctx.showToastAlert({ type: "success", message: response });
      getRecordList();
    }
    setShowDeleteModal(false);
    setModalButtonLoading(false);
  };

  return (
    <>
      <Layout
        page="zones"
        id={FeatureTypes.Zones}
        title="Zones"
        subTitle="Manage Zones here"
        pageTitleButton={ctx.acl(FeatureTypes.Locations, "PostZone")}
        buttonOnClick={() => {
          setItemId(null);
          setShowAddDrawer(true);
        }}
        buttonLabel="Add Zone"
        searchOnModule={FeatureTypes.Zones}
        searchPlaceholder={searchPlaceholder}
        onSearchChange={(value) => {
          setSearchValue(value);
          setOffsetValue(1);
          getRecordList(value, null, null, 1);
        }}
        showPageActionButton={true}
        pageActionButtonLabel="Back"
        pageActionButtonIcon="/images/icons/Previous-icon.svg"
        pageActionButtonOnClick={() => {
          history.goBack();
        }}
        showFilter={false}
      >
        <DataTable
          showActionButton={getActionButtonItems().length ? true : false}
          getActionButtonItems={getActionButtonItems}
          onActionButtonClick={(record, clickedItem = null) => {
            setItemId(record.id);
            setItemName(record.name);
            if (clickedItem === "View Hardware") {
              history.push(`/location/space/zone/hardware/${record.id}`);
            }
          }}
          actionButtonItemClick={(clickedItem) => {
            handleOptionMenuItemClick(clickedItem);
          }}
          dataSource={recordList}
          columns={columnList}
          totalRecords={totalCount}
          pageSize={pageSize}
          currentPage={offsetValue}
          rowKey={"id"}
          onUserPreferenceClick={() => {
            setShowPreferenceDrawer(true);
          }}
          onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
            let sortOrderValue = "";
            if (sorterOptions && sorterOptions.order) {
              sortOrderValue =
                sorterOptions.order === "ascend" ? "ASC" : "DESC";
              setOrderByValue(sortOrderValue);
              setSortByValue(sorterOptions.columnKey);
            }
            setPageSize(pageSizeOptions.pageSize);
            setOffsetValue(pageSizeOptions.current);
            getRecordList(
              searchValue,
              sorterOptions.columnKey,
              sortOrderValue,
              pageSizeOptions.current,
              pageSizeOptions.pageSize
            );
          }}
        />

        <SidePanelChange
          // panelIcon={"zone"}
          panelTitle={"Add Zone"}
          panelVisible={showAddDrawer}
          panelWidth="95%"
          panelBody={
            <ZoneChange
              spaceId={spaceId}
              getRecordList={getRecordList}
              hideChangeDrawer={() => {
                setShowAddDrawer(false);
                setItemId(null);
              }}
            />
          }
        />

        <SidePanelChange
          // panelIcon={"zone"}
          panelTitle={"Update Zone"}
          panelVisible={showUpdateDrawer}
          panelWidth="95%"
          panelBody={
            <ZoneChange
              itemId={itemId}
              spaceId={spaceId}
              getRecordList={getRecordList}
              hideChangeDrawer={() => {
                setShowUpdateDrawer(false);
                setItemId(null);
              }}
            />
          }
        />

        <SidePanelDisplay
          headerData={columnList}
          // panelIcon={"zone"}
          panelTitle={"Zone Details"}
          panelVisible={showDisplayDrawer}
          panelWidth="95%"
          panelBody={<ZoneDisplay itemId={itemId} spaceId={spaceId} />}
          customAction={
            <img
              className="cursor-pointer"
              style={{ width: 25 }}
              src="/images/icons/cancel.svg"
              alt="cancel"
              onClick={() => {
                setShowDisplayDrawer(false);
              }}
            />
          }
          onCancelClick={() => {
            setShowDisplayDrawer(false);
          }}
        />

        <SidePanelPreferences
          module={"/Location/zone/list"}
          // panelIcon={"zone"}
          panelVisible={showPreferenceDrawer}
          onCancelClick={() => {
            setShowPreferenceDrawer(false);
          }}
          onChangeClick={() => {
            setShowPreferenceDrawer(false);
            getRecordList(searchValue, sortByValue, orderByValue, offsetValue);
          }}
        />

        {/* Delete Zone Id */}
        <CustomModal
          showModal={showDeleteModal}
          titleText={"Remove Zone"}
          messageText={`Are you sure you want to remove the ${itemName}?`}
          handleCancel={() => {
            setShowDeleteModal(false);
          }}
          confirmButtonText={`Yes`}
          handleConfirm={deleteZoneData}
          isLoading={modalButtonLoading}
        />
      </Layout>
    </>
  );
};
