import React, { useState, useEffect, useContext } from "react";

import { Layout } from "../../../Core/layout";
import { AppContext } from "../../../Core/store/app-context";
import { DataTable, getClickableItemIndex } from "../../../Core/common/DataTable";
import { FeatureTypes } from "../../../Core/store/app-feature";
import { GetTimezoneOffset } from "../../../Core/common/helpers";
import { SidePanelDisplay } from "../../../Core/common/SidePanelDisplay";
import { SidePanelPreferences } from "../../../Core/common/SidePanelPreferences";

export const SensorList = (props) => {
  const ctx = useContext(AppContext);

  const [pageSize, setPageSize] = useState(10);
  const [offsetValue, setOffsetValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
  const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

  const [columnList, setColumnList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");
  const [listData, setListData] = useState({});

  useEffect(() => {
      async function fetchData() {
          // You can await here
          await getRecordList();
      }
      fetchData();
  }, []);

  const getRecordList = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    offset = offsetValue,
    limit = pageSize
  ) => {
    let queryParams = {
      search: searchBy,
      sortBy: sortBy,
      orderBy: orderBy,
      offset: offset,
      limit: limit,
      utcOffset: GetTimezoneOffset(),
    };
    const response = await ctx.HttpGetList("/Sensor/list", queryParams);
    if (response) {
      setRecordList(response.Data);
      setTotalCount(response.Count);
      setColumnList(response.Headers);
      setSearchPlaceholder(response.searchPlaceholder);
    }
  };

    // add click event if user have view access
    if (columnList?.length > 1) {
        columnList[getClickableItemIndex(columnList)]["onClick"] = (record) => {
            setListData(record);
            setShowDisplayDrawer(true);
        }
    }

  return (
    <>
      <Layout
        page="iot-sensor"
        id={FeatureTypes.Sensors}
        searchOnModule={FeatureTypes.Sensors}
        searchPlaceholder={searchPlaceholder}
        onSearchChange={(value) => {
          setSearchValue(value);
          setOffsetValue(1);
          getRecordList(value, null, null, 1);
        }}
        showFilter={false}
      >
        <DataTable
            showActionButton={false}
            dataSource={recordList}
            columns={columnList}
            totalRecords={totalCount}
            pageSize={pageSize}
            currentPage={offsetValue}
            rowKey={"Id"}
            onUserPreferenceClick={() => {
                setShowPreferenceDrawer(true);
            }}
            onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
                let sortOrderValue = "";
                if (sorterOptions && sorterOptions.order) {
                    sortOrderValue =
                        sorterOptions.order === "ascend" ? "ASC" : "DESC";
                    setOrderByValue(sortOrderValue);
                    setSortByValue(sorterOptions.columnKey);
                }
                setPageSize(pageSizeOptions.pageSize);
                setOffsetValue(pageSizeOptions.current);
                getRecordList(
                    searchValue,
                    sorterOptions.columnKey,
                    sortOrderValue,
                    pageSizeOptions.current,
                    pageSizeOptions.pageSize
                );
            }}
        />
      </Layout>

        <SidePanelDisplay
            listData={listData}
            headerData={columnList}
            panelIcon={"sensors"}
            panelTitle={"Sensor Details"}
            panelVisible={showDisplayDrawer}
            onCancelClick={() => {
                setShowDisplayDrawer(false);
                setListData({});
            }}
        />

        <SidePanelPreferences
            module={"/Sensor/list"}
            panelIcon={"sensors"}
            panelVisible={showPreferenceDrawer}
            onCancelClick={() => {
                setShowPreferenceDrawer(false);
            }}
            onChangeClick={() => {
                setShowPreferenceDrawer(false);
                getRecordList(searchValue, sortByValue, orderByValue, offsetValue);
            }}
        />
    </>
  );
};
