import React, { useState, useEffect, useContext } from "react";
import { Popover } from "antd";

import { AppContext } from "../../Core/store/app-context";

import { Layout } from "../../Core/layout";
import { DataTable, getClickableItemIndex } from "../../Core/common/DataTable";
import { FeatureTypes } from "../../Core/store/app-feature";
import { CustomModal } from "../../Core/common/CustomModal";
import { CustomTooltip } from "../../Core/common/CustomTooltip";
import { GetTimezoneOffset } from "../../Core/common/helpers";
import { OptionsDropdown } from "../../Core/common/OptionsDropdown";
import { SidePanelChange } from "../../Core/common/SidePanelChange";
import { SidePanelDisplay } from "../../Core/common/SidePanelDisplay";
import { SidePanelFilter } from "../../Core/common/SidePanelFilter";
import { SidePanelPreferences } from "../../Core/common/SidePanelPreferences";

import { UserChange } from "./UserChange";
import { UserDisplay } from "./UserDisplay";
import { UserFilter } from "./UserFilter";

export const UserList = (props) => {
  const ctx = useContext(AppContext);

  const [pageSize, setPageSize] = useState(10);
  const [offsetValue, setOffsetValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [showUpdateDrawer, setShowUpdateDrawer] = useState(false);
  const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
  const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

  const [columnList, setColumnList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");
  const [filterData, setFilterData] = useState({ isFilter: false });

  const [itemId, setItemId] = useState(null);
  const [itemName, setItemName] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);

  const [roleList, setRoleList] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);

  // to make the API call after filterData.isFilter is updated (includes init time update)
  useEffect(() => {
    setOffsetValue(1);
      async function fetchData() {
          await getRoleList();
          await getOrganizationList();
          // You can await here
          await getRecordList(searchValue, sortByValue, orderByValue, 1);
      }
      if (!filterData.isFilter) {
          setShowFilterDrawer(false);
      }
      fetchData();
  }, [filterData.isFilter]);

  const getRecordList = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    offset = offsetValue,
    limit = pageSize
  ) => {
    let queryParams = {
      search: searchBy,
      sortBy: sortBy,
      orderBy: orderBy,
      offset: offset,
      limit: limit,
      utcOffset: GetTimezoneOffset(),
      ...filterData,
    };
    const response = await ctx.HttpGetList("/User/list", queryParams);
    if (response) {
      setRecordList(response.Data);
      setTotalCount(response.Count);
      setColumnList(response.Headers);
      setSearchPlaceholder(response.searchPlaceholder);
    }
  };

  const getRoleList = async () => {
    if (roleList.length) {
      return;
    }
    const response = await ctx.HttpGet("/User/GetRoleName");
    if (response) {
      setRoleList(response);
    }
  };

  const getOrganizationList = async () => {
    if (organizationList.length) {
      return;
    }
    const response = await ctx.HttpGet("/User/GetOrgName");
    if (response) {
      setOrganizationList(response);
    }
  };

  const deleteUser = async () => {
    setModalButtonLoading(true);
    const response = await ctx.HttpDelete("/User", { userID: itemId });
    if (response) {
      ctx.showToastAlert({ type: "success", message: response });
      let offset = offsetValue;
      if (recordList.length === 1 && offset > 1) {
        offset = offset - 1;
        setOffsetValue(offset);
      }
      getRecordList(searchValue, sortByValue, orderByValue, offset);
    }
    setShowDeleteModal(false);
    setModalButtonLoading(false);
  };

  const handleColumnClick = (record) => {
    //setListData(record);
  };

  // add click event if user have view access
  if (ctx.acl(FeatureTypes.Users, "GetUser") && columnList?.length > 1) {
    columnList[getClickableItemIndex(columnList)]["onClick"] = (record) => {
        setItemId(record.Id);
        setItemName(record.fullName);
        setShowDisplayDrawer(true);
    };
    columnList.forEach((item) => {
      if (item.dataIndex === "roles") {
        item.render = (text, record) => (
          <div className="flex items-center">
            {text ? (
              <CustomTooltip
                title={text
                  .map((item) => {
                    return getNameById(item?.roleId, roleList);
                  })
                  ?.slice(0, 1)
                  .join(", ")}
                displayText={
                  <span
                    className={
                      item.onClick
                        ? "font-semibold cursor-pointer"
                        : "font-medium"
                    }
                    style={{ color: item.onClick ? "#4E2C90" : "#8892a5" }}
                    onClick={() => {
                      if (typeof item.onClick === "function") {
                        handleColumnClick(record);
                      }
                    }}
                  >
                    {text
                      .map((item) => {
                        return getNameById(item?.roleId, roleList);
                      })
                      ?.slice(0, 1)
                      .join(", ")}
                  </span>
                }
                className="w-36"
              />
            ) : (
              "-"
            )}
            {text?.length > 1 ? (
              <span
                className="ml-2 font-medium inline-flex rounded-full items-center justify-center text-white cursor-pointer"
                style={{
                  backgroundColor: "#4E2C90",
                  width: "22px",
                  height: "22px",
                  fontSize: "11px",
                }}
              >
                <Popover
                  content={renderRemainingItems(
                    text.map((item) => {
                      return getNameById(item?.roleId, roleList);
                    })
                  )}
                  trigger="hover"
                  overlayStyle={{ maxWidth: "50%" }}
                >
                  +{text?.length - 1}
                </Popover>
              </span>
            ) : (
              ""
            )}
          </div>
        );
      } else if (item.dataIndex === "organizations") {
        item.render = (text, record) => (
          <div className="flex items-center">
            {text ? (
              <CustomTooltip
                title={text
                  .map((item) => {
                    return getNameById(item?.orgId, organizationList);
                  })
                  ?.slice(0, 1)
                  .join(", ")}
                displayText={
                  <span
                    className={
                      item.onClick
                        ? "font-semibold cursor-pointer"
                        : "font-medium"
                    }
                    style={{ color: item.onClick ? "#4E2C90" : "#8892a5" }}
                    onClick={() => {
                      if (typeof item.onClick === "function") {
                        handleColumnClick(record);
                      }
                    }}
                  >
                    {text
                      .map((item) => {
                        return getNameById(item?.orgId, organizationList);
                      })
                      ?.slice(0, 1)
                      .join(", ")}
                  </span>
                }
                className="w-36"
              />
            ) : (
              "-"
            )}
            {text?.length > 1 ? (
              <span
                className="ml-2 font-medium inline-flex rounded-full items-center justify-center text-white cursor-pointer"
                style={{
                  backgroundColor: "#4E2C90",
                  width: "22px",
                  height: "22px",
                  fontSize: "11px",
                }}
              >
                <Popover
                  content={renderRemainingItems(
                    text.map((item) => {
                      return getNameById(item?.orgId, organizationList);
                    })
                  )}
                  trigger="hover"
                  overlayStyle={{ maxWidth: "50%" }}
                >
                  +{text?.length - 1}
                </Popover>
              </span>
            ) : (
              ""
            )}
          </div>
        );
      }
    });
  }

  const renderRemainingItems = (text) => (
    <div className="text-h1 font-medium" style={{ color: "#8892A5" }}>
      {text?.slice(1).join(", ")}
    </div>
  );

  const getNameById = (id, nameList) => {
    let filteredArr = nameList.filter((item) => item.Id === id);
    return filteredArr.length ? filteredArr[0].Value : "";
  };

  const handleOptionMenuItemClick = (clickedItem) => {
    setShowDisplayDrawer(false);
    if (clickedItem === "Update") {
      setShowUpdateDrawer(true);
    } else if (clickedItem === "Remove") {
      setShowDeleteModal(true);
    }
  };

  // add action items as per role access
  const getActionButtonItems = (listItem = null) => {
    let actionButtonItems = [];
    if (ctx.acl(FeatureTypes.Users, "PutUser")) {
      actionButtonItems.push("Update");
    }
    if (ctx.acl(FeatureTypes.Users, "DeleteUser")) {
      actionButtonItems.push("Remove");
    }
    return actionButtonItems;
  };

  return (
    <>
      <Layout
        page="users"
        id={FeatureTypes.Users}
        pageTitleButton={ctx.acl(FeatureTypes.Users, "PostUser")}
        buttonOnClick={() => {
            setItemId(null);
            setShowAddDrawer(true);
        }}
        buttonLabel="Add User"
        searchOnModule={FeatureTypes.Users}
        searchPlaceholder={searchPlaceholder}
        onSearchChange={(value) => {
          setSearchValue(value);
          setOffsetValue(1);
          getRecordList(value, null, null, 1);
        }}
        filterApplied={filterData.isFilter}
        onFilterClick={() => {
          setShowFilterDrawer(true);
        }}
      >
        <DataTable
          showActionButton={getActionButtonItems().length ? true : false}
          getActionButtonItems={getActionButtonItems}
          onActionButtonClick={(record) => {
              setItemId(record.Id);
              setItemName(record.fullName);
          }}
          actionButtonItemClick={(clickedItem) => {
            handleOptionMenuItemClick(clickedItem);
          }}
          dataSource={recordList}
          columns={columnList}
          totalRecords={totalCount}
          pageSize={pageSize}
          rowKey={"Id"}
          currentPage={offsetValue}
          onUserPreferenceClick={() => {
            setShowPreferenceDrawer(true);
          }}
          onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
            let sortOrderValue = "";
            if (sorterOptions && sorterOptions.order) {
              sortOrderValue =
                sorterOptions.order === "ascend" ? "ASC" : "DESC";
              setOrderByValue(sortOrderValue);
              setSortByValue(sorterOptions.columnKey);
            }
            setPageSize(pageSizeOptions.pageSize);
            setOffsetValue(pageSizeOptions.current);
            getRecordList(
              searchValue,
              sorterOptions.columnKey,
              sortOrderValue,
              pageSizeOptions.current,
              pageSizeOptions.pageSize
            );
          }}
        />
      </Layout>

        <SidePanelChange
            panelIcon={"users"}
            panelTitle={"Add User"}
            panelVisible={showAddDrawer}
            panelBody={(
                <UserChange
                    roleList={roleList}
                    organizationsList={organizationList}
                    getRecordList={getRecordList}
                    hideChangeDrawer={() => {
                        setShowAddDrawer(false);
                        setItemId(null);
                    }}
                />
            )}
        />

        <SidePanelChange
            panelIcon={"users"}
            panelTitle={"Update User"}
            panelVisible={showUpdateDrawer}
            panelBody={(
                <UserChange
                    itemId={itemId}
                    roleList={roleList}
                    organizationsList={organizationList}
                    getRecordList={getRecordList}
                    hideChangeDrawer={() => {
                        setShowUpdateDrawer(false);
                        setItemId(null);
                    }}
                />
            )}
        />

        <SidePanelDisplay
            headerData={columnList}
            panelIcon={"users"}
            panelTitle={"User Details"}
            panelVisible={showDisplayDrawer}
            panelBody={(
                <UserDisplay
                    itemId={itemId}
                    roleList={roleList}
                    organizationsList={organizationList}
                    getNameById={getNameById}
                />
            )}
            customAction={(
                <OptionsDropdown
                    actionButtonItems={getActionButtonItems()}
                    actionButtonItemClick={(clickedItem) => {
                        handleOptionMenuItemClick(clickedItem);
                    }}
                />
            )}
            onCancelClick={() => {
                setShowDisplayDrawer(false);
            }}
        />

        <SidePanelFilter
            moduleRoute={"/User"}
            filterData={filterData}
            setFilterData={setFilterData}
            panelIcon={"users"}
            panelVisible={showFilterDrawer}
            panelBody={(
                <UserFilter
                    listFilterData={filterData}
                    setListFilterData={setFilterData}
                    roleList={roleList}
                />
            )}
            onCancelClick={() => {
                setShowFilterDrawer(false);
            }}
            onChangeClick={() => {
                setShowFilterDrawer(false);
                if (filterData.isFilter) {
                  setOffsetValue(1);
                  getRecordList(searchValue, sortByValue, orderByValue, 1);
                }
            }}
        />

        <SidePanelPreferences
            module={"/User/list"}
            panelIcon={"users"}
            panelVisible={showPreferenceDrawer}
            onCancelClick={() => {
                setShowPreferenceDrawer(false);
            }}
            onChangeClick={() => {
                setShowPreferenceDrawer(false);
                getRecordList(searchValue, sortByValue, orderByValue, offsetValue);
            }}
        />

      {/* Delete User Id */}
      <CustomModal
        showModal={showDeleteModal}
        titleText="Remove User"
        messageText={`Are you sure you want to remove the ${itemName}?`}
        handleCancel={() => {
          setShowDeleteModal(false);
        }}
        confirmButtonText={"Yes"}
        handleConfirm={deleteUser}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
