import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Button } from "antd";
import { SideBar } from "./sidebar";
import { PageTitleBox } from "../common/PageTitleBox";
import { SearchBox } from "../common/SearchBox";
import { AppContext } from "../../Core/store/app-context";
import { ExportButton } from "../common/ExportButton";

export const Layout = (props) => {
  const {
    page,
    id,
    title,
    subTitle,
    pageTitleButton,
    buttonOnClick,
    buttonLabel,
    buttonIcon,
    mainContentClassName,
    children,
    showSearch,
    onSearchChange,
    searchPlaceholder,
    searchOnModule,
    showFilter,
    onFilterClick,
    showPageActionButton,
    pageActionButtonLabel,
    pageActionButtonIcon,
    pageActionButtonOnClick,
    filterApplied,
    secondaryButtonOnClick,
    secondaryButtonIcon,
    secondaryButtonLabel,
    showExport,
    onExportClick,
    exportFileTypes,
    exportConfirmationModal,
    disabled,
    titleText,
  } = props;

  const ctx = useContext(AppContext);
  const [maxHeightRequired, setMaxHeightRequired] = useState(true);

  // if page have table then don't give max-height else give max-height to handle scroll
  // for table the scroll is handled in different way
  useEffect(() => {
    if (document.querySelector(".flat-table")) {
      setMaxHeightRequired(false);
    } else {
      setMaxHeightRequired(true);
    }
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <Row
        id="layout-section"
        type="flex"
        className={`h-full`}
        style={{ overflow: "hidden" }}
      >
        <Col span={1} className="bg-athens-gray">
          <SideBar page={page} />
        </Col>
        <Col span={23} className="bg-athens-gray">
          {ctx.pageInfo?.HomeTitle ? (
            <div
              style={{
                backgroundColor: "green",
                color: "white",
                height: "21px",
              }}
              className="flex w-full fixed left-0"
            >
              <div className="text-center w-full">
                {ctx.pageInfo?.HomeTitle}
              </div>
            </div>
          ) : (
            ""
          )}
          <Row
            type="flex"
            className={`${
              ctx.pageInfo?.HomeTitle ? "pt-3" : "pt-5"
            } px-2 pb-2 `}
            style={{ marginTop: ctx.pageInfo?.HomeTitle ? "21px" : "0" }}
          >
            <Col span={1} />
            <Col span={22}>
              <PageTitleBox
                id={id}
                title={title}
                subTitle={subTitle}
                pageTitleButton={pageTitleButton}
                buttonOnClick={buttonOnClick}
                buttonLabel={buttonLabel}
                buttonIcon={buttonIcon}
                secondaryButtonOnClick={secondaryButtonOnClick}
                secondaryButtonIcon={secondaryButtonIcon}
                secondaryButtonLabel={secondaryButtonLabel}
              />
              <div
                className={`main-content custom-scrollbar px-1 ${
                  mainContentClassName ? mainContentClassName : ""
                }`}
                style={{
                  maxHeight: !maxHeightRequired ? "none" : "calc(100vh - 95px)",
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
              >
                {/* search, filter, user preference, export */}
                <Row
                  type="flex"
                  style={{
                    display:
                      showSearch !== false || showFilter  !== false || showPageActionButton !== false
                        ? "flex"
                        : "none",
                  }}
                  className={`${
                    showSearch !== false || showFilter !== false || showPageActionButton !== false
                      ? "mt-4 pt-1"
                      : ""
                  }`}
                >
                  <Col span={9}>
                    {showSearch !== false ? (
                      <div className="flex custom-search">
                        <SearchBox
                          placeholder={searchPlaceholder}
                          searchOnModule={searchOnModule}
                          onChange={(value) => {
                            onSearchChange(value);
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col span={6} className="my-auto flex">
                    {showFilter !== false ? (
                      <div className="my-auto">
                        <Button
                          className="pl-2"
                          onClick={(value) => {
                            onFilterClick(value);
                          }}
                          type="link"
                        >
                          <div className="flex">
                            <img
                              src={
                                filterApplied === true
                                  ? "/images/icons/filter-filled.svg"
                                  : "/images/icons/filter.svg"
                              }
                              alt="filter"
                            />
                            <span className="text-h1 font-medium text-regent-gray ml-2">
                              Filter
                            </span>
                          </div>
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col span={9} className="my-auto text-right">
                    {showPageActionButton ? (
                      <Button
                        onClick={() => {
                          pageActionButtonOnClick();
                        }}
                        className="shadow inline-flex items-center font-poppins text-white text-h1 font-medium bg-daisy-bush rounded-lg"
                      >
                        {pageActionButtonIcon ? (
                          <img
                            className="mr-2"
                            alt="back"
                            src={
                              pageActionButtonIcon ? pageActionButtonIcon : ""
                            }
                          />
                        ) : (
                          ""
                        )}
                        {pageActionButtonLabel
                          ? pageActionButtonLabel
                          : ""}
                      </Button>
                    ) : (
                      ""
                    )}
                    {showExport ? 
                      <ExportButton 
                        confirmationModal= {exportConfirmationModal ? exportConfirmationModal : false}
                        fileTypes={exportFileTypes}
                        handleExportClick={onExportClick}
                        disabled={disabled}
                        titleText={titleText}
                      /> : ""}
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col span={24}>{children}</Col>
                </Row>
              </div>
            </Col>
            <Col span={1} />
          </Row>
        </Col>
      </Row>
    </div>
  );
};
