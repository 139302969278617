import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "antd";

import { AppContext } from "../../../Core/store/app-context";

import { CustomTooltip } from "../../../Core/common/CustomTooltip";
import { ItemDetail } from "../../../Core/common/ItemDetail";

export const DistributionDisplay = (props) => {
  const { itemId } = props;
  const [listData, setListData] = useState(null);
  const ctx = useContext(AppContext);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      if (itemId) {
        await getItemData();
      } else {
        setListData({});
      }
    }
    fetchData();
  }, [itemId]);

  const getItemData = async () => {
    const response = await ctx.HttpGet("/Distribution", { id: itemId });
    if (response) {
      setListData(response);
    }
  };

  return (
    <div className="overflow-hidden px-7">
      <div className="border-b-2 pb-4" style={{ borderBottomColor: "#F3F5F8" }}>
        <ItemDetail label="Distribution ID" itemValue={listData?.Id} />
        <ItemDetail label="Distribution Name" itemValue={listData?.Name} />
        <ItemDetail label="Description" itemValue={listData?.Description} />
        <div
          style={{
            backgroundColor: listData?.StatusName === "Active" ? "#84D49D" : "#dbdae0",
            borderRadius: "15px",
            padding: "2px 10px",
          }}
          className="text-xs font-medium text-white inline"
        >
          {listData?.StatusName}
        </div>
      </div>

      <div className="border-b-2 py-2" style={{ borderBottomColor: "#F3F5F8" }}>
        <Row className="text-h1 font-medium" style={{ color: "#8892A5" }}>
          <Col span={18}>Delivery Type</Col>
          <Col>Destinations</Col>
        </Row>
      </div>
      {(listData?.destinations || []).map((data) => {
        return (
          <div className="my-4">
            <Row>
              <Col
                span={8}
                className="text-h1 font-medium"
                style={{ color: "#8892A5" }}
              >
                {data?.typeName}
              </Col>
              <Col span={16}>
                <CustomTooltip
                  title={
                    data.type === 2
                      ? `App ID: ${data.destination}`
                      : data.destination
                  }
                  className="text-right font-semibold text-h1"
                  styles={{ color: "#383A65" }}
                />
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};
