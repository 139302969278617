import React from "react";

export const SidePanelTitle = (props) => {
    const {
        panelIcon,
        panelTitle,
        actionText,
        onActionClick,
        customAction,
    } = props;

    const getHeaderAction = () => {

        if (customAction) {
            return (customAction);
        }
        else if (onActionClick && actionText) {
            return (
                <div
                    className="text-h1 font-medium"
                    onClick={() => onActionClick()}
                    style={{
                        alignSelf: "center",
                        cursor: "pointer",
                        color: "#4E2C90",
                    }}
                >
                    {actionText}
                </div>
            );
        }
        else if (onActionClick) {
            return (
                <img
                    className="cursor-pointer"
                    style={{ width: 25 }}
                    src="/images/icons/cancel.svg"
                    alt="cancel"
                    onClick={() => { onActionClick(); }}
                />
            );
        }
        return ("");
    };

    return (
        <div
            className="flex panel-header items-center"
            style={{ justifyContent: "space-between" }}
        >
            <div style={{ fontSize: 21, color: "#192132" }}>
                {panelIcon ? (
                    <div className="flex item-center">
                        <img src={`/images/icons/api-${panelIcon}-icon-home.svg`} alt="status" className="mr-2" style={{ width: "30px", height: "30px" }} />
                        {panelTitle}
                    </div>)
                    :
                    panelTitle ? panelTitle : ""
                }
            </div>
            {getHeaderAction()}
        </div>
    );
};
