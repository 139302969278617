import React, { useState, useContext } from "react";
import { Input } from "antd";
import { AppContext } from "../../Core/store/app-context";

let debounceTimeout = null;

export const SearchBox = (props) => {
  const { onChange, placeholder, allowClear, searchOnModule } = props;
  const ctx = useContext(AppContext);
  const [searchValue, setSearchValue] = useState(
    searchOnModule && searchOnModule === ctx.moduleState.moduleName
      ? ctx.moduleState.searchValue
      : null
  );

  const debounceSearch = (value) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    setSearchValue(value);
    debounceTimeout = setTimeout(() => {
      onChange(value);
    }, 500);
  };

  return (
    <Input
      type="search"
      size="large"
      value={searchValue}
      style={{
        borderRadius: "20px",
        maxWidth: "440px",
        height: "40px",
        boxShadow: "0 1px 10px #00000012",
      }}
      className="text-h1 font-poppins border-0"
      placeholder={placeholder ? placeholder : "Search"}
      onChange={(e) => {
        debounceSearch(e.target.value);
      }}
      prefix={
        <img src="/images/icons/search.svg" alt="search" className="ml-2" />
      }
      allowClear={allowClear === false ? false : true}
    />
  );
};
