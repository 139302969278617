import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "antd";
import { useParams } from "react-router-dom";

import { AppContext } from "../../../Core/store/app-context";

import { ConvertToDecimal } from "../../../Core/common/helpers";
import { SelectDropdown } from "../../../Core/common/SelectDropdown";
import { ImageSelector } from "../../../Core/common/ImageSelector";
import { CustomInput } from "../../../Core/common/CustomInput";
import { CustomSwitchToggle } from "../../../Core/common/CustomSwitchToggle";
import { CustomModal } from "../../../Core/common/CustomModal";
import { SidePanelFooter } from "../../../Core/common/SidePanelFooter";

export const SpaceChange = (props) => {
  const { itemId, getRecordList, hideChangeDrawer } = props;
  const ctx = useContext(AppContext);
  const { locationId } = useParams();

  const defaultListData = {
    id: "",
    name: "",
    description: "",
    code: "",
    typeName: "",
    typeID: null,
    length: 0,
    lengthMeter: 0,
    locationId: locationId,
    totalArea: 0,
    totalAreaUoM: "sq.ft.",
    totalCapacity: 0,
    totalCapacityUoM: "sq.ft.",
    targetUtilization: 0,
    targetUtilizationUoM: "sq.ft./person",
    image: null,
    model3D: null,
    plan2D: null,
    refXMeter: 0,
    refYMeter: 0,
    status: 1,
    width: 0,
    widthMeter: 0,
    isDefault: 0,
    isGPS: 0,
  };

  const [listAddData, setListAddData] = useState(defaultListData);

  const [spaceTypeList, setSpaceTypeList] = useState([]);
  const [spaceBase64Image, setSpaceBase64Image] = useState("");
  const [space3dModelBase64Image, setSpace3dModelBase64Image] = useState("");
  const [space2dFloorBase64Image, setSpace2dFloorBase64Image] = useState("");

  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      await getSpaceTypeList();
      if (itemId) {
        await getItemData();
      } else {
        setListAddData({ ...defaultListData });
      }
    }
    fetchData();
  }, [itemId]);

  const getItemData = async () => {
    const response = await ctx.HttpGet("/Location/space", { id: itemId });
    if (response) {
      setListAddData(response);
      if (response.image) {
        setSpaceBase64Image("data:image/png;base64," + response.image);
      }

      if (response.model3D) {
        setSpace3dModelBase64Image("data:image/png;base64," + response.model3D);
      }

      if (response.plan2D) {
        setSpace2dFloorBase64Image("data:image/png;base64," + response.plan2D);
      }
    }
  };

  const getSpaceTypeList = async () => {
    const response = await ctx.HttpGet("/Location/space/types");
    setSpaceTypeList(response);
  };

  const onPrimaryClick = () => {
    if (validate()) {
      if (itemId) {
        setShowUpdateModal(true);
      } else {
        setShowAddModal(true);
      }
    }
  };

  const validate = () => {
    if (listAddData.name === "" || listAddData.name.trim() === "") {
      ctx.showToastAlert({ type: "error", message: "Please enter name" });
      return false;
    } else if (listAddData.code === "") {
      ctx.showToastAlert({ type: "error", message: "Please enter code" });
      return false;
    } else if (listAddData.typeID === null) {
      ctx.showToastAlert({ type: "error", message: "Please select type" });
      return false;
    } else if (listAddData.width === 0) {
      ctx.showToastAlert({
        type: "error",
        message: "Please enter width in pixels",
      });
      return false;
    } else if (listAddData.length === 0) {
      ctx.showToastAlert({
        type: "error",
        message: "Please enter length in pixels",
      });
      return false;
    } else if (parseFloat(listAddData.widthMeter) === 0) {
      ctx.showToastAlert({
        type: "error",
        message: "Please enter width in meters",
      });
      return false;
    } else if (parseFloat(listAddData.lengthMeter) === 0) {
      ctx.showToastAlert({
        type: "error",
        message: "Please enter length in meters",
      });
      return false;
    } else if (parseFloat(listAddData.refXMeter) === 0) {
      ctx.showToastAlert({
        type: "error",
        message: "Please enter reference X in meters",
      });
      return false;
    } else if (parseFloat(listAddData.refYMeter) === 0) {
      ctx.showToastAlert({
        type: "error",
        message: "Please enter reference Y in meters",
      });
      return false;
    } else if (!listAddData.image) {
      ctx.showToastAlert({
        type: "error",
        message: "Please upload image of Space",
      });
      return false;
    }
    return true;
  };

  const createSpace = async () => {
    setModalButtonLoading(true);

    let payload = { ...listAddData };

    const response = await ctx.HttpPost("/Location/space", payload);
    if (response) {
      getRecordList();
      ctx.showToastAlert({ type: "success", message: response });
      hideChangeDrawer();
      setListAddData({ ...defaultListData });
      setSpaceBase64Image("");
      setSpace3dModelBase64Image("");
      setSpace2dFloorBase64Image("");
    }
    setShowAddModal(false);
    setModalButtonLoading(false);
  };

  const updateSpace = async () => {
    setModalButtonLoading(true);

    setListAddData({ ...listAddData, id: itemId });
    const response = await ctx.HttpPut("/Location/space", listAddData);
    if (response) {
      getRecordList();
      ctx.showToastAlert({ type: "success", message: response });
      hideChangeDrawer();
      setListAddData({ ...defaultListData });
      setSpaceBase64Image("");
      setSpace3dModelBase64Image("");
      setSpace2dFloorBase64Image("");
    }
    setShowUpdateModal(false);
    setModalButtonLoading(false);
  };

  return (
    <>
      <div className="mx-auto pt-3 px-7">
        <CustomInput
          label="Name of Space"
          maxlength={25}
          isRequired={true}
          inputValue={listAddData.name}
          onChange={(value) => {
            setListAddData({
              ...listAddData,
              name: value,
            });
          }}
        />
        <CustomInput
          label="Description"
          maxlength={25}
          inputValue={listAddData.description}
          onChange={(value) => {
            setListAddData({
              ...listAddData,
              description: value,
            });
          }}
        />
        <CustomInput
          label="Code"
          maxlength={5}
          isRequired={true}
          inputValue={listAddData.code}
          onChange={(value) => {
            setListAddData({
              ...listAddData,
              code: value,
            });
          }}
        />
        <SelectDropdown
          dataList={listAddData.typeID}
          optionsList={spaceTypeList}
          isRequired={true}
          mode="single"
          optionKeyName="Id"
          optionValueName="Id"
          optionDisplayName="Value"
          label="Type"
          value={
            listAddData.typeID !== null ? listAddData.typeID.toString() : null
          }
          onChange={(value, key) => {
            setListAddData({ ...listAddData, typeID: parseInt(value) });
          }}
        />
        <CustomInput
          label="Total Area in sq.ft."
          type="number"
          maxlength={5}
          inputValue={ConvertToDecimal(listAddData?.totalArea)?.toString()}
          onChange={(value) => {
            setListAddData({
              ...listAddData,
              totalArea: ConvertToDecimal(value),
            });
          }}
        />
        <CustomInput
          label="Total Capacity"
          maxlength={5}
          inputValue={parseInt(listAddData?.totalCapacity) || 0}
          onChange={(value) => {
            setListAddData({
              ...listAddData,
              totalCapacity: parseInt(value),
            });
          }}
        />
        <CustomInput
          label="Target utilization rate in sq.ft./person"
          type="number"
          maxlength={5}
          inputValue={ConvertToDecimal(
            listAddData?.targetUtilization
          )?.toString()}
          onChange={(value) => {
            setListAddData({
              ...listAddData,
              targetUtilization: ConvertToDecimal(value),
            });
          }}
        />
        <Col span={24}>
          <Row type="flex">
            <Col span={11} className="relative">
              <CustomInput
                label="Width in pixels"
                maxlength={4}
                isRequired={true}
                inputValue={parseInt(listAddData.width) || 0}
                onChange={(value) => {
                  setListAddData({
                    ...listAddData,
                    width: parseInt(value),
                  });
                }}
              />
            </Col>
            <Col span={2} />
            <Col span={11} className="relative">
              <CustomInput
                label="length in pixels"
                maxlength={4}
                isRequired={true}
                inputValue={parseInt(listAddData.length) || 0}
                onChange={(value) => {
                  setListAddData({
                    ...listAddData,
                    length: parseInt(value),
                  });
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row type="flex">
            <Col span={11} className="relative">
              <CustomInput
                label="Width in meters"
                type="number"
                maxlength={5}
                isRequired={true}
                inputValue={ConvertToDecimal(
                  listAddData.widthMeter
                )?.toString()}
                onChange={(value) => {
                  setListAddData({
                    ...listAddData,
                    widthMeter: ConvertToDecimal(value),
                  });
                }}
              />
            </Col>
            <Col span={2} />
            <Col span={11} className="relative">
              <CustomInput
                label="length in meters"
                type="number"
                isRequired={true}
                maxlength={5}
                inputValue={ConvertToDecimal(
                  listAddData.lengthMeter
                )?.toString()}
                onChange={(value) => {
                  setListAddData({
                    ...listAddData,
                    lengthMeter: ConvertToDecimal(value),
                  });
                }}
              />
            </Col>
          </Row>
        </Col>
        <CustomInput
          label="Reference X in meters"
          type="number"
          isRequired={true}
          maxlength={5}
          inputValue={ConvertToDecimal(listAddData.refXMeter)?.toString()}
          onChange={(value) => {
            setListAddData({
              ...listAddData,
              refXMeter: ConvertToDecimal(value),
            });
          }}
        />
        <CustomInput
          label="Reference Y in meters"
          type="number"
          isRequired={true}
          maxlength={5}
          inputValue={ConvertToDecimal(listAddData.refYMeter)?.toString()}
          onChange={(value) => {
            setListAddData({
              ...listAddData,
              refYMeter: ConvertToDecimal(value),
            });
          }}
        />
        <div className="relative mb-6">
          <CustomSwitchToggle
            label="Status"
            switchValue={listAddData?.status}
            onText="Active"
            offText="Inactive"
            onChange={(checked) =>
              setListAddData({
                ...listAddData,
                status: checked ? 1 : 0,
              })
            }
            checked={listAddData.status}
          />
          <div className="relative my-6">
            <CustomSwitchToggle
              label="Is Default"
              switchValue={listAddData?.isDefault}
              onText="On"
              offText="Off"
              onChange={(checked) =>
                setListAddData({
                  ...listAddData,
                  isDefault: checked ? 1 : 0,
                })
              }
              checked={listAddData.isDefault}
            />
          </div>
          <div className="relative my-6">
            <CustomSwitchToggle
              label="Is GPS"
              switchValue={listAddData?.isGPS}
              onText="On"
              offText="Off"
              onChange={(checked) =>
                setListAddData({
                  ...listAddData,
                  isGPS: checked ? 1 : 0,
                })
              }
              checked={listAddData.isGPS}
            />
          </div>
        </div>
        <div className="relative mb-6">
          <ImageSelector
            selectedBase64Image={spaceBase64Image}
            setSelectedBase64Image={setSpaceBase64Image}
            imageName="Image of Space"
            imageDetails={listAddData}
            setImageDetails={setListAddData}
            imageSizeMax="5"
            isRequired={true}
          />
        </div>

        <div className="relative mb-6">
          <ImageSelector
            selectedBase64Image={space3dModelBase64Image}
            setSelectedBase64Image={setSpace3dModelBase64Image}
            imageName="3D Space Model"
            imageDetails={listAddData}
            setImageDetails={setListAddData}
            imageSizeMax="5"
            imageKeyName="model3D"
            // allowedFiles = {[".c4d", "".fbx", ".glb", ".obj", ".stl", ".3dm", ".3ds", and ".babylon"]}
          />
        </div>

        <div className="relative mb-6">
          <ImageSelector
            selectedBase64Image={space2dFloorBase64Image}
            setSelectedBase64Image={setSpace2dFloorBase64Image}
            imageName="2D Space Floor Image"
            imageDetails={listAddData}
            setImageDetails={setListAddData}
            imageSizeMax="5"
            imageKeyName="plan2D"
          />
        </div>
      </div>

      <SidePanelFooter
        secondaryLabel={"Cancel"}
        onCancelClick={() => {
          hideChangeDrawer();
          setListAddData({ ...defaultListData });
          setSpaceBase64Image("");
          setSpace3dModelBase64Image("");
          setSpace2dFloorBase64Image("");
        }}
        primaryLabel={"Apply"}
        onPrimaryClick={() => onPrimaryClick()}
      />

      {/* Add Space */}
      <CustomModal
        showModal={showAddModal}
        titleText={"Add Space"}
        messageText={`Are you sure you want to add ${listAddData.name}?`}
        handleCancel={() => {
          setShowAddModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={createSpace}
        isLoading={modalButtonLoading}
      />

      {/* Update Space Id */}
      <CustomModal
        showModal={showUpdateModal}
        titleText={"Update Space"}
        messageText={`Are you sure you want to update the changes made to ${listAddData.name}?`}
        handleCancel={() => {
          setShowUpdateModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={updateSpace}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
