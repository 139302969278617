import { useContext, useState, useRef, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { createBrowserHistory } from 'history';

import "./App.css";

import { Login } from "./Core/Login/Login";
import { Home } from "./Core/Home/Home";

import { TenantList } from "./modules/tenant/TenantList";

import { RoleList } from './modules/role/RoleList';
import { UserList } from './modules/user/UserList';
import { SessionList } from './modules/session/SessionList';
import { OrganizationList } from "./modules/organization/OrganizationList";

import { DeviceList } from "./modules/device/DeviceList";
import { SystemList } from "./modules/system/SystemList";

//Rule
import { RuleList } from "./modules/rule/RuleList";
import { RuleAlertList } from './modules/rule/alert/RuleAlertList';
import { RuleLocationList } from './modules/rule/location/RuleLocationList';
import { RuleAssociateList } from './modules/rule/associate/RuleAssociateList';
import { DistributionList } from './modules/rule/distribution/DistributionList';

//Location
import { LocationList } from "./modules/location/LocationList";
import { SpaceList } from './modules/location/space/SpaceList';
import { SpaceMapView } from './modules/location/space/SpaceMapView';
import { ZoneList } from './modules/location/zone/ZoneList';
import { HardwareList } from './modules/location/hardware/HardwareList';

//IOT
import { ReaderList } from "./modules/iot/reader/ReaderList";
import { SensorList } from "./modules/iot/sensor/SensorList";
import { ProviderList } from "./modules/iot/provider/ProviderList";
import { GatewayList } from "./modules/iot/gateway/GatewayList";

//Logs
import { SyncLogList } from "./modules/logs/sync/SyncLogList";
import { AuditLogList } from "./modules/logs/audit/AuditLogList";
import { ChangeLogList } from "./modules/logs/change/ChangeLogList";
import { HealthLogList } from "./modules/logs/health/HealthLogList";
import { MessageLogList } from "./modules/logs/message/MessageLogList";
import { ExceptionLogList } from "./modules/logs/exception/ExceptionLogList";
import { SessionLogList } from './modules/logs/session/SessionLogList';
import { SessionLogDetails } from './modules/logs/session/SessionDetails';

import { TransactionList } from './modules/transaction/TransactionList';

import { AppContext } from "./Core/store/app-context";
import { CustomModal } from './Core/common/CustomModal';

const history = createBrowserHistory();

function App() {
  const ctx = useContext(AppContext);
    // reference for timer
    const timerRef = useRef(null);

    // The state for our timer
    const [timer, setTimer] = useState('60');
    const [showInactivityModal, setShowInactivityModal] = useState(false);

    // commented for time being
    useEffect(() => {
      if (ctx?.userInfo?.TimeoutMins) {
        resetTimer();
      }
    }, [ctx.userInfo])

    const clearTimer = (e) => {

        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next	
        // setTimer('60');

        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec

        if (timerRef.current) clearInterval(timerRef.current);
        timerRef.current = setInterval(() => {
            let total = new Date(e) - new Date();
            let seconds = Math.floor((total / 1000) % 60);

            if (total >= 0) {

                // update the timer
                // check if less than 10 then we need to
                // add '0' at the begining of the variable
                setTimer(
                    (seconds > 9 ? seconds : '0' + seconds)
                )
            } else {
                clearInterval(timerRef.current);
                timerRef.current = undefined;
                setShowInactivityModal(false);
                ctx.AppLogout();
            }
        }, 1000)
    }


    // window.onmousemove = resetTimer;   // catches mouse movements
    // window.onmousedown = resetTimer;      // catches mouse movements
    window.onclick = resetTimer;       // catches mouse clicks - commented for time being
    // window.onscroll = resetTimer;      // catches scrolling
    // window.onkeypress = resetTimer;    //catches keyboard actions


    function resetTimer() {
        if (!ctx.userInfo || !ctx.userInfo.TimeoutMins || showInactivityModal) {
            return;
        }
        let timeout = (ctx.userInfo.TimeoutMins * 1000 * 60) + 1000;
        clearTimeout(window['timeoutVariable']);
        window['timeoutVariable'] = setTimeout(() => {
            if (!showInactivityModal && !timerRef.current) {
                setShowInactivityModal(true);
                clearTimer(new Date().getTime() + 60000); // set 60 second countdown
            }
        }, timeout);  // time is in milliseconds (1000 is 1 second)
    }

    return (
        <>
            <Router history={history} >
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/login" component={Login} />
                    <PrivateRoute exact path="/home" component={Home} />

                    <PrivateRoute exact path="/tenant" component={TenantList} />

                    <PrivateRoute exact path="/role-list" component={RoleList} />
                    <PrivateRoute exact path="/user-list" component={UserList} />
                    <PrivateRoute exact path="/session-list" component={SessionList} />
                    <PrivateRoute exact path="/organization-list" component={OrganizationList} />

                    <PrivateRoute exact path="/location" component={LocationList} />
                    <PrivateRoute exact path="/location/space/:locationId" component={SpaceList} />
                    <PrivateRoute exact path="/location/space/mapView/:spaceId" component={SpaceMapView} />
                    <PrivateRoute exact path="/location/space/zone/:spaceId" component={ZoneList} />
                    <PrivateRoute exact path="/location/space/zone/hardware/:zoneId" component={HardwareList} />

                    <PrivateRoute exact path="/device" component={DeviceList} />
                    <PrivateRoute exact path="/system" component={SystemList} />

                    <PrivateRoute exact path='/rule' component={RuleList} />
                    <PrivateRoute path='/rule-alert/:ruleId' component={RuleAlertList} />
                    <PrivateRoute exact path='/rule-location/:ruleId' component={RuleLocationList} />
                    <PrivateRoute exact path='/rule-associate/:ruleId' component={RuleAssociateList} />
                    <PrivateRoute exact path='/distribution' component={DistributionList} />

                    <PrivateRoute exact path="/logs-sync" component={SyncLogList} />
                    <PrivateRoute exact path="/logs-audit" component={AuditLogList} />
                    <PrivateRoute exact path="/logs-change" component={ChangeLogList} />
                    <PrivateRoute exact path="/logs-health" component={HealthLogList} />
                    <PrivateRoute exact path="/logs-session" component={SessionLogList} />
                    <PrivateRoute exact path="/logs-session/session-details/:sessionId" component={SessionLogDetails} />
                    <PrivateRoute exact path="/logs-message" component={MessageLogList} />
                    <PrivateRoute exact path="/logs-exception" component={ExceptionLogList} />

                    <PrivateRoute exact path="/iot-provider" component={ProviderList} />
                    <PrivateRoute exact path="/iot-sensor" component={SensorList} />
                    <PrivateRoute exact path="/iot-reader" component={ReaderList} />
                    <PrivateRoute exact path="/iot-gateway" component={GatewayList} />

                    <PrivateRoute exact path="/transaction" component={TransactionList} />
                </Switch>
            </Router>
            <CustomModal
                // icon={modalData.icon}
                showModal={showInactivityModal}
                titleText={"Inactivity Timeout"}
                messageText={`User will be logged out in ${timer} seconds`}
                confirmButtonText={"Cancel"}
                showCancelButton={false}
                handleConfirm={() => {
                    setShowInactivityModal(false);
                    clearInterval(timerRef.current);
                    timerRef.current = null;
                    // localStorage.setItem('inactivity-timeout', moment().add(ctx.userInfo.TimeoutMins, 'minutes').format('YYYY-MM-DD HH:mm:ss'));
                }}
                zIndex={1002}
            />
        </>
    );
}

export default App;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const ctx = useContext(AppContext);
  if (!ctx || !ctx.userInfo || !ctx.userInfo.UserID) {
    ctx.AppLogout(false);
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        ctx && ctx.userInfo && ctx.userInfo.UserID ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        )
      }
    />
  )
};