import React, { useState, useEffect, useContext } from "react";

import { Drawer } from "antd";

import { AppContext } from "../store/app-context";
import { CustomDatePicker } from "./CustomDatePicker";
import { CustomInput } from "./CustomInput";
import { GetMomentDateObj } from "./helpers";
import { SelectDropdown } from "./SelectDropdown";
import { SidePanelTitle } from "./SidePanelTitle";
import { SidePanelFooter } from "./SidePanelFooter";

export const SidePanelFilter = (props) => {
    const ctx = useContext(AppContext);

    const {
        moduleRoute,
        filterData,
        setFilterData,
        panelIcon,
        panelWidth,
        panelVisible,
        panelBody,
        onCancelClick,
        onChangeClick,
    } = props;

    const [filterList, setFilterList] = useState([]);

    // list for filters
    const [statusList, setStatusList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [functionList, setFunctionList] = useState([]);
    const [orgCodeList, setOrgCodeList] = useState([]);
    const [auditTypeList, setAuditTypeList] = useState([]);

    const [appList, setAppList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [actionList, setActionList] = useState([]);

    //list for local Search
    const [userSearchResult, setUserSearchResult] = useState([]);
    const [modelSearchResult, setModelSearchResult] = useState([]);

    useEffect(() => {
        if(panelVisible){
            getFilterList();
        }
    }, [panelVisible]);

    const getFilterList = async () => {
        if (filterList.length) {
            return;
        }
        const response = await ctx.HttpGet(moduleRoute + "/filter");
        if (response) {
            setFilterList(response);

            response?.forEach(async (filterItem) => {
                if (filterItem.dataIndex === "OrgCode") {
                    await getOrgCodeList();
                }
                else if (filterItem.dataIndex === "Service") {
                    await getServiceList();
                }
                else if (filterItem.dataIndex === "Status") {
                    await getStatusList();
                }
                else if (filterItem.dataIndex === "AuditType") {
                    await getAuditTypeList();
                }
                else if (filterItem.dataIndex === "AppId") {
                    await getAppList();
                }
                else if (filterItem.dataIndex === "UserId" || filterItem.dataIndex === "CreatedBy") {
                    await getUserList();
                }
                else if (filterItem.dataIndex === "ModelName") {
                    await getModelList();
                }
                else if (filterItem.dataIndex === "Action") {
                    await getActionList();
                }
            });
        }
    };


    const getOrgCodeList = async () => {
        if (orgCodeList.length) {
            return;
        }
        const response = await ctx.HttpGet(moduleRoute + "/orgcode");
        if (response) {
            setOrgCodeList(response);
        }
    };
    const getServiceList = async () => {
        if (serviceList.length) {
            return;
        }
        const response = await ctx.HttpGet(moduleRoute + "/service");
        if (response) {
            setServiceList(response);
            setFunctionList([]);
        }
    };
    const getFunctionList = async (serviceId) => {
        if (serviceId && filterList?.find(x => x.dataIndex === "Function")) {
            const response = await ctx.HttpGet(moduleRoute + "/function", { serviceId });
            if (response) {
                setFunctionList(response);
            }
        }
    };

    const getAppList = async () => {
        if (appList.length) {
            return;
        }
        const response = await ctx.HttpGet(moduleRoute + "/app");
        if (response) {
            setAppList(response);
        }
    };
    const getUserList = async () => {
        if (userList.length) {
            return;
        }
        const response = await ctx.HttpGet(moduleRoute + "/user");
        if (response) {
            setUserList(response);
            setUserSearchResult(response);
        }
    };
    const getActionList = async () => {
        if (actionList.length) {
            return;
        }
        const response = await ctx.HttpGet(moduleRoute + "/action");
        if (response) {
            setActionList(response);
        }
    };
    const getModelList = async () => {
        if (modelList.length) {
            return;
        }
        const response = await ctx.HttpGet(moduleRoute + "/model");
        if (response) {
            setModelList(response);
            setModelSearchResult(response);
        }
    };

    const getStatusList = async () => {
        if (statusList.length) {
            return;
        }
        const response = await ctx.HttpGet(moduleRoute + "/status");
        if (response) {
            setStatusList(response);

        }
    };
    const getAuditTypeList = async () => {
        if (auditTypeList.length) {
            return;
        }
        const response = await ctx.HttpGet(moduleRoute + "/auditType");
        if (response) {
            setAuditTypeList(response);
        }
    };

    const getFilterRenderer = (filterItem) => {

        //Date Range
        if (filterItem.dataIndex === "CreatedOn") {
            return (
                <CustomDatePicker
                    label=""
                    startDate={filterData?.TimestampStart}
                    endDate={filterData?.TimestampEnd}
                    mode="rangePicker"
                    startDatePlaceholder="Start Date"
                    endDatePlaceholder="End Date"
                    onStartDateChange={(date, dateString) => {
                        setFilterData({ ...filterData, TimestampStart: dateString });
                    }}
                    onEndDateChange={(date, dateString) => {
                        setFilterData({ ...filterData, TimestampEnd: dateString });
                    }}
                    disabledStartDate={(current) => {
                        return (
                            (current && current > GetMomentDateObj().endOf("day")) ||
                            current > GetMomentDateObj(filterData?.TimestampEnd)
                        );
                    }}
                    disabledEndDate={(current) => {
                        return (
                            (current && current > GetMomentDateObj().endOf("day")) ||
                            current < GetMomentDateObj(filterData?.TimestampStart)
                        );
                    }}
                />
            );
        }

        // Dropdowns
        else if (["OrgCode", "Service", "Function", "Status", "AuditType", "AppId", "UserId", "CreatedBy", "ModelName", "Action"].includes(filterItem.dataIndex)) {
            let optionsList = null;
            let mode = "single";
            let optionKeyName = "Value";
            let optionValueName = "Value";
            let optionDisplayName = "Value";
            let label = filterItem.title;
            let showSearch = false;
            let onSearchInput = null;
            let value = filterData?.[filterItem.dataIndex];
            let disabled = false;
            let onChange = (value, key) => {
                setFilterData({ ...filterData, [filterItem.dataIndex]: value });
            }

            if (filterItem.dataIndex === "OrgCode") {
                optionsList = orgCodeList;
            } else if (filterItem.dataIndex === "Service") {
                optionsList = serviceList;
                onChange = (value, key) => {
                    setFilterData({ ...filterData, Service: value, Function: null });
                    getFunctionList(value);
                };
            } else if (filterItem.dataIndex === "Function") {
                optionsList = functionList;
                disabled = !filterData?.Service ? true : false;
            } else if (filterItem.dataIndex === "Status") {
                optionsList = statusList;
                optionKeyName = "Id";
                optionValueName = "Id";
                label = "Status";
            } else if (filterItem.dataIndex === "AuditType") {
                optionsList = auditTypeList;
                optionKeyName = "Id";
                optionValueName = "Id";
                label = "Audit Type";
            } else if (filterItem.dataIndex === "AppId") {
                optionsList = appList;
            } else if (filterItem.dataIndex === "UserId" || filterItem.dataIndex === "CreatedBy") {
                optionsList = userSearchResult;
                optionKeyName = "Id";
                optionValueName = "Id";
                value = filterData?.UserId;
                onChange = (value, key) => {
                    setFilterData({ ...filterData, UserId: value });
                };
                showSearch = true;
                onSearchInput = (value) => {
                    let data = userList.filter((item) =>
                        item.Value?.toLowerCase().includes(value?.toLowerCase())
                    );
                    setUserSearchResult(data);
                };
            } else if (filterItem.dataIndex === "ModelName") {
                optionsList = modelSearchResult;
                showSearch = true;
                onSearchInput = (value) => {
                    let data = modelList.filter((item) =>
                        item.Value?.toLowerCase().includes(value?.toLowerCase())
                    );
                    setModelSearchResult(data);
                };
            } else if (filterItem.dataIndex === "Action") {
                optionsList = actionList;
                optionKeyName = "Id";
                optionValueName = "Id";
                label = "Action";
            }

            return (
                <SelectDropdown
                    dataList={filterData?.[filterItem.dataIndex]}
                    optionsList={optionsList}
                    mode={mode}
                    optionKeyName={optionKeyName}
                    optionValueName={optionValueName}
                    optionDisplayName={optionDisplayName}
                    label={label}
                    value={value}
                    onChange={onChange}
                    showSearch={showSearch}
                    onSearchInput={onSearchInput}
                    disabled={disabled}
                />
            );
        }

        // Text
        return (
            <CustomInput
                label={filterItem.title}
                inputValue={filterData?.[filterItem.dataIndex]}
                onChange={(value) => {
                    setFilterData({ ...filterData, [filterItem.dataIndex]: value });
                }}
            />
        );
    };

    return (
        <Drawer
            visible={panelVisible}
            className={"sidepanel-section"}
            onClose={onCancelClick}
            width={panelWidth ? panelWidth : 420}
            bodyStyle={{ padding: 0 }}
            closable={false}
            destroyOnClose={true}
            contentWrapperStyle={{
                borderTopLeftRadius: "24px",
                borderBottomLeftRadius: "24px",
                overflow: "hidden",
            }}
            maskClosable={false}
            zIndex={10}
        >
            <SidePanelTitle
                panelIcon={panelIcon}
                panelTitle={"Filter"}
                actionText={"Clear"}
                onActionClick={() => {
                    setFilterData({ isFilter: false });
                }}
            />

            <div
                className="panel-content custom-scrollbar sidepanel_filter"
                style={{
                    height: `calc(100vh - 143px)`,
                    // marginBottom: `${panelFooter ? "10px" : "0"}`,
                    marginTop: "30px",
                    overflow: "auto",
                    paddingTop: 0,
                }}
            >
                {panelBody ? panelBody :
                <div className="mb-5 mt-1">
                    {filterList?.map((filterItem, index) => {
                        return <div key={`${filterItem}-${index}`}>{getFilterRenderer(filterItem)}</div>
                    })}
                </div>
                }
            </div>

            <SidePanelFooter
                panelWidth={panelWidth}
                onCancelClick={onCancelClick}
                onPrimaryClick={() => {
                    setFilterData({ ...filterData, isFilter: true });
                    onChangeClick();
                }}
            />

        </Drawer>
    );
};