import React, { useState, useEffect, useContext } from "react";

import { Layout } from "../../../Core/layout";
import { DataTable, getClickableItemIndex } from "../../../Core/common/DataTable";
import { AppContext } from "../../../Core/store/app-context";
import { FeatureTypes } from "../../../Core/store/app-feature";
import { GetTimezoneOffset } from "../../../Core/common/helpers";
import { OptionsDropdown } from "../../../Core/common/OptionsDropdown";
import { SidePanelDisplay } from "../../../Core/common/SidePanelDisplay";
import { SidePanelPreferences } from "../../../Core/common/SidePanelPreferences";

import { ProviderChange } from "./ProviderChange";
import { SidePanelChange } from "../../../Core/common/SidePanelChange";

export const ProviderList = (props) => {
  const ctx = useContext(AppContext);

  const [pageSize, setPageSize] = useState(10);
  const [offsetValue, setOffsetValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [showUpdateDrawer, setShowUpdateDrawer] = useState(false);
  const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
  const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

  const [columnList, setColumnList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");
  const [listData, setListData] = useState({});

  useEffect(() => {
      async function fetchData() {
          // You can await here
          await getRecordList();
      }
      fetchData();
  }, []);

  const getRecordList = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    offset = offsetValue,
    limit = pageSize
  ) => {
    let queryParams = {
      search: searchBy,
      sortBy: sortBy,
      orderBy: orderBy,
      offset: offset,
      limit: limit,
      utcOffset: GetTimezoneOffset(),
    };
    const response = await ctx.HttpGetList("/Provider/list", queryParams);
    if (response) {
      setRecordList(response.Data);
      setTotalCount(response.Count);
      setColumnList(response.Headers);
      setSearchPlaceholder(response.searchPlaceholder);
    }
  };

    // add click event if user have view access
    if (ctx.acl(FeatureTypes.Providers, 'GetProvider') && columnList?.length > 1) {
        columnList[getClickableItemIndex(columnList)]["onClick"] = (record) => {
            setListData(record);
            setShowDisplayDrawer(true);
        }
    }
    const handleOptionMenuItemClick = (clickedItem) => {
        setShowDisplayDrawer(false);
        if (clickedItem === "Update") {
            setShowUpdateDrawer(true);
        }
    };

  // add action items as per role access
  const getActionButtonItems = () => {
    let actionButtonItems = [];
    if (ctx.acl(FeatureTypes.Providers, 'PutProvider')) {
      actionButtonItems.push('Update');
    }
    return actionButtonItems;
  }

  return (
    <>
      <Layout
        page="iot-provider"
        id={FeatureTypes.Providers}
        searchOnModule={FeatureTypes.Providers}
        searchPlaceholder={searchPlaceholder}
        onSearchChange={(value) => {
          setSearchValue(value);
          setOffsetValue(1);
          getRecordList(value, null, null, 1);
        }}
        showFilter={false}
      >
        <DataTable
            showActionButton={getActionButtonItems().length ? true : false}
            getActionButtonItems={getActionButtonItems}
            onActionButtonClick={(record) => {
                setListData(record);
            }}
            actionButtonItemClick={(clickedItem) => {
                handleOptionMenuItemClick(clickedItem);
            }}
            dataSource={recordList}
            columns={columnList}
            totalRecords={totalCount}
            pageSize={pageSize}
            currentPage={offsetValue}
            rowKey={"Id"}
            onUserPreferenceClick={() => {
                setShowPreferenceDrawer(true);
            }}
            onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
                let sortOrderValue = "";
                if (sorterOptions && sorterOptions.order) {
                    sortOrderValue =
                        sorterOptions.order === "ascend" ? "ASC" : "DESC";
                    setOrderByValue(sortOrderValue);
                    setSortByValue(sorterOptions.columnKey);
                }
                setPageSize(pageSizeOptions.pageSize);
                setOffsetValue(pageSizeOptions.current);
                getRecordList(
                    searchValue,
                    sorterOptions.columnKey,
                    sortOrderValue,
                    pageSizeOptions.current,
                    pageSizeOptions.pageSize
                );
            }}
        />
      </Layout>

          <SidePanelChange
              panelIcon={"providers"}
              panelTitle={"Provider Device"}
              panelVisible={showUpdateDrawer}
              panelBody={(
                  <ProviderChange
                      itemId={listData?.Id}
                      getRecordList={getRecordList}
                      hideChangeDrawer={() => {
                          setShowUpdateDrawer(false);
                          setListData({});
                      }}
                  />
              )}
          />

          <SidePanelDisplay
              listData={listData}
              headerData={columnList}
              panelIcon={"providers"}
              panelTitle={"Provider Details"}
              panelVisible={showDisplayDrawer}
              customAction={(
                  <OptionsDropdown
                      actionButtonItems={getActionButtonItems()}
                      actionButtonItemClick={(clickedItem) => {
                          handleOptionMenuItemClick(clickedItem);
                      }}
                  />
              )}
              onCancelClick={() => {
                  setShowDisplayDrawer(false);
                  setListData({});
              }}
          />

          <SidePanelPreferences
              module={"/Provider/list"}
              panelIcon={"providers"}
              panelVisible={showPreferenceDrawer}
              onCancelClick={() => {
                  setShowPreferenceDrawer(false);
              }}
              onChangeClick={() => {
                  setShowPreferenceDrawer(false);
                  getRecordList(searchValue, sortByValue, orderByValue, offsetValue);
              }}
          />
    </>
  );
};
