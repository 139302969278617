import { Button } from "antd";

export const SidePanelFooter = (props) => {
    const {
        onCancelClick,
        secondaryLabel,
        onPrimaryClick,
        primaryLabel,
        panelWidth
    } = props;

    return (
      <div
          className="panel-footer"
          style={{
              boxShadow: "0px -3px 6px #0000000a",
              position: "fixed",
              bottom: 0,
              width: panelWidth ? panelWidth : 420,
              zIndex: 2
          }}
      >
          {onCancelClick ? (
              <Button
                  onClick={() => onCancelClick()}
                  className="border-0 text-h1 font-semibold"
                  style={{
                      width: "50%",
                      height: "50px",
                      borderBottomLeftRadius: "24px",
                      color: "#4E2C90",
                  }}
              >
                  {secondaryLabel ? secondaryLabel : "Cancel"}
              </Button>
          ) : (
              " "
          )}
          {onPrimaryClick ? (
              <Button
                  onClick={() => onPrimaryClick()}
                  className="bg-daisy-bush border-0 bg-daisy-bush text-white rounded-none"
                  style={{
                      width: onCancelClick ? "50%" : "100%",
                      height: "50px",
                      borderColor: "#4E2C90",
                  }}
              >
                    {primaryLabel ? primaryLabel : "Apply"}
              </Button>
          ) : (
              " "
          )}
      </div>
  );
};
