import React, { useState, useContext, useEffect } from "react";

import { AppContext } from "../../../Core/store/app-context";
import { CustomModal } from "../../../Core/common/CustomModal";
import { CustomInput } from "../../../Core/common/CustomInput";
import { CustomSwitchToggle } from "../../../Core/common/CustomSwitchToggle";
import { SelectDropdown } from "../../../Core/common/SelectDropdown";
import { SidePanelFooter } from "../../../Core/common/SidePanelFooter";
import { ConvertToDecimal } from "../../../Core/common/helpers";

export const ProviderChange = (props) => {
    const ctx = useContext(AppContext);

    const {
        itemId,
        getRecordList,
        hideChangeDrawer,
    } = props;

    const defaultListData = {
        Id: "",
        Name: "",
    };

    const [systemTypes, setSystemTypes] = useState([]);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [modalButtonLoading, setModalButtonLoading] = useState(false);
    const [listEditData, setListEditData] = useState(defaultListData);

    useEffect(() => {
        async function fetchData() {
            // You can await here
            await getSystemTypes();
            if (itemId) {
                await getItemData();
            }
            else {
                setListEditData(defaultListData);
            }
        }
        fetchData();
    }, [itemId]);


    const getSystemTypes = async () => {
        const response = await ctx.HttpGet("/Provider/systems", {});
        if (response) {
            setSystemTypes(response);
        }
    };

    const getItemData = async () => {
        const response = await ctx.HttpGet("/Provider", { id: itemId });
        if (response) {
            setListEditData(response);
        }
    };

    const validate = () => {
        if (listEditData?.Id === "") {
            ctx.showToastAlert({ type: "error", message: "Please enter App" });
            return false;
        }
        else if (listEditData?.Name === null) {
            ctx.showToastAlert({ type: "error", message: "Please enter provider name" });
            return false;
        }
        return true;
    };

    const onPrimaryClick = () => {
        if (validate()) {
            if (itemId)
                setShowUpdateModal(true);
        }
    };

    const UpdateProvider = async () => {
        setModalButtonLoading(true);

        let payload = { ...listEditData, Id: itemId };

        const response = await ctx.HttpPut("/Provider", payload);
        if (response) {
            getRecordList();
            ctx.showToastAlert({ type: "success", message: response });
            hideChangeDrawer();
            setListEditData(defaultListData);
        }
        setShowUpdateModal(false);
        setModalButtonLoading(false);
    };

    return (
    <>
        <div>
        <div
            className="pb-5 px-8 items-center pt-3"
            style={{ borderColor: "#F3F5F8" }}
        >
            <CustomInput
                label="Name"
                inputValue={listEditData?.Name}
                onChange={(value) => {
                    setListEditData({ ...listEditData, Name: value });
                }}
            />

            <SelectDropdown
                dataList={listEditData?.InSystemId}
                optionsList={systemTypes}
                mode="single"
                optionKeyName="Id"
                optionValueName="Id"
                optionDisplayName="Value"
                value={systemTypes.length>0 && listEditData.InSystemId}
                label="Ingest Credentials"
                onChange={(value, key) => {
                    setListEditData({ ...listEditData, InSystemId: value });
                }}
            />

            <CustomInput
                label="API Url"
                inputValue={listEditData?.ApiUrl}
                onChange={(value) => {
                    setListEditData({ ...listEditData, ApiUrl: value });
                }}
            />
            <SelectDropdown
                dataList={listEditData?.OutSystemId}
                optionsList={systemTypes}
                mode="single"
                optionKeyName="Id"
                optionValueName="Id"
                optionDisplayName="Value"
                value={systemTypes.length>0 && listEditData.OutSystemId}
                label="API Credentials"
                onChange={(value, key) => {
                    setListEditData({ ...listEditData, OutSystemId: value });
                }}
            />
            <SelectDropdown
                dataList={listEditData?.QueSystemId}
                optionsList={systemTypes}
                mode="single"
                optionKeyName="Id"
                optionValueName="Id"
                optionDisplayName="Value"
                value={systemTypes.length>0 && listEditData.QueSystemId}
                label="Proxy Queue"
                onChange={(value, key) => {
                    setListEditData({ ...listEditData, QueSystemId: value });
                }}
            />
            <CustomInput
                label="Tolerance in meters"
                type="number"
                maxlength={5}
                inputValue={ConvertToDecimal(listEditData?.ToleranceMeters)?.toString()}
                onChange={(value) => {
                    setListEditData({...listEditData, ToleranceMeters: ConvertToDecimal(value),
                        });
                }}
            />
            <div className="mb-5">
                <CustomSwitchToggle
                    label="Status"
                    switchValue={listEditData?.Status}
                    onText="Active"
                    offText="Inactive"
                    onChange={(checked) =>
                        setListEditData({
                            ...listEditData,
                            Status: checked ? 1 : 0,
                        })
                    }
                    checked={listEditData?.Status}
                    styleName="labelGreyDarkText"
                />
            </div>
            </div>

                <SidePanelFooter
                    secondaryLabel={"Cancel"}
                    onCancelClick={() => {
                        hideChangeDrawer();
                        setListEditData(defaultListData);
                    }}
                    primaryLabel={"Apply"}
                    onPrimaryClick={() => onPrimaryClick()}
                />

        </div>

        {/* Update Provider */}
        <CustomModal
            showModal={showUpdateModal}
            titleText="Update Provider"
            messageText={`Are you sure you want to update the changes made to ${listEditData?.Name}?`}
            handleCancel={() => {
                setShowUpdateModal(false);
            }}
            confirmButtonText={"Yes"}
            handleConfirm={UpdateProvider}
            isLoading={modalButtonLoading}
        />
    </>
    );
};
