import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Layout } from "../../../Core/layout";
import { AppContext } from "../../../Core/store/app-context";
import { FeatureTypes } from "../../../Core/store/app-feature";
import { DataTable, getClickableItemIndex } from "../../../Core/common/DataTable";
import { OptionsDropdown } from "../../../Core/common/OptionsDropdown";
import { CustomModal } from "../../../Core/common/CustomModal";
import { SidePanelPreferences } from "../../../Core/common/SidePanelPreferences";
import { SidePanelChange } from "../../../Core/common/SidePanelChange";
import { SidePanelDisplay } from "../../../Core/common/SidePanelDisplay";

import { SpaceChange } from "./SpaceChange";
import { SpaceDisplay } from "./SpaceDisplay";

export const SpaceList = (props) => {
  const history = useHistory();
  const ctx = useContext(AppContext);
  const { locationId } = useParams();

  const [pageSize, setPageSize] = useState(10);
  const [offsetValue, setOffsetValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [showUpdateDrawer, setShowUpdateDrawer] = useState(false);
  const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
  const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

  const [columnList, setColumnList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");

  const [itemId, setItemId] = useState(null);
  const [itemName, setItemName] = useState(null);

  // Consent
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);

  useEffect(() => {
    getRecordList();
  }, [searchValue]);

  const getRecordList = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    offset = offsetValue,
    limit = pageSize
  ) => {
    let queryParams = {
      search: searchBy,
      sortBy: sortBy,
      orderBy: orderBy,
      offset: offset,
      limit: limit,
      LocationID: locationId,
    };
    const response = await ctx.HttpGetList("/Location/space/list", queryParams);
    if (response) {
      setRecordList(response.Data);
      setTotalCount(response.Count);
      setColumnList(response.Headers);
      setSearchPlaceholder(response.searchPlaceholder);
    }
  };

  // add click event if user have view access
  if (ctx.acl(FeatureTypes.Locations, "GetSpace") && columnList?.length > 1) {
    columnList[getClickableItemIndex(columnList)]["onClick"] = (record) => {
      setItemId(record.id);
      setShowDisplayDrawer(true);
      setItemName(record.name);
    };
  }

  const handleOptionMenuItemClick = (clickedItem) => {
    setShowDisplayDrawer(false);
    if (clickedItem === "Update") {
      setShowUpdateDrawer(true);
    } else if (clickedItem === "Remove") {
      setShowDeleteModal(true);
    }

    // set module state before redirecting
    // handle state from here, refer rule list

    if (itemId) {
      if (clickedItem === "View Zones") {
        history.push(`/location/space/zone/${itemId}`);
      }
      if (clickedItem === "Map View") {
        history.push(`/location/space/mapView/${itemId}`);
      }
    }
  };

  const deleteSpaceData = async () => {
    setModalButtonLoading(true);
    const response = await ctx.HttpDelete("/Location/space", {
      aId: itemId,
    });
    if (response) {
      ctx.showToastAlert({
        type: "success",
        message: response,
      });
      getRecordList();
    }
    setShowDeleteModal(false);
    setModalButtonLoading(false);
  };

  // add action items as per role access
  const getActionButtonItems = (listItem = null) => {
    let actionButtonItems = [];
    if (ctx.acl(FeatureTypes.Locations, "PutSpace")) {
      actionButtonItems.push("Update");
    }
    if (ctx.acl(FeatureTypes.Locations, "DeleteSpace")) {
      actionButtonItems.push("Remove");
    }
    if (ctx.acl(FeatureTypes.Locations, "GetZoneList")) {
      actionButtonItems.push("View Zones");
    }
    if (ctx.acl(FeatureTypes.Locations, "GetSpaceList")) {
      actionButtonItems.push("Map View");
    }
    return actionButtonItems;
  };
  return (
    <>
      <Layout
        page="spaces"
        id={FeatureTypes.Spaces}
        title="Spaces"
        subTitle="Manage Spaces here"
        pageTitleButton={ctx.acl(FeatureTypes.Locations, "PostSpace")}
        buttonOnClick={() => {
          setItemId(null);
          setShowAddDrawer(true);
        }}
        buttonLabel="Add Space"
        searchOnModule={FeatureTypes.Spaces}
        searchPlaceholder={searchPlaceholder}
        onSearchChange={(value) => {
          setSearchValue(value);
          setOffsetValue(1);
          getRecordList(value, null, null, 1);
        }}
        showPageActionButton={true}
        pageActionButtonLabel="Back"
        pageActionButtonIcon="/images/icons/Previous-icon.svg"
        pageActionButtonOnClick={() => {
          history.goBack();
        }}
        showFilter={false}
      >
        <DataTable
          showActionButton={getActionButtonItems().length ? true : false}
          getActionButtonItems={getActionButtonItems}
          onActionButtonClick={(record, clickedItem = null) => {
            setItemId(record.id);
            setItemName(record.name);
            if (clickedItem === "View Zones") {
              history.push(`/location/space/zone/${record.id}`);
            }
            if (clickedItem === "Map View") {
              history.push(`/location/space/mapView/${record.id}`);
            }
          }}
          actionButtonItemClick={(clickedItem) => {
            handleOptionMenuItemClick(clickedItem);
          }}
          dataSource={recordList}
          columns={columnList}
          totalRecords={totalCount}
          pageSize={pageSize}
          currentPage={offsetValue}
          rowKey={"id"}
          onUserPreferenceClick={() => {
            setShowPreferenceDrawer(true);
          }}
          onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
            let sortOrderValue = "";
            if (sorterOptions && sorterOptions.order) {
              sortOrderValue =
                sorterOptions.order === "ascend" ? "ASC" : "DESC";
              setOrderByValue(sortOrderValue);
              setSortByValue(sorterOptions.columnKey);
            }
            setPageSize(pageSizeOptions.pageSize);
            setOffsetValue(pageSizeOptions.current);
            getRecordList(
              searchValue,
              sorterOptions.columnKey,
              sortOrderValue,
              pageSizeOptions.current,
              pageSizeOptions.pageSize
            );
          }}
        />
      </Layout>

      <SidePanelChange
        // panelIcon={"spaces"}
        panelTitle={"Add Space"}
        panelVisible={showAddDrawer}
        panelBody={
          <SpaceChange
            getRecordList={getRecordList}
            hideChangeDrawer={() => {
              setShowAddDrawer(false);
              setItemId(null);
            }}
          />
        }
      />

      <SidePanelChange
        // panelIcon={"spaces"}
        panelTitle={"Update Space"}
        panelVisible={showUpdateDrawer}
        panelBody={
          <SpaceChange
            itemId={itemId}
            getRecordList={getRecordList}
            hideChangeDrawer={() => {
              setShowUpdateDrawer(false);
              setItemId(null);
            }}
          />
        }
      />

      <SidePanelDisplay
        headerData={columnList}
        // panelIcon={"spaces"}
        panelTitle={"Space Details"}
        panelVisible={showDisplayDrawer}
        panelBody={<SpaceDisplay itemId={itemId} />}
        customAction={
          <OptionsDropdown
            actionButtonItems={getActionButtonItems()}
            actionButtonItemClick={(clickedItem) => {
              handleOptionMenuItemClick(clickedItem);
            }}
          />
        }
        onCancelClick={() => {
          setShowDisplayDrawer(false);
        }}
      />

      <SidePanelPreferences
        module={"/Location/space/list"}
        // panelIcon={"spaces"}
        panelVisible={showPreferenceDrawer}
        onCancelClick={() => {
          setShowPreferenceDrawer(false);
        }}
        onChangeClick={() => {
          setShowPreferenceDrawer(false);
          getRecordList(searchValue, sortByValue, orderByValue, offsetValue);
        }}
      />

      {/* Delete Space Id */}
      <CustomModal
        showModal={showDeleteModal}
        titleText={"Remove Space"}
        messageText={`Are you sure you want to remove the ${itemName}?`}
        handleCancel={() => {
          setShowDeleteModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={deleteSpaceData}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
