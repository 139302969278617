import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { AppContext } from "../../Core/store/app-context";
import { FeatureTypes } from "../../Core/store/app-feature";
import { Layout } from "../../Core/layout";
import { DataTable, getClickableItemIndex } from "../../Core/common/DataTable";
import { CustomModal } from "../../Core/common/CustomModal";
import { OptionsDropdown } from "../../Core/common/OptionsDropdown";
import { SidePanelPreferences } from "../../Core/common/SidePanelPreferences";
import { SidePanelChange } from "../../Core/common/SidePanelChange";
import { SidePanelDisplay } from "../../Core/common/SidePanelDisplay";

import { LocationDisplay } from "./LocationDisplay";
import { LocationChange } from "./LocationChange";

export const LocationList = (props) => {
  const history = useHistory();
  const ctx = useContext(AppContext);

  const [pageSize, setPageSize] = useState(10);
  const [offsetValue, setOffsetValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [showUpdateDrawer, setShowUpdateDrawer] = useState(false);
  const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
  const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

  const [columnList, setColumnList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");

  const [itemId, setItemId] = useState(null);
  const [itemName, setItemName] = useState(null);

  // Consent
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);

  useEffect(() => {
    getRecordList();
  }, [searchValue]);

  const getRecordList = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    offset = offsetValue,
    limit = pageSize
  ) => {
    let queryParams = {
      search: searchBy,
      sortBy: sortBy,
      orderBy: orderBy,
      offset: offset,
      limit: limit,
    };
    const response = await ctx.HttpGetList("/Location/list", queryParams);
    if (response) {
      setRecordList(response.Data);
      setTotalCount(response.Count);
      setColumnList(response.Headers);
      setSearchPlaceholder(response.searchPlaceholder);
    }
  };

  // add click event if user have view access
  if (ctx.acl(FeatureTypes.Locations, "GetLocation") && columnList?.length > 1) {
    columnList[getClickableItemIndex(columnList)]["onClick"] = (record) => {
      setItemId(record.id);
      setItemName(record.name);
      setShowDisplayDrawer(true);
    };
  }

  const handleOptionMenuItemClick = (clickedItem) => {
    setShowDisplayDrawer(false);
    if (clickedItem === "Update") {
      setShowUpdateDrawer(true);
    } else if (clickedItem === "Remove") {
      setShowDeleteModal(true);
    }

    // set module state before redirecting
    // handle state from here, refer rule list
    ctx.setModuleState({
      moduleName: FeatureTypes.Locations,
      searchValue: searchValue,
      sortValue: sortByValue,
      orderByValue: orderByValue,
      offset: offsetValue,
      pageSize: pageSize,
    });

    if (showDisplayDrawer && itemId && clickedItem?.toLowerCase() === "view spaces") {
      history.push(`/location/space/${itemId}`);
    }
  };

  const deleteLocationData = async () => {
    setModalButtonLoading(true);
    const response = await ctx.HttpDelete("/Location", {
      aId: itemId,
    });
    if (response) {
      ctx.showToastAlert({ type: "success", message: response });
      getRecordList();
    }
    setShowDeleteModal(false);
    setModalButtonLoading(false);
  };

  // add action items as per role access
  const getActionButtonItems = (listItem = null) => {
    let actionButtonItems = [];
    if (ctx.acl(FeatureTypes.Locations, "PutLocation")) {
      actionButtonItems.push("Update");
    }
    if (ctx.acl(FeatureTypes.Locations, "DeleteLocation")) {
      actionButtonItems.push("Remove");
    }
    if (ctx.acl(FeatureTypes.Locations, "GetSpaceList")) {
      actionButtonItems.push("View Spaces");
    }
    return actionButtonItems;
  };

  return (
    <>
      <Layout
        page="locations"
        id={FeatureTypes.Locations}
        pageTitleButton={ctx.acl(FeatureTypes.Locations, "PostLocation")}
        buttonOnClick={() => {
          setItemId(null);
          setShowAddDrawer(true);
        }}
        buttonLabel="Add Location"
        searchOnModule={FeatureTypes.Locations}
        searchPlaceholder={searchPlaceholder}
        onSearchChange={(value) => {
          setSearchValue(value);
          setOffsetValue(1);
          getRecordList(value, null, null, 1);
        }}
        showFilter={false}
      >
        <DataTable
          showActionButton={getActionButtonItems().length ? true : false}
          getActionButtonItems={getActionButtonItems}
          onActionButtonClick={(record, clickedItem = null) => {
            setItemId(record.id);
            setItemName(record.name);
            if (clickedItem?.toLowerCase() === "view spaces") {
              history.push(`/location/space/${record.id}`);
            }
          }}
          actionButtonItemClick={(clickedItem) => {
            handleOptionMenuItemClick(clickedItem);
          }}
          dataSource={recordList}
          columns={columnList}
          totalRecords={totalCount}
          pageSize={pageSize}
          currentPage={offsetValue}
          rowKey={"id"}
          onUserPreferenceClick={() => {
            setShowPreferenceDrawer(true);
          }}
          onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
            let sortOrderValue = "";
            if (sorterOptions && sorterOptions.order) {
              sortOrderValue =
                sorterOptions.order === "ascend" ? "ASC" : "DESC";
              setOrderByValue(sortOrderValue);
              setSortByValue(sorterOptions.columnKey);
            }
            setPageSize(pageSizeOptions.pageSize);
            setOffsetValue(pageSizeOptions.current);
            getRecordList(
              searchValue,
              sorterOptions.columnKey,
              sortOrderValue,
              pageSizeOptions.current,
              pageSizeOptions.pageSize
            );
          }}
        />
      </Layout>

      <SidePanelChange
        panelIcon={"locations"}
        panelTitle={"Add Location"}
        panelVisible={showAddDrawer}
        panelBody={
          <LocationChange
            getRecordList={getRecordList}
            hideChangeDrawer={() => {
              setShowAddDrawer(false);
              setItemId(null);
            }}
          />
        }
      />

      <SidePanelChange
        panelIcon={"locations"}
        panelTitle={"Update Location"}
        panelVisible={showUpdateDrawer}
        panelBody={
          <LocationChange
            itemId={itemId}
            getRecordList={getRecordList}
            hideChangeDrawer={() => {
              setShowUpdateDrawer(false);
              setItemId(null);
            }}
          />
        }
      />

      <SidePanelDisplay
        headerData={columnList}
        panelIcon={"locations"}
        panelTitle={"Location Details"}
        panelVisible={showDisplayDrawer}
        panelBody={<LocationDisplay itemId={itemId} />}
        customAction={
          <OptionsDropdown
            actionButtonItems={getActionButtonItems()}
            actionButtonItemClick={(clickedItem) => {
              handleOptionMenuItemClick(clickedItem);
            }}
          />
        }
        onCancelClick={() => {
          setShowDisplayDrawer(false);
        }}
      />

      <SidePanelPreferences
        module={"/Location/list"}
        panelIcon={"locations"}
        panelVisible={showPreferenceDrawer}
        onCancelClick={() => {
          setShowPreferenceDrawer(false);
        }}
        onChangeClick={() => {
          setShowPreferenceDrawer(false);
          getRecordList(searchValue, sortByValue, orderByValue, offsetValue);
        }}
      />

      {/* Delete Location Id */}
      <CustomModal
        showModal={showDeleteModal}
        titleText={"Remove Location"}
        messageText={`Are you sure you want to remove the ${itemName}?`}
        handleCancel={() => {
          setShowDeleteModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={deleteLocationData}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
