import React, { useState, useEffect, useContext } from "react";

import { Layout } from "../../Core/layout";
import { DataTable, getClickableItemIndex } from "../../Core/common/DataTable";
import { AppContext } from "../../Core/store/app-context";
import { FeatureTypes } from "../../Core/store/app-feature";
import { CustomModal } from "../../Core/common/CustomModal";
import { GetTimezoneOffset } from "../../Core/common/helpers";
import { OptionsDropdown } from "../../Core/common/OptionsDropdown";
import { SidePanelChange } from "../../Core/common/SidePanelChange";
import { SidePanelDisplay } from "../../Core/common/SidePanelDisplay";
import { SidePanelPreferences } from "../../Core/common/SidePanelPreferences";

import { SystemChange } from "./SystemChange";
import { SystemDisplay } from "./SystemDisplay";

export const SystemList = (props) => {
  const ctx = useContext(AppContext);

  const [pageSize, setPageSize] = useState(10);
  const [offsetValue, setOffsetValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [showUpdateDrawer, setShowUpdateDrawer] = useState(false);
  const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
  const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

  const [columnList, setColumnList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");

  const [itemId, setItemId] = useState(null);
  const [itemName, setItemName] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);

  useEffect(() => {
      async function fetchData() {
          // You can await here
          await getRecordList();
      }
      fetchData();
  }, []);

  const getRecordList = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    offset = offsetValue,
    limit = pageSize
  ) => {
    let queryParams = {
      search: searchBy,
      sortBy: sortBy,
      orderBy: orderBy,
      offset: offset,
      limit: limit,
      utcOffset: GetTimezoneOffset(),
    };
    const response = await ctx.HttpGetList("/System/list", queryParams);
    if (response) {
      setRecordList(response.Data);
      setTotalCount(response.Count);
      setColumnList(response.Headers);
      setSearchPlaceholder(response.searchPlaceholder);
    }
  };

  const deleteSystem = async () => {
    setModalButtonLoading(true);
    const response = await ctx.HttpDelete("/System", { id: itemId });
    if (response) {
        ctx.showToastAlert({ type: "success", message: response });
        getRecordList();
    }
    setShowDeleteModal(false);
    setModalButtonLoading(false);
  };

  // add click event if system have view access
  if (ctx.acl(FeatureTypes.Systems, 'GetSystem') && columnList?.length > 1) {
    columnList[getClickableItemIndex(columnList)]['onClick'] = (record) => {
        setItemId(record.id);
        setItemName(record.name);
        setShowDisplayDrawer(true);
    }
  };

  const handleOptionMenuItemClick = (clickedItem) => {

    setShowDisplayDrawer(false);
    if (clickedItem === "Update") {
      setShowUpdateDrawer(true);
    }
    else if (clickedItem === "Remove") {
      setShowDeleteModal(true);
    }
  };

  const getActionButtonItems = () => {
    let actionButtonItems = [];
    if (ctx.acl(FeatureTypes.Systems, "PutSystem")) {
      actionButtonItems.push("Update");
    }
    if (ctx.acl(FeatureTypes.Systems, "DeleteSystem")) {
      actionButtonItems.push("Remove");
    }
    return actionButtonItems;
  }

  return (
    <>
      <Layout
        page="systems"
        id={FeatureTypes.Systems}
        pageTitleButton={ctx.acl(FeatureTypes.Systems, "PostSystem")}
        searchOnModule={FeatureTypes.Systems}
        searchPlaceholder={searchPlaceholder}
        onSearchChange={(value) => {
          setSearchValue(value);
          setOffsetValue(1);
          getRecordList(value, null, null, 1);
        }}
        showFilter={false}
        buttonLabel="Add System"
        buttonOnClick={() => {
          setShowAddDrawer(true);
        }}
      >
        <DataTable
            showActionButton={getActionButtonItems().length ? true : false}
            getActionButtonItems={getActionButtonItems}
            onActionButtonClick={(record) => {
                setItemId(record.id);
                setItemName(record.name);
            }}
            actionButtonItemClick={(clickedItem) => {
                handleOptionMenuItemClick(clickedItem);
            }}
            dataSource={recordList}
            columns={columnList}
            totalRecords={totalCount}
            pageSize={pageSize}
            currentPage={offsetValue}
            rowKey={"id"}
            onUserPreferenceClick={() => {
              setShowPreferenceDrawer(true);
            }}
            onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
            let sortOrderValue = "";
            if (sorterOptions && sorterOptions.order) {
                sortOrderValue =
                sorterOptions.order === "ascend" ? "ASC" : "DESC";
                setOrderByValue(sortOrderValue);
                setSortByValue(sorterOptions.columnKey);
            }
            setPageSize(pageSizeOptions.pageSize);
            setOffsetValue(pageSizeOptions.current);
            getRecordList(
                searchValue,
                sorterOptions.columnKey,
                sortOrderValue,
                pageSizeOptions.current,
                pageSizeOptions.pageSize
            );
            }}
        />
      </Layout>


        <SidePanelChange
            panelIcon={"systems"}
            panelTitle={"Add System"}
            panelVisible={showAddDrawer}
            panelBody={(
                <SystemChange
                    getRecordList={getRecordList}
                    hideChangeDrawer={() => {
                        setShowAddDrawer(false);
                        setItemId(null);
                    }}
                />
            )}
        />

        <SidePanelChange
            panelIcon={"systems"}
            panelTitle={"Update System"}
            panelVisible={showUpdateDrawer}
            panelBody={(
                <SystemChange
                    itemId={itemId}
                    getRecordList={getRecordList}
                    hideChangeDrawer={() => {
                        setShowUpdateDrawer(false);
                        setItemId(null);
                    }}
                />
            )}
        />

        <SidePanelDisplay
            headerData={columnList}
            panelIcon={"systems"}
            panelTitle={"System Details"}
            panelVisible={showDisplayDrawer}
            panelBody={(
                  <SystemDisplay
                    itemId={itemId}
                  />
            )}
            customAction={(
                  <OptionsDropdown
                      actionButtonItems={getActionButtonItems()}
                      actionButtonItemClick={(clickedItem) => {
                          handleOptionMenuItemClick(clickedItem);
                      }}
                  />
            )}
            onCancelClick={() => {
                setShowDisplayDrawer(false);
                setItemId(null);
            }}
        />

        <SidePanelPreferences
            module={"/System/list"}
            panelIcon={"systems"}
            panelVisible={showPreferenceDrawer}
            onCancelClick={() => {
                setShowPreferenceDrawer(false);
            }}
            onChangeClick={() => {
                setShowPreferenceDrawer(false);
                getRecordList(searchValue, sortByValue, orderByValue, offsetValue);
            }}
        />

      {/* Delete System */}
      <CustomModal
        showModal={showDeleteModal}
        titleText="Remove System"
        messageText={`Are you sure you want to remove the ${itemName}?`}
        handleCancel={() => {
          setShowDeleteModal(false);
        }}
        confirmButtonText={"Yes"}
        handleConfirm={deleteSystem}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
