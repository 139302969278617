import React, { useState, useEffect, useContext } from "react";

import { AppContext } from "../../Core/store/app-context";
import { Layout } from "../../Core/layout";
import { DataTable, getClickableItemIndex } from "../../Core/common/DataTable";
import { FeatureTypes } from "../../Core/store/app-feature";
import { GetTimezoneOffset } from "../../Core/common/helpers";
import { CustomModal } from "../../Core/common/CustomModal";
import { OptionsDropdown } from "../../Core/common/OptionsDropdown";
import { SidePanelDisplay } from "../../Core/common/SidePanelDisplay";
import { SidePanelPreferences } from "../../Core/common/SidePanelPreferences";

export const SessionList = (props) => {
  const ctx = useContext(AppContext);

  const [pageSize, setPageSize] = useState(10);
  const [offsetValue, setOffsetValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
  const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

  const [columnList, setColumnList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");
  const [listData, setListData] = useState({});

  const [sessionId, setSessionId] = useState("");

  //Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);

  // to make the API call after FilterData.isFilter is updated (includes init time update)
  useEffect(() => {
    setOffsetValue(1);
    getRecordList(searchValue, sortByValue, orderByValue, 1);
  }, []);

  const getRecordList = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    offset = offsetValue,
    limit = pageSize
  ) => {
    let queryParams = {
      search: searchBy,
      sortBy: sortBy,
      orderBy: orderBy,
      offset: offset,
      limit: limit,
      utcOffset: GetTimezoneOffset(),
    };
    const response = await ctx.HttpGetList("/Session/list", queryParams);
    if (response) {
      setRecordList(response.Data);
      setTotalCount(response.Count);
      setColumnList(response.Headers);
      setSearchPlaceholder(response.searchPlaceholder);
    }
  };

  const getItemData = async (id) => {
    const response = await ctx.HttpGet("/Session", { Id: id });
    if (response) {
      setListData(response);
      setShowDisplayDrawer(true);
    }
  };

  // add click event if user have view access
  if (ctx.acl(FeatureTypes.Sessions, 'GetSession') && columnList?.length > 1) {
      columnList[getClickableItemIndex(columnList)]['onClick'] = (record) => {
          setSessionId(record.Id);
          getItemData(record.Id);
      }
  }

  const deleteSession = async () => {
    setModalButtonLoading(true);
    const response = await ctx.HttpDelete("/Session", { id: sessionId });
    if (response) {
      ctx.showToastAlert({ type: "success", message: response });
      let offset = offsetValue;
      if (recordList.length === 1 && offset > 1) {
        offset = offset - 1;
        setOffsetValue(offset);
      }
      getRecordList(searchValue, sortByValue, orderByValue, offset);
    }
    setShowDeleteModal(false);
    setModalButtonLoading(false);
  };

  const handleOptionMenuItemClick = (clickedItem) => {
    setShowDisplayDrawer(false);
    if (clickedItem === "Remove") {
      setShowDeleteModal(true);
    }
    // set module state before redirecting
    // handle state from here, refer rule list    
  };

  // add action items as per role access
  const getActionButtonItems = () => {
    let actionButtonItems = [];
    if (ctx.acl(FeatureTypes.Sessions, 'DeleteSession')) {
      actionButtonItems.push('Remove');
    }
    return actionButtonItems;
  }

  return (
    <>
      <Layout
        page="session"
        id={FeatureTypes.Sessions}
        searchOnModule={FeatureTypes.Sessions}
        searchPlaceholder={searchPlaceholder}
        onSearchChange={(value) => {
          setSearchValue(value);
          setOffsetValue(1);
          getRecordList(value, null, null, 1);
        }}
        showFilter={false}
      >
        <DataTable
            dataSource={recordList}
            columns={columnList}
            totalRecords={totalCount}
            pageSize={pageSize}
            rowKey={"Id"}
            currentPage={offsetValue}
            onUserPreferenceClick={() => {
              setShowPreferenceDrawer(true);
            }}
            showActionButton={getActionButtonItems().length ? true : false}
            getActionButtonItems={getActionButtonItems}
            onActionButtonClick={(record) => {
              setSessionId(record.Id);
            }}
            actionButtonItemClick={(clickedItem) => {
              handleOptionMenuItemClick(clickedItem);
            }}
            onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
              let sortOrderValue = "";
              if (sorterOptions && sorterOptions.order) {
                sortOrderValue =
                sorterOptions.order === "ascend" ? "ASC" : "DESC";
                setOrderByValue(sortOrderValue);
                setSortByValue(sorterOptions.columnKey);
              }
              setPageSize(pageSizeOptions.pageSize);
              setOffsetValue(pageSizeOptions.current);
              getRecordList(
                searchValue,
                sorterOptions.columnKey,
                sortOrderValue,
                pageSizeOptions.current,
                pageSizeOptions.pageSize
              );
            }}
        />
      </Layout>

          <SidePanelDisplay
              listData={listData}
              headerData={columnList}
              panelIcon={"sessions"}
              panelTitle={"Session Details"}
              panelVisible={showDisplayDrawer}
              onCancelClick={() => {
                  setShowDisplayDrawer(false);
                  setListData({});
              }}
              customAction={(
                  <OptionsDropdown
                      actionButtonItems={getActionButtonItems()}
                      actionButtonItemClick={(clickedItem) => {
                          handleOptionMenuItemClick(clickedItem);
                      }}
                  />
              )}
          />
          <SidePanelPreferences
              module={"/Session/list"}
              panelIcon={"sessions"}
              panelVisible={showPreferenceDrawer}
              onCancelClick={() => {
                  setShowPreferenceDrawer(false);
              }}
              onChangeClick={() => {
                  setShowPreferenceDrawer(false);
                  getRecordList(searchValue, sortByValue, orderByValue, offsetValue);
              }}
          />

      {/* Delete Session Id */}
      <CustomModal
        showModal={showDeleteModal}
        titleText="Remove Session"
              messageText={`Are you sure you want to remove session ${sessionId}?`}
        handleCancel={() => {
          setShowDeleteModal(false);
        }}
        confirmButtonText={"Yes"}
        handleConfirm={deleteSession}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
