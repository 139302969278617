import React, { useState, useContext, useEffect } from "react";

import { AppContext } from "../../Core/store/app-context";
import { CustomModal } from "../../Core/common/CustomModal";
import { CustomInput } from "../../Core/common/CustomInput";
import { SelectDropdown } from "../../Core/common/SelectDropdown";
import { CustomSwitchToggle } from "../../Core/common/CustomSwitchToggle";
import { SidePanelFooter } from "../../Core/common/SidePanelFooter";

export const DeviceChange = (props) => {
    const ctx = useContext(AppContext);

    const {
        itemId,
        itemAppId,
        getRecordList,
        hideChangeDrawer,
    } = props;

    const defaultListData = {
        Id: "",
        Name: "",
    };

    const [organizationList, setOrganizationList] = useState([]);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [modalButtonLoading, setModalButtonLoading] = useState(false);
    const [listEditData, setListEditData] = useState(defaultListData);

    useEffect(() => {
        async function fetchData() {
            // You can await here
            await getOrganizationList();
            if (itemId) {
                await getItemData();
            }
            else {
                setListEditData(defaultListData);
            }
        }
        fetchData();
    }, [itemId]);


    const getItemData = async () => {
        const response = await ctx.HttpGet("/Device", { id: itemId, appId: itemAppId });
        if (response) {
            setListEditData(response);
        }
    };

    const getOrganizationList = async () => {
        const response = await ctx.HttpGet("/Device/organization");
        if (response) {
            setOrganizationList(response);
        }
    };

    const validate = () => {
        if (listEditData?.AppID === "") {
            ctx.showToastAlert({ type: "error", message: "Please enter App" });
            return false;
        }
        else if (listEditData?.Type === null) {
            ctx.showToastAlert({ type: "error", message: "Please add device type" });
            return false;
        }
        return true;
    };

    const onPrimaryClick = () => {
        if (validate()) {
            if (itemId)
                setShowUpdateModal(true);
        }
    };

    const updateDevice = async () => {
        setModalButtonLoading(true);

        let payload = { ...listEditData };

        const response = await ctx.HttpPut("/Device", payload);
        setShowUpdateModal(false);
        setModalButtonLoading(false);
        if (response) {
            hideChangeDrawer();
            setListEditData(defaultListData);
            ctx.showToastAlert({ type: "success", message: response });
            await getRecordList();
        }
    };

    return (
        <>
            <div className="pt-3 px-7 relative custom-scrollbar" style={{
                    height: "calc(100vh - 143px)",
                    overflow: "auto"
            }}>
                <CustomInput readOnly label="Id" inputValue={listEditData?.Id} />
                <CustomInput readOnly label="Name" inputValue={listEditData?.Name} />
                <CustomInput readOnly label="Type" inputValue={listEditData?.Type} />
                <SelectDropdown
                    dataList={listEditData?.OrgCode}
                    optionsList={organizationList}
                    mode="single"
                    optionKeyName="Id"
                    optionValueName="Id"
                    optionDisplayName="Value"
                    value={listEditData?.OrgCode}
                    label="Select Organization"
                    onChange={(value, key) => {
                        setListEditData({ ...listEditData, OrgCode: value });
                    }}
                />
                <CustomInput readOnly label="App Id" inputValue={listEditData?.AppID} />
                <CustomInput readOnly label="App Version" inputValue={listEditData?.AppVersion} />
                <CustomInput readOnly label="User Id" inputValue={listEditData?.UserID} />
                <CustomInput readOnly label="IP Address" inputValue={listEditData?.IpAddress} />
                <CustomInput readOnly label="Server URL" inputValue={listEditData?.ServerURL} />
                <CustomSwitchToggle
                    label="Status"
                    switchValue={listEditData?.Status}
                    onText="Active"
                    offText="Inactive"
                    onChange={(checked) =>
                        setListEditData({ ...listEditData, Status: checked ? 1 : 0 })
                    }
                    checked={listEditData?.Status}
                    styleName="labelGreyDarkText"
                />
            </div>
            <SidePanelFooter
                secondaryLabel={"Cancel"}
                onCancelClick={() => {
                    hideChangeDrawer();
                    setListEditData(defaultListData);
                }}
                primaryLabel={"Apply"}
                onPrimaryClick={() => onPrimaryClick()}
            />

            {/* Update Device */}
            <CustomModal
                showModal={showUpdateModal}
                titleText="Update Device"
                messageText={`Are you sure you want to update device ${listEditData?.Id}?`}
                handleCancel={() => {
                    setShowUpdateModal(false);
                }}
                confirmButtonText={"Yes"}
                handleConfirm={updateDevice}
                isLoading={modalButtonLoading}
            />

        </>
    );
};
