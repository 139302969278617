import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { AppContext } from "../../../Core/store/app-context";

import { SidePanelFooter } from "../../../Core/common/SidePanelFooter";
import { SelectDropdown } from "../../../Core/common/SelectDropdown";
import { CustomTabs } from "../../../Core/common/CustomTabs";
import { CustomModal } from "../../../Core/common/CustomModal";
import { CustomSwitchToggle } from "../../../Core/common/CustomSwitchToggle";
import { CustomTextarea } from "../../../Core/common/CustomTextarea";
import { CustomInput } from "../../../Core/common/CustomInput";
import { CustomTooltip } from "../../../Core/common/CustomTooltip";

export const DistributionChange = (props) => {
  const ctx = useContext(AppContext);
  const { itemId, getRecordList, hideChangeDrawer } = props;

  var emailIdValidatePattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const defaultListData = {
    Name: "",
    Description: "",
    Status: 0,
    destinations: [
      {
        distId: "",
        type: 0,
        destination: "",
        status: 0,
      },
    ],
  };

  const [listAddData, setListAddData] = useState({ ...defaultListData });

  const [activeTab, setActiveTab] = useState("header");
  const [primaryText, setPrimaryText] = useState("Next");
  const [secondaryText, setSecondaryText] = useState("Cancel");

  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      if (itemId) {
        await getItemData();
      } else {
        setListAddData({ ...defaultListData });
      }
    }
    fetchData();
  }, [itemId]);

  useEffect(() => {
    if (primaryText === "Apply") {
      setActiveTab("details");
      setSecondaryText("Previous");
    } else {
      setActiveTab("header");
      setSecondaryText("Cancel");
    }
  }, [primaryText]);

  const getItemData = async () => {
    const response = await ctx.HttpGet("/Distribution", {
      id: itemId,
    });
    if (response) {
      setListAddData(response);
    }
  };

  const createDistribution = async () => {
    setModalButtonLoading(true);
    setListAddData({ ...listAddData, Id: null });
    const response = await ctx.HttpPost("/Distribution", listAddData);
    if (response) {
      getRecordList();
      ctx.showToastAlert({ type: "success", message: response });
      hideChangeDrawer();
      setListAddData({ ...defaultListData });
      setPrimaryText("Next");
    }
    setShowAddModal(false);
    setModalButtonLoading(false);
  };

  const updateDistribution = async () => {
    setModalButtonLoading(true);

    setListAddData({ ...listAddData, Id: itemId });
    const response = await ctx.HttpPut("/Distribution", listAddData);
    if (response) {
      getRecordList();
      ctx.showToastAlert({ type: "success", message: response });
      hideChangeDrawer();
      setListAddData({ ...defaultListData });
      setPrimaryText("Next");
    }
    setShowUpdateModal(false);
    setModalButtonLoading(false);
  };

  const validate = () => {
    if (listAddData.Name === "") {
      ctx.showToastAlert({
        type: "error",
        message: "Please enter Distribution name",
      });
      return false;
    } else if (listAddData.Description === "") {
      ctx.showToastAlert({
        type: "error",
        message: "Please enter Distribution description",
      });
      return false;
    }
    return true;
  };

  const onSecondaryClick = () => {
    if (secondaryText === "Cancel") {
      hideChangeDrawer();
      setListAddData({ ...defaultListData });
    } else if (secondaryText === "Previous") {
      setPrimaryText("Next");
    }
  };
  const onPrimaryClick = () => {
    if (primaryText === "Apply") {
      if (isDetailsFormValid()) {
        if (itemId) {
          setShowUpdateModal(true);
        }
        else {
          setShowAddModal(true)
        };
      }
    } else if (primaryText === "Next") {
      if (validate()) {
        setPrimaryText("Apply");
      }
    }
  };

  const isDetailsFormValid = () => {
    let tempDestinations = listAddData.destinations;
    for (let i = 0; i < tempDestinations.length; i++) {
      if (tempDestinations[i].type === 0) {
        ctx.showToastAlert({
          type: "error",
          message: `Please select any option from dropdown`,
        });
        return false;
      }
      if (tempDestinations[i].destination === "") {
        ctx.showToastAlert({
          type: "error",
          message: `Please enter ${getInputLabel(tempDestinations[i].type)}`,
        });
        return false;
      }
      if (
        tempDestinations[i] &&
        tempDestinations[i].type === 1 &&
        !emailIdValidatePattern.test(tempDestinations[i].destination)
      ) {
        ctx.showToastAlert({
          type: "error",
          message: "Please enter valid email address",
        });
        return false;
      }
    }

    // check for duplicate destinations
    const uniqueDestinations = new Set(listAddData.destinations.map(v => v?.destination?.toString()));
    if (uniqueDestinations.size !== listAddData.destinations.length) {
      ctx.showToastAlert({
        type: "error",
        message: "Please enter unique destinations",
      });
      return false;
    }
    return true;
  };

  const addDestination = () => {
    setListAddData({
      ...listAddData,
      destinations: [
        ...listAddData.destinations,
        {
          distId: "",
          type: 0,
          destination: "",
          status: 0,
        },
      ],
    });
  };

  const getInputLabel = (type) => {
    switch (type) {
      case 1:
        return "Email Id";
      case 2:
        return type === "placeholder" ? "Push" : "app Id";
        case 3:
        return type === "placeholder" ? "SMS" : "contact number";
      default:
        return "";
    }
  };

  const deleteDestination = (deleteIndex) => {
    const updatedDestinations = (listAddData.destinations || []).filter(
      (d, index) => index !== deleteIndex
    );
    setListAddData({
      ...listAddData,
      destinations: updatedDestinations,
    });
  };

  const DistributionHeader = () => {
    return (
      <div className="px-7 pt-3">
        {itemId ? 
          <div
            className="mb-6 text-h1 flex items-center"
            style={{ color: "rgb(136, 146, 165)" }}
          >
            <span style={{ width: "130px" }}>Distribution ID:</span>
            <CustomTooltip
              title={listAddData.Id}
              className="text-h1 font-semibold"
              styles={{ color: "rgb(56, 58, 101)" }}
            />
          </div>
        : ""}
        <CustomInput
          label="Distribution name"
          inputValue={listAddData?.Name}
          onChange={(value) => {
            setListAddData({
              ...listAddData,
              Name: value,
            });
          }}
          maxlength={100}
          isRequired = {true}
          onKeyDown={(value) => {
            if (value.keyCode === 8 || value.keyCode === 46) {
              return;
            }
            if (listAddData.Name.length >= 99) {
              ctx.showToastAlert({ type: "error", message: "You cannot enter more than 100 characters" });
            }
          }}
        />
        <CustomTextarea
          label="Description"
          inputValue={listAddData?.Description}
          rows="4"
          onChange={(value) =>
            setListAddData({
              ...listAddData,
              Description: value,
            })
          }
          maxlength={2048}
          isRequired = {true}
          onKeyDown={(value) => {
            if (value.keyCode === 8 || value.keyCode === 46) {
              return;
            }
            if (listAddData.Description.length >= 2047) {
              ctx.showToastAlert({ type: "error", message: "You cannot enter more than 2048 characters" });
            }
          }}
        />
        <CustomSwitchToggle
          label="Status"
          switchValue={listAddData?.Status}
          onText="Enabled"
          offText="Disabled"
          onChange={(checked) =>
            setListAddData({
              ...listAddData,
              Status: checked ? 1 : 0,
            })
          }
          checked={listAddData?.Status}
          className="mb-6"
        />
      </div>
    );
  }

  const DistributionDetail = () => {
    return (
      <div className="px-7">
        <div className="text-right">
          <Button
            type="link"
            onClick={addDestination}
            size="large"
            className="py-0 inline-flex items-center border border-daisy-bush rounded-md px-3"
          >
            <div className="flex font-poppins text-daisy-bush text-sm font-semibold leading-normal">
              <div className="m-auto">
                <PlusOutlined
                  style={{ color: "#4E2C90" }}
                  className="flex m-auto"
                />
              </div>
              <div className=" font-poppins text-daisy-bush text-sm font-semibold leading-normal ml-2" />
              {"Add Destination"}
            </div>
          </Button>
        </div>
        <div className="mt-4 mb-3 text-h1 font-medium leading-normal">
          Select Destinations
        </div>
        {(listAddData.destinations || []).map((destination, index) => {
          return (
            <Row>
              <Col span={11}>
                <SelectDropdown
                  optionsList={ctx.destinations}
                  mode="single"
                  optionKeyName="type"
                  optionValueName="type"
                  optionDisplayName="typeName"
                  label="Select any one"
                  value={destination ? destination.typeName?.toString() : null}
                  onChange={(value, key) => {
                    let updatedData = listAddData.destinations.map(
                      (destination, childIndex) => {
                        return index === childIndex
                          ? { ...destination, ...ctx.destinations[value - 1], destination: "" }
                          : destination;
                      }
                    );
                    setListAddData({
                      ...listAddData,
                      destinations: [...updatedData],
                    });
                  }}
                />
              </Col>
              <Col span={12} className="ml-3 items-center flex">
                <CustomInput
                  label={
                    destination.type &&
                    `${getInputLabel(destination.type, "placeholder")}`
                  }
                  inputValue={
                    destination ? (destination.type === 3 ? parseInt(destination?.destination) || 0 : destination?.destination?.toString()) : null
                  }
                  onChange={(value) => {
                    let updatedData = listAddData.destinations.map(
                      (destination, childIndex) => {
                        return index === childIndex
                          ? { ...destination, destination: destination.type === 3 ? parseInt(value) : value }
                          : destination;
                      }
                    );
                    setListAddData({
                      ...listAddData,
                      destinations: updatedData,
                    });
                  }}
                  maxlength={destination.type === 3 ? 10 : 400}
                />
                <Col
                  className="ml-auto mb-5 pl-2"
                  onClick={() => deleteDestination(index)}
                >
                  <div
                    className="flex items-center justify-center cursor-pointer"
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: "red",
                      borderRadius: "50%",                 
                    }}
                  >
                    <img
                      src="/images/icons/x-mark-delete.svg"
                      alt="dropdownimage"
                    />
                  </div>
                </Col>
              </Col>
            </Row>
          );
        })}
      </div>
    );
  }

  return (
    // tabs started
    <>
      <CustomTabs
        activeTabs={activeTab}
        currentTab={
          activeTab.includes("header") && !activeTab.includes("details")
            ? "header"
            : "details"
        }
        className="step-custom-tab"
        tabData={[
          {
            key: "header",
            name: "1. Header",
            style: {
              backgroundColor: activeTab.includes("details")
                ? "#8892A5"
                : "transparent",
            },
            content: DistributionHeader(),
          },
          {
            key: "details",
            name: "2. Details",
            disabled: activeTab.includes("details") ? false : true,
            content: DistributionDetail()
          },
        ]}
      />
      {/* tabs ended */}

      <SidePanelFooter
        secondaryLabel={secondaryText}
        onCancelClick={() => onSecondaryClick()}
        primaryLabel={primaryText}
        onPrimaryClick={() => onPrimaryClick()}
      />

      {/* Add Distribution */}
      <CustomModal
        showModal={showAddModal}
        titleText="Add Distribution"
        messageText={`Are you sure you want to add ${listAddData.Name}?`}
        handleCancel={() => {
          setShowAddModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={createDistribution}
        isLoading={modalButtonLoading}
      />

      {/* Update Distribution Id */}
      <CustomModal
        showModal={showUpdateModal}
        titleText="Update Distribution"
        messageText={`Are you sure you want to update the changes made to ${listAddData.Name}?`}
        handleCancel={() => {
          setShowUpdateModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={updateDistribution}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
