import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useParams } from "react-router-dom";

import { AppContext } from "../../../Core/store/app-context";

import { CustomTooltip } from "../../../Core/common/CustomTooltip";
import { ItemDetail } from "../../../Core/common/ItemDetail";

export const RuleAlertDisplay = (props) => {
  const ctx = useContext(AppContext);
  const { alertViewDatareasonId, alertViewDataEscalationId } = props;
  const { ruleId } = useParams();
  const [listData, setListData] = useState(null);

  useEffect(() => {
    if ((alertViewDatareasonId || alertViewDatareasonId === 0) && alertViewDataEscalationId) {
      getItemData();
    }
  }, [alertViewDatareasonId, alertViewDataEscalationId]);

  const getItemData = async () => {
    const response = await ctx.HttpGet("/Rule/alert", {
      ruleId: ruleId,
      reasonId: alertViewDatareasonId,
      escalation: alertViewDataEscalationId,
    });
    setListData(response);
  };

  return (
    <div className="overflow-hidden">
      <div
        className={`px-7 ${listData?.distId ? "border-b-2" : ""}"`}
        style={{
          borderColor: listData?.distId ? "rgb(243, 245, 248)" : "",
        }}
      >
        <ItemDetail label="Reason" itemValue={listData?.reasonName} />
        <ItemDetail label="Subject" itemValue={listData?.subject} />
        <ItemDetail label="Distribution Id" itemValue={listData?.distId} />
        <ItemDetail label="Distribution" itemValue={listData?.name} />
        <ItemDetail
          label="Escalation Mins"
          itemValue={listData?.escalationMins}
        />
        <ItemDetail label="Escalation" itemValue={listData?.escalationName} />
        <ItemDetail label="Message" itemValue={listData?.message} />
        <ItemDetail label="Severity" itemValue={listData?.severityName} />
        <ItemDetail itemValue={listData?.status} itemType="status" />
      </div>
      {listData?.distId && listData?.destinations?.length ? (
        <div className="px-7 pt-3 mb-3">
          <Row className="items-center">
            <Col span={3}>
              <img
                src="/images/icons/user.svg"
                alt="user"
                style={{ height: "38px", width: "38px" }}
              />
            </Col>
            <Col>
              <div className="font-medium text-h1" style={{ color: "#383A65" }}>
                Distribution List
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={3}></Col>
            <Col span={21}>
              {listData?.destinations?.map((item) => {
                return (
                  <div className="flex items-center mt-3">
                    <div
                      className="font-medium"
                      style={{
                        color: "#8892A5",
                        fontSize: "11px",
                        width: "35%",
                      }}
                    >
                      {item?.typeName}
                    </div>
                    <div style={{ width: "63%" }}>
                      {item?.destination ? (
                        <CustomTooltip
                          title={item?.destination}
                          className="font-semibold text-h1 break-all"
                          styles={{ color: "#383A65" }}
                        />
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                );
              })}
            </Col>
          </Row>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
