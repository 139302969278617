import React, { useState, useEffect, useContext } from "react";

import { Drawer, Checkbox } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";

import { AppContext } from "../store/app-context";
import { CustomModal } from "./CustomModal";
import { SidePanelTitle } from "./SidePanelTitle";
import { SidePanelFooter } from "./SidePanelFooter";

export const SidePanelPreferences = (props) => {
    const ctx = useContext(AppContext);

    const {
        module,
        panelIcon,
        panelVisible,
        onCancelClick,
        onChangeClick,
    } = props;

    const [preference, setPreference] = useState([]);
    const [isCheckAll, setCheckAll] = useState(false);
    const [renderCount, setRenderCount] = useState(0);
    const [modalLoading, setModalLoading] = useState(false);
    const [showResetModal, setShowResetModal] = useState(false);

    // get user preference on sidepanel open
    useEffect(() => {
        if (panelVisible && module)
            getPreference();
    }, [panelVisible]);

    // set checkall checbox value
    useEffect(() => {
        checkAllValidation();
    }, [preference]);

    const getPreference = async () => {
        const response = await ctx.HttpGet("/Preference/header", { name: module });
        if (response && response.length) {
            resetColumnEvents(response);
        }
    };

    const validate = () => {
        let checkedColumns = preference.filter((item) => item["isChecked"]);
        if (checkedColumns.length <= 1) {
            ctx.showToastAlert({ type: "error", message: "Please select at least one column" });
            return false;
        }
        return true;
    }

    const updatePreference = async () => {
        if (validate()) {
            let payload = {
                name: module,
                headers: preference,
            };
            const response = await ctx.HttpPut("/Preference/header", payload);
            if (response) {
                onChangeClick();
                ctx.showToastAlert({ type: "success", message: response });
            }
        }
    };

    const resetPreference = async () => {
        setModalLoading(true);
        const response = await ctx.HttpDelete("/Preference/header", { name: module });
        setModalLoading(false);
        if (response) {
            setShowResetModal(false);
            onChangeClick();
            ctx.showToastAlert({ type: "success", message: response });
        }
    };

    const checkAll = (e, data) => {
        // setSelectedColumnDataIndex([...selectedColumnDataIndex, data]);
        preference.forEach((item) => {
            if (e.target.checked) {
                item["isChecked"] = true;
            } else {
                if (item.dataIndex !== "StatusName") {
                    item["isChecked"] = false;
                }
            }
        });
        setPreference([...preference]);
        checkAllValidation();
        // update this variable to re-render the UI
        setRenderCount(renderCount + 1);
    };

    const handleOrderChange = (direction, elemId, moduleIndex) => {
        if (direction === "up") {
            // reduce the display order for clicked item
            preference[moduleIndex]["order"] = preference[moduleIndex]["order"] - 1;

            // increase the display order for clicked item
            preference[moduleIndex - 1]["order"] =
                preference[moduleIndex - 1]["order"] + 1;
        } else {
            // increase the display order for clicked item
            preference[moduleIndex]["order"] = preference[moduleIndex]["order"] + 1;

            // reduce the display order for clicked item
            preference[moduleIndex + 1]["order"] =
                preference[moduleIndex + 1]["order"] - 1;
        }
        // sort and update module list
        let sortedModuleList = preference.sort((a, b) => a.order - b.order);
        setPreference([...sortedModuleList]);
        // update this variable to re-render the UI
        setRenderCount(renderCount + 1);
    };

    const onCheckEditableColumn = (e, data) => {
        // setSelectedColumnDataIndex([...selectedColumnDataIndex, data]);
        preference.forEach((item) => {
            if (e.target.checked) {
                if (data === item.dataIndex) {
                    item["isChecked"] = true;
                }
            } else {
                setCheckAll(false);
                if (data === item.dataIndex) {
                    item["isChecked"] = false;
                }
            }
        });
        setPreference([...preference]);
        checkAllValidation();
        // update this variable to re-render the UI
        setRenderCount(renderCount + 1);
    };

    const checkAllValidation = () => {
        let checkedColumns = preference.filter((item) => item["isChecked"]);
        if (checkedColumns.length === preference.length && preference.length > 0) {
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
    };

    const resetColumnEvents = (list) => {
        list.forEach((column) => {
            if (column.render && typeof column.render === "function") {
                delete column.render;
                delete column.width;
            }
            if (column.onClick && typeof column.onClick === "function") {
                delete column.onClick;
            }
            if (column.redirectTo && typeof column.redirectTo === "function") {
                delete column.isLink;
            }
            if (column.fixed) {
                delete column.fixed;
            }
        });
        setPreference([...list]);
    };

    return (
        <Drawer
            visible={panelVisible}
            className={"sidepanel-section"}
            onClose={onCancelClick}
            width={420}
            bodyStyle={{ padding: 0 }}
            closable={false}
            destroyOnClose={true}
            contentWrapperStyle={{
                borderTopLeftRadius: "24px",
                borderBottomLeftRadius: "24px",
                overflow: "hidden",
            }}
            maskClosable={false}
            zIndex={10}
        >
            <SidePanelTitle
                panelIcon={panelIcon}
                panelTitle={"User Preferences"}
                actionText={"Reset"}
                onActionClick={() => setShowResetModal(true)}
            />

            <div
                className="panel-content custom-scrollbar"
                style={{
                    height: `calc(100vh - 143px)`,
                    // marginBottom: `${panelFooter ? "10px" : "0"}`,
                    marginTop: "30px",
                    overflow: "auto",
                    paddingTop: 0,
                }}
            >
                <div className="pb-5">
                    <div
                        className={`flex justify-between items-center border-b-2 py-2`}
                        style={{ borderBottomColor: "#F3F5F8" }}
                    >
                        {"Select All"}
                        <Checkbox
                            name="Select All"
                            checked={isCheckAll}
                            onChange={checkAll}
                        />
                    </div>
                    {preference?.map((item, index) => (
                        <div
                            key={index}
                            className={`flex justify-between items-center ${index === preference.length - 1 ? "pt-2" : "border-b-2 py-2"
                                } ${item?.dataIndex === "StatusName" ? "hidden" : ""}`}
                            style={{ borderBottomColor: "#F3F5F8" }}
                        >
                            <div className="flex items-center">
                                {item?.dataIndex === "StatusName" ? (
                                    <div className="ml-4"></div>
                                ) : (
                                    <div className="mr-2 flex flex-col">
                                        <ArrowUpOutlined
                                            style={{
                                                fontSize: 10,
                                                color: "#4E2C90",
                                                opacity: index === 1 ? 0.5 : 1,
                                                pointerEvents: index === 1 ? "none" : "all",
                                            }}
                                            onClick={(e) => {
                                                handleOrderChange(
                                                    "up",
                                                    "collapse-module-" + index,
                                                    index
                                                );
                                            }}
                                        />
                                        <ArrowDownOutlined
                                            style={{
                                                fontSize: 10,
                                                color: "#4E2C90",
                                                opacity: preference.length - 1 === index ? 0.5 : 1,
                                                pointerEvents:
                                                    preference.length - 1 === index ? "none" : "all",
                                            }}
                                            onClick={(e) => {
                                                handleOrderChange(
                                                    "down",
                                                    "collapse-module-" + index,
                                                    index
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                                {item?.title ? item?.title : item?.dataIndex}
                            </div>
                            <Checkbox
                                name="table-column"
                                checked={item.isChecked ? true : false}
                                onChange={(e) => {
                                    onCheckEditableColumn(e, item.dataIndex);
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <SidePanelFooter
                onCancelClick={onCancelClick}
                onPrimaryClick={updatePreference}
            />

            {/* Reset User Preference */}
            <CustomModal
                showModal={showResetModal}
                titleText="Reset User Preference"
                messageText={"Are you sure you want to reset the user preference?"}
                handleCancel={() => setShowResetModal(false)}
                confirmButtonText={"Yes"}
                handleConfirm={resetPreference}
                isLoading={modalLoading}
            />

        </Drawer>
    );
};