import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button } from "antd";

import { v4 as uuidv4 } from "uuid";
import { AppContext } from "../../../Core/store/app-context";

import { Dot } from "./Dot";
import { ConvertToDecimal } from "../../../Core/common/helpers";
import { SelectDropdown } from "../../../Core/common/SelectDropdown";
import { ImageSelector } from "../../../Core/common/ImageSelector";
import { CustomInput } from "../../../Core/common/CustomInput";
import { CustomSwitchToggle } from "../../../Core/common/CustomSwitchToggle";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { SidePanelFooter } from "../../../Core/common/SidePanelFooter";
import { CustomModal } from "../../../Core/common/CustomModal";

export const ZoneChange = (props) => {
  const { itemId, spaceId, getRecordList, hideChangeDrawer } = props;
  const ctx = useContext(AppContext);

  const defaultListData = {
    id: "",
    name: "",
    description: "",
    code: "",
    groupName: "",
    groupID: null,
    typeName: "",
    typeID: null,
    spaceId: spaceId,
    barcode: "",
    totalArea: 0,
    totalAreaUoM: "sq.ft.",
    totalCapacity: 0,
    totalCapacityUoM: "sq.ft.",
    targetUtilizationRate: 0,
    targetUtilizationUoM: "sq.ft./person",
    status: 1,
    image: "",
    areaPoints: "",
    referencePoints: "",
    uuid: "",
    leafletId: "",
    locationID: "",
  };

  const [listAddData, setListAddData] = useState(defaultListData);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);

  const [zoneTypeList, setZoneTypeList] = useState([]);
  const [zoneGroupList, setZoneGroupList] = useState([]);
  const [zoneBase64Image, setZoneBase64Image] = useState("");
  const [zone2dPlanBase64Image, setZone2dPlanBase64Image] = useState("");
  const [zoneCreated, setZoneCreated] = useState(false);
  const [spaceData, setSpaceData] = useState();
  const [dotList, setDotList] = useState([]);
  const [dotListInMeters, setDotListInMeters] = useState([]);
  const [dotListInImagePixels, setDotListInImagePixels] = useState([]);
  const [polygonAreaPoints, setPolygonAreaPoints] = useState("");
  var zoomButtonClicked = false;

  useEffect(() => {
    async function fetchData() {
      // You can await here
      await getSpaceData();
      await getZoneTypeList();
      await getZoneGroupList();
    }
    fetchData();
  }, []);

  
  useEffect(() => {
    async function fetchData() {
      if (itemId && spaceData) {
        await getItemData();
      } else {
        setListAddData({ ...defaultListData });
      }
    }
    fetchData();
  }, [spaceData]);

  const getItemData = async () => {
    const response = await ctx.HttpGet("/Location/zone", { id: itemId });
    if (response) {
      setListAddData(response);
      setZoneCreated(true);
      if (response.image) {
        setZoneBase64Image("data:image/png;base64," + response.image);
      }

      setListAddData({
        ...response,
        areaPoints: response.areaPoints,
        locationID: spaceData.locationID,
        referencePoints: {
          x: response.referencePoints.refXMeter.toString(),
          y: response.referencePoints.refYMeter.toString(),
        },
      });

      if (response.ArrayAreaPoints) {
        let areaPoints = response.ArrayAreaPoints;
        // Dot List
        setDotList(areaPoints);

        // Polygon Area Points
        let combinedAreaPoints = "";
        areaPoints.forEach((item) => {
          combinedAreaPoints =
            combinedAreaPoints === ""
              ? item.x + "," + item.y
              : combinedAreaPoints + "," + item.x + "," + item.y;
        });

        setPolygonAreaPoints(combinedAreaPoints);

        // Dot List in Image Pixels
        areaPoints.forEach((item) => {
          let realX = (spaceData.width * item.x) / 600;
          let realY = (spaceData.length * item.y) / 500;

          let dot = { x: realX, y: realY };

          setDotListInImagePixels((previousArray) => [...previousArray, dot]);
        });
      }

      if (response.ArrayMeterAreaPoints) {
        response.ArrayMeterAreaPoints.forEach((item) => {
          let dot = { x: item[0], y: item[1] };

          setDotListInMeters((previousArray) => [...previousArray, dot]);
        });
      }
    }
  };

  const getSpaceData = async () => {
    try{
    const response = await ctx.HttpGet("/Location/space", { id: spaceId });
    if (response) {
      setSpaceData(response);
      if (response.image) {
        setZone2dPlanBase64Image("data:image/png;base64," + response.image);
      }
    }
  } catch(e){
    console.log(e);
  }
  };

  const getZoneTypeList = async () => {
    const response = await ctx.HttpGet("/Location/zone/types");
    setZoneTypeList(response);
  };

  const getZoneGroupList = async () => {
    const response = await ctx.HttpGet("/Location/zone/groups");
    setZoneGroupList(response);
  };

  const onMouseClick = (e) => {
    if (e.nativeEvent && !zoomButtonClicked) {
      let offsetX = e.nativeEvent.offsetX;
      let realX = (spaceData.width * offsetX) / 600;

      let offsetY = e.nativeEvent.offsetY;
      let realY = (spaceData.length * offsetY) / 500;

      let dot = { x: offsetX, y: offsetY };

      setDotList([...dotList, dot]);

      setDotListInImagePixels([
        ...dotListInImagePixels,
        { x: realX, y: realY },
      ]);

      let scaleX = spaceData.width / spaceData.widthMeter;
      let scaleY = spaceData.length / spaceData.lengthMeter;

      let xInMeters = (1 / scaleX) * realX;
      let yInMeters = (1 / scaleY) * realY;

      let dotInMeters = { x: xInMeters, y: yInMeters };

      setDotListInMeters([...dotListInMeters, dotInMeters]);
      if(zoneCreated) {
        setZoneCreated(false);
      }
      // console.log("Value of X is :: ", e.nativeEvent.offsetX);
      // console.log("Value of Y is :: ", e.nativeEvent.offsetY);
      // console.log("Value of RealX is :: ", parseInt(realX));
      // console.log("Value of RealY is :: ", parseInt(realY));

      // console.log("Value of ScaleX is :: ", scaleX);
      // console.log("Value of ScaleY is :: ", scaleY);
    } else {
      zoomButtonClicked = false;
    }
  };

  const finalizeAreaPoints = () => {
    if (dotList.length > 0) {
      console.log("Dot List is :: ", dotList);
      console.log("Dot List in pixels is :: ", dotListInImagePixels);
      console.log("Dot List in meters is :: ", dotListInMeters);

      const createdUID = uuidv4();

      let combinedAreaCoordinates = "";

      dotListInMeters.forEach((item) => {
        combinedAreaCoordinates =
          combinedAreaCoordinates === ""
            ? Number(item.x.toFixed(3)) + "," + Number(item.y.toFixed(3)) + ";"
            : combinedAreaCoordinates +
              Number(item.x.toFixed(3)) +
              "," +
              Number(item.y.toFixed(3)) +
              ";";
      });

      setListAddData({
        ...listAddData,
        referencePoints: {
          x: spaceData?.refXMeter?.toString(),
          y: spaceData?.refYMeter?.toString(),
        },
        areaPoints: combinedAreaCoordinates,
        uuid: createdUID,
        locationID: spaceData.locationID,
      });

      setCombinedPolygonAreaPoints(dotList);

      setZoneCreated(true);
    } else {
      ctx.showToastAlert({
        type: "error",
        message: "Please add pointers on the map",
      });
    }
  };

  const setCombinedPolygonAreaPoints = (list) => {
    let combinedPolygonPoints = "";

    list.forEach((item) => {
      combinedPolygonPoints =
        combinedPolygonPoints === ""
          ? item.x + "," + item.y
          : combinedPolygonPoints + "," + item.x + "," + item.y;
    });

    setPolygonAreaPoints(combinedPolygonPoints);
  };

  const removePoints = () => {
    if (dotList.length > 0) {
      setDotList([]);
      setDotListInImagePixels([]);
      setDotListInMeters([]);
      setPolygonAreaPoints("");

      setZoneCreated(false);
    } else {
      ctx.showToastAlert({
        type: "error",
        message: "Please add pointers on the map",
      });
    }
  };

  const deleteDot = (index) => {
    setDotList((dotList) => dotList.filter((value, i) => i !== index));
    setDotListInImagePixels((dotListInImagePixels) =>
      dotListInImagePixels.filter((value, i) => i !== index)
    );
    setDotListInMeters((dotListInMeters) =>
      dotListInMeters.filter((value, i) => i !== index)
    );

    setPolygonAreaPoints("");
  };

  const onPrimaryClick = () => {
    if (validate()) {
      if (itemId) {
        setShowUpdateModal(true);
      } else {
        setShowAddModal(true);
      }
    }
  };

  const validate = () => {
    if (listAddData.name === "" || listAddData.name.trim() === "") {
      ctx.showToastAlert({ type: "error", message: "Please enter name" });
      return false;
    } else if (listAddData.description === "") {
      ctx.showToastAlert({
        type: "error",
        message: "Please enter description",
      });
      return false;
    } else if (listAddData.code === "") {
      ctx.showToastAlert({ type: "error", message: "Please enter code" });
      return false;
    } else if (listAddData.typeID === null) {
      ctx.showToastAlert({ type: "error", message: "Please select type" });
      return false;
    } else if (listAddData.barcode === "") {
      ctx.showToastAlert({ type: "error", message: "Please enter identifier" });
      return false;
    } else if (parseFloat(listAddData.totalArea) === 0) {
      ctx.showToastAlert({
        type: "error",
        message: "Please enter total area in sq.ft.",
      });
      return false;
    } else if (listAddData.totalCapacity === 0) {
      ctx.showToastAlert({
        type: "error",
        message: "Please enter total capacity in sq.ft.",
      });
      return false;
    } else if (parseFloat(listAddData.targetUtilizationRate) === 0) {
      ctx.showToastAlert({
        type: "error",
        message: "Please enter target utilization in sq.ft./person",
      });
      return false;
    } else if (
      listAddData.referencePoints === "" ||
      listAddData.areaPoints === ""
    ) {
      ctx.showToastAlert({ type: "error", message: "Please draw a zone" });
      return false;
    }
    return true;
  };

  const createZone = async () => {
    setModalButtonLoading(true);
    const { uuid } = listAddData;

    let payload = { ...listAddData, floorID: spaceId, extID: uuid };

    const response = await ctx.HttpPost("/Location/zone", payload);
    if (response) {
      getRecordList();
      ctx.showToastAlert({ type: "success", message: response });
      hideChangeDrawer();
      setListAddData({ ...defaultListData });
      setZoneBase64Image("");
      setZone2dPlanBase64Image("");
    }
    setShowAddModal(false);
    setModalButtonLoading(false);
  };

  const updateZone = async () => {
    setModalButtonLoading(true);
    const { uuid } = listAddData;

    setListAddData({
      ...listAddData,
      id: itemId,
      floorID: spaceId,
      extID: uuid,
    });
    const response = await ctx.HttpPut("/Location/zone", listAddData);
    if (response) {
      getRecordList();
      ctx.showToastAlert({ type: "success", message: response });
      hideChangeDrawer();
      setListAddData({ ...defaultListData });
      setZoneBase64Image("");
      setZone2dPlanBase64Image("");
    }
    setShowUpdateModal(false);
    setModalButtonLoading(false);
  };

  return (
    <>
      <div>
        <Row className="pb-0.5">
          {/* Testing */}
          {/* <div style={{ width: spaceData?.width, height: spaceData?.length, border: "1px solid red" }}>
                        <img src={zone2dPlanBase64Image} style={{maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} onClick={(e) => onMouseClick(e)} />
                    </div> */}

          <Col span={16}>
            <div className="grid grid-cols-6 gap-4">
              <div className="col-start-1 col-end-7">
                <div className="flex flex-wrap justify-center bg-transparent">
                  <div onClick={(e) => onMouseClick(e)}>
                    {zone2dPlanBase64Image ? (
                      <>
                        <TransformWrapper
                          initialScale={1}
                          minScale={1}
                          maxScale={8}
                        >
                          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                            <React.Fragment>
                              <div
                                style={{ textAlign: "center" }}
                                onClick={() => {
                                  zoomButtonClicked = true;
                                }}
                              >
                                <div
                                  className="cursor-pointer"
                                  style={{
                                    color: "#8892a5",
                                    fontWeight: 500,
                                    textAlign: "center",
                                    marginRight: 10,
                                    display: "inline",
                                  }}
                                  onClick={() => {
                                    zoomButtonClicked = true;
                                    zoomIn();
                                  }}
                                >
                                  +
                                </div>
                                <div
                                  className="cursor-pointer"
                                  style={{
                                    color: "#8892a5",
                                    fontWeight: 500,
                                    textAlign: "center",
                                    marginRight: 10,
                                    display: "inline",
                                  }}
                                  onClick={() => {
                                    zoomButtonClicked = true;
                                    zoomOut();
                                  }}
                                >
                                  -
                                </div>
                                <div
                                  className="cursor-pointer"
                                  style={{
                                    color: "#8892a5",
                                    fontWeight: 500,
                                    textAlign: "center",
                                    marginRight: 10,
                                    display: "inline",
                                  }}
                                  onClick={() => {
                                    zoomButtonClicked = true;
                                    resetTransform();
                                  }}
                                >
                                  Reset
                                </div>
                              </div>
                              <TransformComponent>
                                <div
                                  className="react-image-dot__wrapper_add"
                                  style={{ width: "600px", height: "500px" }}
                                >
                                  <img
                                    alt="zone"
                                    src={zone2dPlanBase64Image}
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                </div>
                                {dotList.map((dot, i) => (
                                  <Dot
                                    x={dot.x}
                                    y={dot.y}
                                    i={i}
                                    styles={{
                                      backgroundColor: "rgba(78,44,144,1)",
                                    }}
                                    radius="4"
                                  />
                                ))}
                                {polygonAreaPoints ? (
                                  <svg
                                    className="absolute top-0 left-0 w-full h-full"
                                    style={{ height: "100%", width: "100%" }}
                                  >
                                    <polygon
                                      points={polygonAreaPoints}
                                      style={{
                                        fill: "rgba(78, 44, 144, 0.25)",
                                      }}
                                    />
                                  </svg>
                                ) : (
                                  <></>
                                )}
                              </TransformComponent>
                            </React.Fragment>
                          )}
                        </TransformWrapper>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {zone2dPlanBase64Image ? (
                  <>
                    <Row type="flex" className="mt-4 pt-2">
                      <Col span={6} />
                      <Col span={8} className="my-auto text-left">
                        <Button
                          onClick={() => {
                            removePoints();
                          }}
                          className="shadow inline-flex items-center font-poppins text-white text-h1 font-medium bg-daisy-bush rounded-lg"
                        >
                          Remove All
                        </Button>
                      </Col>
                      <Col span={4} className="my-auto text-right">
                        <Button
                          onClick={() => {
                            finalizeAreaPoints();
                          }}
                          className="shadow inline-flex items-center font-poppins text-white text-h1 font-medium bg-daisy-bush rounded-lg"
                        >
                          Done
                        </Button>
                      </Col>
                      <Col span={6} />
                    </Row>
                    <p
                      style={{
                        color: "#8892a5",
                        fontWeight: 500,
                        textAlign: "center",
                        marginTop: "1rem",
                        marginBottom: "0.5rem",
                      }}
                    >
                      To create a zone, start by adding a pointer on the map and
                      click Done when you are finished.
                    </p>
                    <ul>
                      {dotListInImagePixels.map((dot, i) => {
                        return (
                          <li>
                            <p
                              style={{
                                color: "#8892a5",
                                fontWeight: 500,
                                textAlign: "center",
                              }}
                            >
                              Dot: {i} -&#62; with Coordinates- x: {dot.x}, y:{" "}
                              {dot.y}{" "}
                              {zoneCreated ? (
                                <></>
                              ) : (
                                <img
                                  className="cursor-pointer"
                                  style={{
                                    width: 25,
                                    display: "inline",
                                    marginBottom: 3,
                                  }}
                                  src="/images/icons/cancel-red.svg"
                                  alt="delete"
                                  onClick={() => deleteDot(i)}
                                />
                              )}{" "}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Col>
          <Col span={1}></Col>
          <Col span={5}>
            <div className="custom-scrollbar">
              <div className="mx-auto pt-3">
                <CustomInput
                  label="Name of Zone"
                  disabled={!zoneCreated}
                  isRequired={true}
                  maxlength={25}
                  inputValue={listAddData.name}
                  onChange={(value) => {
                    setListAddData({
                      ...listAddData,
                      name: value,
                    });
                  }}
                />
                <CustomInput
                  label="Description"
                  disabled={!zoneCreated}
                  isRequired={true}
                  maxlength={25}
                  inputValue={listAddData.description}
                  onChange={(value) => {
                    setListAddData({
                      ...listAddData,
                      description: value,
                    });
                  }}
                />
                <CustomInput
                  label="Code"
                  disabled={!zoneCreated}
                  maxlength={5}
                  isRequired={true}
                  inputValue={listAddData.code}
                  onChange={(value) => {
                    setListAddData({
                      ...listAddData,
                      code: value,
                    });
                  }}
                />
                <SelectDropdown
                  dataList={listAddData?.groupID}
                  optionsList={zoneGroupList}
                  mode="single"
                  optionKeyName="Id"
                  optionValueName="Id"
                  optionDisplayName="Value"
                  label="Group"
                  value={
                    listAddData?.groupID !== null
                      ? listAddData?.groupID?.toString()
                      : null
                  }
                  onChange={(value, key) => {
                    setListAddData({
                      ...listAddData,
                      groupID: parseInt(value),
                    });
                  }}
                />
                <SelectDropdown
                  dataList={listAddData?.typeID}
                  optionsList={zoneTypeList}
                  isRequired={true}
                  mode="single"
                  optionKeyName="Id"
                  optionValueName="Id"
                  optionDisplayName="Value"
                  label="Type"
                  value={
                    listAddData?.typeID !== null
                      ? listAddData?.typeID.toString()
                      : null
                  }
                  onChange={(value, key) => {
                    setListAddData({ ...listAddData, typeID: parseInt(value) });
                  }}
                />
                <CustomInput
                  label="Identifier"
                  disabled={!zoneCreated}
                  isRequired={true}
                  maxlength={25}
                  inputValue={listAddData.barcode}
                  onChange={(value) => {
                    setListAddData({
                      ...listAddData,
                      barcode: value,
                    });
                  }}
                />
                <CustomInput
                  label="Total Area in sq.ft."
                  type="number"
                  disabled={!zoneCreated}
                  isRequired={true}
                  maxlength={5}
                  inputValue={ConvertToDecimal(
                    listAddData?.totalArea
                  )?.toString()}
                  onChange={(value) => {
                    setListAddData({
                      ...listAddData,
                      totalArea: ConvertToDecimal(value),
                    });
                  }}
                />
                <CustomInput
                  label="Total Capacity"
                  disabled={!zoneCreated}
                  isRequired={true}
                  maxlength={5}
                  inputValue={parseInt(listAddData?.totalCapacity) || 0}
                  onChange={(value) => {
                    setListAddData({
                      ...listAddData,
                      totalCapacity: parseInt(value),
                    });
                  }}
                />
                <CustomInput
                  label="Target utilization rate in sq.ft./person"
                  type="number"
                  disabled={!zoneCreated}
                  isRequired={true}
                  maxlength={5}
                  inputValue={ConvertToDecimal(
                    listAddData?.targetUtilizationRate
                  )?.toString()}
                  onChange={(value) => {
                    setListAddData({
                      ...listAddData,
                      targetUtilizationRate: ConvertToDecimal(value),
                    });
                  }}
                />
                <div className="relative mb-6">
                  <CustomSwitchToggle
                    label="Status"
                    switchValue={listAddData?.status}
                    onText="Active"
                    offText="Inactive"
                    onChange={(checked) =>
                      setListAddData({
                        ...listAddData,
                        status: checked ? 1 : 0,
                      })
                    }
                    checked={listAddData.status}
                  />
                </div>
                <div className="relative mb-6">
                  <ImageSelector
                    selectedBase64Image={zoneBase64Image}
                    setSelectedBase64Image={setZoneBase64Image}
                    imageName="Image of Zone"
                    imageDetails={listAddData}
                    setImageDetails={setListAddData}
                    imageSizeMax="5"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <SidePanelFooter
        panelWidth="95%"
        secondaryLabel={"Cancel"}
        onCancelClick={() => {
          hideChangeDrawer();
          setListAddData({ ...defaultListData });
          setZoneBase64Image("");
          setZone2dPlanBase64Image("");
        }}
        primaryLabel={"Apply"}
        onPrimaryClick={() => onPrimaryClick()}
      />

      {/* Add Zone */}
      <CustomModal
        showModal={showAddModal}
        titleText={"Add Zone"}
        messageText={`Are you sure you want to add ${listAddData.name}?`}
        handleCancel={() => {
          setShowAddModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={createZone}
        isLoading={modalButtonLoading}
      />

      {/* Update Zone Id */}
      <CustomModal
        showModal={showUpdateModal}
        titleText={"Update Zone"}
        messageText={`Are you sure you want to update the changes made to ${listAddData.name}?`}
        handleCancel={() => {
          setShowUpdateModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={updateZone}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
