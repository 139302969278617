import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { Layout } from "../../Core/layout";
import { DataTable, getClickableItemIndex } from "../../Core/common/DataTable";
import { AppContext } from "../../Core/store/app-context";
import { FeatureTypes } from "../../Core/store/app-feature";
import { CustomModal } from "../../Core/common/CustomModal";
import { OptionsDropdown } from "../../Core/common/OptionsDropdown";
import { SidePanelPreferences } from "../../Core/common/SidePanelPreferences";
import { SidePanelChange } from "../../Core/common/SidePanelChange";
import { SidePanelDisplay } from "../../Core/common/SidePanelDisplay";

import { RuleDisplay } from "./RuleDisplay";
import { RuleChange } from "./RuleChange";

export const RuleList = (props) => {
  const ctx = useContext(AppContext);
  const history = useHistory();

  const [pageSize, setPageSize] = useState(10);
  const [offsetValue, setOffsetValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [showUpdateDrawer, setShowUpdateDrawer] = useState(false);
  const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
  const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

  const [columnList, setColumnList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");
  const [ruleTypeId, setRuleTypeId] = useState(0);

  const [itemId, setItemId] = useState(null);
  const [itemName, setItemName] = useState(null);

  //Consent
  const [modalButtonLoading, setModalButtonLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    let moduleStateValue = ctx.moduleState;
    // check if module state is available then use it
    if (
      moduleStateValue.moduleName === FeatureTypes.Rules &&
      (moduleStateValue.offset !== 1 ||
        moduleStateValue.orderByValue ||
        moduleStateValue.searchValue ||
        moduleStateValue.sortValue ||
        moduleStateValue.pageSize !== 10)
    ) {
      setOffsetValue(moduleStateValue.offset);
      setOrderByValue(moduleStateValue.orderByValue);
      setSearchValue(moduleStateValue.searchValue);
      setSortByValue(moduleStateValue.sortValue);
      setPageSize(moduleStateValue.pageSize);
      // if this module have filter then add if condition as done in session log
      getRecordList(
        moduleStateValue.searchValue,
        moduleStateValue.sortValue,
        moduleStateValue.orderByValue,
        moduleStateValue.offset,
        moduleStateValue.pageSize
      );
      ctx.resetModuleState();
    } else {
      setOffsetValue(1);
      getRecordList(searchValue, sortByValue, orderByValue, 1);
    }
  }, []);

  const getRecordList = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    offset = offsetValue,
    limit = pageSize
  ) => {
    let queryParams = {
      search: searchBy,
      sortBy: sortBy,
      orderBy: orderBy,
      offset: offset,
      limit: limit,
    };
    const response = await ctx.HttpGetList("/Rule/list", queryParams);
    if (response) {
      setRecordList(response.Data);
      setTotalCount(response.Count);
      setColumnList(response.Headers);
      setSearchPlaceholder(response.searchPlaceholder);
    }
  };

  const handleOptionMenuItemClick = (clickedItem) => {
    setShowDisplayDrawer(false);
    if (clickedItem === "Update") {
      setShowUpdateDrawer(true);
    } else if (clickedItem === "Remove") {
      setShowDeleteModal(true);
    }

    if (showDisplayDrawer && itemId) {
      if (clickedItem === "View Alerts") {
        history.push(`/rule-alert/${itemId}`);
      }
      if (clickedItem === "Associations") {
        history.push(`/rule-associate/${itemId}`);
      }
      if (clickedItem === "View Locations") {
        history.push(`/rule-location/${itemId}`);
      }
    }

    // set module state before redirecting
    // handle state from here, refer rule list
    ctx.setModuleState({
      moduleName: FeatureTypes.Rules,
      searchValue: searchValue,
      sortValue: sortByValue,
      orderByValue: orderByValue,
      offset: offsetValue,
      pageSize: pageSize,
    });
  };

  const confirmDeleteRule = async () => {
    setModalButtonLoading(true);
    const response = await ctx.HttpDelete("/Rule", {
      id: itemId,
    });
    if (response) {
      ctx.showToastAlert({ type: "success", message: response });
      let offset = offsetValue;
      if (recordList.length === 1 && offset > 1) {
        offset = offset - 1;
        setOffsetValue(offset);
      }
      getRecordList(searchValue, sortByValue, orderByValue, offset);
    }
    setShowDeleteModal(false);
    setModalButtonLoading(false);
  };

  // add click event if user have view access
  if (ctx.acl(FeatureTypes.Rules, "GetRule") && columnList?.length > 1) {
    columnList[getClickableItemIndex(columnList)]["onClick"] = (record) => {
      setItemId(record.Id);
      setItemName(record.Name);
      setRuleTypeId(record.TypeId);
      setShowDisplayDrawer(true);
    };
  }
  //if rule type location then shows view location option

  // add action items as per role access
  const getActionButtonItems = (listItem = null) => {
    let actionButtonItems = [];
    let _ruleTypeId = ruleTypeId;
    if (listItem && Object.keys(listItem).length) {
      _ruleTypeId = listItem.TypeId;
    }
    if (ctx.acl(FeatureTypes.Rules, "PutRule")) {
      actionButtonItems.push("Update");
    }
    if (ctx.acl(FeatureTypes.Rules, "DeleteRule")) {
      actionButtonItems.push("Remove");
    }
    if (ctx.acl(FeatureTypes.Rules, "GetRuleAlertList")) {
      actionButtonItems.push("View Alerts");
    }
    if (ctx.acl(FeatureTypes.Rules, "GetRuleAssociateList")) {
      actionButtonItems.push("Associations");
    }
    if (
      _ruleTypeId >= 100 &&
      _ruleTypeId <= 102 &&
      ctx.acl(FeatureTypes.Rules, "GetRuleLocationList")
    ) {
      actionButtonItems.push("View Locations");
    }
    return actionButtonItems;
  };

  return (
    <>
      <Layout
        page="rule"
        id={FeatureTypes.Rules}
        pageTitleButton={ctx.acl(FeatureTypes.Rules, "PostRule")}
        searchOnModule={FeatureTypes.Rules}
        searchPlaceholder={searchPlaceholder}
        onSearchChange={(value) => {
          setOffsetValue(1);
          setSearchValue(value);
          getRecordList(value, null, null, 1);
        }}
        showFilter={false}
        buttonOnClick={() => {
          setItemId(null);
          setShowAddDrawer(true);
        }}
        buttonLabel="Add Rule"
      >
        <DataTable
          showActionButton={getActionButtonItems().length ? true : false}
          getActionButtonItems={getActionButtonItems}
          onActionButtonClick={(record, clickedItem = null) => {
            setItemId(record.Id);
            setItemName(record.Name);
            setRuleTypeId(record.TypeId);
            if (clickedItem?.toLowerCase() === "view alerts") {
              history.push(`/rule-alert/${record.Id}`);
            }
            if (clickedItem?.toLowerCase() === "associations") {
              history.push(`/rule-associate/${record.Id}`);
            }
            if (clickedItem?.toLowerCase() === "view locations") {
              history.push(`/rule-location/${record.Id}`);
            }
          }}
          actionButtonItemClick={(clickedItem) => {
            handleOptionMenuItemClick(clickedItem);
          }}
          dataSource={recordList}
          columns={columnList}
          totalRecords={totalCount}
          pageSize={pageSize}
          currentPage={offsetValue}
          rowKey={"Id"}
          onUserPreferenceClick={() => {
            setShowPreferenceDrawer(true);
          }}
          onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
            let sortOrderValue = "";
            if (sorterOptions && sorterOptions.order) {
              sortOrderValue =
                sorterOptions.order === "ascend" ? "ASC" : "DESC";
              setOrderByValue(sortOrderValue);
              setSortByValue(sorterOptions.columnKey);
            }
            setPageSize(pageSizeOptions.pageSize);
            setOffsetValue(pageSizeOptions.current);
            getRecordList(
              searchValue,
              sorterOptions.columnKey,
              sortOrderValue,
              pageSizeOptions.current,
              pageSizeOptions.pageSize
            );
          }}
        />
      </Layout>

      <SidePanelChange
        panelIcon={"rule"}
        panelTitle={"Add Rule"}
        panelVisible={showAddDrawer}
        panelBody={
          <RuleChange
            getRecordList={getRecordList}
            hideChangeDrawer={() => {
              setShowAddDrawer(false);
              setItemId(null);
            }}
          />
        }
      />

      <SidePanelChange
        panelIcon={"rule"}
        panelTitle={"Update Rule"}
        panelVisible={showUpdateDrawer}
        panelBody={
          <RuleChange
            itemId={itemId}
            getRecordList={getRecordList}
            hideChangeDrawer={() => {
              setShowUpdateDrawer(false);
              setItemId(null);
            }}
          />
        }
      />

      <SidePanelDisplay
        headerData={columnList}
        panelIcon={"rule"}
        panelTitle={"Rule Details"}
        panelVisible={showDisplayDrawer}
        panelBody={<RuleDisplay itemId={itemId} />}
        customAction={
          <OptionsDropdown
            actionButtonItems={getActionButtonItems()}
            actionButtonItemClick={(clickedItem) => {
              handleOptionMenuItemClick(clickedItem);
            }}
          />
        }
        onCancelClick={() => {
          setShowDisplayDrawer(false);
        }}
      />

      <SidePanelPreferences
        module={"/Rule/list"}
        panelIcon={"rule"}
        panelVisible={showPreferenceDrawer}
        onCancelClick={() => {
          setShowPreferenceDrawer(false);
        }}
        onChangeClick={() => {
          setShowPreferenceDrawer(false);
          getRecordList(searchValue, sortByValue, orderByValue, offsetValue);
        }}
      />

      {/* Delete Rule */}
      <CustomModal
        showModal={showDeleteModal}
        titleText="Remove Rule"
        messageText={`Are you sure you want to remove the ${itemName}?`}
        handleCancel={() => {
          setShowDeleteModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={confirmDeleteRule}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
