import React from "react";
import { FirstLetterUppercase, ReplaceCharToStrigify } from "./helpers";

export const ItemDetail = (props) => {
  const { label, itemValue, itemType, isHTML, replaceCharToStrigify, className } = props;

  let itemValueToPrint;
  if(replaceCharToStrigify && itemValue && itemValue !== null && itemValue !== ""){
    itemValueToPrint = ReplaceCharToStrigify(itemValue);
  } else {
    itemValueToPrint = itemValue;
  }

  return (
    <>
      {itemType !== "status" ? (
        <div className="mb-5">
          <div
            className={`font-semibold text-h1 break-all ${className}`}
            style={{ color: "#383A65" }}
          >
            {isHTML && itemValueToPrint && itemValueToPrint !== null && itemValueToPrint !== "" ? (
              <span dangerouslySetInnerHTML={{__html: itemValueToPrint.replace(/(?:\r\n|\r|\n)/g, '<br>')}}></span>
            ) : (itemValueToPrint && itemValueToPrint !== null && itemValueToPrint !== "") ? itemValueToPrint : "-"}
          </div>
          <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
            {label ? FirstLetterUppercase(label) : "-"}
          </div>
        </div>
      ) : itemType === "status" ? (
        <div className="mb-5">
          <div
            className={`font-semibold text-h1 break-all ${className}`}
            style={{ color: "#383A65" }}
          >
            {itemValueToPrint ? (itemValueToPrint === 1 ? "Active" : "Inactive") : "Inactive"}
          </div>
          <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
            Status
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
