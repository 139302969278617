import React, { useCallback, useEffect, useState, useContext } from "react";
import { Table, Tooltip } from "antd";
import { Link } from "react-router-dom";

import { AppContext } from "../store/app-context";
import { OptionsDropdown } from "./OptionsDropdown";
import { FormatDateLocal } from "./helpers";
import { ReplaceCharToStrigify } from "./helpers";
import { CustomTooltip } from "./CustomTooltip";

export const DataTable = (props) => {
  const [tableBodyWidth, setTableBodyWidth] = useState(
    document.getElementsByClassName("ant-table-body").length
      ? document.getElementsByClassName("ant-table-body")[0].offsetWidth
      : 0
  );
  const [columnWidthTotal, setColumnWidthTotal] = useState(0);
  const ctx = useContext(AppContext);

  let {
    showActionButton,
    onActionButtonClick,
    className,
    dataSource,
    columns,
    rowKey,
    onChange,
    tableScrollSize,
    currentPage,
    pageSize,
    totalRecords,
    actionButtonItemClick,
    showHorizontalScroll,
    pagination,
    isTableExpandable,
    showUserPreference,
    onUserPreferenceClick,
    getActionButtonItems,
  } = props;

  const linkClickEvent = (item, record) => {
    if (typeof item.onClick === "function") {
      item.onClick(record);
    } else {
      return;
    }
  };

  const mapColumnsForTable = useCallback(() => {
    let mappedColumns = [];
    if (!columns?.length) {
      return mappedColumns;
    }

    let clickabledItemIndex = getClickableItemIndex(columns);
    // if first column have onclick then added click event to status icon
    if(columns[clickabledItemIndex].onClick && typeof columns[clickabledItemIndex].onClick === "function") {
      columns[0]['onClick'] = columns[clickabledItemIndex].onClick;
    }
    // if first column have link then added redirection to status icon
    if(columns[clickabledItemIndex].isLink && typeof columns[clickabledItemIndex].redirectTo === "function") {
      columns[0]['isLink'] = columns[clickabledItemIndex].isLink;  
      columns[0]['redirectTo'] = columns[clickabledItemIndex].redirectTo;  
    }

    columns.forEach((item) => {
      if(!item.isChecked) {
        return false;
      }
      let columnObj = {
        title: item.type === DataTypes.ICON && showUserPreference !== false ? (
          <div className={`${isTableExpandable ? "flex" : ""}`}>
            <CustomTooltip
              title="User Preferences"
              placement={isTableExpandable ? "top" : ""}
              displayText={<img
                src="/images/icons/user-preferences-icon.svg"
                alt="User preference"
                className="cursor-pointer"
                onClick={(event) => {
                  event.stopPropagation();
                  onUserPreferenceClick(event);
                }}
              />
            }
          />
          {isTableExpandable ? 
            <TableTitle item={item} isTableExpandable={isTableExpandable} /> : ""}
          </div>
        ) :
        <TableTitle item={item} />,
        dataIndex: item.dataIndex,
        key: item.dataIndex,
        sorter: item.sorter ? true : false,
        sortDirections: ["ascend", "descend", "ascend"],
        fixed: item.fixed,
        showSorterTooltip: false,
        render: (text, record) => {
          if (item.render && typeof item.render === "function") {
            return item.render(text, record, item.dataIndex);
          } else if (item.type === DataTypes.ICON && !item.render) {
              return (
                  <div>
                    {item.isLink && typeof item?.redirectTo === "function" ? (
                      <Link
                        to={{
                          pathname: item.redirectTo(record),
                        }}
                        className="session-link"
                      >
                        <img src={`/images/icons/api-${text}-${item.icon}-icon.svg`}  alt="status"/>
                      </Link>
                    ) : 
                    <CustomTooltip
                      title={text}
                      displayText={<img src={`/images/icons/api-${text}-${item.icon}-icon.svg`} 
                      style={{
                        cursor: item.onClick ? "pointer" : "auto",
                      }}
                      onClick={() => {
                        linkClickEvent(item, record);
                      }}
                      alt="status" />}
                    />}
                  </div>
            );
          } else if (item.type === DataTypes.DATE && !item.render) {
            return <ClickableColumn item={item} linkClickEvent={linkClickEvent} record={record}>
                {item.isLink && typeof item?.redirectTo === "function" ? (
                    <Link
                        to={{
                            pathname: item.redirectTo(record),
                        }}
                        className="session-link"
                    >
                        {text ? <>{FormatDateLocal(text, "-")}</> : "-"}
                    </Link>
                ) : text ? (
                    <>{FormatDateLocal(text, "-")}</>
                ) : (
                    "-"
                )}
            </ClickableColumn>;
          } else if (item.type === DataTypes.YESNO && !item.render) {
            return <ClickableColumn item={item} linkClickEvent={linkClickEvent} record={record}>{text ? "Yes" : "No"}</ClickableColumn>;
          } else if (item.type === DataTypes.DAYS && !item.render) {
              return <ClickableColumn item={item} linkClickEvent={linkClickEvent} record={record}>{text ? text + " day(s)" : "-"}</ClickableColumn> 
          } else if (item.type === DataTypes.MINUTES && !item.render) {
              return <ClickableColumn item={item} linkClickEvent={linkClickEvent} record={record}>{text ? text + " minute(s)" : "-"}</ClickableColumn>
          } else if (item.type === DataTypes.SIGNAL && !item.render) {
            return <ClickableColumn item={item} linkClickEvent={linkClickEvent} record={record}>{parseInt(text) ? parseInt(text) + " db" : "-"}</ClickableColumn>
          } else if (item.type === DataTypes.PERCENTAGE && !item.render) {
            return <ClickableColumn item={item} linkClickEvent={linkClickEvent} record={record}>{parseInt(text) ? parseInt(text) + " %" : "-"}</ClickableColumn>
          } else if (item.type === DataTypes.METERS && !item.render) {
              return <ClickableColumn item={item} linkClickEvent={linkClickEvent} record={record}>{text ? 
                <CustomTooltip
                  overlayClassNames="custom-tooltip-scroll"
                  overlayStyles={{
                      maxWidth: "50%",
                  }}
                  overlayInnerStyle={{
                      maxHeight: "50vh",
                      overflowY: "auto"
                  }}
                  title={text + " meters(s)"}
                  displayText={text + " meters(s)"}
              /> : "-"}
            </ClickableColumn>
          } else if (item.type === DataTypes.GPS && !item.render) {
              return (
                  <div>
                      <CustomTooltip
                          title={text + " | " + record.longitude}
                          displayText={text ? <ClickableColumn item={item} linkClickEvent={linkClickEvent} record={record}>{text + " | " + record.longitude}</ClickableColumn>: "-"}
                          styles={{ maxWidth: "10vw" }}
                          className="block"
                      />
                  </div>
              );
          } else if (item.type === DataTypes.JSON && !item.render) {
              return (
                  <div>
                      {text ? 
                          <CustomTooltip
                              overlayClassNames="custom-tooltip-scroll"
                              overlayStyles={{
                                  maxWidth: "50%",
                              }}
                              overlayInnerStyle={{
                                  maxHeight: "50vh",
                                  overflowY: "auto"
                              }}
                              title={ReplaceCharToStrigify(text)}
                              displayText={<ClickableColumn item={item} linkClickEvent={linkClickEvent} record={record}>{text}</ClickableColumn>}
                          /> 
                        : "-" }
                  </div>
                  );
          } else {
            return text ? (
              <Tooltip
                title={text}
                placement="topLeft"
                overlayClassName="custom-tooltip-scroll"
                overlayStyle={{
                  maxWidth: "50%",
                }}
                overlayInnerStyle={{
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
              >
                <span
                  style={{
                    color: item.isLink || item.onClick ? "#4E2C90" : "",
                    cursor: item.isLink || item.onClick ? "pointer" : "auto",
                  }}
                  className={`overflow-hidden overflow-ellipsis text-h1 whitespace-nowrap block ${
                    item.bold ? "font-semibold" : ""
                  } ${item.isStatusError ? "error-text" : "" }`}
                  onClick={() => {
                    linkClickEvent(item, record);
                  }}
                >
                  {item?.isLink && typeof item?.redirectTo === "function" ? (
                    <Link
                      to={{
                        pathname: item?.redirectTo(record),
                      }}
                      className="session-link"
                    >
                      {text ? text : "-"}
                    </Link>
                  ) : text ? (
                    text
                  ) : (
                    "-"
                  )}
                </span>
              </Tooltip>
            ) : (
              "-"
            );
          }
        },
      };
      // set width for column if passed
      // or field type is date, ip, json
      let itemType = item.type?.toLowerCase();
      if (item.width) {
        columnObj["width"] = item.width;
      } else if (itemType === DataTypes.ICON) {
        columnObj["width"] = "40px";
      } else if (itemType === DataTypes.DATE) {
        columnObj["width"] = "170px";
      } else if (itemType === DataTypes.IP) {
        columnObj["width"] = "120px";
      } else if (itemType === DataTypes.YESNO) {
          columnObj["width"] = "100px";
      } else if (itemType === DataTypes.JSON || itemType === DataTypes.URL) {
        columnObj["width"] = "300px";
      }
      mappedColumns.push(columnObj);
    });
    // add 3 dots option menu column if action button is required
    if (showActionButton) {
      mappedColumns.push({
        title: "",
        dataIndex: "",
        key: "",
        width: "50px",
        fixed: "right",
        render: (text, record) => (
          <OptionsDropdown
            className="mx-auto"
            onActionButtonClick={(item) => { onActionButtonClick(record, item); } }
            actionButtonItems={getActionButtonItems(record)}
            actionButtonItemClick={(clickedItem) => { actionButtonItemClick(clickedItem); } }
          />
        ),
      });
    }
    return mappedColumns;
  }, [
    columns,
    onActionButtonClick,
    actionButtonItemClick,
    showActionButton,
  ]);

  let scrollObj = {
    y: tableScrollSize
    ? tableScrollSize
    : `calc(100vh - ${pagination === false ? ctx.pageInfo?.HomeTitle ? "225" : "205" : ctx.pageInfo?.HomeTitle ? "275" : "262"}px)`,
  };
  if (showHorizontalScroll !== false && columns && columns.length && columns.filter((item) => item.isChecked).length > 7) {
    scrollObj["x"] =
    parseFloat(columnWidthTotal) > parseFloat(tableBodyWidth)
    ? columnWidthTotal
    : tableBodyWidth;
    if(columns?.length) {
      columns[0].fixed = "left";
      columns[1].fixed = "left";
    }
  } else {
    scrollObj["x"] = 0;
  }
  

  useEffect(() => {
    let columnsTotalWidth = 0;
    let mappedColumns = mapColumnsForTable();
    mappedColumns.forEach((column) => {
      if (column.width) {
        columnsTotalWidth += parseFloat(column.width);
      } else {
        columnsTotalWidth += 150; // 150px is default width for columns if horizontal scroll is enabled
      }
    });
    setColumnWidthTotal(columnsTotalWidth);
  }, [mapColumnsForTable]);

  useEffect(() => {
    // to set horizontal scroll for table
    if (document.getElementsByClassName("ant-table-body").length) {
      setTableBodyWidth(
        document.getElementsByClassName("ant-table-body")[0].offsetWidth
      );
    }
  }, []);

  return (
    <Table
      className={`flat-table ${className}`}
      dataSource={dataSource}
      columns={mapColumnsForTable()}
      onChange={onChange}
      rowKey={
        isTableExpandable
          ? rowKey
          : (record) =>
              `${record[rowKey]}${Math.floor(100000 + Math.random() * 900000)}`
      } // added math.floor to avoid key duplication. it creates 6 digit random number
      scroll={scrollObj}
      indentSize={isTableExpandable ? 20 : ""}
      expandIcon={
        isTableExpandable
          ? ({ expanded, onExpand, record }) =>
              record?.children?.length > 0 ? (
                expanded ? (
                  <button
                    style={{
                      outline: "none",
                      backgroundColor: "#4E2C90",
                      width: "28px",
                      height: "25px",
                      borderRadius: "7px",
                    }}
                    onClick={(e) => {
                      onExpand(record, e);
                    }}
                    className="arrow-dropup float-left mr-2"
                  >
                    <img
                      src={"/images/icons/inner-table-dropdown.svg"}
                      alt=""
                      style={{ margin: "0 auto" }}
                    />
                  </button>
                ) : (
                  <button
                    style={{
                      outline: "none",
                      backgroundColor: "rgb(243, 245, 248)",
                      width: "28px",
                      height: "25px",
                      borderRadius: "7px",
                    }}
                    onClick={(e) => {
                      onExpand(record, e);
                    }}
                    className="arrow-dropdown float-left mr-2"
                  >
                    <img
                      src={"/images/icons/inner-table-dropdown.svg"}
                      alt=""
                      style={{
                        margin: "0 auto",
                        transform: "rotate(180deg)",
                        filter: "invert(1)",
                      }}
                    />
                  </button>
                )
              ) : (
                <div style={{ width: "36px", height: "25px" }} className="float-left"></div>
              )
          : undefined
      }
      pagination={
        pagination !== false
          ? {
              current: currentPage,
              position: ["bottomRight"],
              showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total}`,
              pageSize: pageSize,
              total: totalRecords,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50"],
            }
          : false
      }
    />
  );
};

export const DataTypes = {
  CUSTOM: "custom",
  ICON: "icon",
  DATE: "date",
  IP: "ip",
  URL: "url",
  JSON: "json",
  MINUTES: "mins",
  METERS: "meters",
  DAYS: "days",
  YESNO: "yesno",
  LIST: "list",
  GPS: "gps",
  IMAGE : "image",
  SIGNAL: "signal",
  PERCENTAGE: "percentage",
};

const ClickableColumn = (props) => {
  const {children, item, linkClickEvent, record} = props;

  return (
    <span 
      style={{
        color: item.isLink || item.onClick ? "#4E2C90" : "",
        cursor: item.isLink || item.onClick ? "pointer" : "auto",
      }}
      className={`${
        item.bold ? "font-semibold" : ""
      } }`}
      onClick={() => {
        linkClickEvent(item, record);
      }}
    >{children}
    </span>
  )
}

export const ExpandableColumn = (props) => {
  const { record, handleColumnClick, columnList, acl, statusIcon } = props;
  let clickabledIndex = getClickableItemIndex(columnList);
  return (
    <div className="flex items-center">
      <div style={{ minWidth: "35px" }}>
        <CustomTooltip
          title={record.StatusName}
          displayText={
            <img
              src={statusIcon}
              className="cursor-pointer"
              onClick={() => {
                handleColumnClick(record);
              }}
              alt="Status"
            />
          }
        />
      </div>
      <div
        className={`${
          acl ? "font-semibold cursor-pointer" : "font-medium"
        } flex items-center break-all overflow-hidden`}
        style={{ color: acl ? "#4E2C90" : "#8892a5" }}
        onClick={() => {
          handleColumnClick(record);
        }}
      >
        <CustomTooltip
          title={
            record[columnList[clickabledIndex]["dataIndex"]]
              ? columnList[clickabledIndex]["type"] === "date"
                ? FormatDateLocal(record[columnList[clickabledIndex]["dataIndex"]])
                : record[columnList[clickabledIndex]["dataIndex"]]
              : "-"
          }
          className="ml-3"
        />
      </div>
    </div>
  );
};

const TableTitle = (props) => {
  const {item, isTableExpandable} = props;

  return (
    <div className={`flex ${item.sorter ? "cursor-pointer" : ""}`}>
      <CustomTooltip
        title={item.title}
        placement={isTableExpandable ? "top" : "topLeft"}
        displayText={<span className={`table-th-title ${isTableExpandable ? 'ml-6' : ''}`}>{item.title}</span>}
      />
      {item.sorter ? <img src="/images/icons/sort.svg" alt="sort" className="ml-2" /> : ""}
    </div>
  )
}

export const getClickableItemIndex = (list) => {
  // to find the index of clickable item and isChecked = true
  const returnableIndex = list.findIndex((record, index) => record.isChecked && index !== 0);
  return  returnableIndex > 0 ? returnableIndex : 0;
}