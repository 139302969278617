import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import { AppContext } from "../../../Core/store/app-context";

import { Layout } from "../../../Core/layout";
import { FeatureTypes } from "../../../Core/store/app-feature";
import { DataTable, getClickableItemIndex } from "../../../Core/common/DataTable";
import { CustomModal } from "../../../Core/common/CustomModal";
import { OptionsDropdown } from "../../../Core/common/OptionsDropdown";
import { SidePanelPreferences } from "../../../Core/common/SidePanelPreferences";
import { SidePanelChange } from "../../../Core/common/SidePanelChange";
import { SidePanelDisplay } from "../../../Core/common/SidePanelDisplay";

import { RuleAlertChange } from "./RuleAlertChange";
import { RuleAlertDisplay } from "./RuleAlertDisplay";

export const RuleAlertList = (props) => {
  console.log('testing the refresh issue');
  const ctx = useContext(AppContext);
  const { ruleId } = useParams();
  const history = useHistory();

  const [pageSize, setPageSize] = useState(10);
  const [offsetValue, setOffsetValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [showUpdateDrawer, setShowUpdateDrawer] = useState(false);
  const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
  const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

  const [columnList, setColumnList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");

  const [alertViewDatareasonId, setAlertViewDatareasonId] = useState(null);
  const [alertViewDataEscalationId, setAlertViewDataEscalationId] = useState(null);

  //Consent
  const [modalButtonLoading, setModalButtonLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    getRecordList();
  }, []);

  const getRecordList = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    offset = offsetValue,
    limit = pageSize
  ) => {
    let queryParams = {
      search: searchBy,
      sortBy: sortBy,
      orderBy: orderBy,
      offset: offset,
      limit: limit,
      id: ruleId,
    };
    const response = await ctx.HttpGetList("/Rule/alert/list", queryParams);
    if (response) {
      setRecordList(response.Data);
      setTotalCount(response.Count);
      setColumnList(response.Headers);
      setSearchPlaceholder(response.searchPlaceholder);
    }
  };

  const handleOptionMenuItemClick = (clickedItem) => {
    setShowDisplayDrawer(false);
    if (clickedItem === "Update") {
      setShowUpdateDrawer(true);
    } else if (clickedItem === "Remove") {
      setShowDeleteModal(true);
    }

    // set module state before redirecting
    // handle state from here, refer rule list
    
  };

  const confirmDeleteRule = async () => {
    setModalButtonLoading(true);
    const response = await ctx.HttpDelete("/Rule/alert", {
      ruleId: ruleId,
      reasonId: alertViewDatareasonId,
      escalation: alertViewDataEscalationId,
    });
    if (response) {
      ctx.showToastAlert({ type: "success", message: response });
      let offset = offsetValue;
      if (recordList.length === 1  && offset > 1) {
        offset = offset - 1;
        setOffsetValue(offset);
      }
      getRecordList(searchValue, sortByValue, orderByValue, offset);
    }
    setShowDeleteModal(false);
    setModalButtonLoading(false);
  };

  // add click event if user have view access
  if (ctx.acl(FeatureTypes.Rules, 'GetRuleAlert') && columnList?.length > 1) {
    columnList[getClickableItemIndex(columnList)]['onClick'] = (record) => {
      setAlertViewDatareasonId(record.reasonId ? record.reasonId : 0);
      setAlertViewDataEscalationId(record.escalation);
      setShowDisplayDrawer(true);      
    }
  }

  // add action items as per role access
  const getActionButtonItems = (listItem = null) => {
    let actionButtonItems = [];
    if (ctx.acl(FeatureTypes.Rules, 'PutRuleAlert')) {
      actionButtonItems.push('Update');
    }
    if (ctx.acl(FeatureTypes.Rules, 'DeleteRuleAlert')) {
      actionButtonItems.push('Remove');
    }
    return actionButtonItems;
  }

  return (
    <>
      <Layout
        page="rule-alert"
        id={FeatureTypes.RulesAlert}
        title="Rule Alert setup"
        subTitle="Manage Rule alerts lifecycle here"
        pageTitleButton={ctx.acl(FeatureTypes.Rules, 'PostRuleAlert')}
        searchOnModule={FeatureTypes.RulesAlert}
        searchPlaceholder={searchPlaceholder}
        onSearchChange={(value) => {
          setOffsetValue(1);
          setSearchValue(value);
          getRecordList(value, null, null, 1);
        }}
        showFilter={false}
        buttonOnClick={() => {
          setAlertViewDatareasonId(0);
          setAlertViewDataEscalationId(null);
          setShowAddDrawer(true);
        }}
        buttonLabel="Add Alert"
        showPageActionButton={true}
        pageActionButtonLabel="Rules setup"
        pageActionButtonIcon="/images/icons/Previous-icon.svg"
        pageActionButtonOnClick={() => {
          history.push(`/rule`);
        }}
      >
        <DataTable
          showActionButton={getActionButtonItems().length ? true : false}
          getActionButtonItems={getActionButtonItems}
          onActionButtonClick={(record, clickedItem = null) => {
            setAlertViewDatareasonId(record.reasonId ? record.reasonId : 0);
            setAlertViewDataEscalationId(record.escalation);
            }}
            actionButtonItemClick={(clickedItem) => {
              handleOptionMenuItemClick(clickedItem);
            }}
            dataSource={recordList}
            columns={columnList}
            totalRecords={totalCount}
            pageSize={pageSize}
            currentPage={offsetValue}
            rowKey={"Id"}
            pagination={true}
            onUserPreferenceClick={() => {
              setShowPreferenceDrawer(true);
            }}
            onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
            let sortOrderValue = "";
            if (sorterOptions && sorterOptions.order) {
                sortOrderValue =
                sorterOptions.order === "ascend" ? "ASC" : "DESC";
                setOrderByValue(sortOrderValue);
                setSortByValue(sorterOptions.columnKey);
            }
            setPageSize(pageSizeOptions.pageSize);
            setOffsetValue(pageSizeOptions.current);
            getRecordList(
                searchValue,
                sorterOptions.columnKey,
                sortOrderValue,
                pageSizeOptions.current,
                pageSizeOptions.pageSize
            );
            }}
        />
      </Layout>

      <SidePanelChange
        // panelIcon={"rule"}
        panelTitle={"Add Alert"}
        panelVisible={showAddDrawer}
        panelBody={
          <RuleAlertChange
            getRecordList={getRecordList}
            hideChangeDrawer={() => {
              setShowAddDrawer(false);
              setAlertViewDatareasonId(null);
              setAlertViewDataEscalationId(null);
            }}
          />
        }
      />

      <SidePanelChange
        // panelIcon={"rule"}
        panelTitle={"Update Alert"}
        panelVisible={showUpdateDrawer}
        panelBody={
          <RuleAlertChange
            getRecordList={getRecordList}
            alertViewDatareasonId={alertViewDatareasonId}
            alertViewDataEscalationId={alertViewDataEscalationId}
            hideChangeDrawer={() => {
              setShowUpdateDrawer(false);
              setAlertViewDatareasonId(null);
              setAlertViewDataEscalationId(null);
            }}
          />
        }
      />

      <SidePanelDisplay
        headerData={columnList}
        // panelIcon={"rule"}
        panelTitle={"Alert Details"}
        panelVisible={showDisplayDrawer}
        panelBody={<RuleAlertDisplay 
          alertViewDatareasonId={alertViewDatareasonId}
          alertViewDataEscalationId={alertViewDataEscalationId}  
        />}
        customAction={
          <OptionsDropdown
            actionButtonItems={getActionButtonItems()}
            actionButtonItemClick={(clickedItem) => {
              handleOptionMenuItemClick(clickedItem);
            }}
          />
        }
        onCancelClick={() => {
          setShowDisplayDrawer(false);
        }}
      />

      <SidePanelPreferences
        module={"/Rule/alert/list"}
        // panelIcon={"rule"}
        panelVisible={showPreferenceDrawer}
        onCancelClick={() => {
          setShowPreferenceDrawer(false);
        }}
        onChangeClick={() => {
          setShowPreferenceDrawer(false);
          getRecordList(searchValue, sortByValue, orderByValue, offsetValue);
        }}
      />

      {/* Delete Rule Alert*/}
      <CustomModal
        showModal={showDeleteModal}
        titleText="Remove Alert"
        messageText={`Are you sure you want to remove the alert?`}
        handleCancel={() => {
          setShowDeleteModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={confirmDeleteRule}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
