import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { Layout } from "../../../Core/layout";
import { FeatureTypes } from "../../../Core/store/app-feature";
import { AppContext } from "../../../Core/store/app-context";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { CustomSwitchToggle } from "../../../Core/common/CustomSwitchToggle";
// import { ImageZoomer } from "../../../Core/common/ImageZoomer";

export const SpaceMapView = (props) => {
  const ctx = useContext(AppContext);
  const history = useHistory();
  const [zone, setZone] = useState(false);
  const [reader, setReader] = useState(false);

  return (
    <>
      <Layout
        page="spaces"
        id={FeatureTypes.Spaces}
        title="Floor 1"
        subTitle="Floor 1 map overview"
        pageTitleButton={ctx.acl(FeatureTypes.Locations, "PostZone")}
        buttonOnClick={() => {
          console.log("add zone");
        }}
        buttonLabel="Add Zone"
        buttonIcon={
          <PlusOutlined style={{ color: "#4E2C90" }} className="flex m-auto" />
        }
        secondaryButtonOnClick={() => {
          console.log("add reader");
        }}
        secondaryButtonLabel={ctx.acl(FeatureTypes.Readers, "PostReader") ? "Add Reader" : ""}
      >
        {/* back */}
        <Row type="flex" className="mb-5">
          <Col span={12}></Col>
          <Col span={12} className="my-auto text-right">
            <Button
              onClick={() => {
                history.goBack();
              }}
              className="shadow inline-flex items-center font-poppins text-white text-h1 font-medium bg-daisy-bush rounded-lg"
            >
              <img
                className="mr-2"
                alt="back"
                src="/images/icons/Previous-icon.svg"
              />
              Back
            </Button>
          </Col>
        </Row>

        {/* map overview container */}
        <div
          className="bg-white p-3"
          style={{
            borderRadius: "12px",
          }}
        >
          <div className="flex items-center justify-between">
            <div
              style={{
                color: "#383A65",
                fontSize: "15px",
                fontWeight: 600,
              }}
            >
              Floor 1 map overview
            </div>
            <div className="flex items-center">
              <CustomSwitchToggle
                switchValue={zone}
                onText="Zone on"
                offText="Zone off"
                onChange={(checked) => setZone(checked)}
                className="mr-3 toggleActiveColorBlue"
                styleName="labelDarkBlueText"
              />
              <CustomSwitchToggle
                switchValue={reader}
                onText="Reader on"
                offText="Reader off"
                onChange={(checked) => setReader(checked)}
                className="toggleActiveColorBlue"
                styleName="labelDarkBlueText"
              />
            </div>
          </div>
          {/* commenting - to work on it later
          <ImageZoomer
            imageDetails={[
              {
                image: "/images/floor-image.png",
                width: 997,
                height: 803,
                pointers: [
                  { lat: 400, lng: 400 },
                  { lat: 600, lng: 400, icon: "/images/icons/minus-icon.svg" },
                  { lat: 400, lng: 600, borderColor: "rgb(217 81 40 / 40%)" },
                ],
              },
            ]}
            // pageMargin={216}
            // hideResetControl={true}
            // hideZoomControl={true}
            // zoomerMinHeight={450}
          /> */}
        </div>
      </Layout>
    </>
  );
};
