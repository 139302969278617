import React, { useState, useEffect, useContext } from "react";

import { AppContext } from "../../../Core/store/app-context";
import { Layout } from "../../../Core/layout";
import { DataTable, getClickableItemIndex } from "../../../Core/common/DataTable";
import { FeatureTypes } from "../../../Core/store/app-feature";
import { GetTimezoneOffset } from "../../../Core/common/helpers";
import { DownloadFileBlob } from "../../../Core/common/helpers";
import { SidePanelFilter } from "../../../Core/common/SidePanelFilter";
import { SidePanelDisplay } from "../../../Core/common/SidePanelDisplay";
import { SidePanelPreferences } from "../../../Core/common/SidePanelPreferences";

export const SessionLogList = (props) => {
  const ctx = useContext(AppContext);

  const [pageSize, setPageSize] = useState(10);
  const [offsetValue, setOffsetValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
  const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

  const [columnList, setColumnList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");
  const [listData, setListData] = useState({});
  const [filterData, setFilterData] = useState({ isFilter: false });
  
  // to make the API call after FilterData.isFilter is updated (includes init time update)
  useEffect(() => {
    let moduleStateValue = ctx.moduleState;
    // check if module state is available then use it
    if (moduleStateValue.moduleName === FeatureTypes.SessionLogs && (moduleStateValue.offset !== 1 || moduleStateValue.orderByValue || moduleStateValue.searchValue || moduleStateValue.sortValue || moduleStateValue.pageSize !== 10 || moduleStateValue.filterData.isFilter)) { 
      setOffsetValue(moduleStateValue.offset);
      setOrderByValue(moduleStateValue.orderByValue);
      setSearchValue(moduleStateValue.searchValue);
      setSortByValue(moduleStateValue.sortValue);
      setPageSize(moduleStateValue.pageSize);
      setFilterData(moduleStateValue.filterData);
      if (moduleStateValue.filterData.isFilter === filterData.isFilter) {
        getRecordList(false, moduleStateValue.searchValue, moduleStateValue.sortValue, moduleStateValue.orderByValue, moduleStateValue.offset, moduleStateValue.pageSize);
        ctx.resetModuleState(); 
      }
    } else {
      setOffsetValue(1);
      getRecordList(false, searchValue, sortByValue, orderByValue, 1);
      if(!filterData.isFilter){
        setShowFilterDrawer(false);
      }
    }
  }, [filterData.isFilter]);

  const getRecordList = async (
    exportData = false,
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    offset = offsetValue,
    limit = pageSize
  ) => {
    let queryParams = {
      search: searchBy,
      sortBy: sortBy,
      orderBy: orderBy,
      offset: offset,
      limit: limit,
      utcOffset: GetTimezoneOffset(),
      ...filterData,
    };
    if (exportData) {
      const response = await ctx.HttpFile("/SessionLog/Export", queryParams);
      if (response) {
        DownloadFileBlob(response, "Session log.csv");
      }
    } else {
      const response = await ctx.HttpGetList("/SessionLog/list", queryParams);
      if (response) {
        setRecordList(response.Data);
        setTotalCount(response.Count);
        setColumnList(response.Headers);
        setSearchPlaceholder(response.searchPlaceholder);
      }
    }
  };

   // add click event if user have view access
  if (ctx.acl(FeatureTypes.SessionLogs, 'GetSessionLog') && columnList?.length > 1) {
      columnList[getClickableItemIndex(columnList)]['isLink'] = true;
      columnList[getClickableItemIndex(columnList)]['redirectTo'] = (record) => { return `/logs-session/session-details/${record.Id}`; }
      columnList[getClickableItemIndex(columnList)]['onClick'] = (record) => {
          // set module state before redirection
          ctx.setModuleState({
              moduleName: FeatureTypes.SessionLogs,
              searchValue: searchValue,
              sortValue: sortByValue,
              orderByValue: orderByValue,
              offset: offsetValue,
              pageSize: pageSize,
              filterData: filterData
          })
      }
  }

  return (
    <>
      <Layout
        page="log-session"
        id={FeatureTypes.SessionLogs}
        searchOnModule={FeatureTypes.SessionLogs}
        searchPlaceholder={searchPlaceholder}
        onSearchChange={(value) => {
          setSearchValue(value);
          setOffsetValue(1);
          getRecordList(false, value, null, null, 1);
        }}
        filterApplied={filterData.isFilter}
        onFilterClick={() => {
          setShowFilterDrawer(true);
        }}
        showExport={ctx.acl(FeatureTypes.SessionLogs, 'GetSessionLogExport')}
        exportConfirmationModal={true}
        exportFileTypes={[{type: "csv"}]}
        disabled={totalCount === 0}
        onExportClick={(fileType, fullData = false) => {
          //fileType = Type of the file to export
          //fullData = If we want full data or not
          getRecordList(true, searchValue, sortByValue, orderByValue, 1, totalCount);
        }}
      >
        <DataTable
            dataSource={recordList}
            columns={columnList}
            totalRecords={totalCount}
            pageSize={pageSize}
            rowKey={"Id"}
            currentPage={offsetValue}
            onUserPreferenceClick={() => {
              setShowPreferenceDrawer(true);
            }}
            onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
            let sortOrderValue = "";
            if (sorterOptions && sorterOptions.order) {
                sortOrderValue =
                sorterOptions.order === "ascend" ? "ASC" : "DESC";
                setOrderByValue(sortOrderValue);
                setSortByValue(sorterOptions.columnKey);
            }
            setPageSize(pageSizeOptions.pageSize);
            setOffsetValue(pageSizeOptions.current);
            getRecordList(
                false,
                searchValue,
                sorterOptions.columnKey,
                sortOrderValue,
                pageSizeOptions.current,
                pageSizeOptions.pageSize
            );
            }}
        />
      </Layout>


          <SidePanelFilter
              moduleRoute={"/SessionLog"}
              filterData={filterData}
              setFilterData={setFilterData}
              panelIcon={"sessionlog"}
              panelVisible={showFilterDrawer}
              onCancelClick={() => {
                  setShowFilterDrawer(false);
              }}
              onChangeClick={() => {
                  setShowFilterDrawer(false);
                  if(filterData.isFilter){
                    setOffsetValue(1);
                    getRecordList(false, searchValue, sortByValue, orderByValue, 1);
                  }
              }}
          />
          <SidePanelDisplay
              listData={listData}
              headerData={columnList}
              panelIcon={"sessionlog"}
              panelTitle={"Session Log Details"}
              panelVisible={showDisplayDrawer}
              onCancelClick={() => {
                  setShowDisplayDrawer(false);
                  setListData({});
              }}
          />
          <SidePanelPreferences
              module={"/SessionLog/list"}
              panelIcon={"sessionlog"}
              panelVisible={showPreferenceDrawer}
              onCancelClick={() => {
                  setShowPreferenceDrawer(false);
              }}
              onChangeClick={() => {
                  setShowPreferenceDrawer(false);
                  getRecordList(false, searchValue, sortByValue, orderByValue, offsetValue);
              }}
          />
    </>
  );
};
