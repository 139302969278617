import React, { useState, useEffect, useContext } from "react";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { useParams, useHistory } from "react-router-dom";

import { Layout } from "../../../Core/layout";
import { FormatDateLocal } from "../../../Core/common/helpers";
import { AppContext } from "../../../Core/store/app-context";
import { FeatureTypes } from "../../../Core/store/app-feature";

import { DataTable, getClickableItemIndex } from "../../../Core/common/DataTable";
import { CustomModal } from "../../../Core/common/CustomModal";
import { CustomTooltip } from "../../../Core/common/CustomTooltip";

import { SidePanelPreferences } from "../../../Core/common/SidePanelPreferences";
import { SidePanelChange } from "../../../Core/common/SidePanelChange";
import { SidePanelDisplay } from "../../../Core/common/SidePanelDisplay";

import { RuleLocationChange } from "./RuleLocationChange";
import { OptionsDropdown } from "../../../Core/common/OptionsDropdown";

export const RuleLocationList = (props) => {
  const ctx = useContext(AppContext);
  const history = useHistory();
  const { ruleId } = useParams();

  const [pageSize, setPageSize] = useState(10);
  const [offsetValue, setOffsetValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [showUpdateDrawer, setShowUpdateDrawer] = useState(false);
  const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
  const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

  const [columnList, setColumnList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");

  const [itemId, setItemId] = useState(null);
  const [itemName, setItemName] = useState(null);
  const [locationViewLocationId, setLocationViewLocationId] = useState("");

  //Consent
  const [modalButtonLoading, setModalButtonLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [listData, setListData] = useState({});

  const [locationSequenceChangeCount, setLocationSequenceChangeCount] =
    useState(0);

  const [ruleSequence, setRuleSequence] = useState({
    min: 0,
    max: 0,
  });

  useEffect(() => {
    getRecordList();
  }, []);

  const getRecordList = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    offset = offsetValue,
    limit = pageSize
  ) => {
    let queryParams = {
      search: searchBy,
      sortBy: sortBy,
      orderBy: orderBy,
      offset: offset,
      limit: limit,
      id: ruleId,
    };
    const response = await ctx.HttpGetList("/Rule/location/list", queryParams);
    if (response) {
      setRecordList(response.Data);
      setTotalCount(response.Count);
      setColumnList(response.Headers);
      setSearchPlaceholder(response.searchPlaceholder);
      //Updating min max sequence number
      if (response.Data.length) {
        setRuleSequence({
          min: response.Data[0].sequence,
          max: response.Data[response.Data.length - 1].sequence,
        });
      }
    }
  };

  const handleOptionMenuItemClick = (clickedItem) => {
    setShowDisplayDrawer(false);
    if (clickedItem === "Update") {
      setShowUpdateDrawer(true);
    } else if (clickedItem === "Remove") {
      setShowDeleteModal(true);
    }

    // set module state before redirecting
    // handle state from here, refer rule list
  };

  const confirmDeleteLocation = async () => {
    setModalButtonLoading(true);
    const response = await ctx.HttpDelete("/Rule/location", {
      ruleId: ruleId,
      typeId: itemId,
      locationId: locationViewLocationId,
    });
    if (response) {
      ctx.showToastAlert({ type: "success", message: response });
      let offset = offsetValue;
      if (recordList.length === 1 && offset > 1) {
        offset = offset - 1;
        setOffsetValue(offset);
      }
      getRecordList(searchValue, sortByValue, orderByValue, offset);
    }
    setShowDeleteModal(false);
    setModalButtonLoading(false);
  };

  const handleOrderChange = async (direction, text) => {
    let locationToUpdateIndex = recordList
      .map(function (e) {
        return e.locationId;
      })
      .indexOf(text);

    if (locationToUpdateIndex >= 0) {
      let payload = {
        current: {
          ruleId: recordList[locationToUpdateIndex]["ruleId"],
          typeId: recordList[locationToUpdateIndex]["typeId"],
          locationId: recordList[locationToUpdateIndex]["locationId"],
          sequence: recordList[locationToUpdateIndex]["sequence"],
        },
        next: {
          ruleId: null,
          typeId: null,
          locationId: null,
          sequence: null,
        },
      };
      if (direction === "up") {
        payload.next.ruleId = recordList[locationToUpdateIndex - 1]["ruleId"];
        payload.next.typeId = recordList[locationToUpdateIndex - 1]["typeId"];
        payload.next.locationId = recordList[locationToUpdateIndex - 1]["locationId"];
        payload.next.sequence = recordList[locationToUpdateIndex - 1]["sequence"];
      } else {
        payload.next.ruleId = recordList[locationToUpdateIndex + 1]["ruleId"];
        payload.next.typeId = recordList[locationToUpdateIndex + 1]["typeId"];
        payload.next.locationId = recordList[locationToUpdateIndex + 1]["locationId"];
        payload.next.sequence = recordList[locationToUpdateIndex + 1]["sequence"];
      }
      const response = await ctx.HttpPost("/Rule/location/swap", payload);
      if (response) {
        if (direction === "up") {
          // reduce the sequence for clicked item
          recordList[locationToUpdateIndex]["sequence"] = recordList[locationToUpdateIndex]["sequence"] - 1;

          // increase the sequence for above item
          recordList[locationToUpdateIndex - 1]["sequence"] = recordList[locationToUpdateIndex - 1]["sequence"] + 1;
        } else {
          // increase the sequence for clicked item
          recordList[locationToUpdateIndex]["sequence"] = recordList[locationToUpdateIndex]["sequence"] + 1;

          // reduce the sequence for above item
          recordList[locationToUpdateIndex + 1]["sequence"] = recordList[locationToUpdateIndex + 1]["sequence"] - 1;
        }

        // sort and update Location list
        let sortedLocationList = recordList.sort(
          (a, b) => a.sequence - b.sequence
        );
        setRecordList(JSON.parse(JSON.stringify(sortedLocationList)));
        // update this variable to re-render the UI
        setLocationSequenceChangeCount(locationSequenceChangeCount + 1);
      }
    }
  };

  // add click event if user have view access
  if (ctx.acl(FeatureTypes.Rules, "GetRuleLocation") && columnList?.length > 1) {
    columnList[getClickableItemIndex(columnList)].render = (text, record, index) => (
      <>
        {ctx.acl(FeatureTypes.Rules, "PostRuleLocationSwap") ? (
          <div className="flex items-center ">
            <div className="mr-2 flex flex-col">
              <ArrowUpOutlined
                style={{
                  fontSize: 10,
                  color: "#4E2C90",
                  opacity: record?.sequence === ruleSequence.min ? 0.5 : 1,
                  pointerEvents: record?.sequence === ruleSequence.min ? "none" : "all",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOrderChange("up", record.locationId);
                }}
              />
              <ArrowDownOutlined
                style={{
                  fontSize: 10,
                  color: "#4E2C90",
                  opacity: ruleSequence.max === record?.sequence ? 0.5 : 1,
                  pointerEvents: ruleSequence.max === record?.sequence ? "none" : "all",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOrderChange("down", record.locationId);
                }}
              />
            </div>
            <div
              className="font-semibold cursor-pointer flex items-center justify-center break-all"
              style={{ color: "#4E2C90" }}
              onClick={() => {
                setItemId(record.typeId);
                setLocationViewLocationId(record.locationId);
                setItemName(record.locationName);
                setShowDisplayDrawer(true);
                setListData(record);
              }}
            >
              <CustomTooltip
                title={
                  text ? columnList[getClickableItemIndex(columnList)]["type"] ===
                      "date"
                      ? FormatDateLocal(text)
                      : text
                    : "-"
                }
                className="ml-2"
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
  const getActionButtonItems = (listItem = null) => {
    let actionButtonItems = [];
    if (ctx.acl(FeatureTypes.Rules, "PutRuleLocation")) {
      actionButtonItems.push("Update");
    }
    if (ctx.acl(FeatureTypes.Rules, "DeleteRuleLocation")) {
      actionButtonItems.push("Remove");
    }
    return actionButtonItems;
  };

  return (
    <>
      <Layout
        page="rule-location"
        id={FeatureTypes.RulesLocation}
        title="Rule Location setup"
        subTitle="Manage Rule locations here"
        pageTitleButton={ctx.acl(FeatureTypes.Rules, "PostRuleLocation")}
        searchOnModule={FeatureTypes.RulesLocation}
        searchPlaceholder={searchPlaceholder}
        onSearchChange={(value) => {
          setSearchValue(value);
          setOffsetValue(1);
          getRecordList(value, null, null, 1);
        }}
        showFilter={false}
        buttonOnClick={() => {
          setItemId(null);
          setShowAddDrawer(true);
        }}
        buttonLabel="Add Location"
        showPageActionButton={true}
        pageActionButtonLabel="Rules setup"
        pageActionButtonIcon="/images/icons/Previous-icon.svg"
        pageActionButtonOnClick={() => {
          history.push(`/rule`);
        }}
      >
        <DataTable
          showActionButton={getActionButtonItems().length ? true : false}
          getActionButtonItems={getActionButtonItems}
          onActionButtonClick={(record, clickedItem = null) => {
            setItemId(record.typeId);
            setItemName(record.locationName);
            setLocationViewLocationId(record.locationId);
          }}
          actionButtonItemClick={(clickedItem) => {
            handleOptionMenuItemClick(clickedItem);
          }}
          dataSource={recordList}
          columns={columnList}
          totalRecords={totalCount}
          pageSize={pageSize}
          currentPage={offsetValue}
          rowKey={"locationId"}
          pagination={true}
          onUserPreferenceClick={() => {
            setShowPreferenceDrawer(true);
          }}
          onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
            let sortOrderValue = "";
            if (sorterOptions && sorterOptions.order) {
              sortOrderValue =
                sorterOptions.order === "ascend" ? "ASC" : "DESC";
              setOrderByValue(sortOrderValue);
              setSortByValue(sorterOptions.columnKey);
            }
            setPageSize(pageSizeOptions.pageSize);
            setOffsetValue(pageSizeOptions.current);
            getRecordList(
              searchValue,
              sorterOptions.columnKey,
              sortOrderValue,
              pageSizeOptions.current,
              pageSizeOptions.pageSize
            );
          }}
        />
      </Layout>

      <SidePanelChange
        // panelIcon={"users"}
        panelTitle={"Add Rule Location"}
        panelVisible={showAddDrawer}
        panelBody={
          <RuleLocationChange
            getRecordList={getRecordList}
            hideChangeDrawer={() => {
              setShowAddDrawer(false);
              setItemId(null);
              setLocationViewLocationId(null);
            }}
          />
        }
      />

      <SidePanelChange
        // panelIcon={"users"}
        panelTitle={"Update Rule Location"}
        panelVisible={showUpdateDrawer}
        panelBody={
          <RuleLocationChange
            itemId={itemId}
            locationViewLocationId={locationViewLocationId}
            getRecordList={getRecordList}
            hideChangeDrawer={() => {
              setShowUpdateDrawer(false);
              setItemId(null);
              setLocationViewLocationId(null);
            }}
          />
        }
      />

      <SidePanelDisplay
        listData={listData}
        headerData={columnList}
        // panelIcon={"gateways"}
        panelTitle={"Rule Location Details"}
        panelVisible={showDisplayDrawer}
        onCancelClick={() => {
          setShowDisplayDrawer(false);
          setListData({});
        }}
        customAction={
          <OptionsDropdown
            actionButtonItems={getActionButtonItems()}
            actionButtonItemClick={(clickedItem) => {
              handleOptionMenuItemClick(clickedItem);
            }}
          />
        }
      />

      <SidePanelPreferences
        module={"/Rule/location/list"}
        // panelIcon={"users"}
        panelVisible={showPreferenceDrawer}
        onCancelClick={() => {
          setShowPreferenceDrawer(false);
        }}
        onChangeClick={() => {
          setShowPreferenceDrawer(false);
          getRecordList(searchValue, sortByValue, orderByValue, offsetValue);
        }}
      />

      {/* Delete Rule Location */}
      <CustomModal
        showModal={showDeleteModal}
        titleText="Remove Location"
        messageText={`Are you sure you want to remove the ${itemName}?`}
        handleCancel={() => {
          setShowDeleteModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={confirmDeleteLocation}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
