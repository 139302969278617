import React, { useEffect, useContext, useState } from "react";
import { Row, Col } from "antd";
import { AppContext } from "../../../Core/store/app-context";
import { ItemDetail } from "../../../Core/common/ItemDetail";

export const SpaceDisplay = (props) => {
  const { itemId } = props;
  const ctx = useContext(AppContext);

  const [listData, setListData] = useState();
  const [spaceBase64Image, setSpaceBase64Image] = useState("");
  const [space3dModelBase64Image, setSpace3dModelBase64Image] = useState("");
  const [space2dFloorBase64Image, setSpace2dFloorBase64Image] = useState("");

  useEffect(() => {
    if (itemId) {
      getItemData();
    }
  }, [itemId]);

  const getItemData = async () => {
    const response = await ctx.HttpGet("/Location/space", { id: itemId });
    if (response) {
      setListData(response);

      if (response.image) {
        setSpaceBase64Image("data:image/png;base64," + response.image);
      }

      if (response.model3D) {
        setSpace3dModelBase64Image("data:image/png;base64," + response.model3D);
      }

      if (response.plan2D) {
        setSpace2dFloorBase64Image("data:image/png;base64," + response.plan2D);
      }
    }
  };

  return (
    <div>
      <div
        className="flex items-center pb-5 border-b-2 px-5"
        style={{ borderColor: "#F3F5F8" }}
      >
        <div className="ml-3">
          <div
            className="font-semibold text-h2 flex items-center mb-2 break-all"
            style={{ color: "#383A65" }}
          >
            {listData?.name ? listData?.name : "-"}
          </div>
          <div
            className="text-h1 font-medium my-2"
            style={{ color: "#8892A5" }}
          >
            Type: {listData?.typeName ? listData?.typeName : "-"}
          </div>
          <div
            style={{
              backgroundColor: listData?.status === 1 ? "#84D49D" : "#dbdae0",
              borderRadius: "15px",
              padding: "2px 10px",
            }}
            className="text-xs font-medium text-white inline"
          >
            {listData?.status === 1 ? "Active" : "Inactive"}
          </div>
        </div>
      </div>
      <div className="pt-5 px-8" style={{ borderColor: "#F3F5F8" }}>
        <div className="flex items-center " style={{ marginBottom: "22px" }}>
          <img
            src="/images/icons/user.svg"
            alt="user"
            style={{ height: "38px", width: "38px" }}
          />
          <div
            className="ml-3 font-medium text-h1"
            style={{ color: "#383A65" }}
          >
            Basic details
          </div>
        </div>
        <div style={{ marginBottom: "22px" }}>
          <div
            className="font-semibold text-h1 mb-1.5 break-all"
            style={{ color: "#383A65" }}
          >
            {listData?.name ? listData?.name : "-"}
          </div>
          <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
            Space name
          </div>
        </div>
        <div style={{ marginBottom: "22px" }}>
          <div
            className="font-semibold text-h1 mb-1.5 break-all"
            style={{ color: "#383A65" }}
          >
            {listData?.description ? listData?.description : "-"}
          </div>
          <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
            Description
          </div>
        </div>
        <div>
          <Row className="pb-0.5" style={{ marginBottom: "22px" }}>
            <Col span={12}>
              <div
                className="font-semibold text-h1 mb-1.5 break-all"
                style={{ color: "#383A65" }}
              >
                {listData?.code ? listData?.code : "-"}
              </div>
              <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
                Code
              </div>
            </Col>
            <Col>
              <div
                className="font-semibold text-h1 mb-1.5 break-all"
                style={{ color: "#383A65" }}
              >
                {listData?.typeName ? listData?.typeName : "-"}
              </div>
              <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
                Type
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Row className="pb-0.5" style={{ marginBottom: "22px" }}>
            <Col span={12}>
              <div
                className="font-semibold text-h1 mb-1.5 break-all"
                style={{ color: "#383A65" }}
              >
                {listData?.totalArea ? listData?.totalArea : "-"}
              </div>
              <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
                Total Area (sq.ft.)
              </div>
            </Col>
            <Col>
              <div
                className="font-semibold text-h1 mb-1.5 break-all"
                style={{ color: "#383A65" }}
              >
                {listData?.totalCapacity ? listData?.totalCapacity : "-"}
              </div>
              <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
                Total Capacity (sq.ft.)
              </div>
            </Col>
          </Row>
        </div>
        <div style={{ marginBottom: "22px" }}>
          <div
            className="font-semibold text-h1 mb-1.5 break-all"
            style={{ color: "#383A65" }}
          >
            {listData?.targetUtilization ? listData?.targetUtilization : "-"}
          </div>
          <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
            Target Utilization (sq.ft./person)
          </div>
        </div>
        <div>
          <Row className="pb-0.5" style={{ marginBottom: "22px" }}>
            <Col span={12}>
              <div
                className="font-semibold text-h1 mb-1.5 break-all"
                style={{ color: "#383A65" }}
              >
                {listData?.width ? listData?.width : "-"}
              </div>
              <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
                Width (pixels)
              </div>
            </Col>
            <Col>
              <div
                className="font-semibold text-h1 mb-1.5 break-all"
                style={{ color: "#383A65" }}
              >
                {listData?.length ? listData?.length : "-"}
              </div>
              <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
                Height (pixels)
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Row className="pb-0.5" style={{ marginBottom: "22px" }}>
            <Col span={12}>
              <div
                className="font-semibold text-h1 mb-1.5 break-all"
                style={{ color: "#383A65" }}
              >
                {listData?.widthMeter ? listData?.widthMeter : "-"}
              </div>
              <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
                Width (meters)
              </div>
            </Col>
            <Col>
              <div
                className="font-semibold text-h1 mb-1.5 break-all"
                style={{ color: "#383A65" }}
              >
                {listData?.lengthMeter ? listData?.lengthMeter : "-"}
              </div>
              <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
                Height (meters)
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Row className="pb-0.5" style={{ marginBottom: "22px" }}>
            <Col span={12}>
              <div
                className="font-semibold text-h1 mb-1.5 break-all"
                style={{ color: "#383A65" }}
              >
                {listData?.refXMeter.toString()
                  ? listData?.refXMeter.toString()
                  : "-"}
              </div>
              <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
                Reference X (meters)
              </div>
            </Col>
            <Col>
              <div
                className="font-semibold text-h1 mb-1.5 break-all"
                style={{ color: "#383A65" }}
              >
                {listData?.refYMeter?.toString()
                  ? listData?.refYMeter?.toString()
                  : "-"}
              </div>
              <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
                Reference Y (meters)
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col span={12}>
              <ItemDetail
                label="Is Default"
                itemValue={listData?.isDefault === 1 ? "On" : "Off"}
              />
            </Col>
            <Col>
              <ItemDetail
                label="Is GPS"
                itemValue={listData?.isGPS === 1 ? "On" : "Off"}
              />
            </Col>
          </Row>
        </div>
        <div className="relative my-6">
          <div className="flex flex-col">
            {spaceBase64Image ? (
              <>
                <div className="flex w-full">
                  <div className="relative w-full">
                    <img
                      className="rounded-lg w-full"
                      src={spaceBase64Image}
                      alt="space"
                      style={{ height: "12rem" }}
                    />
                  </div>
                </div>
                <div className="flex mt-2" style={{ justifyContent: "center" }}>
                  <div className="font-poppins text-xs font-medium leading-tight bg-white text-pickled-bluewood">
                    Space Image
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {space3dModelBase64Image ? (
          <>
            <div className="relative my-6">
              <div className="flex flex-col">
                <div className="flex w-full">
                  <div className="relative w-full">
                    <img
                      className="rounded-lg w-full"
                      src={space3dModelBase64Image}
                      alt="3d Space Model"
                      style={{ height: "12rem" }}
                    />
                  </div>
                </div>
                <div className="flex mt-2" style={{ justifyContent: "center" }}>
                  <div className="font-poppins text-xs font-medium leading-tight bg-white text-pickled-bluewood">
                    3D Space Model
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {space2dFloorBase64Image ? (
          <>
            <div className="relative my-6">
              <div className="flex flex-col">
                <div className="flex w-full">
                  <div className="relative w-full">
                    <img
                      className="rounded-lg w-full"
                      src={space2dFloorBase64Image}
                      alt="2d Space Floor"
                      style={{ height: "12rem" }}
                    />
                  </div>
                </div>
                <div className="flex mt-2" style={{ justifyContent: "center" }}>
                  <div className="font-poppins text-xs font-medium leading-tight bg-white text-pickled-bluewood">
                    2D Space Floor Image
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
