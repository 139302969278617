import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../../Core/store/app-context";
import { Row, Col } from "antd";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export const ZoneDisplay = (props) => {
  const { itemId, spaceId } = props;
  const ctx = useContext(AppContext);
  const [spaceData, setSpaceData] = useState();
  const [listData, setListData] = useState();
  const [zoneBase64Image, setZoneBase64Image] = useState("");
  const [zone2dPlanBase64Image, setZone2dPlanBase64Image] = useState("");
  const [zoneAreaPoints, setZoneAreaPoints] = useState([]);
  const [polygonAreaPoints, setPolygonAreaPoints] = useState("");

  useEffect(() => {
    if (itemId) {
      getItemData();
      getSpaceData();
    }
  }, [itemId]);

  const getSpaceData = async () => {
    const response = await ctx.HttpGet("/Location/space", { id: spaceId });
    if (response) {
      setSpaceData(response);
      if (response.image) {
        setZone2dPlanBase64Image("data:image/png;base64," + response.image);
      }
    }
  };

  const getItemData = async () => {
    const response = await ctx.HttpGet("/Location/zone", { id: itemId });
    if (response) {
      setListData(response);

      if (response.image) {
        setZoneBase64Image("data:image/png;base64," + response.image);
      }

      if (response.ArrayAreaPoints) {
        setZoneAreaPoints(response.ArrayAreaPoints);

        let areaPoints = response.ArrayAreaPoints;
        let combinedAreaPoints = "";

        areaPoints.forEach((item) => {
          combinedAreaPoints =
            combinedAreaPoints === ""
              ? item.x + "," + item.y
              : combinedAreaPoints + "," + item.x + "," + item.y;
        });

        setPolygonAreaPoints(combinedAreaPoints);
      }
    }
  };

  return (
    <div>
      <Row className="pb-0.5">
        <Col span={16}>
          <div
            className="grid grid-cols-6 gap-4"
            style={{ marginTop: "7.5rem" }}
          >
            <div className="col-start-1 col-end-7">
              <div className="flex flex-wrap justify-center bg-transparent">
                <div>
                  {zone2dPlanBase64Image ? (
                    <TransformWrapper
                      initialScale={1}
                      minScale={1}
                      maxScale={8}
                    >
                      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <React.Fragment>
                          <div style={{ textAlign: "center" }}>
                            <div
                              className="cursor-pointer"
                              style={{
                                color: "#8892a5",
                                fontWeight: 500,
                                textAlign: "center",
                                marginRight: 10,
                                display: "inline",
                              }}
                              onClick={() => zoomIn()}
                            >
                              +
                            </div>
                            <div
                              className="cursor-pointer"
                              style={{
                                color: "#8892a5",
                                fontWeight: 500,
                                textAlign: "center",
                                marginRight: 10,
                                display: "inline",
                              }}
                              onClick={() => zoomOut()}
                            >
                              -
                            </div>
                            <div
                              className="cursor-pointer"
                              style={{
                                color: "#8892a5",
                                fontWeight: 500,
                                textAlign: "center",
                                marginRight: 10,
                                display: "inline",
                              }}
                              onClick={() => resetTransform()}
                            >
                              Reset
                            </div>
                          </div>
                          <TransformComponent>
                            <div
                              className="react-image-dot__wrapper_add"
                              style={{ width: "600px", height: "500px" }}
                            >
                              <img
                                src={zone2dPlanBase64Image}
                                style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                            {polygonAreaPoints ? (
                              <svg
                                className="absolute top-0 left-0 w-full h-full"
                                style={{ height: "100%", width: "100%" }}
                              >
                                <polygon
                                  points={polygonAreaPoints}
                                  style={{ fill: "rgba(78, 44, 144, 0.25)" }}
                                />
                              </svg>
                            ) : (
                              <></>
                            )}
                          </TransformComponent>
                        </React.Fragment>
                      )}
                    </TransformWrapper>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={1}></Col>
        <Col span={5}>
          <div
            className="flex items-center pb-5 border-b-2 px-5"
            style={{ borderColor: "#F3F5F8" }}
          >
            <div className="ml-3">
              <div
                className="font-semibold text-h2 flex items-center mb-2 break-all"
                style={{ color: "#383A65" }}
              >
                {listData?.name ? listData?.name : "-"}
              </div>
              <div
                className="text-h1 font-medium my-2"
                style={{ color: "#8892A5" }}
              >
                Type: {listData?.typeName ? listData?.typeName : "-"}
              </div>
              <div
                style={{
                  backgroundColor:
                    listData?.status === 1 ? "#84D49D" : "#dbdae0",
                  borderRadius: "15px",
                  padding: "2px 10px",
                }}
                className="text-xs font-medium text-white inline"
              >
                {listData?.status === 1 ? "Active" : "Inactive"}
              </div>
            </div>
          </div>
          <div className="pt-5 px-8" style={{ borderColor: "#F3F5F8" }}>
            <div
              className="flex items-center "
              style={{ marginBottom: "22px" }}
            >
              <img
                src="/images/icons/user.svg"
                alt="user"
                style={{ height: "38px", width: "38px" }}
              />
              <div
                className="ml-3 font-medium text-h1"
                style={{ color: "#383A65" }}
              >
                Basic details
              </div>
            </div>
            <div style={{ marginBottom: "22px" }}>
              <div
                className="font-semibold text-h1 mb-1.5 break-all"
                style={{ color: "#383A65" }}
              >
                {listData?.name ? listData?.name : "-"}
              </div>
              <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
                Zone name
              </div>
            </div>
            <div>
              <Row className="pb-0.5" style={{ marginBottom: "22px" }}>
                <Col span={12}>
                  <div
                    className="font-semibold text-h1 mb-1.5 break-all"
                    style={{ color: "#383A65" }}
                  >
                    {listData?.description ? listData?.description : "-"}
                  </div>
                  <div
                    className="font-medium text-h1"
                    style={{ color: "#8892A5" }}
                  >
                    Description
                  </div>
                </Col>
                <Col>
                  <div
                    className="font-semibold text-h1 mb-1.5 break-all"
                    style={{ color: "#383A65" }}
                  >
                    {listData?.code ? listData?.code : "-"}
                  </div>
                  <div
                    className="font-medium text-h1"
                    style={{ color: "#8892A5" }}
                  >
                    Code
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row className="pb-0.5" style={{ marginBottom: "22px" }}>
                <Col span={12}>
                  <div
                    className="font-semibold text-h1 mb-1.5 break-all"
                    style={{ color: "#383A65" }}
                  >
                    {listData?.typeName ? listData?.typeName : "-"}
                  </div>
                  <div
                    className="font-medium text-h1"
                    style={{ color: "#8892A5" }}
                  >
                    Type
                  </div>
                </Col>
                <Col>
                  <div
                    className="font-semibold text-h1 mb-1.5 break-all"
                    style={{ color: "#383A65" }}
                  >
                    {listData?.groupName ? listData?.groupName : "-"}
                  </div>
                  <div
                    className="font-medium text-h1"
                    style={{ color: "#8892A5" }}
                  >
                    Group
                  </div>
                </Col>
              </Row>
            </div>
            <div style={{ marginBottom: "22px" }}>
              <div
                className="font-semibold text-h1 mb-1.5 break-all"
                style={{ color: "#383A65" }}
              >
                {listData?.barcode ? listData?.barcode : "-"}
              </div>
              <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
                Identifier
              </div>
            </div>
            <div style={{ marginBottom: "22px" }}>
              <div
                className="font-semibold text-h1 mb-1.5 break-all"
                style={{ color: "#383A65" }}
              >
                {listData?.totalArea ? listData?.totalArea : "-"}
              </div>
              <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
                Total Area (sq.ft.)
              </div>
            </div>
            <div style={{ marginBottom: "22px" }}>
              <div
                className="font-semibold text-h1 mb-1.5 break-all"
                style={{ color: "#383A65" }}
              >
                {listData?.totalCapacity ? listData?.totalCapacity : "-"}
              </div>
              <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
                Total Capacity (sq.ft.)
              </div>
            </div>
            <div style={{ marginBottom: "22px" }}>
              <div
                className="font-semibold text-h1 mb-1.5 break-all"
                style={{ color: "#383A65" }}
              >
                {listData?.targetUtilizationRate
                  ? listData?.targetUtilizationRate
                  : "-"}
              </div>
              <div className="font-medium text-h1" style={{ color: "#8892A5" }}>
                Target Utilization (sq.ft./person)
              </div>
            </div>
            <div className="relative my-6">
              <div className="flex flex-col">
                {zoneBase64Image ? (
                  <>
                    <div className="flex w-full">
                      <div className="relative w-full">
                        <img
                          className="rounded-lg w-full"
                          src={zoneBase64Image}
                          alt="Zones"
                          style={{ height: "12rem" }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
