import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../Core/store/app-context";
import { Row, Col } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { CustomTabs } from "../../Core/common/CustomTabs";
import { CustomTooltip } from "../../Core/common/CustomTooltip";
import { ItemDetail } from "../../Core/common/ItemDetail";


export const RoleDisplay = (props) => {
    const {
        itemId
    } = props;
  const [listData, setListData] = useState(null);
  const [activeTab, setActiveTab] = useState("details");
  const ctx = useContext(AppContext);

    useEffect(() => {
        async function fetchData() {
            // You can await here
            if (itemId) {
                await getItemData();
              }
              else {
                setListData({});
            }
        }
        setActiveTab("details");
        fetchData();
    }, [itemId]);

    useEffect(() => {
        if (!listData && !listData?.appId) {
            return;
        }
        if (listData?.modules?.length && listData?.modules[0]?.moduleType) {
            return;
        }
        mapModuleData();
    }, [listData]);

    const getItemData = async () => {
        const response = await ctx.HttpGet("/Role", { ID: itemId });
        if (response) {
            setListData(response);
        }
    };

  const mapModuleData = async () => {
    const moduleList = await ctx.HttpGet("/Role/module/list", {
      roleType: listData?.roleType,
      appID: listData?.appId,
    });
    if (!moduleList.length) {
      return;
    }
    listData?.modules.forEach((roleModuleId) => {
      moduleList?.forEach((item) => {
        if (item?.moduleId === roleModuleId?.moduleId) {
          roleModuleId["moduleType"] = item.moduleType;
          roleModuleId["moduleIcon"] = item.moduleIcon;
          roleModuleId.objects.forEach((funcId) => {
            item.objects.forEach((moduleListFuncId) => {
              if (moduleListFuncId.functionId === funcId.functionId) {
                funcId["name"] = moduleListFuncId.name;
              }
            });
          });
        }
      });
    });
    // sort modules based on display order
    listData.modules = listData.modules.sort(
      (a, b) => a.displayOrder - b.displayOrder
    );
    setListData(listData);
  };

  const DetailedView = () => {
    return (
      <div
        className="px-7 custom-scrollbar"
        style={{
          maxHeight: "calc(100vh - 275px)",
          overflowY: "auto",
          marginTop: "-10px",
        }}
      >
        <Row className="pt-1">
          <Col span={12}>
            <ItemDetail label="App ID" itemValue={listData?.appId} />
          </Col>
          <Col>
            <ItemDetail label="Role type" itemValue={listData?.roleType
                ? listData?.roleType === 1
                  ? "User"
                  : listData?.roleType === 2
                  ? "Service"
                  : "-"
                : "-"} />
          </Col>
        </Row>
        <ItemDetail label="Description" itemValue={listData?.description} />
        {listData?.roleType === 1 ? (
          <>
            <Row>
              <Col span={12}>
                <ItemDetail label="Session timeout" itemValue= {listData?.sessionTimeout
                    ? listData?.sessionTimeout + " mins"
                    : "-"} />
              </Col>
              <Col>
                <ItemDetail label="Session limit" itemValue={listData?.sessionLimit} />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <ItemDetail label="Privileged" itemValue={listData?.privileged === 1
                    ? "Yes"
                    : "No"
                  } />
              </Col>
              <Col>
                <ItemDetail label="Inactivity timeout" itemValue={listData?.inactivityTimeout
                    ? listData?.inactivityTimeout + " mins"
                    : "-"} />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <ItemDetail label="Login tolerance" itemValue={listData?.loginTolerance
                    ? listData?.loginTolerance + " mins"
                    : "-"} />
              </Col>
              <Col span={12}>
                <ItemDetail label="Max invalid login attempts" itemValue={listData?.loginAttempts} />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <ItemDetail label="Login lock" itemValue={listData?.loginLockDays
                    ? listData?.loginLockDays === 1
                      ? listData?.loginLockDays + " day"
                      : listData?.loginLockDays + " days"
                    : "-"} />
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
      </div>
    );
  };

  const PrivilegesView = () => {
    return (
      <div className="px-7 custom-scrollbar">
        {listData?.modules?.length && listData.modules[0] !== null ? (
          listData?.modules?.map((res) => {
            return (
              <>
                {res ? (
                  <div key={res?.moduleId} className="mb-4">
                    <Row className="mb-3 items-center">
                      <Col span={2}>
                        <div
                          className="flex items-center justify-center rounded-full"
                          style={{
                            backgroundColor: "#F3F5F8",
                            width: "27px",
                            height: "27px",
                          }}
                        >
                          <img
                            src={`/images/icons/api-${
                              res?.moduleIcon
                                ? res?.moduleIcon
                                : res?.moduleType === 2
                                ? "background-service-icon"
                                : "controller-icon"
                            }-add-privilege.svg`}
                            onError= { event => {event.target.src="/images/icons/api-generic-icon-add-privilege.svg"}}
                            alt={
                              res?.moduleName ? res?.moduleName : res?.moduleId
                            }
                          />
                        </div>
                      </Col>
                      <Col
                        span={22}
                        className="pl-2 text-h1 font-medium"
                        style={{ color: "#383A65" }}
                      >
                        {res?.moduleName ? res?.moduleName : res?.moduleId}
                      </Col>
                    </Row>
                    {res?.objects?.map((result) => {
                      return (
                        <>
                          {res?.moduleType === 2 ? (
                            result?.intervalMins ? (
                              <Row key={result?.functionId} className="mb-2">
                                <Col span={2}></Col>
                                <Col
                                  span={18}
                                  className="pl-2 text-h1"
                                  style={{ color: "#383A65" }}
                                >
                                  {result?.name
                                    ? result?.name
                                    : result?.functionId}
                                </Col>
                                <Col span={4} className="text-right">
                                  <div
                                    className="text-h1"
                                    style={{ color: "#383A65" }}
                                  >
                                    {result?.intervalMins} Mins
                                  </div>
                                </Col>
                              </Row>
                            ) : (
                              ""
                            )
                          ) : (
                            <Row key={result?.functionId} className="mb-2">
                              <Col span={2}></Col>
                              <Col
                                span={18}
                                className="pl-2 text-h1"
                                style={{ color: "#383A65" }}
                              >
                                {result?.name
                                  ? result?.name
                                  : result?.functionId}
                              </Col>
                              <Col span={4} className="text-right">
                                <CheckOutlined
                                  style={{ color: "#4E2C90" }}
                                  className="text-h1"
                                />
                              </Col>
                            </Row>
                          )}
                        </>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          })
        ) : (
          <div
            style={{
              backgroundColor: "white",
              borderRadius: 12,
              padding: 30,
              marginTop: 20,
            }}
          >
            <div className="ant-empty ant-empty-normal">
              <div className="ant-empty-image">
                <svg
                  className="ant-empty-img-simple"
                  width="64"
                  height="41"
                  viewBox="0 0 64 41"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                    <ellipse
                      className="ant-empty-img-simple-ellipse"
                      cx="32"
                      cy="33"
                      rx="32"
                      ry="7"
                    ></ellipse>
                    <g className="ant-empty-img-simple-g" fillRule="nonzero">
                      <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                      <path
                        d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                        className="ant-empty-img-simple-path"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="ant-empty-description">No Data</div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className="pb-7 border-b-2 px-7"
        style={{
          borderColor: "#F3F5F8",
        }}
      >
        <div className="font-semibold text-h2" style={{ color: "#383A65" }}>
          {listData?.name ? (
            <CustomTooltip
              title={listData?.name}
              className="block"
              styles={{ maxWidth: "340px" }}
            />
          ) : (
            "-"
          )}
        </div>
        <div className="text-h1 font-medium my-2" style={{ color: "#8892A5" }}>
          {listData?.accessPoints
            ? listData?.accessPoints + " access points"
            : "0 access point"}
        </div>
        <div
          style={{
            backgroundColor: listData?.Status === 1 ? "#84D49D" : "#dbdae0",
            borderRadius: "15px",
            padding: "2px 10px",
          }}
          className="text-xs font-medium text-white inline"
        >
          {listData?.Status === 1 ? "Active" : "Inactive"}
        </div>
      </div>
      <CustomTabs
        activeTabs={activeTab}
        currentTab={
            activeTab.includes("details") && !activeTab.includes("privileges")
                ? "details"
                : "privileges"
        }
        onTabClick={(key, event) => {
          setActiveTab(key);
        }}
        tabData={[
          {
            key: "details",
            name: "Details",
            topDetails: "calc(100vh - 285px)",
            content: (
              <div className="pt-1">
                <DetailedView />
              </div>
            ),
          },
          {
            key: "privileges",
            name: "Privileges",
            topDetails: "calc(100vh - 285px)",
            content: (
              <div className="pt-1">
                <PrivilegesView />
              </div>
            ),
          },
        ]}
      />
    </>
  );
};
