import { Menu, Dropdown } from "antd";

export const OptionsDropdown = (props) => {
  const { actionButtonItems, actionButtonItemClick, onActionButtonClick, className } =
    props;

  const optionMenu = (
    <Menu>
      {actionButtonItems.map((item, index) => {
        return (
          <Menu.Item key={`option-menu-${index}`} onClick={() => {
            if (typeof onActionButtonClick === "function") {
              onActionButtonClick(item);
            }
            actionButtonItemClick(item)}}
            >
            <div>{item}</div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return actionButtonItems && actionButtonItems.length ? (
    <Dropdown overlay={optionMenu} trigger={["click"]}>
      <img
        src="/images/map/threedots.svg"
        alt="view more"
        className={`cursor-pointer ${className}`}
        style={{ filter: "brightness(0)", height: "18px", width: "18px" }}
      />
    </Dropdown>
  ) : (
    ""
  );
};
