import React, { useEffect, useContext, useState } from "react";
import { Row, Col } from "antd";
import { AppContext } from "../../Core/store/app-context";
import { ItemDetail } from "../../Core/common/ItemDetail";

export const LocationDisplay = (props) => {
  const { itemId } = props;
  const ctx = useContext(AppContext);
  const [listData, setListData] = useState(null);

  const [locationBase64Image, setLocationBase64Image] = useState("");
  const [location3dModelBase64Image, setLocation3dModelBase64Image] =
    useState("");
  const [locationStackedFloorBase64Image, setLocationStackedFloorBase64Image] =
    useState("");
  const [locationOtherBase64Image, setLocationOtherBase64Image] = useState("");

  useEffect(() => {
    async function fetchData() {
      // You can await here
      if (itemId) {
        await getItemData();
      } else {
        setListData({});
      }
    }
    fetchData();
  }, [itemId]);

  const getItemData = async () => {
    const response = await ctx.HttpGet("/Location", { locationID: itemId });
    if (response) {
      setListData(response);

      if (response.image) {
        setLocationBase64Image("data:image/png;base64," + response.image);
      }

      if (response.model3D) {
        setLocation3dModelBase64Image(
          "data:image/png;base64," + response.model3D
        );
      }

      if (response.plan2D) {
        setLocationStackedFloorBase64Image(
          "data:image/png;base64," + response.plan2D
        );
      }

      if (response.files) {
        setLocationOtherBase64Image("data:image/png;base64," + response.files);
      }
    }
  };

  return (
    <div>
      <div
        className="flex items-center pb-5 border-b-2 px-5"
        style={{ borderColor: "#F3F5F8" }}
      >
        <div className="ml-3">
          <div
            className="font-semibold text-h2 flex items-center mb-2 break-all"
            style={{ color: "#383A65" }}
          >
            {listData?.name ? listData?.name : "-"}
          </div>
          <div
            className="text-h1 font-medium my-2"
            style={{ color: "#8892A5" }}
          >
            Type: {listData?.typeName ? listData?.typeName : "-"}
          </div>
          <div
            style={{
              backgroundColor: listData?.status === 1 ? "#84D49D" : "#dbdae0",
              borderRadius: "15px",
              padding: "2px 10px",
            }}
            className="text-xs font-medium text-white inline"
          >
            {listData?.status === 1 ? "Active" : "Inactive"}
          </div>
        </div>
      </div>
      <div className="pt-5 px-8" style={{ borderColor: "#F3F5F8" }}>
        <div className="flex items-center " style={{ marginBottom: "22px" }}>
          <img
            src="/images/icons/user.svg"
            alt="user"
            style={{ height: "38px", width: "38px" }}
          />
          <div
            className="ml-3 font-medium text-h1"
            style={{ color: "#383A65" }}
          >
            Basic details
          </div>
        </div>
        <ItemDetail label="Location name" itemValue={listData?.name} />
        <div>
          <Row>
            <Col span={12}>
              <ItemDetail
                label="Description"
                itemValue={listData?.description}
              />
            </Col>
            <Col>
              <ItemDetail label="Code" itemValue={listData?.code} />
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col span={12}>
              <ItemDetail label="Type" itemValue={listData?.typeName} />
            </Col>
            <Col>
              <ItemDetail label="Associated to" itemValue={listData?.orgName} />
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col span={9}>
              <ItemDetail
                label="Total Area(sq.ft.)"
                itemValue={listData?.totalArea}
              />
            </Col>
            <Col span={10}>
              <ItemDetail
                label="Ann. Rate($/sq.ft.)"
                itemValue={listData?.annualRate}
              />
            </Col>
            <Col>
              <ItemDetail
                label="Capacity"
                itemValue={listData?.totalCapacity}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col span={24}>
              <ItemDetail label="Address" itemValue={listData?.fullAddress} />
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col span={12}>
              <ItemDetail
                label="Is Default"
                itemValue={listData?.isDefault === 1 ? "On" : "Off"}
              />
            </Col>
            <Col>
              <ItemDetail label="Zip Code" itemValue={listData?.zip}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col span={12}>
              <ItemDetail label="Latitude" itemValue={listData?.latitude} />
            </Col>
            <Col>
              <ItemDetail label="Longitude" itemValue={listData?.longitude} />
            </Col>
          </Row>
        </div>
        <div className="relative my-6">
          <div className="flex flex-col">
            {locationBase64Image ? (
              <>
                <div className="flex w-full">
                  <div className="relative w-full">
                    <img
                      className="rounded-lg w-full"
                      src={locationBase64Image}
                      alt="Location"
                      style={{ height: "12rem" }}
                    />
                  </div>
                </div>
                <div className="flex mt-2" style={{ justifyContent: "center" }}>
                  <div className="font-poppins text-xs font-medium leading-tight bg-white text-pickled-bluewood">
                    Location Image
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {location3dModelBase64Image ? (
          <>
            <div className="relative my-6">
              <div className="flex flex-col">
                <div className="flex w-full">
                  <div className="relative w-full">
                    <img
                      className="rounded-lg w-full"
                      src={location3dModelBase64Image}
                      alt="Location"
                      style={{ height: "12rem" }}
                    />
                  </div>
                </div>
                <div className="flex mt-2" style={{ justifyContent: "center" }}>
                  <div className="font-poppins text-xs font-medium leading-tight bg-white text-pickled-bluewood">
                    3D Space Model
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {locationStackedFloorBase64Image ? (
          <>
            <div className="relative my-6">
              <div className="flex flex-col">
                <div className="flex w-full">
                  <div className="relative w-full">
                    <img
                      className="rounded-lg w-full"
                      src={locationStackedFloorBase64Image}
                      alt="Location"
                      style={{ height: "12rem" }}
                    />
                  </div>
                </div>
                <div className="flex mt-2" style={{ justifyContent: "center" }}>
                  <div className="font-poppins text-xs font-medium leading-tight bg-white text-pickled-bluewood">
                    Location Stacked Floor Image
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {locationOtherBase64Image ? (
          <>
            <div className="relative my-6">
              <div className="flex flex-col">
                <div className="flex w-full">
                  <div className="relative w-full">
                    <img
                      className="rounded-lg w-full"
                      src={locationOtherBase64Image}
                      alt="Location"
                      style={{ height: "12rem" }}
                    />
                  </div>
                </div>
                <div className="flex mt-2" style={{ justifyContent: "center" }}>
                  <div className="font-poppins text-xs font-medium leading-tight bg-white text-pickled-bluewood">
                    Location Other Image
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
