import React, { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../Core/store/app-context";

import { CustomInput } from "../../../Core/common/CustomInput";
import { CustomModal } from "../../../Core/common/CustomModal";
import { CustomSwitchToggle } from "../../../Core/common/CustomSwitchToggle";
import { CustomTextarea } from "../../../Core/common/CustomTextarea";
import { SelectDropdown } from "../../../Core/common/SelectDropdown";
import { ConvertToDecimal } from "../../../Core/common/helpers";
import { ImageSelector } from "../../../Core/common/ImageSelector";
import { SidePanelFooter } from "../../../Core/common/SidePanelFooter";

export const ReaderChange = (props) => {
    const ctx = useContext(AppContext);

    const {
        itemId,
        getRecordList,
        hideChangeDrawer
    } = props;

  const [readerTypeList, setReaderTypeList] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);
  const [buildingList, setBuildingList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [blobBase64Image, setBlobBase64Image] = useState("");

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);
  const [listAddData, setListAddData] = useState({});

  useEffect(() => {
      async function fetchData() {
          // You can await here
          await getReaderTypeList();
          await getProviderList();
          await getOrganizationList();
          if (itemId) {
            await getReaderData();
          }
          else {
            setListAddData({});
          }
      }
      fetchData();
  }, [itemId]);

  useEffect(() => {
    if(listAddData?.FloorId) {
      getZoneList(listAddData?.FloorId);
    }
  }, [listAddData?.FloorId]);

  useEffect(() => {
    if(listAddData?.orgId) {
      getBuildingList(listAddData?.orgId);
    }
  }, [listAddData?.orgId]);

    const getReaderTypeList = async () => {
        const response = await ctx.HttpGet("/Reader/readertypes");
        if (response) {
            setReaderTypeList(response);
        }
    };
    const getProviderList = async () => {
        const response = await ctx.HttpGet("/Reader/providertypes");
        if (response) {
            setProviderList(response);
        }
    };
    const getZoneList = async (value) => {
        const response = await ctx.HttpGet("/Reader/zone", { FloorId: value });
        if (response) {
            setZoneList(response);
        }
    };
    const getFloorList = async (value) => {
        const response = await ctx.HttpGet("/Reader/floor", { buildId: value });
        if (response) {
            setFloorList(response);
        }
    };
    const getOrganizationList = async () => {
        const response = await ctx.HttpGet("/Reader/organization");
        if (response) {
            setOrganizationList(response);
        }
    };
    const getBuildingList = async (Id) => {
        const response = await ctx.HttpGet("/Reader/building", { orgId: Id });
        if (response) {
            setBuildingList(response);
        }
    };

    const getReaderData = async () => {
        const response = await ctx.HttpGet("/Reader", { id: itemId });
        if (response) {
            setListAddData(response);
            if (response.Image) {
                setBlobBase64Image("data:image/png;base64," + response.Image);
            }
        }
    };

    const onPrimaryClick = () => {
        if (validateForm()) {
            if (itemId) {
                setShowUpdateModal(true);
            }
        }
    };

    const validateForm = () => {
        if (listAddData.Name === "" || listAddData.Name.trim() === "") {
            ctx.showToastAlert({ type: "error", message: "Please enter name" });
            return false;
        }
        else if (listAddData.ProviderId === null) {
            ctx.showToastAlert({ type: "error", message: "Please select provider" });
            return false;
        }
        else if (listAddData.TypeId === null) {
            ctx.showToastAlert({ type: "error", message: "Please select reader type" });
            return false;
        }
        else if (listAddData.orgId === null) {
            ctx.showToastAlert({ type: "error", message: "Please select organization" });
            return false;
        }
        else if (listAddData.buildId === null) {
            ctx.showToastAlert({ type: "error", message: "Please select location" });
            return false;
        }
        else if (listAddData.Description === "") {
            ctx.showToastAlert({ type: "error", message: "Please enter description" });
            return false;
        }
        else if (listAddData.FloorId === null) {
            ctx.showToastAlert({ type: "error", message: "Please select floor ID" });
            return false;
        }
        return true;
    };

    const updateReader = async () => {
        setModalButtonLoading(true);

        let payload = { ...listAddData, Id: itemId };

        const response = await ctx.HttpPut("/Reader", payload);
        if (response) {
            getRecordList();
            ctx.showToastAlert({ type: "success", message: response });
            setListAddData({});
            setBlobBase64Image("");
            hideChangeDrawer();
        }
        setShowUpdateModal(false);
        setModalButtonLoading(false);
    };

    return (
        <>
            <div className="mb-3 pt-3 px-7 relative custom-scrollbar" style={{
                    height: "calc(100vh - 153px)",
                    overflow: "auto"
            }}>
                <CustomInput
                    label="Reader name"
                    inputValue={listAddData?.Name}
                    onChange={(value) =>
                        setListAddData({ ...listAddData, Name: value })
                    }
                    isRequired={true}
                    maxlength={255}
                    onKeyDown={(value) => {
                        if (value.keyCode === 8 || value.keyCode === 46) {
                            return;
                        }
                        if (listAddData.Name.length >= 254) {
                            ctx.showToastAlert({ type: "error", message: "You cannot enter more than 255 characters" });
                        }
                    }}
                    disabled={true}
                />
                <SelectDropdown
                    dataList={listAddData?.ProviderId}
                    optionsList={providerList}
                    mode="single"
                    isRequired={true}
                    optionKeyName="Id"
                    optionValueName="Id"
                    optionDisplayName="Value"
                    label="Provider"
                    className="custom-select-static-dropdown"
                    value={
                        listAddData?.ProviderId !== null
                            ? listAddData?.ProviderId?.toString()
                            : null
                    }
                    onChange={(value, key) => {
                        setListAddData({
                            ...listAddData,
                            ProviderId: parseInt(value),
                        });
                    }}
                    disabled={true}
                />
                <SelectDropdown
                    dataList={listAddData?.TypeId}
                    optionsList={readerTypeList}
                    isRequired={true}
                    mode="single"
                    optionKeyName="Id"
                    optionValueName="Id"
                    optionDisplayName="Value"
                    label="Reader type"
                    className="custom-select-static-dropdown"
                    value={
                        listAddData?.TypeId !== null
                            ? listAddData?.TypeId?.toString()
                            : null
                    }
                    onChange={(value, key) => {
                        setListAddData({
                            ...listAddData,
                            TypeId: parseInt(value),
                        });
                    }}
                />
                {/* <SelectDropdown
                    dataList={listAddData?.orgId}
                    optionsList={organizationList}
                    mode="single"
                    isRequired={true}
                    optionKeyName="Id"
                    optionValueName="Id"
                    optionDisplayName="Value"
                    label="Organization"
                    className="custom-select-static-dropdown"
                    value={
                        listAddData?.orgId !== null
                            ? listAddData?.orgId?.toString()
                            : null
                    }
                    onChange={(value, key) => {
                        setListAddData({
                            ...listAddData,
                            orgId: value,
                            buildId: null
                        });
                        getBuildingList(value);
                    }}
                    disabled={true}
                />
                <SelectDropdown
                    dataList={listAddData?.buildId}
                    optionsList={buildingList}
                    mode="single"
                    isRequired={true}
                    optionKeyName="Id"
                    optionValueName="Id"
                    optionDisplayName="Value"
                    label="Location"
                    className="custom-select-static-dropdown"
                    value={
                        listAddData?.buildId !== null
                            ? listAddData?.buildId?.toString()
                            : null
                    }
                    onChange={(value, key) => {
                        setListAddData({
                            ...listAddData,
                            buildId: value,
                        });
                        getFloorList(value);
                    }}
                    disabled={true}
                /> */}
                <CustomTextarea
                    label="Description"
                    inputValue={listAddData?.Description}
                    rows="4"
                    isRequired={true}
                    onChange={(value) =>
                        setListAddData({ ...listAddData, Description: value })
                    }
                    maxlength={500}
                    onKeyDown={(value) => {
                        if (value.keyCode === 8 || value.keyCode === 46) {
                            return;
                        }
                        if (listAddData.Description.length >= 499) {
                            ctx.showToastAlert({ type: "error", message: "You cannot enter more than 500 characters" });
                        }
                    }}
                />
                <SelectDropdown
                    dataList={listAddData?.FloorId}
                    optionsList={floorList}
                    mode="single"
                    isRequired={true}
                    optionKeyName="Id"
                    optionValueName="Id"
                    optionDisplayName="Value"
                    label="Floor"
                    className="custom-select-static-dropdown"
                    value={
                        listAddData?.FloorId !== null
                            ? props.type === "Edit" ? listAddData?.floorName?.toString() : listAddData?.FloorId?.toString()
                            : null
                    }
                    onChange={(value, key) => {
                        setListAddData({
                            ...listAddData,
                            FloorId: value,
                        });
                        getZoneList(value);
                    }}
                    disabled={true}
                />
                <SelectDropdown
                    dataList={listAddData?.RoomId}
                    optionsList={zoneList}
                    mode="single"
                    optionKeyName="Id"
                    optionValueName="Id"
                    optionDisplayName="Value"
                    label="Room"
                    className="custom-select-static-dropdown"
                    value={
                        listAddData?.RoomId !== null
                            ? listAddData?.RoomId?.toString()
                            : null
                    }
                    onChange={(value, key) => {
                        setListAddData({
                            ...listAddData,
                            RoomId: value,
                        });
                    }}
                    disabled={listAddData?.FloorId ? false : true}
                />
                <CustomInput
                    label="X in meters"
                    type="number"
                    maxlength={3}
                    inputValue={ConvertToDecimal(listAddData?.XMeters)?.toString()}
                    onChange={(value) => {
                        setListAddData({
                            ...listAddData,
                            XMeters: ConvertToDecimal(value),
                        });
                    }}
                />
                <CustomInput
                    label="Y in meters"
                    type="number"
                    maxlength={3}
                    inputValue={ConvertToDecimal(listAddData?.YMeters)?.toString()}
                    onChange={(value) => {
                        setListAddData({
                            ...listAddData,
                            YMeters: ConvertToDecimal(value),
                        });
                    }}
                />
                <CustomInput
                    label="Battery level"
                    maxlength={5}
                    inputValue={parseInt(listAddData?.BatteryLevel) || 0}
                    onChange={(value) => {
                        setListAddData({
                            ...listAddData,
                            BatteryLevel: parseInt(value),
                        });
                    }}
                    disabled={true}
                />
                <CustomInput
                    label="Signal strength"
                    maxlength={5}
                    inputValue={parseInt(listAddData?.SignalStrength) || 0}
                    onChange={(value) => {
                        setListAddData({
                            ...listAddData,
                            SignalStrength: parseInt(value),
                        });
                    }}
                    disabled={true}
                />
                <CustomInput
                    label="MAC Address"
                    maxlength={17}
                    inputValue={listAddData?.MacAddress}
                    onChange={(value) =>
                        setListAddData({ ...listAddData, MacAddress: value })
                    }
                />
                <CustomInput
                    label="Host name"
                    maxlength={255}
                    inputValue={listAddData?.HostName}
                    onChange={(value) =>
                        setListAddData({ ...listAddData, HostName: value })
                    }
                />
                <CustomInput
                    label="Manufacturer name"
                    maxlength={50}
                    inputValue={listAddData?.ManufacturerName}
                    onChange={(value) =>
                        setListAddData({ ...listAddData, ManufacturerName: value })
                    }
                    disabled={true}
                />
                <CustomInput
                    label="Manufacturer model"
                    maxlength={50}
                    inputValue={listAddData?.ManufacturerModel}
                    onChange={(value) =>
                        setListAddData({ ...listAddData, ManufacturerModel: value })
                    }
                />
                <CustomSwitchToggle
                    label="Status"
                    switchValue={listAddData?.Status}
                    onText="Enabled"
                    offText="Disabled"
                    onChange={(checked) =>
                        setListAddData({
                            ...listAddData,
                            Status: checked ? 1 : 0,
                        })
                    }
                    checked={listAddData?.Status}
                    className="mb-6"
                />
                <ImageSelector
                    selectedBase64Image={blobBase64Image}
                    setSelectedBase64Image={setBlobBase64Image}
                    imageName="Image of Blob"
                    imageDetails={listAddData}
                    setImageDetails={setListAddData}
                    imageSizeMax="5"
                />

                <SidePanelFooter
                    secondaryLabel={"Cancel"}
                    onCancelClick={() => {
                        hideChangeDrawer();
                        setListAddData({});
                        setBlobBase64Image("");
                    }}
                    primaryLabel={"Apply"}
                    onPrimaryClick={() => onPrimaryClick()}
                />
            </div>

            {/* Update Reader */}
            <CustomModal
                showModal={showUpdateModal}
                titleText="Update Reader"
                messageText={`Are you sure you want to update the changes made to ${listAddData?.Name}?`}
                handleCancel={() => {
                    setShowUpdateModal(false);
                }}
                confirmButtonText={"Yes"}
                handleConfirm={updateReader}
                isLoading={modalButtonLoading}
            />
        </>
    );
};
