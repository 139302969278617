import React from "react";
import { Switch } from "antd";

export const CustomSwitchToggle = (props) => {
  const {
    label,
    switchValue,
    onChange,
    onText,
    offText,
    styleName,
    className,
  } = props;
  return (
    <div className="flex justify-between mb-5">
      {label ? (
        <div
          className="font-medium leading-normal"
          style={{
            fontSize: styleName === "labelGreyDarkText" ? "12px" : "11px",
            color: styleName === "labelGreyDarkText" ? "#8892A5" : "",
          }}
        >
          {label}
        </div>
      ) : (
        ""
      )}
      <div className={`flex items-center ${className}`}>
        <div
          className="mx-2 font-poppins font-medium text-xs leading-normal"
          style={{
            color:
              styleName === "greenTextActive" && switchValue === 1
                ? "#84D49D"
                : "labelDarkBlueText" ? "#2C2F59" : "rgb(136, 146, 165)",
          }}
        >
          {switchValue ? onText : offText}
        </div>
        <Switch
          onChange={(checked) => onChange(checked)}
          checked={switchValue}
        />
      </div>
    </div>
  );
};
