import React, { useState, useEffect, useContext } from "react";

import { AppContext } from "../../Core/store/app-context";

import { Layout } from "../../Core/layout";
import { FeatureTypes } from "../../Core/store/app-feature";
import { DataTable, getClickableItemIndex } from "../../Core/common/DataTable";
import { CustomModal } from "../../Core/common/CustomModal";
import { GetTimezoneOffset } from "../../Core/common/helpers";
import { OptionsDropdown } from "../../Core/common/OptionsDropdown";
import { SidePanelChange } from "../../Core/common/SidePanelChange";
import { SidePanelDisplay } from "../../Core/common/SidePanelDisplay";
import { SidePanelFilter } from "../../Core/common/SidePanelFilter";
import { SidePanelPreferences } from "../../Core/common/SidePanelPreferences";

import { RoleChange } from "./RoleChange";
import { RoleDisplay } from "./RoleDisplay";
import { RoleFilter } from "./RoleFilter";

export const RoleList = (props) => {
  const ctx = useContext(AppContext);

  const [pageSize, setPageSize] = useState(10);
  const [offsetValue, setOffsetValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [showUpdateDrawer, setShowUpdateDrawer] = useState(false);
  const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
  const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

  const [columnList, setColumnList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");
  const [filterData, setFilterData] = useState({ isFilter: false });

  const [itemId, setItemId] = useState(null);
  const [itemName, setItemName] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);

  useEffect(() => {
    setOffsetValue(1);
    getRecordList(searchValue, sortByValue, orderByValue, 1);
    if(!filterData.isFilter){
      setShowFilterDrawer(false);
    }
  }, [filterData.isFilter]);

  const getRecordList = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    offset = offsetValue,
    limit = pageSize
  ) => {
    let queryParams = {
      search: searchBy,
      sortBy: sortBy,
      orderBy: orderBy,
      offset: offset,
      limit: limit,
      utcOffset: GetTimezoneOffset(),
      ...filterData,
    };
    const response = await ctx.HttpGetList("/Role/list", queryParams);
    if (response) {
      setRecordList(response.Data);
      setTotalCount(response.Count);
      setColumnList(response.Headers);
      setSearchPlaceholder(response.searchPlaceholder);
    }
  };

  const deleteRole = async () => {
      setModalButtonLoading(true);
      const response = await ctx.HttpDelete("/Role", { id: itemId });
      if (response) {
          ctx.showToastAlert({ type: "success", message: response });
          let offset = offsetValue;
          if (recordList.length === 1 && offset > 1) {
              offset = offset - 1;
              setOffsetValue(offset);
          }
          getRecordList(searchValue, sortByValue, orderByValue, offset);
      }
      setShowDeleteModal(false);
      setModalButtonLoading(false);
  };

  // add click event if user have view access
  if (ctx.acl(FeatureTypes.Roles, "GetRole") && columnList?.length > 1) {
    columnList[getClickableItemIndex(columnList)]["onClick"] = (record) => {
        setItemId(record.Id);
        setItemName(record.name);
        setShowDisplayDrawer(true);
    };
  }
  const handleOptionMenuItemClick = (clickedItem) => {
      setShowDisplayDrawer(false);
      if (clickedItem === "Update") {
          setShowUpdateDrawer(true);
      } else if (clickedItem === "Remove") {
          setShowDeleteModal(true);
      }
  };

  // add action items as per role access
  const getActionButtonItems = () => {
    let actionButtonItems = [];
    if (ctx.acl(FeatureTypes.Roles, "PutRole")) {
      actionButtonItems.push("Update");
    }
    if (ctx.acl(FeatureTypes.Roles, "DeleteRole")) {
      actionButtonItems.push("Remove");
    }
    return actionButtonItems;
  };

  return (
    <>
      <Layout
        page="roles"
        id={FeatureTypes.Roles}
        pageTitleButton={ctx.acl(FeatureTypes.Roles, "PostRole")}
        buttonOnClick={() => {
            setItemId(null);
            setShowAddDrawer(true);
        }}
        buttonLabel="Add role"
        searchOnModule={FeatureTypes.Roles}
        searchPlaceholder={searchPlaceholder}
        onSearchChange={(value) => {
          setOffsetValue(1);
          setSearchValue(value);
          getRecordList(value, null, null, 1);
        }}
        filterApplied={filterData.isFilter}
        onFilterClick={async () => {
            setShowFilterDrawer(true);
        }}
      >
        <DataTable
          showActionButton={getActionButtonItems().length ? true : false}
          getActionButtonItems={getActionButtonItems}
          onActionButtonClick={(record) => {
              setItemId(record.Id);
              setItemName(record.name);
          }}
          actionButtonItemClick={(clickedItem) => {
            handleOptionMenuItemClick(clickedItem);
          }}
          dataSource={recordList}
          columns={columnList}
          totalRecords={totalCount}
          pageSize={pageSize}
          rowKey={"Id"}
          currentPage={offsetValue}
          onUserPreferenceClick={() => {
              setShowPreferenceDrawer(true);
          }}
          onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
            let sortOrderValue = "";
            if (sorterOptions && sorterOptions.order) {
              sortOrderValue =
                sorterOptions.order === "ascend" ? "ASC" : "DESC";
              setOrderByValue(sortOrderValue);
              setSortByValue(sorterOptions.columnKey);
            }
            setOffsetValue(pageSizeOptions.current);
            setPageSize(pageSizeOptions.pageSize);
            getRecordList(
              searchValue,
              sorterOptions.columnKey,
              sortOrderValue,
              pageSizeOptions.current,
              pageSizeOptions.pageSize
            );
          }}
        />
      </Layout>

        <SidePanelChange
            panelIcon={"roles"}
            panelTitle={"Add Role"}
            panelVisible={showAddDrawer}
            panelBody={(
                <RoleChange
                    getRecordList={getRecordList}
                    hideChangeDrawer={() => {
                        setShowAddDrawer(false);
                        setItemId(null);
                    }}
                />
            )}
        />

        <SidePanelChange
            panelIcon={"roles"}
            panelTitle={"Update Role"}
            panelVisible={showUpdateDrawer}
            panelBody={(
                <RoleChange
                    itemId={itemId}
                    getRecordList={getRecordList}
                    hideChangeDrawer={() => {
                        setShowUpdateDrawer(false);
                        setItemId(null);
                    }}
                />
            )}
        />

        <SidePanelDisplay
            headerData={columnList}
            panelIcon={"roles"}
            panelTitle={"Role Details"}
            panelVisible={showDisplayDrawer}
            panelBody={(
                <RoleDisplay
                    itemId={itemId}
                />
            )}
            customAction={(
                <OptionsDropdown
                    actionButtonItems={getActionButtonItems()}
                    actionButtonItemClick={(clickedItem) => {
                        handleOptionMenuItemClick(clickedItem);
                    }}
                />
            )}
            onCancelClick={() => {
                setShowDisplayDrawer(false);
            }}
        />

        <SidePanelFilter
            moduleRoute={"/Role"}
            filterData={filterData}
            setFilterData={setFilterData}
            panelIcon={"roles"}
            panelVisible={showFilterDrawer}
            panelBody={(
                <RoleFilter
                    listFilterData={filterData}
                    setListFilterData={setFilterData}
                />
            )}
            onCancelClick={() => {
                setShowFilterDrawer(false);
            }}
            onChangeClick={() => {
                setShowFilterDrawer(false);
                if(filterData.isFilter){
                  setOffsetValue(1);
                  getRecordList(searchValue, sortByValue, orderByValue, 1);
                }
            }}
        />

        <SidePanelPreferences
            module={"/Role/list"}
            panelIcon={"roles"}
            panelVisible={showPreferenceDrawer}
            onCancelClick={() => {
                setShowPreferenceDrawer(false);
            }}
            onChangeClick={() => {
                setShowPreferenceDrawer(false);
                getRecordList(searchValue, sortByValue, orderByValue, offsetValue);
            }}
        />

      {/* Delete Role */}
      <CustomModal
        showModal={showDeleteModal}
        titleText="Remove Role"
        messageText={`Are you sure you want to remove the ${itemName}?`}
        handleCancel={() => {
          setShowDeleteModal(false);
        }}
        confirmButtonText={"Yes"}
        handleConfirm={deleteRole}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
