import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "antd";
import GooglePlacesAutocomplete, {geocodeByAddress,getLatLng} from "react-google-places-autocomplete";

import { AppContext } from "../../Core/store/app-context";

import { ConvertToDecimal } from "../../Core/common/helpers";
import { CustomSwitchToggle } from "../../Core/common/CustomSwitchToggle";
import { SelectDropdown } from "../../Core/common/SelectDropdown";
import { ImageSelector } from "../../Core/common/ImageSelector";
import { CustomInput } from "../../Core/common/CustomInput";
import { CustomTabs } from "../../Core/common/CustomTabs";
import { CustomModal } from "../../Core/common/CustomModal";
import { SidePanelFooter } from "../../Core/common/SidePanelFooter";

export const LocationChange = (props) => {
  const { itemId, getRecordList, hideChangeDrawer } = props;
  const ctx = useContext(AppContext);
  const [locationTypeList, setLocationTypeList] = useState([]);
  const [organizationsList, setOrganizationsList] = useState([]);
  const [locationBase64Image, setLocationBase64Image] = useState("");
  const [location3dModelBase64Image, setLocation3dModelBase64Image] =
    useState("");
  const [locationStackedFloorBase64Image, setLocationStackedFloorBase64Image] =
    useState("");
  const [locationOtherBase64Image, setLocationOtherBase64Image] = useState("");
  const [hasGoogleMapsAPIKey, setHasGoogleMapsAPIKey] = useState(
    process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY
      ? process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY.length > 0
        ? true
        : false
      : false
  );

  const defaultListData = {
    name: "",
    description: "",
    id: "",
    code: "",
    totalArea: 0,
    totalAreaUoM: "sq.ft.",
    annualRate: 0,
    totalCapacity: 0,
    totalCapacityUoM: "sq.ft.",
    selectedAddress: "",
    fullAddress: "",
    address1: "",
    address2: null,
    city: "",
    state: "",
    country: "",
    zip: 0,
    latitude: "",
    longitude: "",
    status: 1,
    isDefault: 0,
    image: null,
    plan2D: null,
    model3D: null,
    files: null,
    typeName: "",
    typeID: null,
    orgName: [],
    orgID: null,
    orgLatitude: "",
    orgLongitude: "",
  };

  const [listAddData, setListAddData] = useState(defaultListData);

  const [activeTab, setActiveTab] = useState("locationName");
  const [primaryText, setPrimaryText] = useState("Next");
  const [secondaryText, setSecondaryText] = useState("Cancel");

  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      await getLocationTypeList();
      await getOrganizationList();
      if (itemId) {
        await getItemData();
      } else {
        setListAddData({ ...defaultListData });
      }
    }
    fetchData();
  }, [itemId]);

  const getItemData = async () => {
    const response = await ctx.HttpGet("/Location", {
      locationID: itemId,
    });
    if (response) {
      setListAddData(response);

      if (response.image) {
        setLocationBase64Image("data:image/png;base64," + response.image);
      }

      if (response.model3D) {
        setLocation3dModelBase64Image(
          "data:image/png;base64," + response.model3D
        );
      }

      if (response.plan2D) {
        setLocationStackedFloorBase64Image(
          "data:image/png;base64," + response.plan2D
        );
      }

      if (response.files) {
        setLocationOtherBase64Image("data:image/png;base64," + response.files);
      }
      if (response.fullAddress) {
        setListAddData({
          ...response,
          selectedAddress: {
            label: response.fullAddress,
            value: response.fullAddress,
          },
        });
      }
    }
  };

  const getLocationTypeList = async () => {
    const response = await ctx.HttpGet("/Location/types");
    setLocationTypeList(response);
  };

  const getOrganizationList = async () => {
    const response = await ctx.HttpGet("/Location/organizations");
    setOrganizationsList(response);
  };

  const handleOrganizationChange = async (id) => {
    const response = await ctx.HttpGet("/Organization", { id: id });
    if (response) {
      setListAddData({
        ...listAddData,
        orgID: id,
        orgName: response.name,
      });
    }
  };
  const handleChange = async (key, value) => {
    let length = value.value.terms.length;
    geocodeByAddress(value.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setListAddData({
          ...listAddData,
          selectedAddress: {
            label: value.label,
            value: value.value.description,
          },
          fullAddress: value.label,
          address1: value.value.structured_formatting.main_text,
          city: value?.value?.terms[length - 3]?.value
            ? value?.value?.terms[length - 3]?.value
            : "",
          state: value?.value?.terms[length - 2]?.value
            ? value?.value?.terms[length - 2]?.value
            : "",
          country: value?.value?.terms[length - 1]?.value
            ? value?.value?.terms[length - 1]?.value
            : "",
          latitude: lat ? parseFloat(lat) : "",
          longitude: lng ? parseFloat(lng) : "",
        });
      });
  };

  useEffect(() => {
    if (primaryText === "Apply") {
      setActiveTab("images");
      setSecondaryText("Previous");
    } else {
      setActiveTab("locationName");
      setSecondaryText("Cancel");
    }
  }, [primaryText]);

  const onSecondaryClick = () => {
    if (secondaryText === "Cancel") {
      hideChangeDrawer();
      setListAddData({ ...defaultListData });
      setLocationBase64Image("");
      setLocation3dModelBase64Image("");
      setLocationStackedFloorBase64Image("");
      setLocationOtherBase64Image("");
    } else if (secondaryText === "Previous") {
      setPrimaryText("Next");
    }
  };
  const onPrimaryClick = () => {
    if (primaryText === "Apply") {
      if (validate()) {
        if (itemId) {
          setShowUpdateModal(true);
        }
        else {
          setShowAddModal(true);
        }
      }
    } else if (primaryText === "Next") {
      if (validate()) {
        setPrimaryText("Apply");
      }
    }
  };

  const validate = () => {
    if (listAddData.name === "" || listAddData.name.trim() === "") {
      ctx.showToastAlert({ type: "error", message: "Please enter name" });
      return false;
    } else if (listAddData.code === "") {
      ctx.showToastAlert({ type: "error", message: "Please enter code" });
      return false;
    } else if (listAddData.typeID === null) {
      ctx.showToastAlert({ type: "error", message: "Please select type" });
      return false;
    } else if (listAddData.orgID === null) {
      ctx.showToastAlert({
        type: "error",
        message: "Please select organization",
      });
      return false;
    } else if (parseFloat(listAddData.totalArea) === 0) {
      ctx.showToastAlert({
        type: "error",
        message: "Please enter total area in sq.ft.",
      });
      return false;
    } else if (parseFloat(listAddData.annualRate) === 0) {
      ctx.showToastAlert({
        type: "error",
        message: "Please enter annual rate in sq.ft",
      });
      return false;
    } else if (listAddData.totalCapacity === 0) {
      ctx.showToastAlert({
        type: "error",
        message: "Please enter total capacity in sq.ft.",
      });
      return false;
    } else if (listAddData.fullAddress === "") {
      ctx.showToastAlert({ type: "error", message: "Please enter Address" });
      return false;
    } else if (listAddData.zip === 0) {
      ctx.showToastAlert({ type: "error", message: "Please enter zip code" });
      return false;
    } else if (listAddData.country === "") {
      ctx.showToastAlert({ type: "error", message: "Please enter country" });
      return false;
    } else if (!listAddData.latitude) {
      ctx.showToastAlert({ type: "error", message: "Please enter latitude" });
      return false;
    } else if (!listAddData.longitude) {
      ctx.showToastAlert({ type: "error", message: "Please enter longitude" });
      return false;
    } else if (!listAddData.image && activeTab === "images") {
      ctx.showToastAlert({
        type: "error",
        message: "Please upload location image ",
      });
      return false;
    }
    return true;
  };

  const createLocation = async () => {
    setModalButtonLoading(true);

    setListAddData({ ...listAddData, Id: null });
    const response = await ctx.HttpPost("/Location", listAddData);
    if (response) {
      getRecordList();
      ctx.showToastAlert({ type: "success", message: response });
      hideChangeDrawer();
      setListAddData({ ...defaultListData });
      setPrimaryText("Next");
      setLocationBase64Image("");
      setLocation3dModelBase64Image("");
      setLocationStackedFloorBase64Image("");
      setLocationOtherBase64Image("");
    }
    setShowAddModal(false);
    setModalButtonLoading(false);
  };

  const updateLocation = async () => {
    setModalButtonLoading(true);

    setListAddData({ ...listAddData, Id: itemId });
    const response = await ctx.HttpPut("/Location", listAddData);
    if (response) {
      getRecordList();
      ctx.showToastAlert({ type: "success", message: response });
      hideChangeDrawer();
      setListAddData({ ...defaultListData });
      setPrimaryText("Next");
      setLocationBase64Image("");
      setLocation3dModelBase64Image("");
      setLocationStackedFloorBase64Image("");
      setLocationOtherBase64Image("");
    }
    setShowUpdateModal(false);
    setModalButtonLoading(false);
  };

  return (
    <>
      <CustomTabs
        activeTab={activeTab}
        currentTab={
          activeTab.includes("locationName") && !activeTab.includes("images")
            ? "locationName"
            : "images"
        }
        className="step-custom-tab"
        tabData={[
          {
            key: "locationName",
            name: "1. Basic details",
            style: {
              backgroundColor: activeTab.includes("images")
                ? "#8892A5"
                : "transparent",
            },
            content: (
              <div className="px-7 pt-1">
                <CustomInput
                  label="Name of Location"
                  isRequired={true}
                  maxlength={25}
                  inputValue={listAddData.name}
                  onChange={(value) => {
                    setListAddData({
                      ...listAddData,
                      name: value,
                    });
                  }}
                />
                <CustomInput
                  label="Description"
                  maxlength={25}
                  inputValue={listAddData.description}
                  onChange={(value) => {
                    setListAddData({
                      ...listAddData,
                      description: value,
                    });
                  }}
                />
                <CustomInput
                  label="Code"
                  maxlength={5}
                  isRequired={true}
                  inputValue={listAddData.code}
                  onChange={(value) => {
                    setListAddData({
                      ...listAddData,
                      code: value,
                    });
                  }}
                />
                <SelectDropdown
                  dataList={listAddData?.typeID}
                  optionsList={locationTypeList}
                  isRequired={true}
                  mode="single"
                  optionKeyName="Id"
                  optionValueName="Id"
                  optionDisplayName="Name"
                  label="type"
                  value={listAddData?.typeID}
                  onChange={(value, key) => {
                    setListAddData({ ...listAddData, typeID: value });
                  }}
                />
                <SelectDropdown
                  dataList={listAddData?.orgID}
                  optionsList={organizationsList}
                  isRequired={true}
                  mode="single"
                  optionKeyName="Id"
                  optionValueName="Id"
                  optionDisplayName="Value"
                  label="organization"
                  value={listAddData?.orgID}
                  onChange={(value, key) => {
                    handleOrganizationChange(value);
                  }}
                />
                <CustomInput
                  label="Total Area in sq.ft."
                  type="number"
                  isRequired={true}
                  maxlength={5}
                  inputValue={ConvertToDecimal(
                    listAddData?.totalArea
                  )?.toString()}
                  onChange={(value) => {
                    setListAddData({
                      ...listAddData,
                      totalArea: ConvertToDecimal(value),
                    });
                  }}
                />
                <CustomInput
                  label="Annual rate in $/sq.ft."
                  type="number"
                  isRequired={true}
                  maxlength={5}
                  inputValue={ConvertToDecimal(
                    listAddData?.annualRate
                  )?.toString()}
                  onChange={(value) => {
                    setListAddData({
                      ...listAddData,
                      annualRate: ConvertToDecimal(value),
                    });
                  }}
                />
                <CustomInput
                  label="Total Capacity"
                  isRequired={true}
                  maxlength={5}
                  inputValue={parseInt(listAddData?.totalCapacity) || 0}
                  onChange={(value) => {
                    setListAddData({
                      ...listAddData,
                      totalCapacity: parseInt(value),
                    });
                  }}
                />
                {hasGoogleMapsAPIKey ? (
                  <>
                    <div className="relative my-6 custom-address-dropdown">
                      <div
                        className="absolute font-poppins text-xs font-medium leading-tight bg-white text-regent-gray z-50"
                        style={{ top: "-9px", left: "9px", padding: "2px" }}
                      >
                        <span
                          style={{
                            position: "relative",
                            left: 0,
                            width: "14px",
                            height: "14px",
                            color: "#FF7850",
                            padding: "2px",
                          }}
                        >
                          *
                        </span>
                        Address
                      </div>
                      <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}
                        selectProps={{
                          value: listAddData.selectedAddress,
                          onChange: (value) =>
                            handleChange("clinicAddress", value),
                        }}
                      />
                    </div>
                    <CustomInput
                      label="Zip Code"
                      isRequired={true}
                      maxlength={7}
                      type="number"
                      inputValue={parseInt(listAddData.zip) || 0}
                      onChange={(value) =>
                        setListAddData({
                          ...listAddData,
                          zip: parseInt(value),
                        })
                      }
                    />
                    <CustomInput
                      label="Country"
                      isRequired={true}
                      disabled={true}
                      inputValue={listAddData.country}
                      onChange={(value) =>
                        setListAddData({
                          ...listAddData,
                          country: value,
                        })
                      }
                      maxlength="50"
                    />
                    <Col span={24}>
                      <Row type="flex">
                        <Col span={11} className="relative">
                          <CustomInput
                            label="Latitude"
                            isRequired={true}
                            maxlength={18}
                            disabled={true}
                            inputValue={listAddData.latitude}
                            onChange={(value) =>
                              setListAddData({
                                ...listAddData,
                                latitude: value,
                              })
                            }
                          />
                        </Col>
                        <Col span={2} />
                        <Col span={11} className="relative">
                          <CustomInput
                            label="Longitude"
                            isRequired={true}
                            maxlength={18}
                            disabled={true}
                            inputValue={listAddData.longitude}
                            onChange={(value) =>
                              setListAddData({
                                ...listAddData,
                                longitude: value,
                              })
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </>
                ) : (
                  ""
                )}

                <div className="mb-6">
                  <CustomSwitchToggle
                    label="Status"
                    switchValue={listAddData?.status}
                    onText="Active"
                    offText="Inactive"
                    onChange={(checked) =>
                      setListAddData({
                        ...listAddData,
                        status: checked ? 1 : 0,
                      })
                    }
                    checked={listAddData.status}
                  />
                </div>
                <div className="mb-6">
                  <CustomSwitchToggle
                    label="Is Default"
                    switchValue={listAddData?.isDefault}
                    onText="On"
                    offText="Off"
                    onChange={(checked) =>
                      setListAddData({
                        ...listAddData,
                        isDefault: checked ? 1 : 0,
                      })
                    }
                    checked={listAddData.isDefault}
                  />
                </div>
              </div>
            ),
          },
          {
            key: "images",
            name: "2. Image details",
            disabled: activeTab.includes("images") ? false : true,
            content: (
              <div className="px-7">
                <div className="relative mb-6">
                  <ImageSelector
                    selectedBase64Image={locationBase64Image}
                    setSelectedBase64Image={setLocationBase64Image}
                    imageName="Image of Location"
                    imageDetails={listAddData}
                    setImageDetails={setListAddData}
                    imageSizeMax="5"
                    isRequired={true}
                  />
                </div>
                <div className="relative mb-6">
                  <ImageSelector
                    selectedBase64Image={location3dModelBase64Image}
                    setSelectedBase64Image={setLocation3dModelBase64Image}
                    imageName="3D Space Model"
                    imageDetails={listAddData}
                    setImageDetails={setListAddData}
                    imageSizeMax="5"
                    imageKeyName="model3D"
                    // allowedFiles = {[".c4d", "".fbx", ".glb", ".obj", ".stl", ".3dm", ".3ds", and ".babylon"]}
                  />
                </div>
                <div className="relative mb-6">
                  <ImageSelector
                    selectedBase64Image={locationStackedFloorBase64Image}
                    setSelectedBase64Image={setLocationStackedFloorBase64Image}
                    imageName="Location Stacked Floor Image"
                    imageDetails={listAddData}
                    setImageDetails={setListAddData}
                    imageSizeMax="5"
                    imageKeyName="plan2D"
                  />
                </div>
                <div className="relative mb-6">
                  <ImageSelector
                    selectedBase64Image={locationOtherBase64Image}
                    setSelectedBase64Image={setLocationOtherBase64Image}
                    imageName="Other Files"
                    imageDetails={listAddData}
                    setImageDetails={setListAddData}
                    imageSizeMax="5"
                    imageKeyName="files"
                  />
                </div>
              </div>
            ),
          },
        ]}
      />

      <SidePanelFooter
        secondaryLabel={secondaryText}
        onCancelClick={() => onSecondaryClick()}
        primaryLabel={primaryText}
        onPrimaryClick={() => onPrimaryClick()}
      />

      {/* Add Location */}
      <CustomModal
        showModal={showAddModal}
        titleText={"Add Location"}
        messageText={`Are you sure you want to add ${listAddData.name}?`}
        handleCancel={() => {
          setShowAddModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={createLocation}
        isLoading={modalButtonLoading}
      />

      {/* Update Location Id */}
      <CustomModal
        showModal={showUpdateModal}
        titleText={"Update Location"}
        messageText={`Are you sure you want to update the changes made to ${listAddData.name}?`}
        handleCancel={() => {
          setShowUpdateModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={updateLocation}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
