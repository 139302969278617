import React, { useEffect, useContext, useState } from "react";

import { AppContext } from "../../Core/store/app-context";

import {
  FormatDateLocal,
  GetBase64,
  GetFirstChar,
  ValidateFile,
} from "../../Core/common/helpers";
import { CustomModal } from "../../Core/common/CustomModal";
import { SelectDropdown } from "../../Core/common/SelectDropdown";
import { CustomInput } from "../../Core/common/CustomInput";
import { CustomSwitchToggle } from "../../Core/common/CustomSwitchToggle";
import { ItemDetail } from "../../Core/common/ItemDetail";
import { SidePanelFooter } from "../../Core/common/SidePanelFooter";

export const UserChange = (props) => {
    const ctx = useContext(AppContext);
    const {
        itemId,
        roleList,
        organizationsList,
        getRecordList,
        hideChangeDrawer,
    } = props;

    const defaultListData = {
        Id: itemId,
        fullName: "",
        loginId: "",
        locked: 0,
        approver: 0,
        Status: 1,
        userImage: "",
        userType: null,
        roles: [],
        organizations: [],
    };
    const [userTypeList, setUserTypeList] = useState([]);
    const [userBase64Image, setUserBase64Image] = useState("");
    const [isUserUnlocked, setIsUserUnlocked] = useState(null);
    const [isSelectRoleDropDownOpen, setIsSelectRoleDropDownOpen] = useState(false);
    const [isSelectOrganizationDropDownOpen, setIsSelectOrganizationDropDownOpen] = useState(false);

    //Consent
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showUnlockUserModal, setShowUnlockUserModal] = useState(false);
    const [modalButtonLoading, setModalButtonLoading] = useState(false);
    const [listEditData, setListEditData] = useState(defaultListData);

    useEffect(() => {
        async function fetchData() {
            // You can await here
            await getUserTypeList();
            if (itemId) {
                await getItemData();
            }
            else {
                setListEditData({ ...defaultListData });
            }
        }
        setUserBase64Image("");
        fetchData();
    }, [itemId]);


    const getItemData = async () => {
        const response = await ctx.HttpGet("/User", { userId: itemId });
        if (response) {
            setListEditData(response);
            if (response.userImage) {
                setUserBase64Image("data:image/png;base64," + response.userImage);
            }
        }
    };

    const getUserTypeList = async () => {
        if (userTypeList.length) {
            return;
        }
        const response = await ctx.HttpGet("/User/types");
        if (response) {
            setUserTypeList(response);
        }
    };

    const handleFileChange = (e) => {
        if (!ValidateFile(ctx, e.target.files[0], 2, ["jpg", "jpeg", "png"])) {
            e.target.value = "";
        } else {
            GetBase64(e.target.files[0])
                .then((result) => {
                    setUserBase64Image(result);
                    let imageData = result.split(",");
                    setListEditData({
                        ...listEditData,
                        userImage: imageData[1],
                    });
                })
                .catch((e) => {
                    alert("error", e);
                });
        }
    };

    const validate = () => {
        if (listEditData.fullName === "" || listEditData.fullName?.trim() === "") {
            ctx.showToastAlert({ type: "error", message: "Please enter name" });
            return false;
        } else if (listEditData.Id === null || listEditData.Id === "" || listEditData.Id?.trim() === "") {
            ctx.showToastAlert({ type: "error", message: "Please enter user id" });
            return false;
        } else if (!listEditData?.userType) {
            ctx.showToastAlert({ type: "error", message: "Please select User Type" });
            return false;
        } else if (!listEditData?.roles?.length) {
            ctx.showToastAlert({ type: "error", message: "Please select role" });
            return false;
        } else if (!listEditData?.organizations?.length) {
            ctx.showToastAlert({ type: "error", message: "Please select organization" });
            return false;
        }
        return true;
    };

    const onPrimaryClick = () => {
        if (validate()) {
            if (itemId)
                setShowUpdateModal(true);
            else
                setShowAddModal(true);
        }
    };

    const createUser = async () => {
        setModalButtonLoading(true);

        let payload = { ...listEditData };

        const response = await ctx.HttpPost("/User", payload);
        if (response) {
            getRecordList();
            ctx.showToastAlert({ type: "success", message: response });
            hideChangeDrawer();
            setListEditData({ ...defaultListData });
            setUserBase64Image("");
        }
        setShowAddModal(false);
        setModalButtonLoading(false);
    };

    const updateUser = async () => {
        setModalButtonLoading(true);

        let payload = { ...listEditData, Id: itemId };

        const response = await ctx.HttpPut("/User", payload);
        if (response) {
            getRecordList();
            ctx.showToastAlert({ type: "success", message: response });
            hideChangeDrawer();
            setListEditData({ ...defaultListData });
        }
        setShowUpdateModal(false);
        setModalButtonLoading(false);
    };

    const unlockUser = async () => {
        setModalButtonLoading(true);

        let payload = { ...listEditData, locked: 0 };
        const response = await ctx.HttpPut("/User", payload);
        if (response) {
            setIsUserUnlocked(true);
            getRecordList();
            ctx.showToastAlert({ type: "success", message: response });
            hideChangeDrawer();
            setListEditData({ ...defaultListData });
        }
        setShowUnlockUserModal(false);
        setModalButtonLoading(false);
    };

    return (
        <>
            <div className="relative custom-scrollbar" style={{
                    height: "calc(100vh - 143px)",
                    overflow: "auto"
            }}>
                {itemId ?   
                    <div
                    className="flex items-center pb-5 border-b-2 px-8"
                    style={{ borderColor: "#F3F5F8" }}>
                        {userBase64Image ? (
                            <div
                                className="bg-center bg-contain bg-no-repeat rounded-full relative"
                                style={{
                                    backgroundImage: `url(${userBase64Image})`,
                                    minWidth: "87px",
                                    height: "87px",
                                    border: "1px solid #ededed",
                                }}
                            >
                                <span
                                    className="absolute rounded-full bottom-0 right-0"
                                    style={{
                                        width: "24px",
                                        height: "24px",
                                        backgroundColor: "#4E2C90",
                                        padding: "6px",
                                    }}
                                >
                                    <img src="/images/map/edit.svg" alt="edit" />
                                    <input
                                        type="file"
                                        className="absolute rounded-full top-0 left-0 opacity-0 cursor-pointer"
                                        style={{ width: "24px", height: "24px" }}
                                        onChange={handleFileChange}
                                    />
                                </span>
                            </div>
                        ) : (
                            <div
                                className="rounded-full relative flex items-center justify-center"
                                style={{
                                    backgroundColor: listEditData?.userImage ? "white" : "pink",
                                    textTransform: "uppercase",
                                    minWidth: "87px",
                                    height: "87px",
                                    border: "1px solid #ededed",
                                    fontSize: "2rem",
                                }}
                            >
                                {GetFirstChar(listEditData?.fullName)}
                                <span
                                    className="absolute rounded-full bottom-0 right-0"
                                    style={{
                                        width: "24px",
                                        height: "24px",
                                        backgroundColor: "#4E2C90",
                                        padding: "6px",
                                    }}
                                >
                                    <img src="/images/map/edit.svg" alt="edit" />
                                    <input
                                        type="file"
                                        className="absolute rounded-full top-0 left-0 opacity-0 cursor-pointer"
                                        style={{ width: "24px", height: "24px" }}
                                        onChange={handleFileChange}
                                    />
                                </span>
                            </div>
                        )} 
                        <div className="ml-3">
                            <div
                                className="font-semibold text-h2 flex items-center mb-2 break-all"
                                style={{ color: "#383A65" }}
                            >
                                {listEditData?.fullName ? listEditData?.fullName : "-"}
                            </div>

                            <CustomSwitchToggle
                                label=""
                                switchValue={listEditData?.Status}
                                onText="Active"
                                offText="Inactive"
                                onChange={(checked) =>
                                    setListEditData({
                                        ...listEditData,
                                        Status: checked ? 1 : 0,
                                    })
                                }
                                checked={listEditData.Status}
                                className="flex-row-reverse justify-end"
                            />
                        </div>
                    </div>
                :
                    <div
                        className="flex items-center justify-center text-h4 rounded-full mx-auto relative"
                        style={{ width: "70px", height: "70px" }}
                        >
                        {userBase64Image ? (
                            <div
                            style={{
                                backgroundImage: `url(${userBase64Image})`,
                                width: "70px",
                                height: "70px",
                                borderColor: "#E0E0E3",
                            }}
                            className="absolute top-0 left-0 cursor-pointer rounded-full bg-center border bg-contain bg-no-repeat"
                            >
                            <input
                                type="file"
                                className="absolute top-0 left-0 opacity-0 cursor-pointer rounded-full"
                                style={{ width: "70px", height: "70px" }}
                                onChange={($event) => {
                                handleFileChange($event);
                                }}
                            />
                            </div>
                        ) : (
                            <div
                            className="flex items-center justify-center text-h4 rounded-full mx-auto border relative"
                            style={{
                                width: "70px",
                                height: "70px",
                                borderColor: "#E0E0E3",
                                color: "#E0E0E3",
                            }}
                            >
                            +
                            <input
                                type="file"
                                className="absolute top-0 left-0 opacity-0 cursor-pointer rounded-full"
                                style={{ width: "70px", height: "70px" }}
                                onChange={($event) => {
                                handleFileChange($event);
                                }}
                            />
                            </div>
                        )}
                    </div>
                }
                <div className={"pt-5 px-8"} style={{ borderColor: "#F3F5F8" }}>
                    <div className="flex items-center " style={{ marginBottom: "22px" }}>
                        <img
                            src="/images/icons/user.svg"
                            alt="user"
                            style={{ height: "38px", width: "38px" }}
                        />
                        <div
                            className="ml-3 font-medium text-h1"
                            style={{ color: "#383A65" }}
                        >
                            Basic details
                        </div>
                    </div>
                    <CustomInput
                        label="Full name"
                        maxlength={255}
                        isRequired={true}
                        inputValue={listEditData.fullName}
                        onChange={(value) => {
                            setListEditData({ ...listEditData, fullName: value });
                        }}
                        onKeyDown={(value) => {
                            if (value.keyCode === 8 || value.keyCode === 46) {
                                return;
                            }
                            if (listEditData.fullName?.length >= 254) {
                                ctx.showToastAlert({
                                    type: "error",
                                    message: "You cannot enter more than 255 characters",
                                });
                            }
                        }}
                    />
                    <CustomInput
                        label="User Id"
                        disabled={itemId ? true : false}
                        isRequired={true}
                        inputValue={listEditData.Id}
                        onChange={(value) => {
                            setListEditData({ ...listEditData, Id: value });
                        }}
                    />
                    <SelectDropdown
                        dataList={listEditData?.roles}
                        optionsList={roleList}
                        isRequired={true}
                        mode="multiple"
                        optionKeyName="Id"
                        optionValueName="Value"
                        optionDisplayName="Value"
                        label="Select roles"
                        value={listEditData?.roles.map((item) => {
                            return item?.roleId;
                        })}
                        isRadioSelection={true}
                        isDefaultSelected="defaultRole"
                        dataListIdKeyName="roleId"
                        className={`with-custom-close-icon`}
                        suffixIcon={
                            <img
                                src="/images/icons/plus-with-bg.svg"
                                alt="dropdownimage"
                                style={{
                                    transform: isSelectRoleDropDownOpen
                                        ? "rotate(45deg)"
                                        : "rotate(0deg)",
                                    transition: "300ms all ease-in-out",
                                }}
                            />
                        }
                        onChange={(value, key) => {
                            let roles = [];
                            key.map((x) => {
                                let filteredRole = listEditData?.roles.filter(
                                    (item) => item.roleId === x.key
                                );
                                return roles.push({
                                    roleId: x.key,
                                    isDefault:
                                        filteredRole.length && filteredRole[0]?.isDefault
                                            ? filteredRole[0].isDefault
                                            : 0,
                                    status: 1,
                                });
                            });
                            setListEditData({
                                ...listEditData,
                                roles: roles,
                            });
                        }}
                        onDropdownVisibleChange={(isOpen) => {
                            setIsSelectRoleDropDownOpen(isOpen);
                        }}
                        onSelect={(value) => {
                            listEditData?.roles?.forEach((item) => {
                                if (item?.roleId === value) {
                                    item.isDefault = 1;
                                } else {
                                    item.isDefault = 0;
                                }
                            });
                            setListEditData({ ...listEditData });
                        }}
                    />

                    <SelectDropdown
                        dataList={listEditData?.organizations}
                        optionsList={organizationsList}
                        isRequired={true}
                        mode="multiple"
                        optionKeyName="Id"
                        optionValueName="Value"
                        optionDisplayName="Value"
                        label="Select organizations"
                        className={`with-custom-close-icon`}
                        isRadioSelection={true}
                        isDefaultSelected="defaultOrg"
                        dataListIdKeyName="orgId"
                        value={listEditData?.organizations.map((item) => {
                            return item?.orgId;
                        })}
                        suffixIcon={
                            <img
                                src="/images/icons/plus-with-bg.svg"
                                alt="dropdownimage"
                                style={{
                                    transform: isSelectOrganizationDropDownOpen
                                        ? "rotate(45deg)"
                                        : "rotate(0deg)",
                                    transition: "300ms all ease-in-out",
                                }}
                            />
                        }
                        onChange={(value, key) => {
                            let organizations = [];
                            key.map((x) => {
                                let filteredOrg = listEditData?.organizations.filter(
                                    (item) => item.orgId === x.key
                                );
                                return organizations.push({
                                    orgId: x.key,
                                    isDefault:
                                        filteredOrg.length && filteredOrg[0]?.isDefault
                                            ? filteredOrg[0].isDefault
                                            : 0,
                                    status: 1,
                                });
                            });
                            setListEditData({
                                ...listEditData,
                                organizations: organizations,
                            });
                        }}
                        onDropdownVisibleChange={(isOpen) => {
                            setIsSelectOrganizationDropDownOpen(isOpen);
                        }}
                        onSelect={(value) => {
                            listEditData?.organizations?.forEach((item) => {
                                if (item?.orgId === value) {
                                    item.isDefault = 1;
                                } else {
                                    item.isDefault = 0;
                                }
                            });
                            setListEditData({ ...listEditData });
                        }}
                    />
                    <SelectDropdown
                        dataList={listEditData?.userType}
                        optionsList={userTypeList}
                        isRequired={true}
                        mode="single"
                        optionKeyName="Id"
                        optionValueName="Id"
                        optionDisplayName="Value"
                        label="User Type"
                        value={listEditData?.userType !== null ? listEditData?.userType?.toString() : null}
                        onChange={(value, key) => {
                            setListEditData({ ...listEditData, userType: value });
                        }}
                    />
                    <CustomInput
                        label="Card Id"
                        disabled={itemId ? true : false}
                        isRequired={false}
                        inputValue={listEditData.loginId}
                        onChange={(value) => {
                            setListEditData({ ...listEditData, loginId: value });
                        }}
                    />
                </div>
                    <div className="px-8">
                        <CustomSwitchToggle
                            label="Is Approver"
                            switchValue={listEditData?.approver}
                            onText="Yes"
                            offText="No"
                            onChange={(checked) =>
                                setListEditData({
                                    ...listEditData,
                                    approver: checked ? 1 : 0,
                                })
                            }
                            checked={listEditData.approver}
                            styleName="labelGreyDarkText"
                        />
                    </div>
                    {listEditData?.locked === 1 ? (
                        <div>
                            <div className="px-8">
                                <ItemDetail
                                    label="Last invalid login attempt"
                                    itemValue={FormatDateLocal(
                                        listEditData?.lastInvalidLoginAttempt,
                                        "-"
                                    )}
                                />
                                <ItemDetail
                                    label="Invalid login attempts"
                                    itemValue={listEditData?.invalidLoginAttempt}
                                />
                            </div>
                            <div
                                style={{
                                    backgroundColor: !isUserUnlocked ? "#F3F5F8" : "#F3F5F8",
                                }}
                                className="py-4 px-8 mt-4 flex items-center cursor-pointer"
                                onClick={() => {
                                    if (listEditData?.locked === 1) {
                                        setListEditData({ ...listEditData, locked: 0 });
                                        setShowUnlockUserModal(true);
                                    }
                                }}
                            >
                                <img
                                    src={
                                        !isUserUnlocked
                                            ? "/images/icons/lock.svg"
                                            : "/images/map/unlock.svg"
                                    }
                                    alt="lock"
                                    className="mr-2"
                                    style={{
                                        height: "18px",
                                        filter: !isUserUnlocked
                                            ? "none"
                                            : "grayscale(1) brightness(2.5)",
                                    }}
                                />
                                <div
                                    style={{ color: isUserUnlocked ? "#8892A5" : "#4E2C90" }}
                                    className="text-h1 font-medium"
                                >
                                    {!isUserUnlocked ? "Click to unlock user" : "User unlocked"}
                                </div>
                            </div>{" "}
                        </div>
                    ) : (
                        <div className="px-8">
                            <ItemDetail
                                label="Last login"
                                itemValue={FormatDateLocal(listEditData?.lastLogin, "-")}
                            />
                        </div>
                    )}

            </div>
            <SidePanelFooter
                secondaryLabel={"Cancel"}
                onCancelClick={() => {
                    hideChangeDrawer();
                    setListEditData({ ...defaultListData });
                }}
                primaryLabel={"Apply"}
                onPrimaryClick={() => onPrimaryClick()}
            />

            {/* Add User */}
            <CustomModal
                showModal={showAddModal}
                titleText="Add User"
                messageText={`Are you sure you want to add ${listEditData.fullName}?`}
                handleCancel={() => {
                    setShowAddModal(false);
                }}
                confirmButtonText={"Yes"}
                handleConfirm={createUser}
                isLoading={modalButtonLoading}
            />

            {/* Unlock User */}
            <CustomModal
                showModal={showUnlockUserModal}
                titleText="Update User"
                messageText={`Are you sure you want to unlock the ${listEditData?.fullName}?`}
                handleCancel={() => {
                    setShowUnlockUserModal(false);
                }}
                confirmButtonText={"Yes"}
                handleConfirm={unlockUser}
                isLoading={modalButtonLoading}
            />

            {/* Update User Id */}
            <CustomModal
                showModal={showUpdateModal}
                titleText="Update User"
                messageText={`Are you sure you want to update the changes made to ${listEditData?.fullName}?`}
                handleCancel={() => {
                    setShowUpdateModal(false);
                }}
                confirmButtonText={"Yes"}
                handleConfirm={updateUser}
                isLoading={modalButtonLoading}
            />
        </>
    );
};
