import React, { useState, useContext, useEffect } from "react";

import { Row, Col } from "antd";

import { AppContext } from "../../Core/store/app-context";
import { ImageSelector } from "../../Core/common/ImageSelector";
import { CustomModal } from "../../Core/common/CustomModal";
import { CustomInput } from "../../Core/common/CustomInput";
import { CustomSwitchToggle } from "../../Core/common/CustomSwitchToggle";
import { CustomTextarea } from "../../Core/common/CustomTextarea";
import { SelectDropdown } from "../../Core/common/SelectDropdown";
import { SidePanelFooter } from "../../Core/common/SidePanelFooter";

export const TenantChange = (props) => {
    const ctx = useContext(AppContext);

    const {
        itemId,
        getRecordList,
        hideChangeDrawer,
    } = props;

    const defaultListData = {
        Id: "",
        Name: "",
    };

    const [tenantBase64Image, setTenantBase64Image] = useState("");
    const [systemTypes, setSystemTypes] = useState([]);
    const [organizationList, setOrganizationList] = useState([]);

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [modalButtonLoading, setModalButtonLoading] = useState(false);
    const [listEditData, setListEditData] = useState(defaultListData);

    useEffect(() => {
        async function fetchData() {
            // You can await here
            await getSystemTypes();
            await getOrganizationList();
            if (itemId) {
                await getItemData();
            }
            else {
                setListEditData(defaultListData);
                setTenantBase64Image("");
            }
        }
        fetchData();

    }, [itemId]);

    const getSystemTypes = async () => {
        const response = await ctx.HttpGet("/Tenant/system");
        if (response) {
            setSystemTypes(response);
        }
    };

    const getOrganizationList = async () => {
        const response = await ctx.HttpGet("/Tenant/organization");
        if (response) {
            setOrganizationList(response);
        }
    };

    const getItemData = async () => {
        const response = await ctx.HttpGet("/Tenant", { id: itemId });
        if (response) {
            setListEditData(response);

            if (response.image) {
                setTenantBase64Image("data:image/png;base64," + response.image);
            }
        }
    };

    const validate = () => {
        if (listEditData.Id === "") {
            ctx.showToastAlert({ type: "error", message: "Please enter tenant Id" });
            return false;
        }
        else if (listEditData.Name === null) {
            ctx.showToastAlert({ type: "error", message: "Please enter tenant Name" });
            return false;
        }
        return true;
    };

    const onPrimaryClick = () => {
        if (validate()) {
            if (itemId)
                setShowUpdateModal(true);
        }
    };

    const updateTenant = async () => {
        setModalButtonLoading(true);
        const response = await ctx.HttpPut("/Tenant", listEditData);
        if (response) {
            hideChangeDrawer();
            setListEditData(defaultListData);
            ctx.showToastAlert({ type: "success", message: response });
            await getRecordList();
        }
        setShowUpdateModal(false);
        setModalButtonLoading(false);
    };

    return (
        <>
            <div className="pt-3 px-7 relative custom-scrollbar" style={{
                    height: "calc(100vh - 143px)",
                    overflow: "auto"
            }}>
                    <CustomInput
                        label="Name"
                        inputValue={listEditData?.Name}
                        onChange={(value) => {
                            setListEditData({ ...listEditData, Name: value });
                        }}
                    />
                    <SelectDropdown
                        dataList={listEditData.OrgCode}
                        optionsList={organizationList}
                        mode="single"
                        optionKeyName="Id"
                        optionValueName="Id"
                        optionDisplayName="Value"
                        value={organizationList.length > 0 && listEditData.OrgCode}
                        label="Select Organization"
                        onChange={(value, key) => {
                            setListEditData({ ...listEditData, OrgCode: value });
                        }}
                    />
                    <SelectDropdown
                        dataList={listEditData?.StorageSystemId}
                        optionsList={systemTypes}
                        mode="single"
                        optionKeyName="Id"
                        optionValueName="Id"
                        optionDisplayName="Value"
                        value={systemTypes.length > 0 && listEditData.StorageSystemId}
                        label="Logging"
                        onChange={(value, key) => {
                            setListEditData({ ...listEditData, StorageSystemId: value });
                        }}
                    />
                    <SelectDropdown
                        dataList={listEditData?.MessageSystemId}
                        optionsList={systemTypes}
                        mode="single"
                        optionKeyName="Id"
                        optionValueName="Id"
                        optionDisplayName="Value"
                        value={systemTypes.length > 0 && listEditData.MessageSystemId}
                        label="Messaging"
                        onChange={(value, key) => {
                            setListEditData({ ...listEditData, MessageSystemId: value });
                        }}
                    />
                    <SelectDropdown
                        dataList={listEditData?.BlobSystemId}
                        optionsList={systemTypes}
                        mode="single"
                        optionKeyName="Id"
                        optionValueName="Id"
                        optionDisplayName="Value"
                        value={systemTypes.length > 0 && listEditData.BlobSystemId}
                        label="Blob"
                        onChange={(value, key) => {
                            setListEditData({ ...listEditData, BlobSystemId: value });
                        }}
                    />
                    <SelectDropdown
                        dataList={listEditData?.OpenidSystemId}
                        optionsList={systemTypes}
                        mode="single"
                        optionKeyName="Id"
                        optionValueName="Id"
                        optionDisplayName="Value"
                        value={systemTypes.length > 0 && listEditData.OpenidSystemId}
                        label="Authentication"
                        onChange={(value, key) => {
                            setListEditData({ ...listEditData, OpenidSystemId: value });
                        }}
                    />
                    <CustomInput
                        label="Welcome text"
                        inputValue={listEditData?.WelcomeText}
                        onChange={(value) => {
                            setListEditData({ ...listEditData, WelcomeText: value });
                        }}
                    />
                    <CustomInput
                        label="Security title"
                        inputValue={listEditData?.SecurityTitle}
                        onChange={(value) => {
                            setListEditData({ ...listEditData, SecurityTitle: value });
                        }}
                    />
                    <CustomTextarea
                        label="Security text"
                        inputValue={listEditData?.SecurityText}
                        rows="3"
                        onChange={(value) =>
                            setListEditData({ ...listEditData, SecurityText: value })
                        }
                    />
                    <CustomTextarea
                        label="Home Security title"
                        inputValue={listEditData?.HomeTitle}
                        rows="2"
                        onChange={(value) => {
                            setListEditData({ ...listEditData, HomeTitle: value });
                        }}
                    />
                    <Row className="mb-5">
                        <Col span={18}>
                            <CustomInput
                                label="Log cleanup"
                                inputValue={parseInt(listEditData?.LogCleanupDays) || 0}
                                onChange={(value) => {
                                    setListEditData({ ...listEditData, LogCleanupDays: value });
                                }}
                            />
                        </Col>
                        <Col span={5} className="ml-auto">
                            <div
                                className="input-with-label-in-textbox font-poppins p-3 text-h1 font-medium"
                                style={{ color: "#8892A5", height: "45px" }}
                            >
                                Days
                            </div>
                        </Col>
                    </Row>
                    <CustomSwitchToggle
                        label="Log change"
                        switchValue={listEditData?.CanLogChange}
                        onText="Enabled"
                        offText="Disabled"
                        onChange={(checked) =>
                            setListEditData({ ...listEditData, CanLogChange: checked ? 1 : 0 })
                        }
                        checked={listEditData?.CanLogChange}
                        styleName="labelGreyDarkText"
                    />
                    <CustomSwitchToggle
                        label="Log session"
                        switchValue={listEditData?.CanLogSession}
                        onText="Enabled"
                        offText="Disabled"
                        onChange={(checked) =>
                            setListEditData({ ...listEditData, CanLogSession: checked ? 1 : 0 })
                        }
                        checked={listEditData?.CanLogSession}
                        styleName="labelGreyDarkText"
                    />
                    <CustomSwitchToggle
                        label="Log message"
                        switchValue={listEditData?.CanLogMessage}
                        onText="Enabled"
                        offText="Disabled"
                        onChange={(checked) =>
                            setListEditData({ ...listEditData, CanLogMessage: checked ? 1 : 0 })
                        }
                        checked={listEditData?.CanLogMessage}
                        styleName="labelGreyDarkText"
                    />
                    <CustomSwitchToggle
                        label="Is default"
                        switchValue={listEditData?.IsDefault}
                        onText="Enabled"
                        offText="Disabled"
                        onChange={(checked) =>
                            setListEditData({ ...listEditData, IsDefault: checked ? 1 : 0 })
                        }
                        checked={listEditData?.IsDefault}
                        styleName="labelGreyDarkText"
                    />
                    <CustomSwitchToggle
                        label="Status"
                        switchValue={listEditData?.Status}
                        onText="Enabled"
                        offText="Disabled"
                        onChange={(checked) =>
                            setListEditData({ ...listEditData, Status: checked ? 1 : 0 })
                        }
                        checked={listEditData?.Status}
                        styleName="labelGreyDarkText"
                    />
                    <div className="relative my-6">
                        <ImageSelector
                            selectedBase64Image={tenantBase64Image}
                            setSelectedBase64Image={setTenantBase64Image}
                            imageName={listEditData?.Name}
                            imageDetails={listEditData}
                            setImageDetails={setListEditData}
                            imageSizeMax="5"
                        />
                    </div>

            </div>

            <SidePanelFooter
                secondaryLabel={"Cancel"}
                onCancelClick={() => {
                    hideChangeDrawer();
                    setListEditData(defaultListData);
                }}
                primaryLabel={"Apply"}
                onPrimaryClick={() => onPrimaryClick()}
            />

            {/* Update Tenant */}
            <CustomModal
                showModal={showUpdateModal}
                titleText="Update Tenant"
                messageText={`Are you sure you want to update the changes made to ${listEditData?.Name}?`}
                handleCancel={() => {
                    setShowUpdateModal(false);
                }}
                confirmButtonText={"Yes"}
                handleConfirm={updateTenant}
                isLoading={modalButtonLoading}
            />
        </>
    );
};
