import React, { useState, useContext, useEffect } from "react";
import { Row, Col } from "antd";
import { useParams } from "react-router-dom";

import { AppContext } from "../../../Core/store/app-context";

import { SelectDropdown } from "../../../Core/common/SelectDropdown";
import { SidePanelFooter } from "../../../Core/common/SidePanelFooter";
import { CustomInput } from "../../../Core/common/CustomInput";
import { CustomSwitchToggle } from "../../../Core/common/CustomSwitchToggle";
import { CustomTextarea } from "../../../Core/common/CustomTextarea";
import { CustomTooltip } from "../../../Core/common/CustomTooltip";
import { CustomModal } from "../../../Core/common/CustomModal";

export const RuleAlertChange = (props) => {
  const {
    alertViewDatareasonId,
    alertViewDataEscalationId,
    getRecordList,
    hideChangeDrawer,
  } = props;
  const ctx = useContext(AppContext);

  const { ruleId } = useParams();

  const defaultListData = {
    ruleId: ruleId,
    distId: "",
    reasonId: null,
    severity: null,
    status: 0,
    subject: "",
    message: "",
    escalation: null,
    escalationMins: 0,
  };

  const [listAddData, setListAddData] = useState(defaultListData);

  const [reasonList, setReasonList] = useState([]);
  const [severityList, setSeverityList] = useState([]);
  const [escalationList, setEscalationList] = useState([]);
  const [distributionList, setDistributionList] = useState([]);
  const [distributionType, setDistributionType] = useState([]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      await getDistributionList();
      await getAlertReasonList();
      await getSeverityList();
      await getAlertExcalationList();
      if ((alertViewDatareasonId || alertViewDatareasonId === 0) && alertViewDataEscalationId) {
        await getItemData();
      } else {
        setListAddData({ ...defaultListData });
      }
    }
    fetchData();
  }, [alertViewDatareasonId, alertViewDataEscalationId]);

  useEffect(() => {
    if (listAddData?.distId) {
      getDistributionType(listAddData?.distId);
    }
  }, [listAddData?.distId]);

  const getItemData = async () => {
    const response = await ctx.HttpGet("/Rule/alert", {
      ruleId: ruleId,
      reasonId: alertViewDatareasonId,
      escalation: alertViewDataEscalationId,
    });
    if(response) {
      setListAddData({
        ...response,
        reasonId: response.reasonId ? response.reasonId : 0,
        severity: response.severity ? response.severity : 0,
      })
    }
  };

  const getAlertReasonList = async () => {
    const response = await ctx.HttpGet("/Rule/alertreason", { id: ruleId });
    setReasonList(response);
  };
  const getSeverityList = async () => {
    const response = await ctx.HttpGet("/Rule/alertseverity");
    setSeverityList(response);
  };
  const getAlertExcalationList = async () => {
    const response = await ctx.HttpGet("/Rule/alertexcalation");
    setEscalationList(response);
  };
  const getDistributionList = async () => {
    const response = await ctx.HttpGet("/Rule/distribution");
    setDistributionList(response);
  };
  const getDistributionType = async (id) => {
    const response = await ctx.HttpGet("/Distribution/type", {
      distID: id,
    });
    setDistributionType(response);
  };

  const validate = () => {
    if (listAddData.reasonId === null) {
      ctx.showToastAlert({ type: "error", message: "Please select reason" });
      return false;
    } else if (listAddData.escalation === null) {
      ctx.showToastAlert({
        type: "error",
        message: "Please select escalation level",
      });
      return false;
    } else if (
      listAddData.escalation !== 10 &&
      listAddData.escalationMins === 0
    ) {
      ctx.showToastAlert({
        type: "error",
        message: "Please enter escalation in mins",
      });
      return false;
    } else if (listAddData.severity === null) {
      ctx.showToastAlert({ type: "error", message: "Please select severity" });
      return false;
    } else if (listAddData.subject === "") {
      ctx.showToastAlert({ type: "error", message: "Please enter subject" });
      return false;
    } else if (listAddData.message === "") {
      ctx.showToastAlert({ type: "error", message: "Please enter message" });
      return false;
    } else if (listAddData.distId === "") {
      ctx.showToastAlert({
        type: "error",
        message: "Please select distribution name",
      });
      return false;
    }
    return true;
  };

  const onPrimaryClick = () => {
    if (validate()) {
      if ((alertViewDatareasonId || alertViewDatareasonId === 0) && alertViewDataEscalationId) {
        setShowUpdateModal(true);
      } else {
        setShowAddModal(true);
      }
    }
  };

  const createRuleAlert = async () => {
    setModalButtonLoading(true);

    let payload = { ...listAddData, ruleId: ruleId };

    const response = await ctx.HttpPost("/Rule/alert", payload);
    if (response) {
      getRecordList();
      ctx.showToastAlert({ type: "success", message: response });
      hideChangeDrawer();
      setListAddData({ ...defaultListData });
    }
    setShowAddModal(false);
    setModalButtonLoading(false);
  };

  const updateRuleAlert = async () => {
    setModalButtonLoading(true);

    let payload = { ...listAddData, ruleId: ruleId };

    const response = await ctx.HttpPut("/Rule/alert", payload);
    if (response) {
      getRecordList();
      ctx.showToastAlert({ type: "success", message: response });
      hideChangeDrawer();
      setListAddData({ ...defaultListData });
    }
    setShowUpdateModal(false);
    setModalButtonLoading(false);
  };

  return (
    <>
      <div className="px-7 pt-3">
        <SelectDropdown
          dataList={listAddData?.reasonId}
          optionsList={reasonList}
          isRequired={true}
          mode="single"
          optionKeyName="Id"
          optionValueName="Id"
          optionDisplayName="Value"
          label="alert reason"
          value={
            listAddData?.reasonId !== null
              ? listAddData?.reasonId?.toString()
              : null
          }
          onChange={(value, key) => {
            setListAddData({
              ...listAddData,
              reasonId: parseInt(value),
            });
          }}
          disabled={
            (alertViewDatareasonId || alertViewDatareasonId === 0) && alertViewDataEscalationId ? true : false
          }
        />

        <SelectDropdown
          dataList={listAddData?.escalation}
          optionsList={escalationList}
          isRequired={true}
          mode="single"
          optionKeyName="Id"
          optionValueName="Id"
          optionDisplayName="Value"
          label="escalation level"
          value={
            listAddData?.escalation !== null
              ? listAddData?.escalation?.toString()
              : null
          }
          onChange={(value, key) => {
            setListAddData({
              ...listAddData,
              escalation: parseInt(value),
            });
          }}
          disabled={
            (alertViewDatareasonId || alertViewDatareasonId === 0) && alertViewDataEscalationId ? true : false
          }
        />

        <Row>
          <Col span={18}>
            <CustomInput
              label="Escalate"
              isRequired={true}
              inputValue={parseInt(listAddData?.escalationMins) || 0}
              onChange={(value) =>
                setListAddData({
                  ...listAddData,
                  escalationMins: parseInt(value),
                })
              }
            />
          </Col>
          <Col span={5} className="ml-auto">
            <div
              className="input-with-label-in-textbox font-poppins p-3 text-h1 font-medium"
              style={{ color: "#8892A5", height: "45px" }}
            >
              Mins
            </div>
          </Col>
        </Row>

        <CustomSwitchToggle
          label="Status"
          switchValue={listAddData?.status}
          onText="Enabled"
          offText="Disabled"
          onChange={(checked) =>
            setListAddData({
              ...listAddData,
              status: checked ? 1 : 0,
            })
          }
          checked={listAddData?.status}
          className="mb-6"
        />

        <SelectDropdown
          dataList={listAddData?.severity}
          optionsList={severityList}
          isRequired={true}
          mode="single"
          optionKeyName="Id"
          optionValueName="Id"
          optionDisplayName="Value"
          label="severity"
          value={
            listAddData?.severity !== null
              ? listAddData?.severity?.toString()
              : null
          }
          onChange={(value, key) => {
            setListAddData({
              ...listAddData,
              severity: parseInt(value),
            });
          }}
        />

        <CustomInput
          label="Subject"
          inputValue={listAddData?.subject}
          isRequired={true}
          onChange={(value) =>
            setListAddData({ ...listAddData, subject: value })
          }
          maxlength="200"
          onKeyDown={(value) => {
            if (value.keyCode === 8 || value.keyCode === 46) {
              return;
            }
            if (listAddData?.subject.length >= 199) {
              ctx.showToastAlert({
                type: "error",
                message: "You cannot enter more than 200 characters",
              });
              return;
            }
          }}
        />
        <CustomTextarea
          label="Message"
          inputValue={listAddData?.message}
          isRequired={true}
          rows="4"
          onChange={(value) =>
            setListAddData({ ...listAddData, message: value })
          }
          maxlength="3000"
          onKeyDown={(value) => {
            if (value.keyCode === 8 || value.keyCode === 46) {
              return;
            }
            if (listAddData?.message.length >= 2999) {
              ctx.showToastAlert({
                type: "error",
                message: "You cannot enter more than 3000 characters",
              });
            }
          }}
        />

        <SelectDropdown
          dataList={listAddData?.distId}
          optionsList={distributionList}
          isRequired={true}
          mode="single"
          optionKeyName="Id"
          optionValueName="Id"
          optionDisplayName="Value"
          label="distribution name"
          value={
            listAddData?.distId !== "" ? listAddData?.distId?.toString() : null
          }
          onChange={(value, key) => {
            setListAddData({
              ...listAddData,
              distId: value.toString(),
            });
          }}
        />
        {listAddData?.distId && distributionType.length ? (
          <div
            className="overflow-auto px-3 pb-3 mb-6"
            style={{
              maxHeight: "200px",
              border: "1px solid #E0E0E3",
              borderRadius: "12px",
            }}
          >
            <div
              className="flex items-center mt-3 justify-between pb-3"
              style={{ borderBottom: "1px solid #E0E0E3" }}
            >
              <div style={{ color: "#8892A5", fontSize: "11px", width: "35%" }}>
                Delivery Type
              </div>
              <div style={{ color: "#8892A5", fontSize: "11px" }}>
                Destinations
              </div>
            </div>
            {distributionType.map((item) => {
              return (
                <div className="flex items-center mt-3 justify-between">
                  <div
                    className="font-medium"
                    style={{ color: "#8892A5", fontSize: "11px", width: "35%" }}
                  >
                    {item?.typeName}
                  </div>
                  <div style={{ width: "64%" }}>
                    {item?.destination ? (
                      <CustomTooltip
                        title={item?.destination}
                        className="font-semibold text-h1 break-all text-right"
                        styles={{ color: "#383A65" }}
                      />
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>

      <SidePanelFooter
        secondaryLabel={"Cancel"}
        onCancelClick={() => {
          hideChangeDrawer();
          setListAddData({ ...defaultListData });
        }}
        primaryLabel={"Apply"}
        onPrimaryClick={() => onPrimaryClick()}
      />

      {/* Add Rule Alert */}
      <CustomModal
        showModal={showAddModal}
        titleText="Add Alert"
        messageText={`Are you sure you want to add alert?`}
        handleCancel={() => {
          setShowAddModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={createRuleAlert}
        isLoading={modalButtonLoading}
      />

      {/* Update Rule Alert */}
      <CustomModal
        showModal={showUpdateModal}
        titleText="Update Alert"
        messageText={`Are you sure you want to update the changes made to alert?`}
        handleCancel={() => {
          setShowUpdateModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={updateRuleAlert}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
