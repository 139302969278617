import React from "react";

export const CustomTextarea = (props) => {
  const {
    label,
    placeholder,
    inputValue,
    onChange,
    maxlength,
    onKeyDown,
    rows,
    floatingLabel,
    disabled,
    isRequired
  } = props;

  return (
    <div className="relative mb-6">
      {label && inputValue ? (
        <div
        className={`font-poppins ${
            floatingLabel !== false
              ? "absolute text-sub0 font-medium leading-tight bg-white text-regent-gray"
              : "font-semibold leading-normal text-h1 text-pickled-bluewood"
          }`}
          style={{
            top: floatingLabel !== false ? "-9px" : "",
            left: floatingLabel !== false ? "9px" : "",
            padding: floatingLabel !== false ? "2px" : "",
            zIndex: floatingLabel !== false ? "1" : "auto",
            backgroundImage: disabled
              ? "linear-gradient(#fff, whitesmoke)"
              : "",
          }}
        >
          {isRequired ? <span className="mr-1 error-text" style={{ fontSize: "14px" }}>*</span> : ""}
          {label}
        </div>
      ) : (
        ""
      )}
      <div className={`
        ${inputValue === 0 ? "" : isRequired && !inputValue  ? "input-div custom-required-placeholder relative" : ""}`}>
          <textarea
            placeholder={label ? label : placeholder}
            value={inputValue}
            rows={rows}
            onChange={(e) => onChange(e.target.value)}
            disabled={disabled}
            maxLength={maxlength}
            onKeyDown={(e) => {
              if (typeof onKeyDown === "function") {
                onKeyDown(e.target.value);
              }
            }}
            //   className="input-with-label-in-textbox text-pickled-bluewood font-poppins p-3 text-h1 font-medium leading-tight bg-white w-full"
            style={{
              color: disabled ? "rgba(0, 0, 0, 0.25)" : "#383A65",
              backgroundColor: disabled ? "#f5f5f5" : "#fff",
              cursor: disabled ? "not-allowed" : "text",
            }}
            className={`font-poppins font-medium ${
              floatingLabel !== false
                ? "input-with-label-in-textbox p-3 text-h1 leading-tight w-full"
                : "text-xs rounded-lg leading-normal"
            }`}
          />
        </div>
    </div>
  );
};
