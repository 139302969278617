import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

import { Layout } from "../../../Core/layout";
import { DataTable, getClickableItemIndex } from "../../../Core/common/DataTable";
import { AppContext } from "../../../Core/store/app-context";
import { FormatDateLocal } from "../../../Core/common/helpers";
import { ItemDetail } from "../../../Core/common/ItemDetail";
import { SidePanelDisplay } from "../../../Core/common/SidePanelDisplay";
import { SidePanelPreferences } from "../../../Core/common/SidePanelPreferences";

export const SessionLogDetails = (props) => {
  const ctx = useContext(AppContext);
  const params = useParams();

  const [pageSize, setPageSize] = useState(10);
  const [offsetValue, setOffsetValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
  const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

  const [columnList, setColumnList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [listData, setListData] = useState();
  const [listDetail, setListDetail] = useState({});

  useEffect(() => {
    getRecord();
  }, []);

  const getRecord = async () => {
    let queryParams = { id: params.sessionId };
    const response = await ctx.HttpGet("/SessionLog", queryParams);
    if (response) {
        setListData(response);
        await getRecordList();
    }
  };

  const getRecordList = async (
    searchBy,
    sortBy = sortByValue,
    orderBy = orderByValue,
    offset = offsetValue,
    limit = pageSize
  ) => {
    let queryParams = {
      id: params.sessionId,
      search: searchBy,
      sortBy: sortBy,
      orderBy: orderBy,
      offset: offset,
      limit: limit,
    };
    const response = await ctx.HttpGetList("/SessionLog/detail/list", queryParams);
    if (response) {
      setRecordList(response.Data);
      setTotalCount(response.Count);
      setColumnList(response.Headers);
    }
  };

    if (columnList?.length > 1) {
        columnList[getClickableItemIndex(columnList)]['onClick'] = (record) => {
            setListDetail(record);
            setShowDisplayDrawer(true);
        }
    };

  return (
    <>
      <Layout showSearch={false} showFilter={false} showPageActionButton={false}>
        <div
          style={{ backgroundColor: "#8892A51A", color: "#4E2C90" }}
          className="text-h1 font-medium rounded-md px-3 py-2 inline-block"
        >
          <Link
            to={{ pathname: `/logs-session` }}
            className="inline-flex items-center session-link"
          >
            <img
              src="/images/icons/forward-arrow.svg"
              alt="dropdownimage"
              className="mr-2"
              style={{ transform: "rotate(180deg)" }}
            />{" "}
            Session Log
          </Link>
        </div>

        <div className="bg-white p-5 mt-5" style={{ borderRadius: "10px" }}>
          <Row className="items-end">
            <Col span={10}>
                <>
                    <ItemDetail label="User ID" itemValue={listData?.UserId} />
                    <ItemDetail label="User Agent" itemValue={listData?.UserAgent} />
                </>
            </Col>
            <Col span={4}>
                <>
                    <ItemDetail label="App ID" itemValue={listData?.AppId} />
                    <ItemDetail label="Computer" itemValue={listData?.Machine} />
                </>
            </Col>
            <Col span={4}>
                <>
                    <ItemDetail label="Service ID" itemValue={listData?.Service} />
                    <ItemDetail label="Created On" itemValue={FormatDateLocal(listData?.CreatedOn, "-")} />
                </>
            </Col>
            <Col span={4}>
                <>
                    <ItemDetail label="Auth Type" itemValue={listData?.AuthTypeName} />
                    <ItemDetail label="Updated On" itemValue={FormatDateLocal(listData?.UpdatedOn, "-")} />
                </>
            </Col>
            <Col span={2}>
                <>
                    <ItemDetail label="IP Address" itemValue={listData?.RemoteIP} />
                    <ItemDetail label="Status" itemValue={listData?.StatusName} />
                </>
            </Col>
          </Row>
        </div>

        <div
          className="my-5 pb-2 relative"
          style={{ borderBottom: "1px solid #E0E0E3" }}
        >
          <div className="text-h1 font-semibold" style={{ color: "#383A65" }}>
            Session Details{" "}
            <span
              style={{ borderBottom: "2px solid #383A65", width: "120px" }}
              className="absolute left-0 bottom-0"
            ></span>
          </div>
        </div>

        <DataTable
            dataSource={recordList}
            columns={columnList}
            totalRecords={totalCount}
            pageSize={pageSize}
            rowKey={"Id"}
            onUserPreferenceClick={() => {
              setShowPreferenceDrawer(true);
            }}
            currentPage={offsetValue}
            tableScrollSize="calc(100vh - 465px)"
            onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
              let sortOrderValue = "";
              if (sorterOptions && sorterOptions.order) {
                sortOrderValue =
                sorterOptions.order === "ascend" ? "ASC" : "DESC";
                setOrderByValue(sortOrderValue);
                setSortByValue(sorterOptions.columnKey);
              }
              setPageSize(pageSizeOptions.pageSize);
              setOffsetValue(pageSizeOptions.current);
              getRecordList(
                null,
                sorterOptions.columnKey,
                sortOrderValue,
                pageSizeOptions.current,
                pageSizeOptions.pageSize
              );
            }}
        />
      </Layout>

          <SidePanelDisplay
              listData={listDetail}
              headerData={columnList}
              panelIcon={"sessionlog"}
              panelTitle={"Session Log Details"}
              panelVisible={showDisplayDrawer}
              onCancelClick={() => {
                  setShowDisplayDrawer(false);
                  setListDetail({});
              }}
          />
          <SidePanelPreferences
              module={"/SessionLog/detail/list"}
              panelIcon={"sessionlog"}
              panelVisible={showPreferenceDrawer}
              onCancelClick={() => {
                  setShowPreferenceDrawer(false);
              }}
              onChangeClick={() => {
                  setShowPreferenceDrawer(false);
                  getRecord(false, searchValue, sortByValue, orderByValue, offsetValue);
              }}
          />
     </>
  );
};
