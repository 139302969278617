import React, { useEffect, useContext, useState } from "react";
import { Row, Col, Popover } from "antd";

import { AppContext } from "../../Core/store/app-context";

import { FormatDateLocal, GetFirstChar } from "../../Core/common/helpers";
import { ItemDetail } from "../../Core/common/ItemDetail";

export const UserDisplay = (props) => {
  const ctx = useContext(AppContext);

  const {
        itemId,
        roleList,
        organizationsList,
        getNameById
  } = props;

    const [userData, setUserData] = useState({});
    const [userBase64Image, setUserBase64Image] = useState("");

    useEffect(() => {
        setUserBase64Image("");
        if (itemId) {
            getItemData();
        }
    }, [itemId]);

  const getItemData = async () => {
    const response = await ctx.HttpGet("/User", { userId: itemId });
    if (response) {
      setUserData(response);
      if (response.userImage) {
        setUserBase64Image("data:image/png;base64," + response.userImage);
      }
    }
  };

  const hoverContent = (
    <div>
      <div className="text-h1 font-semibold" style={{ color: "#383A65" }}>
        {FormatDateLocal(userData?.lastInvalidLoginAttempt, "-")}
      </div>
      <div style={{ color: "#8892A5" }} className="text-h1 font-medium">
        Locked On
      </div>
    </div>
  );

  return (
    <div style={{ paddingBottom: "20px" }}>
      <div
        className="flex items-center pb-5 border-b-2 px-8"
        style={{ borderColor: "#F3F5F8" }}
      >
        {userBase64Image ? (
          <div
            className="bg-center bg-contain bg-no-repeat rounded-full relative"
            style={{
              backgroundImage: `url(${userBase64Image})`,
              minWidth: "87px",
              height: "87px",
              border: "1px solid #ededed",
            }}
          ></div>
        ) : (
          <div
            className="rounded-full relative flex items-center justify-center"
            style={{
              backgroundColor: userData?.userImage ? "white" : "pink",
              textTransform: "uppercase",
              minWidth: "87px",
              height: "87px",
              border: "1px solid #ededed",
              fontSize: "2rem",
            }}
          >
            {GetFirstChar(userData?.fullName)}
          </div>
        )}

        <div className="ml-3">
          <div
            className="font-semibold text-h2 flex items-center mb-2 break-all"
            style={{ color: "#383A65" }}
          >
            {userData?.fullName ? userData?.fullName : "-"}
            {userData?.locked === 1 ? (
              <div className="cursor-pointer">
                <Popover content={hoverContent} trigger="hover">
                  <img
                    src="/images/icons/lock.svg"
                    alt="lock"
                    className="ml-2 lockimage"
                  />
                </Popover>
              </div>
            ) : (
              ""
            )}
          </div>
          <>
            <div
              className="text-h1 font-medium my-2 break-all"
              style={{ color: "#8892A5" }}
            >
            {userData?.Id ? userData?.Id : "-"}
            </div>
            <div
              style={{
                backgroundColor: userData?.Status === 1 ? "#84D49D" : "#dbdae0",
                borderRadius: "15px",
                padding: "2px 10px",
              }}
              className="text-xs font-medium text-white inline"
            >
              {userData?.Status === 1 ? "Active" : "Inactive"}
            </div>
          </>
        </div>
      </div>
      <div className="pt-5 px-8 border-b-2" style={{ borderColor: "#F3F5F8" }}>
        <div className="flex items-center " style={{ marginBottom: "22px" }}>
          <img
            src="/images/icons/user.svg"
            alt="user"
            style={{ height: "38px", width: "38px" }}
          />
          <div
            className="ml-3 font-medium text-h1"
            style={{ color: "#383A65" }}
          >
            Basic details
          </div>
        </div>
        <ItemDetail label="Full name" itemValue={userData?.fullName} />
          <ItemDetail
            label="User roles"
            itemValue={
              userData?.roles?.length
                ? userData?.roles
                    .map((item) => {
                      return (
                        getNameById(item?.roleId, roleList) +
                        (item?.isDefault ? "(Default)" : "")
                      );
                    })
                    ?.join(" , ")
                : "-"
            }
          />
          <ItemDetail
            label="Organizations"
            itemValue={
              userData?.organizations?.length
                ? userData?.organizations
                    .map((item) => {
                      return (
                        getNameById(item?.orgId, organizationsList) +
                        (item?.isDefault ? "(Default)" : "")
                      );
                    })
                    .join(" , ")
                : "-"
            }
          />
      </div>
      <div className="pt-5 px-8">
        <div className="flex items-center " style={{ marginBottom: "22px" }}>
          <div
            className="flex justify-center items-center rounded-full"
            style={{
              backgroundColor: "#F3F5F8",
              width: "38px",
              height: "38px",
            }}
          >
            <img src="/images/icons/graph.svg" alt="user" />
          </div>
          <div
            className="ml-3 font-medium text-h1"
            style={{ color: "#383A65" }}
          >
            User data
          </div>
        </div>
        <ItemDetail label="User Type" itemValue={userData?.TypeName} />
        <ItemDetail label="Card Id" itemValue={userData?.loginId} />
        <ItemDetail label="Is Approver" itemValue={userData?.approver === 1 ? "Yes" : "No"} />
          {userData?.locked === 1 ? (
            <Row>
              <Col span={13}>
                <ItemDetail
                  label="Last invalid login attempt"
                  itemValue={FormatDateLocal(
                    userData?.lastInvalidLoginAttempt,
                    "-"
                  )}
                />
              </Col>
              <Col span={11}>
                <ItemDetail
                  label="Invalid login attempts"
                  itemValue={userData?.invalidLoginAttempt}
                />
              </Col>
            </Row>
          ) : (
            <ItemDetail
              label="Last login"
              itemValue={FormatDateLocal(userData?.lastLogin, "-")}
            />
          )}
      </div>
    </div>
  );
};
