import React from "react";
import { Drawer } from "antd";
import { DataTypes } from "./DataTable";
import { ItemDetail } from "./ItemDetail";
import { FormatDateLocal, ReplaceCharToStrigify, } from "./helpers";
import { SidePanelTitle } from "./SidePanelTitle";

export const SidePanelDisplay = (props) => {
    const {
        listData,
        headerData,
        customAction,
        panelIcon,
        panelTitle,
        panelWidth,
        panelVisible,
        panelBody,
        onCancelClick,
    } = props;

    const getDataTypeFormat = (type, data) => {
        if (type === DataTypes.JSON) {
            return ReplaceCharToStrigify(data);
        } else if (type === DataTypes.DATE) {
            return FormatDateLocal(data);
        } else if (type === DataTypes.YESNO) {
            return data ? "Yes" : "No";
        } else if (type === DataTypes.DAYS) {
            return data ? data + " day(s)" : "-";
        } else if (type === DataTypes.MINUTES) {
            return data ? data + " minute(s)" : "-";
        } else if (type === DataTypes.SIGNAL) {
            return parseInt(data) ? parseInt(data) + " db" : "-";
        } else if (type === DataTypes.PERCENTAGE) {
            return parseInt(data) ? parseInt(data) + " %" : "-";
        } else if (type === DataTypes.METERS) {
            return parseFloat(data) ? parseFloat(data) + " meters(s)" : "-";
        } else {
            return data ? data : "-";
        }
    };
    const getCommonBody = () => {
        if (headerData?.length > 0 && Object.keys(listData)?.length > 0) {
            return (
                <div>
                    <div className="mb-5">
                        {headerData?.map((headerItem) => {
                            return (
                                <ItemDetail
                                    key={headerItem?.dataIndex}
                                    label={headerItem?.type === "icon" ? "Status" : headerItem.title}
                                    className={headerItem.dataIndex === "Message" ? listData?.StatusName?.toLowerCase() + '-text' : ""}
                                    itemValue={
                                        listData[headerItem?.dataIndex]
                                            ? getDataTypeFormat(
                                                headerItem.type,
                                                listData[headerItem?.dataIndex]
                                            )
                                            : "-"
                                    }
                                />
                            );
                        })}
                    </div>
                </div>
            );
        }
        return (" ");
    };

    return (
        <Drawer
            visible={panelVisible}
            className={"sidepanel-section"}
            onClose={typeof onCancelClick === "function" ? onCancelClick : ()=>{}}
            width={panelWidth ? panelWidth : 420}
            bodyStyle={{ padding: 0 }}
            closable={false}
            destroyOnClose={true}
            contentWrapperStyle={{
                borderTopLeftRadius: "24px",
                borderBottomLeftRadius: "24px",
                overflow: "hidden",
            }}
            maskClosable={true}
            zIndex={10}
        >
            <SidePanelTitle
                panelIcon={panelIcon}
                panelTitle={panelTitle}
                onActionClick={onCancelClick}
                customAction={customAction}
            />

            <div
                className={panelBody ? "no-pad-panel-content  custom-scrollbar" : "panel-content custom-scrollbar"}
                style={{
                    height: `calc(100vh - 95px)`,
                    // marginBottom: `${panelFooter ? "10px" : "0"}`,
                    marginTop: "30px",
                    overflow: "auto",
                    paddingTop: 0,
                }}
            >
                {panelBody ? panelBody : getCommonBody()}
            </div>

        </Drawer>
    );
};