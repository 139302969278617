import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "antd";

import { AppContext } from "../../Core/store/app-context";

import { CustomInput } from "../../Core/common/CustomInput";
import { CustomSwitchToggle } from "../../Core/common/CustomSwitchToggle";
import { CustomTextarea } from "../../Core/common/CustomTextarea";
import { CustomModal } from "../../Core/common/CustomModal";
import { SidePanelFooter } from "../../Core/common/SidePanelFooter";
import { SelectDropdown } from "../../Core/common/SelectDropdown";

export const RuleChange = (props) => {
  const { itemId, getRecordList, hideChangeDrawer } = props;
  const ctx = useContext(AppContext);

  const defaultListData = {
    Name: "",
    Description: "",
    ObjectTypeId: null,
    TypeId: null,
    AlarmMin: 0,
    AlarmMax: 0,
    WarningMin: 0,
    WarningMax: 0,
    ToleranceMins: 0,
    NotReportedMins: 0,
    MinBatteryLevel: 0,
    Severity: null,
    IsSystem: 0,
    Status: 0,
  };

  const [listAddData, setListAddData] = useState(defaultListData);

  const [categoryList, setCategoryList] = useState([]);
  const [ruleTypeList, setRuleTypeList] = useState([]);
  const [severityList, setSeverityList] = useState([]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      await getCategoryList();
      await getSeverityList();
      if (itemId) {
        await getItemData();
      } else {
        setListAddData({ ...defaultListData });
      }
    }
    fetchData();
  }, [itemId]);

  useEffect(() => {
    if (listAddData?.Id) {
      getRuleTypeList(listAddData?.ObjectTypeId);
    }
  }, [listAddData?.Id]);

  const getItemData = async () => {
    const response = await ctx.HttpGet("/Rule", {
      id: itemId,
    });
    if(response) {
      setListAddData({
        ...response,
        Severity: response.Severity ? response.Severity : 0,
      })
    }
  };

  const getCategoryList = async () => {
    const response = await ctx.HttpGet("/Rule/objecttype");
    setCategoryList(response);
  };

  const getRuleTypeList = async (id) => {
    const response = await ctx.HttpGet("/Rule/ruletype", {
      categoryId: id,
    });
    setRuleTypeList(response);
  };
  const getSeverityList = async () => {
    const response = await ctx.HttpGet("/Rule/alertseverity");
    setSeverityList(response);
  };

  const onPrimaryClick = () => {
    if (validate()) {
      if (itemId) {
        setShowUpdateModal(true);
      } else {
        setShowAddModal(true);
      }
    }
  };

  const validate = () => {
    if (listAddData.Name === "" || listAddData.Name.trim() === "") {
      ctx.showToastAlert({ type: "error", message: "Please enter name" });
      return false;
    } else if (listAddData.ObjectTypeId === null) {
      ctx.showToastAlert({ type: "error", message: "Please select category" });
      return false;
    } else if (listAddData.TypeId === null) {
      ctx.showToastAlert({
        type: "error",
        message: "Please select rule type/usecase",
      });
      return false;
    } else if (listAddData.Severity === null) {
      ctx.showToastAlert({ type: "error", message: "Please select severity" });
      return false;
    }
    return true;
  };

  const createRule = async () => {
    setModalButtonLoading(true);

    let payload = { ...listAddData };

    const response = await ctx.HttpPost("/Rule", payload);
    if (response) {
      getRecordList();
      ctx.showToastAlert({ type: "success", message: response });
      hideChangeDrawer();
      setListAddData({ ...defaultListData });
    }
    setShowAddModal(false);
    setModalButtonLoading(false);
  };

  const updateRule = async () => {
    setModalButtonLoading(true);

    let payload = { ...listAddData, Id: itemId };

    const response = await ctx.HttpPut("/Rule", payload);
    if (response) {
      getRecordList();
      ctx.showToastAlert({ type: "success", message: response });
      hideChangeDrawer();
      setListAddData({ ...defaultListData });
    }
    setShowUpdateModal(false);
    setModalButtonLoading(false);
  };

  return (
    <>
      <div className="px-7 pt-3">
        <CustomInput
          label="Rule name"
          inputValue={listAddData?.Name}
          onChange={(value) => setListAddData({ ...listAddData, Name: value })}
          isRequired={true}
          maxlength={50}
          onKeyDown={(value) => {
            if (value.keyCode === 8 || value.keyCode === 46) {
              return;
            }
            if (listAddData.Name.length >= 49) {
              ctx.showToastAlert({
                type: "error",
                message: "You cannot enter more than 50 characters",
              });
            }
          }}
        />
        <CustomTextarea
          label="Description"
          inputValue={listAddData?.Description}
          rows="4"
          onChange={(value) =>
            setListAddData({ ...listAddData, Description: value })
          }
          maxlength={100}
          onKeyDown={(value) => {
            if (value.keyCode === 8 || value.keyCode === 46) {
              return;
            }
            if (listAddData?.Description.length >= 99) {
              ctx.showToastAlert({
                type: "error",
                message: "You cannot enter more than 100 characters",
              });
            }
          }}
        />
        <SelectDropdown
          dataList={listAddData?.ObjectTypeId}
          optionsList={categoryList}
          isRequired={true}
          mode="single"
          optionKeyName="Id"
          optionValueName="Id"
          optionDisplayName="Value"
          label="category"
          value={
            listAddData?.ObjectTypeId !== null
              ? listAddData?.ObjectTypeId.toString()
              : null
          }
          onChange={(value, key) => {
            setListAddData({
              ...listAddData,
              ObjectTypeId: parseInt(value),
              TypeId: null,
            });
            getRuleTypeList(key.key);
          }}
          disabled={itemId ? true : false}
        />
        <SelectDropdown
          dataList={listAddData?.TypeId}
          optionsList={ruleTypeList}
          isRequired={true}
          mode="single"
          optionKeyName="Id"
          optionValueName="Id"
          optionDisplayName="Value"
          label="rule type/usecase"
          value={
            listAddData?.TypeId !== null
              ? listAddData?.TypeId?.toString()
              : null
          }
          onChange={(value, key) => {
            setListAddData({
              ...listAddData,
              TypeId: parseInt(value),
            });
          }}
          disabled={
            listAddData?.ObjectTypeId !== null ? (itemId ? true : false) : true
          }
        />
        <CustomSwitchToggle
          label="Status"
          switchValue={listAddData?.Status}
          onText="Active"
          offText="Inactive"
          onChange={(checked) =>
            setListAddData({
              ...listAddData,
              Status: checked ? 1 : 0,
            })
          }
          checked={listAddData.Status}
          className="mb-6"
        />
        <SelectDropdown
          dataList={listAddData?.Severity}
          optionsList={severityList}
          isRequired={true}
          mode="single"
          optionKeyName="Id"
          optionValueName="Id"
          optionDisplayName="Value"
          label="severity"
          value={
            listAddData?.Severity !== null
              ? listAddData?.Severity?.toString()
              : null
          }
          onChange={(value, key) => {
            setListAddData({
              ...listAddData,
              Severity: parseInt(value),
            });
          }}
        />
        {parseInt(listAddData.TypeId) === 908 ||
        parseInt(listAddData.TypeId) === 909 ||
        parseInt(listAddData.TypeId) === 810 ||
        (listAddData.TypeId > 1 && listAddData.TypeId < 100) ||
        parseInt(listAddData.TypeId) === 100 ||
        parseInt(listAddData.TypeId) === 102 ? (
          <div className="font-poppins text-sub0 font-medium leading-tight bg-white mb-4">
            Rule Configuration
          </div>
        ) : (
          ""
        )}

        {parseInt(listAddData.TypeId) === 908 ? (
          <CustomInput
            label="Battery level minimum"
            maxlength={3}
            inputValue={parseInt(listAddData?.MinBatteryLevel) || 0}
            onChange={(value) =>
              setListAddData({
                ...listAddData,
                MinBatteryLevel: parseInt(value),
              })
            }
          />
        ) : parseInt(listAddData.TypeId) === 909 ||
          parseInt(listAddData.TypeId) === 810 ? (
          <Row>
            <Col span={18}>
              <CustomInput
                label={
                  parseInt(listAddData.TypeId) === 810
                    ? "Missing duration"
                    : "Not reporting duration"
                }
                maxlength={6}
                inputValue={parseInt(listAddData?.NotReportedMins) || 0}
                onChange={(value) =>
                  setListAddData({
                    ...listAddData,
                    NotReportedMins: parseInt(value),
                  })
                }
              />
            </Col>
            <Col span={5} className="ml-auto">
              <div
                className="input-with-label-in-textbox font-poppins p-3 text-h1 font-medium"
                style={{ color: "#8892A5", height: "45px" }}
              >
                Mins
              </div>
            </Col>
          </Row>
        ) : listAddData.TypeId > 1 && listAddData.TypeId < 100 ? (
          <>
            <CustomInput
              label="alarm minimum"
              inputValue={parseInt(listAddData?.AlarmMin) || 0}
              maxlength={6}
              onChange={(value) =>
                setListAddData({
                  ...listAddData,
                  AlarmMin: parseInt(value),
                })
              }
            />
            <CustomInput
              label="warning minimum"
              inputValue={parseInt(listAddData?.WarningMin) || 0}
              maxlength={6}
              onChange={(value) =>
                setListAddData({
                  ...listAddData,
                  WarningMin: parseInt(value),
                })
              }
            />
            <CustomInput
              label="warning maximum"
              inputValue={parseInt(listAddData?.WarningMax) || 0}
              maxlength={6}
              onChange={(value) =>
                setListAddData({
                  ...listAddData,
                  WarningMax: parseInt(value),
                })
              }
            />
            <CustomInput
              label="alarm maximum"
              inputValue={parseInt(listAddData?.AlarmMax) || 0}
              maxlength={6}
              onChange={(value) =>
                setListAddData({
                  ...listAddData,
                  AlarmMax: parseInt(value),
                })
              }
            />
          </>
        ) : parseInt(listAddData.TypeId) === 100 ||
          parseInt(listAddData.TypeId) === 102 ? (
          <Row>
            <Col span={18}>
              <CustomInput
                label="Tolerance"
                inputValue={parseInt(listAddData?.ToleranceMins) || 0}
                maxlength={6}
                onChange={(value) =>
                  setListAddData({
                    ...listAddData,
                    ToleranceMins: parseInt(value),
                  })
                }
              />
            </Col>
            <Col span={5} className="ml-auto">
              <div
                className="input-with-label-in-textbox font-poppins p-3 text-h1 font-medium"
                style={{ color: "#8892A5", height: "45px" }}
              >
                Mins
              </div>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </div>

      <SidePanelFooter
        secondaryLabel={"Cancel"}
        onCancelClick={() => {
          hideChangeDrawer();
          setListAddData({ ...defaultListData });
        }}
        primaryLabel={"Apply"}
        onPrimaryClick={() => onPrimaryClick()}
      />

      {/* Add Rule */}
      <CustomModal
        showModal={showAddModal}
        titleText="Add Rule"
        messageText={`Are you sure you want to add ${listAddData.Name}?`}
        handleCancel={() => {
          setShowAddModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={createRule}
        isLoading={modalButtonLoading}
      />

      {/* Update Rule */}
      <CustomModal
        showModal={showUpdateModal}
        titleText="Update Rule"
        messageText={`Are you sure you want to update the changes made to ${listAddData.Name}?`}
        handleCancel={() => {
          setShowUpdateModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={updateRule}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
