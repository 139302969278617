import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Table } from "antd";

import { AppContext } from "../../../Core/store/app-context";

import { SelectDropdown } from "../../../Core/common/SelectDropdown";
import { CustomInput } from "../../../Core/common/CustomInput";
import { useParams } from "react-router-dom";
import { CustomModal } from "../../../Core/common/CustomModal";
import { SidePanelFooter } from "../../../Core/common/SidePanelFooter";

export const RuleAssociateChange = (props) => {
  const { getRecordList, hideChangeDrawer } = props;
  const ctx = useContext(AppContext);

  const { ruleId } = useParams();

  const defaultListData = {
    ruleId: ruleId,
    available: [],
  };

  const [listAddData, setListAddData] = useState(defaultListData);

  const [associateRuleList, setAssociateRuleList] = useState([]);
  const [assetTypeList, setAssetTypeList] = useState([]);

  const [associateName, setAssociateName] = useState("");
  const [associateType, setAssociateType] = useState("");
  const [associateId, setAssociateId] = useState("");

  //Consent
  const [showAddModal, setShowAddModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);

  useEffect(() => {
    getAssetTypeList();
  }, []);

  useEffect(() => {
    if (associateName || associateType || associateId) {
      getAssociateListSearch();
    }
  }, [associateName, associateType, associateId]);

  const getAssetTypeList = async () => {
    const response = await ctx.HttpGet("/Rule/associate/type", {
      ruleId: ruleId,
    });
    setAssetTypeList(response);
  };
  const getAssociateListSearch = async (
    name = associateName,
    type = associateType,
    id = associateId
  ) => {
    const response = await ctx.HttpGet("/Rule/associate/search", {
      ruleId: ruleId,
      name: name,
      type: type,
      id: id,
    });
    setAssociateRuleList(response?.available);
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "Type",
    },
    {
      title: "Name",
      dataIndex: "Name",
    },
    {
      title: "Id",
      dataIndex: "Id",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setListAddData({ ...listAddData, available: selectedRows });
    },
  };

  const validate = () => {
    if (
      (!associateName || !associateId || !associateType) &&
      listAddData?.available.length === 0
    ) {
      ctx.showToastAlert({
        type: "error",
        message: "Please select at least one association",
      });
      return false;
    }
    return true;
  };

  const onPrimaryClick = () => {
    if (validate()) {
      setShowAddModal(true);
    }
  };

  const createAssociate = async () => {
    setModalButtonLoading(true);

    setListAddData({ ...listAddData, ruleId: ruleId });
    const response = await ctx.HttpPost("/Rule/associate", listAddData);
    if (response) {
      getRecordList();
      ctx.showToastAlert({ type: "success", message: response });
      hideChangeDrawer();
      setListAddData({ ...defaultListData });
      setAssociateName("");
      setAssociateId("");
      setAssociateType("");
      setAssociateRuleList([]);
    }
    setShowAddModal(false);
    setModalButtonLoading(false);
  };

  return (
    <>
      <div className="px-7">
        <div className="font-poppins text-h1 font-semibold leading-normal text-pickled-bluewood pb-3">
          Select criteria
        </div>
        <Row className="justify-between">
          <Col span={6}>
            <CustomInput
              label="Id"
              inputValue={associateId}
              debounceInput={true}
              allowClear={true}
              onChange={(value) => {
                setAssociateId(value);
              }}
            />
          </Col>
          <Col span={11}>
            <CustomInput
              label="Name"
              inputValue={associateName}
              debounceInput={true}
              allowClear={true}
              onChange={(value) => {
                setAssociateName(value);
              }}
            />
          </Col>
          <Col span={6}>
            <SelectDropdown
              dataList={associateType !== "" ? associateType : null}
              optionsList={assetTypeList}
              mode="single"
              optionKeyName="Id"
              optionValueName="Id"
              optionDisplayName="Value"
              label="Type"
              value={associateType !== "" ? associateType : null}
              onChange={(value, key) => {
                setAssociateRuleList([]);
                setAssociateType(value);
              }}
              disabled={assetTypeList.length ? false : true}
            />
          </Col>
        </Row>

        <div>
            <Table
              className="flat-table flat-table-white-bg column-direction-righttoleft flat-table-direction-rtl-with-scroll"
              rowSelection={{
                type: "checkbox",
                preserveSelectedRowKeys: true,
                ...rowSelection,
              }}
              columns={columns}
              scroll={{ y: "calc(100vh - 310px)" }}
              dataSource={associateRuleList}
              pagination={false}
              rowKey="Id"
            />
        </div>
      </div>

      <SidePanelFooter
        panelWidth={650}
        secondaryLabel={"Cancel"}
        onCancelClick={() => {
          hideChangeDrawer();
          setListAddData({ ...defaultListData });
          setAssociateName("");
          setAssociateId("");
          setAssociateType("");
          setAssociateRuleList([]);
        }}
        primaryLabel={"Apply"}
        onPrimaryClick={() => onPrimaryClick()}
      />
      {/* Add Associate Rule */}
      <CustomModal
        showModal={showAddModal}
        titleText="Add Associate Rule"
        messageText={`Are you sure you want to add associate rule?`}
        handleCancel={() => {
          setShowAddModal(false);
        }}
        confirmButtonText={`Yes`}
        handleConfirm={createAssociate}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
