import React from "react";
import { Select, Tag } from "antd";
import { SearchBox } from "./SearchBox";
import { FirstLetterUppercase } from "./helpers";
const { Option } = Select;

export const SelectDropdown = (props) => {
  const {
    dataList,
    label,
    optionsList,
    mode,
    showSearch,
    showArrow,
    suffixIcon,
    removeIcon,
    onChange,
    optionKeyName,
    optionValueName,
    optionDisplayName,
    value,
    onDropdownVisibleChange,
    className,
    onSearchInput,
    floatingLabel,
    placeholderOption,
    disabled,
    isRequired,
    isRadioSelection,
    onSelect,
    isDefaultSelected,
    dataListIdKeyName,
  } = props;

  const tagRender = (props) => {
    let keyNameToCheck = dataListIdKeyName ? dataListIdKeyName : 'Id';
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    let isChecked = false;
    dataList.forEach(item=>{
      if (item[keyNameToCheck] === value && item.isDefault) {
        isChecked = true;
      } 
    })

    return (
      <Tag
        closable={closable}
        onClose={onClose}
        onMouseDown={onPreventMouseDown}
        className="relative mr-2 font-poppins text-xs font-medium leading-normal text-regent-gray custom-select-dropdown ant-select-multiple ant-select-lg  ant-select-selection-item"
      >
          <input
            type="radio"
            className="mr-1 select-radio-dropdown relative"
            value={value}
            checked={isChecked}
            name={isDefaultSelected}
            id={value}
            onChange={(e) => {
              onSelect(e.target.value);
            }}
          />
          <label htmlFor={value} className="cursor-pointer">{label}</label>          
      </Tag>
    );
  }

  return (
    <div
      className={`${floatingLabel !== false ? "relative" : ""} mb-6 ${
        disabled ? "opacity-50" : ""
      }`}
    >
      {(typeof dataList === "number" && dataList !== null) ||
      dataList?.length ? (
        <div
          className={`font-poppins z-10 ${
            floatingLabel !== false
              ? "absolute text-sub0 font-medium leading-tight bg-white text-regent-gray "
              : "text-h1 font-semibold leading-normal text-pickled-bluewood pb-2"
          }`}
          style={{
            top: floatingLabel !== false ? "-9px" : "",
            left: floatingLabel !== false ? "9px" : "",
            padding: floatingLabel !== false ? "2px" : "",
          }}
        >
          {isRequired ? (
            <span className="mr-1 error-text" style={{ fontSize: "14px" }}>
              *
            </span>
          ) : (
            ""
          )}
          {label ? FirstLetterUppercase(label) : "Select Option"}
        </div>
      ) : (
        ""
      )}
      <Select
        onInputKeyDown={(event)=>{
          if (showSearch) {
            event.preventDefault();
          }
        }}
        suffixIcon={
          suffixIcon ? (
            suffixIcon
          ) : (
            <img src="/images/icons/tickDropdown.svg" alt="dropdownimage" />
          )
        }
        size="large"
        className={`custom-select-dropdown custom-placeholder alert-filter-open-time-dropdown rounded-lg font-poppins text-xs font-medium leading-normal text-daisy-bush w-full ${
          (typeof dataList === "number" && dataList !== null) ||
          dataList?.length
            ? mode === "multiple" && floatingLabel !== false
              ? "input-with-label-in-textbox pt-3"
              : "input-with-label-in-textbox"
            : "bg-white-lilac"
        } ${mode === "single" ? "single-select-dropdown" : ""} ${className} ${
          isRequired ? "custom-required-placeholder" : ""
        } ${showSearch ? 'search-enabled' : ''}`}
        style={{
          height:
            (typeof dataList === "number" && dataList !== null) ||
            dataList?.length > 0
              ? !floatingLabel !== false && mode === "single"
                ? "45px"
                : "auto"
              : "45px",
        }}
        placeholder={label ? FirstLetterUppercase(label) : "Select Option"}
        onChange={(value, key) => {
          onChange(value, key);
        }}
        mode={mode ? mode : "single"}
        showSearch={showSearch ? showSearch : false}
        showArrow={showArrow === false ? false : true}
        disabled={disabled}
        tagRender={isRadioSelection ? tagRender : null}
        removeIcon={
          removeIcon ? (
            removeIcon
          ) : (
            <img src="/images/icons/x-mark-delete.svg" alt="delete" />
          )
        }
        value={value}
        onDropdownVisibleChange={(isOpen) => {
          if (typeof onDropdownVisibleChange === "function") {
            onDropdownVisibleChange(isOpen);
          }
        }}
        dropdownRender={(menu) =>
          showSearch === true ? (
            <div>
              <div className="custom-select-dropdown-option-inside-search">
                <SearchBox
                  onChange={(value) => {
                    onSearchInput(value);
                  }}
                />
              </div>
              {menu}
            </div>
          ) : (
            menu
          )
        }
      >
        {mode === "single" ? (
          <Option selected disabled style={{ display: "none" }}>
            {isRequired ? (
              <span className="mr-1 error-text" style={{ fontSize: "14px" }}>
                *
              </span>
            ) : (
              ""
            )}
            {placeholderOption === "hide-option-selected" ? "" : "Select"}{" "}
            {label.toLowerCase()}
          </Option>
        ) : (
          ""
        )}
        {optionsList?.length &&
          optionsList.map((singleEle) => (
            <Option
              key={singleEle[optionKeyName]}
              value={isRadioSelection ? singleEle[optionKeyName] : singleEle[optionValueName]}
              className={`flex justify-between py-1 items-center flex-row-reverse ${
                mode === "multiple"
                  ? "custom-select-dropdown-with-checkbox"
                  : ""
              }`}
            >
              <div className="font-poppins text-xs font-medium leading-normal text-regent-gray flex items-center">
                <span
                  className="options-checkbox"
                  style={{
                    width: "20px",
                    height: "21px",
                    border: "1px solid #E0E0E3",
                    marginRight: "10px",
                    display: "none",
                  }}
                ></span>
                <span style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth : "300px"
              }}>{singleEle[optionDisplayName]}</span>
              </div>
            </Option>
          ))}
      </Select>
    </div>
  );
};
