import React, { useState, useEffect, useContext } from "react";

import { Layout } from "../../Core/layout";
import { DataTable, getClickableItemIndex } from "../../Core/common/DataTable";
import { AppContext } from "../../Core/store/app-context";
import { FeatureTypes } from "../../Core/store/app-feature";
import { CustomModal } from "../../Core/common/CustomModal";
import { GetTimezoneOffset } from "../../Core/common/helpers";
import { OptionsDropdown } from "../../Core/common/OptionsDropdown";
import { SidePanelChange } from "../../Core/common/SidePanelChange";
import { SidePanelDisplay } from "../../Core/common/SidePanelDisplay";
import { SidePanelPreferences } from "../../Core/common/SidePanelPreferences";

import { DeviceChange } from "./DeviceChange";

export const DeviceList = (props) => {
  const ctx = useContext(AppContext);

  const [pageSize, setPageSize] = useState(10);
  const [offsetValue, setOffsetValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [showUpdateDrawer, setShowUpdateDrawer] = useState(false);
  const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
  const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

  const [columnList, setColumnList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");
  const [listData, setListData] = useState({});

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);

  useEffect(() => {
      async function fetchData() {
          // You can await here
          await getRecordList();
      }
      fetchData();
  }, []);

  const getRecordList = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    offset = offsetValue,
    limit = pageSize
  ) => {
    let queryParams = {
      search: searchBy,
      sortBy: sortBy,
      orderBy: orderBy,
      offset: offset,
      limit: limit,
      utcOffset: GetTimezoneOffset(),
    };
    const response = await ctx.HttpGetList("/Device/list", queryParams);
    if (response) {
      setRecordList(response.Data);
      setTotalCount(response.Count);
      setColumnList(response.Headers);
      setSearchPlaceholder(response.searchPlaceholder);
    }
  };

  const getItemData = async (record) => {
    const response = await ctx.HttpGet("/Device", { id: record.Id, appId: record.AppID });
    if (response) {
        setListData(response);
    }
  };

  // add click event if user have view access
  if (ctx.acl(FeatureTypes.Devices, 'GetDevice') && columnList?.length > 1) {
      columnList[getClickableItemIndex(columnList)]["onClick"] = (record) => {
          getItemData(record);
          setShowDisplayDrawer(true);
      }
  }

  const handleOptionMenuItemClick = (clickedItem) => {
    setShowDisplayDrawer(false);
    if (clickedItem === "Update") {
      setShowUpdateDrawer(true);
    }
    else if (clickedItem === "Remove") {
      setShowDeleteModal(true);
    }
    else if (clickedItem === "Reset") {
      setShowResetModal(true);
    }
  };

  const deleteDevice = async () => {
    setModalButtonLoading(true);
    const response = await ctx.HttpDelete("/Device", { id: listData.Id, appId: listData.AppID });
    if (response) {
      ctx.showToastAlert({ type: "success", message: response, });
      getRecordList();
    }
    setShowDeleteModal(false);
    setModalButtonLoading(false);
  };

  const resetDevice = async () => {
    setModalButtonLoading(true);
    const response = await ctx.HttpPost("/Device/resetDevice", { id: listData.Id, appId: listData.AppID });
    if (response) {
      ctx.showToastAlert({ type: "success", message: response, });
    }
    setShowResetModal(false);
    setModalButtonLoading(false);
  };

  // add action items as per role access
  const getActionButtonItems = (record = {}) => {
    let actionButtonItems = [];
    let _deviceType = Object.keys(record).length ? record?.Type?.toLocaleLowerCase() : listData?.Type?.toLocaleLowerCase();
      if (ctx.acl(FeatureTypes.Devices, "PostResetDevice") && _deviceType && _deviceType !== "web") {
      actionButtonItems.push("Reset");
    }
    if (ctx.acl(FeatureTypes.Devices, "PutDevice")) {
      actionButtonItems.push("Update");
    }
    if (ctx.acl(FeatureTypes.Devices, "DeleteDevice")) {
      actionButtonItems.push("Remove");
    }
    return actionButtonItems;
  }

    return (
    <>
      <Layout
        page="devices"
        id={FeatureTypes.Devices}
        searchOnModule={FeatureTypes.Devices}
        searchPlaceholder={searchPlaceholder}
        onSearchChange={(value) => {
          setSearchValue(value);
          setOffsetValue(1);
          getRecordList(value, null, null, 1);
        }}
        showFilter={false}
      >
        <DataTable
          showActionButton={getActionButtonItems().length ? true : false}
          getActionButtonItems={getActionButtonItems}
          onActionButtonClick={(record) => {
            setListData(record);
          }}
          actionButtonItemClick={(clickedItem) => {
            handleOptionMenuItemClick(clickedItem);
          }}
          dataSource={recordList}
          columns={columnList}
          totalRecords={totalCount}
          pageSize={pageSize}
          currentPage={offsetValue}
          rowKey={"Id"}
          onUserPreferenceClick={() => {
            setShowPreferenceDrawer(true);
          }}
          onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
            let sortOrderValue = "";
            if (sorterOptions && sorterOptions.order) {
              sortOrderValue =
                sorterOptions.order === "ascend" ? "ASC" : "DESC";
              setOrderByValue(sortOrderValue);
              setSortByValue(sorterOptions.columnKey);
            }
            setPageSize(pageSizeOptions.pageSize);
            setOffsetValue(pageSizeOptions.current);
            getRecordList(
              searchValue,
              sorterOptions.columnKey,
              sortOrderValue,
              pageSizeOptions.current,
              pageSizeOptions.pageSize
            );
          }}
        />
      </Layout>

        <SidePanelChange
            panelIcon={"devices"}
            panelTitle={"Update Device"}
            panelVisible={showUpdateDrawer}
            panelBody={(
                <DeviceChange
                    itemId={listData?.Id}
                    itemAppId={listData?.AppID}
                    getRecordList={getRecordList}
                    hideChangeDrawer={() => {
                        setShowUpdateDrawer(false);
                        setListData({});
                    }}
                />
            )}
        />

        <SidePanelDisplay
            listData={listData}
            headerData={columnList}
            panelIcon={"devices"}
            panelTitle={"Device Details"}
            panelVisible={showDisplayDrawer}
            customAction={(
                  <OptionsDropdown
                      actionButtonItems={getActionButtonItems()}
                      actionButtonItemClick={(clickedItem) => {
                          handleOptionMenuItemClick(clickedItem);
                      }}
                  />
            )}
            onCancelClick={() => {
                setShowDisplayDrawer(false);
                setListData({});
            }}
        />

        <SidePanelPreferences
            module={"/Device/list"}
            panelIcon={"devices"}
            panelVisible={showPreferenceDrawer}
            onCancelClick={() => {
                setShowPreferenceDrawer(false);
            }}
            onChangeClick={() => {
                setShowPreferenceDrawer(false);
                getRecordList(searchValue, sortByValue, orderByValue, offsetValue);
            }}
        />

      {/* Delete Device */}
      <CustomModal
        showModal={showDeleteModal}
        titleText="Remove Device"
        messageText={`Are you sure you want to remove device ${listData?.Id}?`}
        handleCancel={() => {
          setShowDeleteModal(false);
        }}
        confirmButtonText={"Yes"}
        handleConfirm={deleteDevice}
        isLoading={modalButtonLoading}
      />

      {/* Reset Device */}
      <CustomModal
        showModal={showResetModal}
        titleText="Reset Device"
        messageText={`Are you sure you want to reset device ${listData?.Id}?`}
        handleCancel={() => {
          setShowResetModal(false);
        }}
        confirmButtonText={"Yes"}
        handleConfirm={resetDevice}
        isLoading={modalButtonLoading}
      />
    </>
  );
};
