import React, { useState } from "react";
import { Dropdown, Menu } from "antd";
import { CustomModal } from "./CustomModal";

let staticMessageText = "Are you sure you want to export?";

export const ExportButton = (props) => {
  const { fileTypes, handleExportClick, disabled, confirmationModal, titleText } = props;
  const [modalVisibility, setModalVisibility] = useState(false);
  const [downloadFileType, setDownloadFileType] = useState(null);
  const [isExportFullData, setIsExportFullData] = useState(false);
  const [messageText, setMessageText] = useState(staticMessageText);

  const exportOptions = (
    <Menu className="rounded-lg floating-profile-menu p-0">
      {fileTypes?.map((file) => (
        <Menu.Item
          key={file.type}
          className="px-5"
          disabled={file.disable && file.disable === true ? true : false}
          onClick={() => {
            setModalVisibility(true);
            setDownloadFileType(file.type);
            setIsExportFullData(file.fullData);
            if(file.message) {
              setMessageText(file.message);
            } else {
              setMessageText(staticMessageText);
            }
          }}
        >
          <div className={`body-color2 text-sm font-medium py-1`}>
            {file.type?.toUpperCase()}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  if (!fileTypes && fileTypes?.length === 0) {
    return <div>Please pass fileTypes</div>;
  }
  return (
    <>
      {fileTypes?.length === 1 ? (
        <div
          className={`inline-flex ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
          style={{
            borderRadius: "7px",
            backgroundColor: "#4e2c90",
            boxShadow: "rgb(0 0 0 / 10%) 0px 2px 6px",
            padding: "8px 16px",
            color: "#fff",
          }}
          onClick={() => {
            if(!disabled) {
            setModalVisibility(true);
            setDownloadFileType(fileTypes[0].type);
          }}}
        >
          <div className="my-auto pr-1">
            <img src="/images/icons/export-white-icon.svg" alt="export" />
          </div>
          <div
            className={`text-sm font-medium font-poppins leading-normal white-color my-auto break-all mx-2`}
            style={{ padding: "0px 7px" }}
          >
            Export
          </div>
        </div>
      ) : (
        <Dropdown
          overlay={exportOptions}
          className={disabled ? "cursor-not-allowed" : "cursor-pointer"}
          trigger={["click"]}
          disabled={disabled}
        >
          <div
            className={`inline-flex`}
            style={{
              borderRadius: "7px",
              backgroundColor: "#4e2c90",
              boxShadow: "rgb(0 0 0 / 10%) 0px 2px 6px",
              padding: "8px 16px",
              color: "#fff",
            }}
          >
            <div className="my-auto pr-1">
              <img src="/images/icons/export-white-icon.svg" alt="export" />
            </div>
            <div
              className={`text-sm font-medium font-poppins leading-normal white-color my-auto break-all mx-2`}
              style={{ padding: "0px 7px" }}
            >
              Export
            </div>
          </div>
        </Dropdown>
      )}
      {confirmationModal ? (
        <CustomModal
          showModal={modalVisibility}
          titleText={`${titleText ? titleText : downloadFileType?.toUpperCase() + " export"}`}
          messageText={messageText}
          handleCancel={() => {
            if(isExportFullData) {
              handleExportClick(downloadFileType, false);
            }
            setModalVisibility(false);
          }}
          confirmButtonText={`Yes`}
          cancelButtonText={`No`}
          handleConfirm={() => {
            handleExportClick(downloadFileType, isExportFullData ? true : false);
            setModalVisibility(false);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};
