import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { FirstLetterUppercase } from "./helpers";

let debounceTimeout = null;

export const CustomInput = (props) => {
  const {
    label,
    type,
    placeholder,
    inputValue,
    onChange,
    maxlength,
    onKeyDown,
    floatingLabel,
    disabled,
    debounceInput,
    allowClear,
    readOnly,
    isRequired
  } = props;
  const [inputDebounceValue, setInputDebounceValue] = useState(null);

  useEffect(()=> {
    if(inputValue !== inputDebounceValue && debounceInput === true) {
      setInputDebounceValue(inputValue);
    }
  },[inputValue])

  const debounceInputEffect = (value) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setInputDebounceValue(value);
    debounceTimeout = setTimeout(() => {
      if(type === "number" && (value.length >= maxlength + 1)){
        return; 
      }
      onChange(value);
    }, 500);
  };

  return (
    <div className={`${floatingLabel !== false ? "relative mb-6" : ""}`}>
      {label && (debounceInput === true ? inputDebounceValue : inputValue || inputValue === 0) ? (
        <div
          className={`font-poppins ${
            floatingLabel !== false
              ? "absolute text-sub0 font-medium leading-tight bg-white text-regent-gray"
              : "font-semibold leading-normal text-h1 text-pickled-bluewood"
          }`}
          style={{
            top: floatingLabel !== false ? "-9px" : "",
            left: floatingLabel !== false ? "9px" : "",
            padding: floatingLabel !== false ? "2px" : "",
            zIndex: floatingLabel !== false ? "2" : "auto",
            backgroundImage: disabled
              ? "linear-gradient(#fff, whitesmoke)"
              : "",
          }}
        >
          {isRequired ? <span className="mr-1 error-text" style={{ fontSize: "14px" }}>*</span> : ""}
          {FirstLetterUppercase(label)}
        </div>
      ) : (
        ""
      )}
      <div
        className={`flex ${
          label ? (floatingLabel !== false ? "z-0" : "pt-2 pb-5") : ""
        } ${inputValue === 0 ? "" : isRequired && !inputValue  ? "input-div custom-required-placeholder relative" : ""}`}
      >
        <Input
          type={type ? type : "text"}
          value={debounceInput === true ? inputDebounceValue : inputValue}
          readOnly={readOnly}
          min={type === 'number' ? "0" : ""}
          onChange={(e) => {
            if (debounceInput === true) {
              debounceInputEffect(e.target.value);
            } else {
              if(type === "number" && (e.target.value.length >= maxlength + 1)){
                return; 
              }
              onChange(e.target.value);
            }
          }}
          style={{
            height: "45px",
            color: disabled ? "rgba(0, 0, 0, 0.25)" : "#383A65",
            backgroundColor: disabled ? "#f5f5f5" : "#fff",
            cursor: disabled ? "not-allowed" : "text",
          }}
          size="large"
          placeholder={
            label
              ? FirstLetterUppercase(label)
              : FirstLetterUppercase(placeholder)
          }
          className={`font-poppins font-medium ${
            floatingLabel !== false
              ? "input-with-label-in-textbox p-3 text-h1 leading-tight w-full"
              : "text-xs rounded-lg leading-normal"
          }`}
          disabled={disabled}
          maxLength={maxlength}
          allowClear={allowClear}
          onKeyDown={(e) => {
            if (typeof onKeyDown === "function") {   
              onKeyDown(e.target.value, e);
            }
          }}
          onKeyPress={(e) => {
            if (type === "number" && (e.key === '-' || e.key === '+' || e.key === 'e')) {
                e.preventDefault();
            }
          }}
        />
      </div>
    </div>
  );
};
