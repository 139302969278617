import React from "react";

import { Drawer } from "antd";
import { SidePanelTitle } from "./SidePanelTitle";

export const SidePanelChange = (props) => {

    const {
        panelIcon,
        panelTitle,
        panelWidth,
        panelVisible,
        panelBody,
    } = props;

    return (
        <Drawer
            visible={panelVisible}
            className={"sidepanel-section"}
            onClose={true}
            destroyOnClose={true}
            width={panelWidth ? panelWidth : 420}
            bodyStyle={{ padding: 0 }}
            closable={false}
            contentWrapperStyle={{
                borderTopLeftRadius: "24px",
                borderBottomLeftRadius: "24px",
                overflow: "hidden",
            }}
            maskClosable={false}
            zIndex={10}
        >
            <SidePanelTitle
                panelIcon={panelIcon}
                panelTitle={panelTitle}
            />

            <div
                className={panelBody ? "no-pad-panel-content  custom-scrollbar" : "panel-content custom-scrollbar"}
                style={{
                    height: `calc(100vh - 143px)`,
                    marginTop: "30px",
                    overflow: "auto",
                    paddingTop: 0,
                }}
            >
                {panelBody}
            </div>

        </Drawer>
    );
};