import React, { useState, useEffect, useContext } from "react";

import { Layout } from "../../Core/layout";
import { AppContext } from "../../Core/store/app-context";
import { DataTable, ExpandableColumn, getClickableItemIndex } from "../../Core/common/DataTable";
import { CustomModal } from "../../Core/common/CustomModal";
import { FeatureTypes } from "../../Core/store/app-feature";
import { GetTimezoneOffset } from "../../Core/common/helpers";
import { OptionsDropdown } from "../../Core/common/OptionsDropdown";
import { SidePanelChange } from "../../Core/common/SidePanelChange";
import { SidePanelDisplay } from "../../Core/common/SidePanelDisplay";
import { SidePanelPreferences } from "../../Core/common/SidePanelPreferences";

import { OrganizationChange } from "./OrganizationChange";
import { OrganizationDisplay } from "./OrganizationDisplay";

export const OrganizationList = (props) => {
  const ctx = useContext(AppContext);
  let isTableExpandable = true;
    const [pageSize, setPageSize] = useState(10);
    const [offsetValue, setOffsetValue] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [sortByValue, setSortByValue] = useState("");
    const [orderByValue, setOrderByValue] = useState("");
    const [showAddDrawer, setShowAddDrawer] = useState(false);
    const [showUpdateDrawer, setShowUpdateDrawer] = useState(false);
    const [showDisplayDrawer, setShowDisplayDrawer] = useState(false);
    const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);

    const [columnList, setColumnList] = useState([]);
    const [recordList, setRecordList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [searchPlaceholder, setSearchPlaceholder] = useState("Search");

    const [itemId, setItemId] = useState(null);
    const [parentId, setParentId] = useState(null);
    const [itemName, setItemName] = useState(null);

    // Consent
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [modalButtonLoading, setModalButtonLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            // You can await here
            await getRecordList();
        }
        fetchData();
    }, [searchValue]);

    const getRecordList = async (
        searchBy = searchValue,
        sortBy = sortByValue,
        orderBy = orderByValue,
        offset = offsetValue,
        limit = pageSize
    ) => {
        let queryParams = {
            search: searchBy,
            sortBy: sortBy,
            orderBy: orderBy,
            offset: offset,
            limit: limit,
            utcOffset: GetTimezoneOffset(),
        };
        const response = await ctx.HttpGetList("/Organization/list", queryParams);
        if (response) {
            setRecordList(response.Data);
            setTotalCount(response.Count);
            setColumnList(response.Headers);
            setSearchPlaceholder(response.searchPlaceholder);
        }
    };

  // add click event if user have view access
    if (ctx.acl(FeatureTypes.Organizations, 'GetOrganization') && columnList?.length > 1) {
        // below code is for expandable column merging (required wherever we are using expandable table)
        if (columnList[0]) {
            let clickabledIndex = getClickableItemIndex(columnList);
            columnList[0]["width"] = "22vw";
            columnList[0]["title"] = columnList[clickabledIndex]['title'];
            columnList[0]["sorter"] = columnList[clickabledIndex]['sorter'];
            columnList[0].render = (text, record) => (
                <ExpandableColumn
                    record={record}
                    columnList={columnList}
                    acl={ctx.acl(FeatureTypes.Organizations, "GetOrganization")}
                    handleColumnClick={handleColumnClick}
                    statusIcon={`/images/icons/api-${record.StatusName}-status-organization-icon.svg`}
                />
            );
            columnList[clickabledIndex].render = () => {}
            columnList[clickabledIndex]['width'] = "0"
        }
    }

  // add action items as per role access
    const getActionButtonItems = () => {
        let actionButtonItems = [];
        if (ctx.acl(FeatureTypes.Organizations, "PostOrganization")) {
            actionButtonItems.push("Add");
        }
        if (ctx.acl(FeatureTypes.Organizations, "PutOrganization")) {
            actionButtonItems.push("Update");
        }
        if (ctx.acl(FeatureTypes.Organizations, "DeleteOrganization")) {
            actionButtonItems.push("Remove");
        }
        return actionButtonItems;
    };

    const handleColumnClick = (record) => {
        if (ctx.acl(FeatureTypes.Organizations, "GetOrganization")) {
            setItemId(record.Id);
            setItemName(record.name);
            setShowDisplayDrawer(true);
            setParentId(record.Id);
        }
    };

    const handleOptionMenuItemClick = (clickedItem) => {
        setShowDisplayDrawer(false);
        if (clickedItem === "Update") {
            setShowUpdateDrawer(true);
        }
        else if (clickedItem === "Add") {
            setShowAddDrawer(true);
        }
        else if (clickedItem === "Remove") {
            setShowDeleteModal(true);
        }
    };

    const deleteOrganization = async () => {
        setModalButtonLoading(true);
        const response = await ctx.HttpDelete("/Organization", { id: itemId });
        if (response) {
            ctx.showToastAlert({ type: "success", message: response });
            let offset = offsetValue;
            if (recordList.length === 1 && offset > 1) {
                offset = offset - 1;
                setOffsetValue(offset);
            }
            getRecordList(searchValue, sortByValue, orderByValue, offset);
        }
        setShowDeleteModal(false);
        setModalButtonLoading(false);
    };

    return (
        <>
            <Layout
                page="organizations"
                id={FeatureTypes.Organizations}
                searchOnModule={FeatureTypes.Organizations}
                searchPlaceholder={searchPlaceholder}
                onSearchChange={(value) => {
                    setSearchValue(value);
                    getRecordList(value);
                }}
                showFilter={false}
                pageTitleButton={ctx.acl(FeatureTypes.Organizations, "PostOrganization")}
                buttonLabel={"Add Organization"}
                buttonOnClick={() => {
                    setItemId(null);
                    setParentId(null);
                    setShowAddDrawer(true);
                }}
            >
                <DataTable
                    className="expandable-dynamic-table expandable-with-status-icon"
                    columns={columnList}
                    dataSource={recordList}
                    totalRecords={totalCount}
                    isTableExpandable={isTableExpandable}
                    rowKey="Id"
                    pageSize={pageSize}
                    currentPage={offsetValue}
                    showActionButton={getActionButtonItems().length ? true : false}
                    getActionButtonItems={getActionButtonItems}
                    pagination={false}
                    onActionButtonClick={(record, clickedItem = null) => {
                        setItemId(record.Id);
                        setItemName(record.name);
                        if (clickedItem === "Add") {
                            setParentId(record.Id);
                        }
                    }}
                    actionButtonItemClick={(clickedItem) => {
                        handleOptionMenuItemClick(clickedItem);
                    }}
                    showUserPreference={true}
                    onUserPreferenceClick={() => {
                        setShowPreferenceDrawer(true);
                    }}
                    onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
                        let sortOrderValue = "";
                        // below code is for expandable column sort after column merging (required wherever we are using expandable table)
                        if (isTableExpandable && sorterOptions.columnKey === columnList[0].dataIndex) {
                            sorterOptions.columnKey = columnList[1].dataIndex;
                        }
                        if (sorterOptions && sorterOptions.order) {
                            sortOrderValue =
                                sorterOptions.order === "ascend" ? "ASC" : "DESC";
                            setOrderByValue(sortOrderValue);
                            setSortByValue(sorterOptions.columnKey);
                        }
                        setPageSize(pageSizeOptions.pageSize);
                        setOffsetValue(pageSizeOptions.current);
                        getRecordList(
                            searchValue,
                            sorterOptions.columnKey,
                            sortOrderValue,
                            pageSizeOptions.current,
                            pageSizeOptions.pageSize
                        );
                    }}
                />
            </Layout>


            <SidePanelChange
                panelIcon={"organizations"}
                panelTitle={"Add Organization"}
                panelVisible={showAddDrawer}
                panelBody={(
                    <OrganizationChange
                        parentId={parentId}
                        getRecordList={getRecordList}
                        hideChangeDrawer={() => {
                            setShowAddDrawer(false);
                            setItemId(null);
                        }}
                    />
                )}
            />

            <SidePanelChange
                panelIcon={"organizations"}
                panelTitle={"Update Organization"}
                panelVisible={showUpdateDrawer}
                panelBody={(
                    <OrganizationChange
                        itemId={itemId}
                        getRecordList={getRecordList}
                        hideChangeDrawer={() => {
                            setShowUpdateDrawer(false);
                            setItemId(null);
                        }}
                    />
                )}
            />

            <SidePanelDisplay
                headerData={columnList}
                panelIcon={"organizations"}
                panelTitle={"Organization Details"}
                panelVisible={showDisplayDrawer}
                panelBody={(
                    <OrganizationDisplay
                        itemId={itemId}
                    />
                )}
                customAction={(
                    <OptionsDropdown
                        actionButtonItems={getActionButtonItems()}
                        actionButtonItemClick={(clickedItem) => {
                            handleOptionMenuItemClick(clickedItem);
                        }}
                    />
                )}
                onCancelClick={() => {
                    setShowDisplayDrawer(false);
                    setItemId(null);
                }}
            />

            <SidePanelPreferences
                module={"/Organization/list"}
                panelIcon={"organizations"}
                panelVisible={showPreferenceDrawer}
                onCancelClick={() => {
                    setShowPreferenceDrawer(false);
                }}
                onChangeClick={() => {
                    setShowPreferenceDrawer(false);
                    getRecordList(searchValue, sortByValue, orderByValue, offsetValue);
                }}
            />

            {/* Delete Organization */}
            <CustomModal
                showModal={showDeleteModal}
                titleText={`Remove Organization`}
                messageText={`Are you sure you want to remove the ${itemName}?`}
                handleCancel={() => {
                    setShowDeleteModal(false);
                }}
                confirmButtonText={"Yes"}
                handleConfirm={deleteOrganization}
                isLoading={modalButtonLoading}
            />
        </>
    );
};