import React, { useEffect, useContext, useState } from "react";
import { Checkbox, Col, Collapse, Radio, Row } from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { AppContext } from "../../Core/store/app-context";

import { SearchBox } from "../../Core/common/SearchBox";
import { CustomTabs } from "../../Core/common/CustomTabs";
import { CustomModal } from "../../Core/common/CustomModal";
import { CustomInput } from "../../Core/common/CustomInput";
import { CustomTextarea } from "../../Core/common/CustomTextarea";
import { CustomSwitchToggle } from "../../Core/common/CustomSwitchToggle";
import { SelectDropdown } from "../../Core/common/SelectDropdown";
import { SidePanelFooter } from "../../Core/common/SidePanelFooter";

const { Panel } = Collapse;

export const RoleChange = (props) => {
  const ctx = useContext(AppContext);
    const {
        itemId,
        getRecordList,
        hideChangeDrawer,
    } = props;
    const defaultListData = {
      name: "",
      description: "",
      Status: 1,
      roleType: null,
      privileged: 0,
      appId: null,
      inactivityTimeout: 0,
      sessionTimeout: 0,
      sessionLimit: 0,
      loginAttempts: 0,
      loginTolerance: 0,
      loginLockDays: 0,
      modules: [],
    }

  const [roleTypeList, setRoleTypeList] = useState([]);
  const [roleAppIdList, setRoleAppIdList] = useState([]);

  const [activeTab, setActiveTab] = useState("roleName");
  const [primaryText, setPrimaryText] = useState("Next");
  const [secondaryText, setSecondaryText] = useState("Cancel");

  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);

  const [listEditData, setListEditData] = useState({...defaultListData});

  //privilege variable
  const [moduleList, setModuleList] = useState([]);
  const [rawModuleList, setRawModuleList] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedModuleNames, setSelectedModuleNames] = useState([]);
  const [selectedFunctionNames, setSelectedFunctionNames] = useState([]);
  const [serviceId, setServiceId] = useState(null);
  const [backgroundIntervalMinutes, setBackgroundIntervalMinutes] = useState({});
  const [moduleOrderChangeCount, setModuleOrderChangeCount] = useState(0);

    useEffect(() => {
        async function fetchData() {
            // You can await here
            await getRoleTypeList();
            if (itemId) {
                await getItemData();
            }
            else {
                setRoleAppIdList([]);
                setListEditData({ ...defaultListData });
            }
        }
        setSelectedModules([]);
        setSelectedModuleNames([]);
        setSelectedFunctionNames([]);
        setServiceId(null);
        fetchData();

    }, [itemId]);

    useEffect(() => {
        if (listEditData?.appId) {
            getModuleList();
        }
    }, [listEditData.appId]);

    useEffect(() => {
        if (serviceId) {
            setSelectedModulesForEditView();
        }
    }, [serviceId]);

    useEffect(() => {
        if (primaryText === "Apply") {
            setActiveTab("modules");

            setSecondaryText("Previous");
        }
        else {
            setActiveTab("roleName");

            setSecondaryText("Cancel");
        }
    }, [primaryText]);

    const getItemData = async () => {
        const response = await ctx.HttpGet("/Role", { id: itemId });
        if (response) {
            setListEditData(response);

            await getAppList(response.roleType)
        }
    };

    const getRoleTypeList = async () => {
        if (roleTypeList?.length) {
            return;
        }
        const response = await ctx.HttpGet("/Role/types");
        if (response) {
            setRoleTypeList(response);
        }
    };

    const getAppList = async (id = 1) => {
        const response = await ctx.HttpGet("/Role/app/list", { roleType: id });
        if (response) {
            setRoleAppIdList(response);
        }
    };

    const getModuleList = async () => {
        setModuleList([]);
        const response = await ctx.HttpGet("/Role/module/list", { roleType: listEditData.roleType, appID: listEditData.appId });
        if (response?.length > 0) {
            // set display order based on index
            response.forEach((item, index) => {
                item["displayOrder"] = index + 1;
            });
            setModuleList(response);
            setRawModuleList(response);
            setServiceId(response[0].serviceId);
        }
    };

    const validate = () => {
        if (listEditData.roleType === null) {
            ctx.showToastAlert({ type: "error", message: "Please select role type" });
            return false;
        } else if (listEditData.name === "" || listEditData.name.trim() === "") {
            ctx.showToastAlert({ type: "error", message: "Please enter role name" });
            return false;
        } else if (listEditData?.appId === null) {
            ctx.showToastAlert({ type: "error", message: "Please select appId" });
            return false;
        } else if (
            listEditData.loginAttempts > 0 &&
            listEditData.loginTolerance <= 0  ) {
            ctx.showToastAlert({ type: "error", message: "Invalid login attempt. Time period is required" });
            return false;
        } else if (
            listEditData.loginAttempts <= 0 &&
            listEditData.loginTolerance > 0   ) {
            ctx.showToastAlert({ type: "error", message: "Invalid login attempt. Count is required" });
            return false;
        } else if (listEditData?.privileged === null) {
            ctx.showToastAlert({ type: "error", message: "Please select privilege status" });
            return false;
        }
        return true;
    };

    const createRole = async () => {
        setModalButtonLoading(true);

        setListEditData({ ...listEditData, Id: null });
        const response = await ctx.HttpPost("/Role", listEditData);
        if (response) {
            getRecordList();
            ctx.showToastAlert({ type: "success", message: response });
            hideChangeDrawer();
            setListEditData({ ...defaultListData });
            setPrimaryText('Next');
        }
        setShowAddModal(false);
        setModalButtonLoading(false);
    };

    const updateRole = async () => {
        setModalButtonLoading(true);

        setListEditData({ ...listEditData, Id: itemId });
        const response = await ctx.HttpPut("/Role", listEditData);
        if (response) {
            getRecordList();
            ctx.showToastAlert({ type: "success", message: response });
            hideChangeDrawer();
            setListEditData({ ...defaultListData });
            setPrimaryText('Next');
        }
        setShowUpdateModal(false);
        setModalButtonLoading(false);
    };

    const onSecondaryClick = () => {
        if (secondaryText === "Cancel") {
            hideChangeDrawer();
            setListEditData({ ...defaultListData });
        }
        else if (secondaryText === "Previous") {
            setPrimaryText("Next");
        }
    };
    const onPrimaryClick = () => {
        if (primaryText === "Apply") {
            if (validate()) {
                if (itemId)
                    setShowUpdateModal(true);
                else
                    setShowAddModal(true);
            }
        }
        else if (primaryText === "Next") {
            if (validate()) {
                setPrimaryText("Apply");
            }
        }
    };

    const updateFunctionNames = (selectedModules) => {
        let functionsToUpdate = [];
        let modulesToUpdate = [];
        selectedModules.forEach((module) => {
            if (module && module.objects.length) {
                module.objects.forEach((item) => {
                    functionsToUpdate.push(module.moduleId + "-" + item.functionId);
                });
            }
            rawModuleList.forEach((moduleItem) => {
                if (
                    moduleItem?.moduleId === module?.moduleId &&
                    module?.objects?.length
                ) {
                    modulesToUpdate.push(module?.moduleId);
                }
            });
        });
        setSelectedModuleNames(modulesToUpdate);
        setSelectedFunctionNames(functionsToUpdate);
    };

  const setSelectedModulesForEditView = () => {
    if (!listEditData.modules.length) {
      return;
    }
    let enteredBackgroundServiceFunctions = {};
    // reset displayOrder of moduleList to, will set it based on opened role
    rawModuleList.forEach((item) => {
      item.displayOrder = 99;
    });

    // map moduleType and display order before updating;
    listEditData?.modules.forEach((roleModuleId) => {
      rawModuleList?.forEach((item) => {
        if (item?.moduleId === roleModuleId?.moduleId) {
          roleModuleId["moduleType"] = item.moduleType;
          roleModuleId["moduleIcon"] = item.moduleIcon;
          item["displayOrder"] = roleModuleId.displayOrder;
        }
      });
    });
    // sort module list with displayOrder from retrived data
    let sortedModuleList = rawModuleList.sort(
      (a, b) => a.displayOrder - b.displayOrder
    );
    sortedModuleList.forEach((moduleItem, index) => {
      moduleItem.displayOrder = index + 1;
    });
    listEditData.modules.forEach((item) => {
      if (item.moduleType === 2) {
        item.objects.forEach((objFunction) => {
          // add to array only if value is more than 0
          if (objFunction.intervalMins) {
            enteredBackgroundServiceFunctions[objFunction.functionId] =
              objFunction.intervalMins;
          }
        });
      }
      mapObjectList(item);
    });
    if (Object.keys(enteredBackgroundServiceFunctions).length) {
      setBackgroundIntervalMinutes(enteredBackgroundServiceFunctions);
    }
    updateFunctionNames(listEditData.modules);
  };

  const onCheckChange = (event, checkedItem, type, moduleDetail = null) => {
    if (
      (event.target.checked && moduleDetail?.moduleType !== 2) ||
      (event.target.value && moduleDetail?.moduleType === 2)
    ) {
      if (type === "functionSelect") {
        if (selectedModules.length) {
          let moduleItem = selectedModules.filter(
            (item) => item.moduleId === moduleDetail.moduleId
          );
          if (moduleItem.length) {
            selectedModules.forEach((item) => {
              if (item.moduleId === moduleDetail.moduleId) {
                // check if functionid already exists in objects
                let pos = item.objects
                  .map(function (e) {
                    return e.functionId;
                  })
                  .indexOf(checkedItem.functionId);
                // if exists then update else push
                if (pos >= 0) {
                  item.objects[pos].intervalMins = event.target.value;
                } else {
                  item.objects.push({
                    functionId: checkedItem.functionId,
                    intervalMins:
                      moduleDetail.moduleType !== 2 ? 0 : event.target.value,
                    status: 1,
                  });
                }
              }
            });
          } else {
            selectedModules.push({
              serviceId: serviceId,
              moduleId: moduleDetail.moduleId,
              moduleName: moduleDetail.moduleName,
              displayOrder: moduleDetail.displayOrder,
              status: 1,
              objects: [
                {
                  functionId: checkedItem.functionId,
                  intervalMins:
                    moduleDetail.moduleType !== 2 ? 0 : event.target.value,
                  status: 1,
                },
              ],
            });
          }
        } else {
          selectedModules.push({
            serviceId: serviceId,
            moduleId: moduleDetail.moduleId,
            moduleName: moduleDetail.moduleName,
            displayOrder: moduleDetail.displayOrder,
            status: 1,
            objects: [
              {
                functionId: checkedItem.functionId,
                intervalMins:
                  moduleDetail.moduleType !== 2 ? 0 : event.target.value,
                status: 1,
              },
            ],
          });
        }
      } else if (type === "checkAll") {
        if (selectedModules.length) {
          let moduleItem = selectedModules.filter(
            (item) => item.moduleId === checkedItem.moduleId
          );
          if (moduleItem.length) {
            let checkedItemList = [];
            let selectedItemList = [];
            checkedItem.objects.forEach((item) => {
              checkedItemList.push(item.name);
            });
            selectedModules.forEach((item) => {
              item.objects.forEach((functionItem) => {
                selectedItemList.push(functionItem.functionId);
              });
            });
            let moduleFunctionIdsToAdd = checkedItemList.filter(
              (item) => !selectedItemList.includes(item)
            );
            selectedModules.forEach((item) => {
              if (item.moduleId === checkedItem.moduleId) {
                moduleFunctionIdsToAdd.forEach((functionItem) => {
                  item.objects.push({
                    functionId: functionItem,
                    intervalMins: 0,
                    status: 1,
                  });
                });
              }
            });
          } else {
            mapObjectList(checkedItem);
          }
        } else {
          mapObjectList(checkedItem);
        }
      }
    } else {
      if (type === "functionSelect") {
        selectedModules.forEach((item) => {
          if (item.moduleId === moduleDetail.moduleId) {
            let pos = item.objects
              .map(function (e) {
                return e.functionId;
              })
              .indexOf(checkedItem.functionId);
            if (pos >= 0) {
              item.objects.splice(pos, 1);
              selectedModuleNames.splice(
                selectedModuleNames.indexOf(item.moduleId),
                1
              );
            }
          }
        });
      } else if (type === "checkAll") {
        selectedModules.forEach((item, index) => {
          if (item.moduleId === checkedItem.moduleId) {
            let pos = item.objects
              .map(function (e) {
                return e.functionId;
              })
              .indexOf(checkedItem.moduleId);
            if (pos >= 0) {
              item.objects.splice(pos, 1);
            }
          }
        });

        let pos = selectedModules
          .map(function (e) {
            return e.moduleId;
          })
          .indexOf(checkedItem.moduleId);

        if (pos >= 0) {
          selectedModules.splice(pos, 1);

          selectedModuleNames.splice(
            selectedModuleNames.indexOf(checkedItem.moduleId),
            1
          );
        }
      }
    }
    setSelectedModules(selectedModules);
    setListEditData({
      ...listEditData,
      modules: selectedModules,
    });
    updateFunctionNames(selectedModules);
  };

  const mapObjectList = (checkedItem) => {
    let objectsList = [];
    checkedItem.objects.forEach((item) => {
      objectsList.push({
        functionId: item.functionId,
        intervalMins: checkedItem.moduleType === 2 ? item.intervalMins : 0,
        status: 1,
      });
    });
    selectedModules.push({
      serviceId: serviceId,
      moduleId: checkedItem.moduleId,
      moduleName: checkedItem.moduleName,
      displayOrder: checkedItem.displayOrder,
      status: 1,
      objects: objectsList,
    });
  };

  const searchFromList = (value) => {
    if (value) {
      const newResults = rawModuleList.filter((res) =>
        res.moduleName.toLowerCase().includes(value.toLowerCase())
      );
      setModuleList(newResults);
    } else {
      setModuleList(rawModuleList);
    }
  };

  const handleOrderChange = (direction, elemId, moduleIndex) => {
    // for change on UI - not required now
    // var elementToMove = document.getElementById(elemId);
    // elementToMove.parentElement.insertBefore(elementToMove, direction === 'up' ? elementToMove.previousElementSibling : elementToMove.nextElementSibling.nextElementSibling);

    if (direction === "up") {
      // reduce the display order for clicked item
      moduleList[moduleIndex]["displayOrder"] =
        moduleList[moduleIndex]["displayOrder"] - 1;

      // increase the display order for clicked item
      moduleList[moduleIndex - 1]["displayOrder"] =
        moduleList[moduleIndex - 1]["displayOrder"] + 1;
    } else {
      // increase the display order for clicked item
      moduleList[moduleIndex]["displayOrder"] =
        moduleList[moduleIndex]["displayOrder"] + 1;

      // reduce the display order for clicked item
      moduleList[moduleIndex + 1]["displayOrder"] =
        moduleList[moduleIndex + 1]["displayOrder"] - 1;
    }
    // sort and update module list
    let sortedModuleList = moduleList.sort(
      (a, b) => a.displayOrder - b.displayOrder
    );
    setModuleList(sortedModuleList);

    // update display order of selected module items
    selectedModules.forEach((selectedModuleItem) => {
      sortedModuleList.forEach((moduleItem) => {
        if (selectedModuleItem.moduleId === moduleItem.moduleId) {
          selectedModuleItem.displayOrder = moduleItem.displayOrder;
        }
      });
    });
    setListEditData({
      ...listEditData,
      modules: selectedModules,
    });
    // update this variable to re-render the UI
    setModuleOrderChangeCount(moduleOrderChangeCount + 1);
  };

  const getSelectedFunctionsCount = (moduleToCheck) => {
    let count = 0,
      totalCount = 0;
    let slashElement = (
      <span
        style={{
          fontSize: "9px",
          fontWeight: "900",
          padding: "0 1px",
          position: "relative",
          top: "-1px",
        }}
      >
        /
      </span>
    );
    let selectedModuleFullDetail = rawModuleList.filter(
      (item) => item.moduleId === moduleToCheck
    );
    if (selectedModuleFullDetail.length) {
      totalCount = selectedModuleFullDetail[0].objects.length;
    }
    if (!selectedModules.length) {
      return (
        <span>
          {count}
          {slashElement}
          {totalCount}
        </span>
      );
    }
    let selectedModuleDetail = selectedModules.filter(
      (item) => item.moduleId === moduleToCheck
    );
    if (!selectedModuleDetail.length) {
      return (
        <span>
          {count}
          {slashElement}
          {totalCount}
        </span>
      );
    }
    return (
      <span>
        {selectedModuleDetail[0].objects.length}
        {slashElement}
        {totalCount}
      </span>
    );
  };

  const customPanelHeader = (item, panelKey, index) => {
    const selectedFunctionCount = getSelectedFunctionsCount(item.moduleId);
    return (
      <div className="flex items-center">
        <div className="mr-2 flex flex-col">
          <ArrowUpOutlined
            style={{
              fontSize: 10,
              color: "#4E2C90",
              opacity: index === 0 ? 0.5 : 1,
              pointerEvents: index === 0 ? "none" : "all",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleOrderChange("up", panelKey, index);
            }}
          />
          <ArrowDownOutlined
            style={{
              fontSize: 10,
              color: "#4E2C90",
              opacity: moduleList.length - 1 === index ? 0.5 : 1,
              pointerEvents: moduleList.length - 1 === index ? "none" : "all",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleOrderChange("down", panelKey, index);
            }}
          />
        </div>
        <img
          src={`/images/icons/api-${
            item?.moduleIcon
              ? item?.moduleIcon
              : item?.moduleType === 2
              ? "background-service-icon"
              : "controller-icon"
          }-add-privilege.svg`}
          onError={(event) => {
            event.target.src =
              "/images/icons/api-generic-icon-add-privilege.svg";
          }}
          alt={item?.moduleName ? item?.moduleName : item.moduleId}
          className="mr-2"
        />
        <div className="overflow-hidden">
          {item?.moduleName ? item?.moduleName : item.moduleId}
        </div>
        {item.moduleType !== 2 ? (
          <div
            className={`font-semibold ${selectedFunctionCount ? "" : "hidden"}`}
            style={{
              marginLeft: 11,
              fontSize: "12px",
              backgroundColor: "#F3F5F8",
              color: "#4E2C90",
              width: "fit-content",
              lineHeight: "1.5",
              borderRadius: "30%",
              textAlign: "center",
              padding: "2px 5px",
            }}
          >
            {selectedFunctionCount}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const RoleDetail = () => {
    return (
        <div className="px-7 pt-3">
            <SelectDropdown
                dataList={listEditData.roleType}
                optionsList={roleTypeList}
                isRequired={true}
                mode="single"
                optionKeyName="Id"
                optionValueName="Id"
                optionDisplayName="Value"
                label="Role Type"
                value={
                    listEditData.roleType !== null
                        ? listEditData.roleType?.toString()
                        : null
                }
                onChange={(value, key) => {
                    setListEditData({ ...listEditData, roleType: value, appId: null });
                    getAppList(parseInt(key.key));
                }}
                disabled={itemId ? true : false}
            />
            <CustomInput
                label="Role name"
                isRequired={true}
                inputValue={listEditData?.name}
                onChange={(value) =>
                    setListEditData({ ...listEditData, name: value })
                }
                maxlength={100}
                onKeyDown={(value) => {
                    if (value.keyCode === 8 || value.keyCode === 46) {
                        return;
                    }
                    if (listEditData.name.length >= 99) {
                        ctx.showToastAlert({
                            type: "error",
                            message: "You cannot enter more than 100 characters",
                        });
                    }
                }}
            />
            <CustomTextarea
                label="Description"
                inputValue={listEditData?.description}
                rows="4"
                onChange={(value) =>
                    setListEditData({ ...listEditData, description: value })
                }
                maxlength={200}
                onKeyDown={(value) => {
                    if (value.keyCode === 8 || value.keyCode === 46) {
                        return;
                    }
                    if (listEditData.description.length >= 199) {
                        ctx.showToastAlert({
                            type: "error",
                            message: "You cannot enter more than 200 characters",
                        });
                    }
                }}
            />
            <SelectDropdown
                dataList={listEditData.appId}
                optionsList={roleAppIdList}
                isRequired={true}
                mode="single"
                optionKeyName="Value"
                optionValueName="Value"
                optionDisplayName="Value"
                label="App Id"
                value={listEditData.appId}
                className="custom-select-static-dropdown"
                onChange={(value, key) => {
                    setListEditData({ ...listEditData, appId: value });
                }}
                disabled={itemId ? true : false}
            />
            {listEditData?.roleType?.toString() === "2" ? (
                ""
            ) : (
                <>
                    <Row>
                        <Col span={18}>
                            <CustomInput
                                label="Inactivity timeout"
                                maxlength={6}
                                inputValue={parseInt(listEditData?.inactivityTimeout) || 0}
                                onChange={(value) =>
                                    setListEditData({
                                        ...listEditData,
                                        inactivityTimeout: parseInt(value),
                                    })
                                }
                            />
                        </Col>
                        <Col span={5} className="ml-auto">
                            <div
                                className="input-with-label-in-textbox font-poppins p-3 text-h1 font-medium"
                                style={{ color: "#8892A5", height: "45px" }}
                            >
                                Mins
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18}>
                            <CustomInput
                                label="Session timeout"
                                maxlength={6}
                                inputValue={parseInt(listEditData?.sessionTimeout) || 0}
                                onChange={(value) =>
                                    setListEditData({
                                        ...listEditData,
                                        sessionTimeout: parseInt(value),
                                    })
                                }
                            />
                        </Col>
                        <Col span={5} className="ml-auto">
                            <div
                                className="input-with-label-in-textbox font-poppins p-3 text-h1 font-medium"
                                style={{ color: "#8892A5", height: "45px" }}
                            >
                                Mins
                            </div>
                        </Col>
                    </Row>
                    <CustomInput
                        label="Session limit"
                        maxlength={9}
                        inputValue={parseInt(listEditData?.sessionLimit) || 0}
                        onChange={(value) =>
                            setListEditData({
                                ...listEditData,
                                sessionLimit: parseInt(value),
                            })
                        }
                    />
                    <CustomInput
                        label="Max invalid login attempts"
                        maxlength={9}
                        inputValue={parseInt(listEditData?.loginAttempts) || 0}
                        onChange={(value) =>
                            setListEditData({
                                ...listEditData,
                                loginAttempts: parseInt(value || 0),
                            })
                        }
                    />
                    <Row>
                        <Col span={18}>
                            <CustomInput
                                label="Login tolerance"
                                maxlength={6}
                                inputValue={parseInt(listEditData?.loginTolerance) || 0}
                                onChange={(value) =>
                                    setListEditData({
                                        ...listEditData,
                                        loginTolerance: parseInt(value || 0),
                                    })
                                }
                            />
                        </Col>
                        <Col span={5} className="ml-auto">
                            <div
                                className="input-with-label-in-textbox font-poppins p-3 text-h1 font-medium"
                                style={{ color: "#8892A5", height: "45px" }}
                            >
                                Mins
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18}>
                            <CustomInput
                                label="Login Lock"
                                maxlength={6}
                                inputValue={parseInt(listEditData?.loginLockDays) || 0}
                                onChange={(value) =>
                                    setListEditData({
                                        ...listEditData,
                                        loginLockDays: parseInt(value),
                                    })
                                }
                            />
                        </Col>
                        <Col span={5} className="ml-auto">
                            <div
                                className="input-with-label-in-textbox font-poppins p-3 text-h1 font-medium"
                                style={{ color: "#8892A5", height: "45px" }}
                            >
                                Days
                            </div>
                        </Col>
                    </Row>

                    <div className="flex justify-between mb-6">
                        <div
                            className="font-medium leading-normal"
                            style={{ fontSize: "11px" }}
                        >
                            Is privileged
                        </div>
                        <div className="flex items-center">
                            <Radio.Group
                                onChange={(e) => {
                                    setListEditData({
                                        ...listEditData,
                                        privileged: e.target.value,
                                    });
                                }}
                                value={
                                    listEditData?.privileged ? listEditData?.privileged : 0
                                }
                                options={[
                                    { label: "Yes", value: 1 },
                                    { label: "No", value: 0 },
                                ]}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
  };

  const RolePrivilege = () => {
    return (
      <>
        <div className="flex custom-search px-7">
          <SearchBox
            onChange={(value) => {
              searchFromList(value);
            }}
          />
        </div>
        <div
          className="mt-6 custom-collapse custom-scrollbar"
          style={{
            height: "calc(100vh - 400px)",
            overflowY: "auto",
          }}
        >
          {moduleList.length ? (
            <Collapse
              bordered={false}
              expandIconPosition="right"
              expandIcon={({ isActive }) => (
                <div
                  className="flex items-center justify-center rounded-full"
                  style={{
                    backgroundColor: "#f4f6f5",
                    width: "24px",
                    height: "24px",
                  }}
                >
                  <PlusOutlined rotate={isActive ? 45 : 0} />
                </div>
              )}
            >
              {moduleList?.length &&
                moduleList?.map((item, index) => (
                  <Panel
                    header={customPanelHeader(
                      item,
                      "collapse-module-" + index,
                      index
                    )}
                    key={"collapse-module-" + index}
                    className="text-h1 font-medium mb-3 orderable-collapse"
                    style={{ color: "#383A65" }}
                    id={"collapse-module-" + index}
                  >
                    <div>
                      {item.moduleType === 2 ? (
                        ""
                      ) : (
                        <>
                          {item.objects.length > 0 ? (
                            <Row style={{ padding: "0 4px 0 35px" }}>
                              <Col
                                span={22}
                                style={{ color: "#4E2C90", fontSize: "11px" }}
                              >
                                Select module
                              </Col>
                              <Col span={2} className="text-right">
                                <Checkbox
                                  onChange={(e) => {
                                    onCheckChange(e, item, "checkAll");
                                  }}
                                  checked={selectedModuleNames.includes(
                                    item.moduleId
                                  )}
                                  onClick={(e) => e.stopPropagation()}
                                  value=""
                                />
                              </Col>
                            </Row>
                          ) : (
                            <div className="ant-empty ant-empty-normal">
                              <div className="ant-empty-image">
                                <svg
                                  className="ant-empty-img-simple"
                                  width="64"
                                  height="41"
                                  viewBox="0 0 64 41"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g
                                    transform="translate(0 1)"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <ellipse
                                      className="ant-empty-img-simple-ellipse"
                                      cx="32"
                                      cy="33"
                                      rx="32"
                                      ry="7"
                                    ></ellipse>
                                    <g
                                      className="ant-empty-img-simple-g"
                                      fillRule="nonzero"
                                    >
                                      <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                      <path
                                        d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                        className="ant-empty-img-simple-path"
                                      ></path>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className="ant-empty-description">
                                No Data
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      <div
                        className="roleCheckBox"
                        style={{ padding: "0px 4px 0px 35px" }}
                      >
                        {item.objects.map((object, index) => {
                          return (
                            <div key={"features-" + index}>
                              {item.moduleType === 2 ? (
                                <Row className="mb-3">
                                  <Col span={17}>
                                    <div className="relative">
                                      <div
                                        className="absolute font-poppins text-sub0 font-medium leading-tight text-regent-gray"
                                        style={{
                                          top: "-9px",
                                          left: "9px",
                                          padding: "2px",
                                          backgroundImage:
                                            "linear-gradient(#f7f8fa, white)",
                                        }}
                                      >
                                        {object.name
                                          ? object.name
                                          : object.functionId}
                                      </div>
                                      <input
                                        name="inactivityTimeout"
                                        className="input-with-label-in-textbox text-pickled-bluewood font-poppins p-3 text-h1 font-medium leading-tight bg-white w-full"
                                        onBlur={(e) => {
                                          onCheckChange(
                                            e,
                                            object,
                                            "functionSelect",
                                            item
                                          );
                                        }}
                                        onChange={(e) => {
                                          setBackgroundIntervalMinutes({
                                            ...backgroundIntervalMinutes,
                                            [object.functionId]: e.target.value,
                                          });
                                        }}
                                        value={
                                          backgroundIntervalMinutes[
                                            object.functionId
                                          ]
                                            ? backgroundIntervalMinutes[
                                                object.functionId
                                              ]
                                            : ""
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col span={6} className="ml-auto">
                                    <div
                                      className="input-with-label-in-textbox font-poppins p-3 text-h1 font-medium leading-tight"
                                      style={{ color: "#8892A5" }}
                                    >
                                      Mins
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                <Checkbox
                                  key={index}
                                  onChange={(e) => {
                                    onCheckChange(
                                      e,
                                      object,
                                      "functionSelect",
                                      item
                                    );
                                  }}
                                  checked={selectedFunctionNames.includes(
                                    item.moduleId + "-" + object.functionId
                                  )}
                                  value=""
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  {object.name}
                                </Checkbox>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Panel>
                ))}
            </Collapse>
          ) : (
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 12,
                padding: 30,
                marginTop: 20,
              }}
            >
              <div className="ant-empty ant-empty-normal">
                <div className="ant-empty-image">
                  <svg
                    className="ant-empty-img-simple"
                    width="64"
                    height="41"
                    viewBox="0 0 64 41"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      transform="translate(0 1)"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <ellipse
                        className="ant-empty-img-simple-ellipse"
                        cx="32"
                        cy="33"
                        rx="32"
                        ry="7"
                      ></ellipse>
                      <g className="ant-empty-img-simple-g" fillRule="nonzero">
                        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                        <path
                          d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                          className="ant-empty-img-simple-path"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="ant-empty-description">No Data</div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

    return (
        <>
            <div className="px-7 pb-5"
                style={{ borderColor: "#F3F5F8" }}>
                <div className="font-semibold text-h2" style={{ color: "#383A65" }}>
                    {listEditData?.name ? listEditData?.name : "-"}
                </div>
                <div className="text-h1 font-medium my-2" style={{ color: "#8892A5" }}>
                    {listEditData?.accessPoints
                        ? listEditData?.accessPoints + " access points"
                        : "-"}
                </div>
                <CustomSwitchToggle
                    label=""
                    switchValue={listEditData.Status}
                    onText="Active"
                    offText="Inactive"
                    onChange={(checked) =>
                        setListEditData({ ...listEditData, Status: checked ? 1 : 0 })
                    }
                    checked={listEditData.Status}
                    styleName="greenTextActive"
                    className="flex-row-reverse justify-end"
                />
            </div>
            <CustomTabs
                activeTabs={activeTab}
                currentTab={
                    activeTab.includes("roleName") && !activeTab.includes("modules")
                        ? "roleName"
                        : "modules"
                }
                tabData={[
                    {
                        key: "roleName",
                        name: "Details",
                        topDetails: "calc(100vh - 322px)",
                        content: RoleDetail(),
                    },
                    {
                        key: "modules",
                        name: "Privileges",
                        topDetails: "calc(100vh - 322px)",
                        disabled: activeTab.includes("modules") ? false : true,
                        content: RolePrivilege(),
                    },
                ]}
            />

            <SidePanelFooter
                secondaryLabel={secondaryText}
                onCancelClick={() => onSecondaryClick()}
                primaryLabel={primaryText}
                onPrimaryClick={() => onPrimaryClick()}
            />

            {/* Add Role */}
            <CustomModal
                showModal={showAddModal}
                titleText="Add Role"
                messageText={`Are you sure you want to add a new role ${listEditData?.name}?`}
                handleCancel={() => {
                    setShowAddModal(false);
                }}
                confirmButtonText={"Yes"}
                handleConfirm={createRole}
                isLoading={modalButtonLoading}
            />

            {/* Update Role */}
            <CustomModal
                showModal={showUpdateModal}
                titleText="Update Role"
                messageText={`Are you sure you want to update the changes made to ${listEditData?.name}?`}
                handleCancel={() => {
                    setShowUpdateModal(false);
                }}
                confirmButtonText={"Yes"}
                handleConfirm={updateRole}
                isLoading={modalButtonLoading}
            />
        </>
    );
};
