import React, { useState, useContext, useEffect } from "react";
import { Row, Col } from "antd";

import GooglePlacesAutocomplete, {
    geocodeByAddress, getLatLng,
} from "react-google-places-autocomplete";

import { AppContext } from "../../Core/store/app-context";
import { ImageSelector } from "../../Core/common/ImageSelector";
import { CustomModal } from "../../Core/common/CustomModal";
import { CustomInput } from "../../Core/common/CustomInput";
import { SelectDropdown } from "../../Core/common/SelectDropdown";
import { CustomTooltip } from "../../Core/common/CustomTooltip";
import { CustomTextarea } from "../../Core/common/CustomTextarea";
import { CustomSwitchToggle } from "../../Core/common/CustomSwitchToggle";
import { SidePanelFooter } from "../../Core/common/SidePanelFooter";

export const OrganizationChange = (props) => {
    const ctx = useContext(AppContext);

    const {
        itemId,
        parentId,
        getRecordList,
        hideChangeDrawer,
    } = props;

    const defaultListData = {
        id: "",
        parentId: "",
        name: "",
        type: null,
        code: "",
        description: "",
        selectedAddress: "",
        fullAddress: "",
        address1: "",
        address2: null,
        city: "",
        state: "",
        country: "",
        zip: "",
        latitude: "",
        longitude: "",
        image: "",
        status: 0,
        objectId: "",
        storageLoc: "",
        userFlag: 0,
        edgeFlag: 0
    };

    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [modalButtonLoading, setModalButtonLoading] = useState(false);
    const [listAddData, setListAddData] = useState(defaultListData);

    const [organizationTypeList, setOrganizationTypeList] = useState([]);
    const [organizationBase64Image, setOrganizationBase64Image] = useState("");
    const [hasGoogleMapsAPIKey, setHasGoogleMapsAPIKey] = useState(
        process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY
            ? process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY.length > 0
                ? true : false : false
    );

    useEffect(() => {
        async function fetchData() {
            // You can await here

            await getOrganizationTypeList();
            if (itemId) {
                await getItemData();
            }
        }
        setOrganizationBase64Image("");
        setListAddData({...defaultListData});
        fetchData();
    }, [itemId]);

    const getItemData = async () => {
        const response = await ctx.HttpGet("/Organization", { id: itemId });
        if (response) {
            setListAddData(response);
            if (response.image) {
                setOrganizationBase64Image("data:image/png;base64," + response.image);
            }
            if(response.fullAddress) {
                setListAddData({
                    ...response,
                    selectedAddress: {
                      label: response.fullAddress,
                      value: response.fullAddress,
                    },
                  });
            }
        }
    };

    const getOrganizationTypeList = async () => {
        if (organizationTypeList?.length)
            return;

        const response = await ctx.HttpGet("/Organization/type");
        if (response) {
            setOrganizationTypeList(response);
        }
    }

    const validate = () => {
        if (listAddData?.name === "") {
            ctx.showToastAlert({ type: "error", message: "Please enter name" });
            return false;
        } else if (listAddData?.type === null) {
            ctx.showToastAlert({ type: "error", message: "Please select type of organization" });
            return false;
        } else if (listAddData?.code === "") {
            ctx.showToastAlert({ type: "error", message: "Please enter code" });
            return false;
        }
        return true;
    };

    const onPrimaryClick = () => {
        if (validate()) {
            if (itemId)
                setShowUpdateModal(true);
            else
                setShowAddModal(true);
        }
    };

    const addOrganization = async () => {
        setModalButtonLoading(true);

        let payload = { ...listAddData, Id: null, parentId: parentId };

        const response = await ctx.HttpPost("/Organization", payload);
        if (response) {
            getRecordList();
            ctx.showToastAlert({ type: "success", message: response });
            hideChangeDrawer();
            setOrganizationBase64Image("");
            setListAddData({...defaultListData});
        }
        setShowAddModal(false);
        setModalButtonLoading(false);
    };
    const updateOrganization = async () => {
        setModalButtonLoading(true);

        let payload = { ...listAddData, Id: itemId };

        const response = await ctx.HttpPut("/Organization", payload);
        if (response) {
            getRecordList();
            ctx.showToastAlert({ type: "success", message: response });
            hideChangeDrawer();
            setOrganizationBase64Image("");
            setListAddData({...defaultListData});
        }
        setShowUpdateModal(false);
        setModalButtonLoading(false);
    };

    const handleChange = async (key, value) => {
        let length = value.value.terms.length;
        geocodeByAddress(value.label)
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                setListAddData({
                    ...listAddData,
                    selectedAddress: {
                        label: value.label,
                        value: value.value.description,
                    },
                    fullAddress: value.label,
                    address1: value.value.structured_formatting.main_text,
                    city: value.value.terms[length - 3].value
                        ? value.value.terms[length - 3].value
                        : "",
                    state: value.value.terms[length - 2].value
                        ? value.value.terms[length - 2].value
                        : "",
                    country: value.value.terms[length - 1].value
                        ? value.value.terms[length - 1].value
                        : "",
                    latitude: lat ? parseFloat(lat) : "",
                    longitude: lng ? parseFloat(lng) : "",
                });
            });
    };

    return (
        <>
            <div className="relative custom-scrollbar" style={{
                    height: "calc(100vh - 143px)",
                    overflow: "auto"
            }}>
                <div
                    className="flex items-center pb-5 border-b-2 px-8"
                    style={{ borderColor: "#F3F5F8" }}
                >
                    <div>
                        <div
                            className="font-semibold text-h2 flex items-center mb-2 break-all"
                            style={{ color: "#383A65" }}
                        >
                            {listAddData?.name ? listAddData?.name : "-"}
                        </div>
                        <div
                            className="text-h1 font-medium my-2 overflow-hidden overflow-ellipsis whitespace-nowrap flex"
                            style={{ color: "#8892A5", maxWidth: "360px" }}
                        >
                            Code: {listAddData?.code ? <CustomTooltip
                                title={listAddData?.code}
                            /> : "-"}
                        </div>
                        <CustomSwitchToggle
                            label=""
                            switchValue={listAddData?.Status}
                            onText="Active"
                            offText="Inactive"
                            onChange={(checked) =>
                                setListAddData({ ...listAddData, Status: checked ? 1 : 0 })
                            }
                            checked={listAddData?.Status}
                            className="flex-row-reverse"
                        />
                    </div>
                </div>

                <div className="pt-5 px-8"
                     style={{ borderColor: "#F3F5F8" }}>
                    <div className="flex items-center " style={{ marginBottom: "22px" }}>
                        <img
                            src="/images/icons/user.svg"
                            alt="user"
                            style={{ height: "38px", width: "38px" }}
                        />
                        <div
                            className="ml-3 font-medium text-h1"
                            style={{ color: "#383A65" }}
                        >
                            Basic details
                        </div>
                    </div>
                    <CustomInput
                        label="Name"
                        inputValue={listAddData?.name}
                        onChange={(value) =>
                            setListAddData({ ...listAddData, name: value })
                        }
                        maxlength="100"
                        isRequired={true}
                    />

                    <SelectDropdown
                        dataList={listAddData?.type}
                        optionsList={organizationTypeList}
                        isRequired={true}
                        mode="single"
                        optionKeyName="Id"
                        optionValueName="Id"
                        optionDisplayName="Name"
                        label="Type"
                        value={
                            listAddData?.type !== null ? listAddData.type : null
                        }
                        onChange={(value, key) => {
                            setListAddData({ ...listAddData, type: value });
                        }}
                    />
                    <CustomTextarea
                        label="Description"
                        maxlength={200}
                        inputValue={listAddData?.description}
                        onChange={(value) => {
                            setListAddData({ ...listAddData, description: value });
                        }}
                        onKeyDown={(value) => {
                            if (value.keyCode === 8 || value.keyCode === 46) {
                                return;
                            }
                            if (listAddData?.description?.length >= 199) {
                                ctx.showToastAlert({ type: "error", message: "You cannot enter more than 200 characters" });
                            }
                        }}
                    />
                    <CustomInput
                        label="Code"
                        inputValue={
                            listAddData?.code ? listAddData?.code : ""
                        }
                        onChange={(value) =>
                            setListAddData({ ...listAddData, code: value })
                        }
                        maxlength="50"
                        isRequired={true}
                    />
                    <CustomInput
                        label="Object ID"
                        maxlength={50}
                        inputValue={
                            listAddData?.objectId ? listAddData.objectId : ""
                        }
                        onChange={(value) => {
                            setListAddData({ ...listAddData, objectId: value });
                        }}
                    />
                    <CustomInput
                        label="Storage Location"
                        inputValue={
                            listAddData?.storageLoc ? listAddData?.storageLoc : ""
                        }
                        onChange={(value) =>
                            setListAddData({ ...listAddData, storageLoc: value })
                        }
                        maxlength="50"
                    />

                    {hasGoogleMapsAPIKey ? (
                        <>
                            <div className="relative my-6 custom-address-dropdown">
                                <div
                                    className="absolute font-poppins text-xs font-medium leading-tight bg-white text-regent-gray z-50"
                                    style={{ top: "-9px", left: "9px", padding: "2px" }}
                                >
                                    Address
                                </div>
                                <GooglePlacesAutocomplete
                                    apiKey={process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}
                                    selectProps={{
                                        value: listAddData?.selectedAddress,
                                        onChange: (value) => handleChange("clinicAddress", value),
                                    }}
                                />
                            </div>
                            <CustomInput
                                label="Zip Code"
                                maxlength={7}
                                type="number"
                                inputValue={listAddData?.zip}
                                onChange={(value) =>
                                    setListAddData({ ...listAddData, zip: value })
                                }
                            />
                            <CustomInput
                                label="Country"
                                inputValue={listAddData?.country}
                                onChange={(value) =>
                                    setListAddData({ ...listAddData, country: value })
                                }
                                maxlength="50"
                                disabled={true}
                            />
                            <Col span={24}>
                                <Row type="flex">
                                    <Col span={11} className="relative">
                                        <CustomInput
                                            label="Latitude"
                                            maxlength={18}
                                            inputValue={listAddData?.latitude}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col span={2} />
                                    <Col span={11} className="relative">
                                        <CustomInput
                                            label="Longitude"
                                            maxlength={18}
                                            inputValue={listAddData?.longitude}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    ) : (
                        ""
                    )}

                    <CustomSwitchToggle
                        label="Can Assign Users"
                        switchValue={listAddData?.userFlag}
                        onText="On"
                        offText="Off"
                        onChange={(checked) =>
                            setListAddData({ ...listAddData, userFlag: checked ? 1 : 0 })
                        }
                        checked={listAddData.userFlag}
                    />
                    <CustomSwitchToggle
                        label="Occasionally connected"
                        switchValue={listAddData?.edgeFlag}
                        onText="On"
                        offText="Off"
                        onChange={(checked) =>
                            setListAddData({ ...listAddData, edgeFlag: checked ? 1 : 0 })
                        }
                        checked={listAddData.edgeFlag}
                    />
                    <div className="relative my-6">
                        <ImageSelector
                            selectedBase64Image={organizationBase64Image}
                            setSelectedBase64Image={setOrganizationBase64Image}
                            imageDetails={listAddData}
                            setImageDetails={setListAddData}
                            imageSizeMax="5"
                        />
                    </div>
                </div>

                <SidePanelFooter
                    secondaryLabel={"Cancel"}
                    onCancelClick={() => {
                        hideChangeDrawer();
                        setListAddData({...defaultListData});
                        setOrganizationBase64Image("");
                    }}
                    primaryLabel={"Apply"}
                    onPrimaryClick={() => onPrimaryClick()}
                />
            </div>

            {/* Add Organization */}
            <CustomModal
                showModal={showAddModal}
                titleText={`Add Organization`}
                messageText={`Are you sure you want to add ${listAddData?.name}?`}
                handleCancel={() => {
                    setShowAddModal(false);
                }}
                confirmButtonText={"Yes"}
                handleConfirm={addOrganization}
                isLoading={modalButtonLoading}
            />

            {/* Update Organization */}
            <CustomModal
                showModal={showUpdateModal}
                titleText={`Update Organization`}
                messageText={`Are you sure you want to update the changes made to ${listAddData?.name}?`}
                handleCancel={() => {
                    setShowUpdateModal(false);
                }}
                confirmButtonText={"Yes"}
                handleConfirm={updateOrganization}
                isLoading={modalButtonLoading}
            />
        </>
    );
};
