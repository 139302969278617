import React, { useState, useEffect, useContext } from "react";

import { AppContext } from "../../Core/store/app-context";
import { SelectDropdown } from "../../Core/common/SelectDropdown";

export const RoleFilter = (props) => {
  const ctx = useContext(AppContext);
    const {
        listFilterData,
        setListFilterData
    } = props;

  const [appFilterDropdown, setAppFilterDropdown] = useState([]);
  const [typeFilterDropdown, setTypeFilterDropdown] = useState([]);
  const [statusFilterDropdown, setStatusFilterDropdown] = useState([]);

    useEffect(() => {
        async function fetchData() {
            // You can await here
            await getRoleTypeList();
            await getStatusList();
        }
        fetchData();
    }, []);

    const getStatusList = async () => {
        const response = await ctx.HttpGet("/Role/status");
        if (response) {
            setStatusFilterDropdown(response);
        }
    };

    const getRoleTypeList = async () => {
        const response = await ctx.HttpGet("/Role/types");
        if (response) {
            setTypeFilterDropdown(response);
            getAppList();
        }
    };

    const getAppList = async (id = 1) => {
        const response = await ctx.HttpGet("/Role/app/list", { roleType: id });
        if (response) {
            setAppFilterDropdown(response);
        }
    };

    return (
        <div className="pt-3">
            <SelectDropdown
                dataList={listFilterData?.status}
                optionsList={statusFilterDropdown}
                mode="single"
                optionKeyName="Id"
                optionValueName="Id"
                optionDisplayName="Value"
                label="Select Status"
                value={listFilterData?.status}
                onChange={(value) => {
                    setListFilterData({ ...listFilterData, status: value });
                }}
            />

            <SelectDropdown
                dataList={listFilterData?.roleType}
                optionsList={typeFilterDropdown}
                mode="single"
                optionKeyName="Id"
                optionValueName="Id"
                optionDisplayName="Value"
                label="Type"
                value={listFilterData?.roleType}
                onChange={(value) => {
                    setListFilterData({ ...listFilterData, roleType: value, appId: [] });
                    getAppList(value);
                }}
            />

            <SelectDropdown
                dataList={listFilterData?.appId}
                optionsList={appFilterDropdown}
                mode="multiple"
                optionKeyName="Value"
                optionValueName="Value"
                optionDisplayName="Value"
                label="Select App Id"
                value={listFilterData?.appId}
                onChange={(value) => {
                    setListFilterData({ ...listFilterData, appId: value });
                }}
            />
        </div>
    );
};
