import React, { useState, useEffect, useContext } from "react";

import { AppContext } from "../../Core/store/app-context";
import { SelectDropdown } from "../../Core/common/SelectDropdown";
import { CustomDatePicker } from "../../Core/common/CustomDatePicker";

export const UserFilter = (props) => {
  const ctx = useContext(AppContext);

    const {
        listFilterData,
        setListFilterData,
        roleList
    } = props;

  const [roleFilterDropdown, setRoleFilterDropdown] = useState([]);
  const [typeFilterDropdown, setTypeFilterDropdown] = useState([]);
  const [statusFilterDropdown, setStatusFilterDropdown] = useState([]);

  useEffect(() => {
    setRoleFilterDropdown(roleList);
  }, [roleList]);

  useEffect(() => {
    getUserStatusList();
    getUserTypeList();
  }, []);

  const getUserTypeList = async () => {
    const response = await ctx.HttpGet("/User/types");
    if (response) {
      setTypeFilterDropdown(response);
    }
  };

  const getUserStatusList = async () => {
    const response = await ctx.HttpGet("/User/status");
    if (response) {
      setStatusFilterDropdown(response);
    }
  };

  return (
    <div className="pt-3">
      <SelectDropdown
        dataList={listFilterData?.status}
        optionsList={statusFilterDropdown}
        mode="single"
        optionKeyName="Id"
        optionValueName="Id"
        optionDisplayName="Value"
        label="Select Status"
        value={listFilterData?.status}
        onChange={(value) => {
          setListFilterData({ ...listFilterData, status: value });
        }}
      />

      <SelectDropdown
        dataList={listFilterData?.roles}
        optionsList={roleFilterDropdown}
        mode="multiple"
        optionKeyName="Id"
        optionValueName="Id"
        optionDisplayName="Value"
        label="Select roles"
        showSearch={true}
        value={listFilterData?.roles}
        onChange={(value) => {
          setListFilterData({ ...listFilterData, roles: value });
        }}
        onSearchInput={(value) => {
          let data = roleList.filter((role) =>
            role.Value.toLowerCase().includes(value.toLowerCase())
          );
          setRoleFilterDropdown(data);
        }}
      />

      <SelectDropdown
        dataList={listFilterData?.userType}
        optionsList={typeFilterDropdown}
        mode="single"
        optionKeyName="Id"
        optionValueName="Id"
        optionDisplayName="Value"
        label="Select Type"
        value={listFilterData?.userType}
        onChange={(value) => {
          setListFilterData({ ...listFilterData, userType: value });
        }}
      />

      <CustomDatePicker
        label=""
        startDate={listFilterData.lastLoggedOn}
        startDatePlaceholder="Last logged on"
        onStartDateChange={(date, dateString) => {
          setListFilterData({ ...listFilterData, lastLoggedOn: dateString });
        }}
      />
    </div>
  );
};
